import React, { Component, createRef } from 'react'
import { withRouter } from 'react-router'
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import axios from 'axios';
import Loading from './loading'
import $ from 'jquery';
import checked from '../images/checked.png';
import Cookies from 'universal-cookie';
import error from '../images/redError.png';
import downarrow from '../images/downarrow.svg';
import Searchcountry from './searchCountry'
import SinglePopup from './singlePopup';

class EditMobileOTPModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showEditName: false,
            showEditImage: false,
            errorMsg: '',
            errorFlag: 'd',
            name: '',
            msg: '', msgType: '', alert: '',
            userN: '',
            otp: '',
            enableLoader: false,
            logoutPopup: false,
            lables: constantURL.getLables(),
            countryName: 'IN',
            ipAddress: '127.0.0.1',
            showChooseCountry: false,
            countryDetails: this.props.countryDetails,
            countryCode: this.props.countryDetails.countryCode,
            mobNo: this.props.mobNo,
            gender: sessionStorage.getItem("gender"),
            address: sessionStorage.getItem("address"),
            email: sessionStorage.getItem("emailid"),
            dob: sessionStorage.getItem("dob"),


        }
        this.handleKeyUp = this.handleKeyUp.bind(this);
    }


    componentDidMount() {
        this.getIpData();
        this.getTempMetaInfo();
    }

    getTempMetaInfo() {
        const headers = {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'ip': this.state.ipAddress,
            'msisdn': this.props.mobNo,
        };


        const url = constantURL.PROFILER2_COMBINED_IP + "/v3/getMetaInfo?isWifi=true&gameType=HardyGames&countryCode=" + this.state.countryCode + "&isSocialLogin=false";

        axios.get(url, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data);
                let map = new Map();
                if (data && data.code == 2000) {
                    var metaInfoData = data.data.metaKeyValue;
                    console.log((data.data.metaKeyValue));

                    for (var i = 0; i < metaInfoData.length; i++) {
                        map.set(metaInfoData[i].keyName, metaInfoData[i].valueName);
                    }

                    var jsonText = JSON.stringify(Array.from(map.entries()));
                    sessionStorage.setItem('tempAppId', map.get('APP_ID'))
                    console.log(jsonText)

                    this.sendOtp();

                } else {
                    this.setState({
                        showPopup: true,
                        msgType: 'E',
                        msg: "Error in generating OTP",
                        alert: "Error!"
                    })
                }
            })
            .catch(err => {
                this.setState({
                    showPopup: true,
                    msgType: 'E',
                    msg: "Error in generating OTP",
                    alert: "Error!"
                })
            })

    }


    sendOtp() {
        const headers = {
            'deviceId': constantURL.DEVICE_ID,
            'appid': sessionStorage.getItem('tempAppId'),
            'Content-type': 'application/json',
            'Accept': 'application/json',
        };

        const url = constantURL.PROFILER2_COMBINED_IP + "/v1/sendOtp?username=" + this.state.mobNo + "&type=resend_otp";
        axios.get(url, { headers }).then(response => response.data)
            .then((data) => {
                if (data.code != 2000) {
                    this.setState({
                        showPopup: true,
                        msgType: 'E',
                        msg: "Error in generating OTP",
                        alert: "Error!"
                    })
                }
            })
            .catch(err => {
                this.setState({
                    showPopup: true,
                    msgType: 'E',
                    msg: "Error in generating OTP",
                    alert: "Error!"
                })
            })
    }

    getIpData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        console.log(res.data);
        this.setState({ ipAddress: res.data.IPv4 });
    }



    handleKeyUp(event) {

        const { lables } = this.state;
        this.setState({
            otp: event.target.value,
        });
    }

    validateOtp() {
        // this.props.showMobileNoInMainPage();
        // this.props.closeOtpPopup();


        const headers = {
            'deviceId': constantURL.DEVICE_ID,
            'appid': sessionStorage.getItem('tempAppId'),
            'Content-type': 'application/json',
            'Accept': 'application/json',
        };

        const body = {
            'username': this.state.countryCode + "" + this.state.mobNo,
            'otp': this.state.otp,
        };


        const url = constantURL.PROFILER2_COMBINED_IP + "/v1/validateOtp?username=" + this.state.countryCode + "" + this.state.mobNo + "&otp=" + this.state.otp;
        axios.post(url, body, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data.code)
                if (data.code == 2000) {
                    this.setState({ errorMsg: '' });
                    this.saveDetails();
                } else {
                    this.setState({ errorMsg: 'Invalid OTP' });
                }
            })
            .catch(err => {
                this.setState({ errorMsg: 'Invalid OTP' });
            })

    }


    saveDetails() {
        const { lables } = this.state;

        const headers = {
            'Content-Type': 'application/json',
            'appid': sessionStorage["appid"],
            'access_token': sessionStorage.getItem("accessToken"),

        };
        const data1 = new FormData();
        data1.append('email', this.state.email == 'null' || this.state.email == null ? '' : this.state.email);
        data1.append('address', this.state.address == 'null' || this.state.address == null ? '' : this.state.address);
        data1.append('gender', this.state.gender == 'null' || this.state.gender == null ? '' : this.state.gender);
        data1.append('dateOfBirth', this.state.dob == 'null' || this.state.dob == null ? '' : this.state.dob);
        data1.append('mobileNumber', this.state.mobNo == 'null' || this.state.mobNo == null ? '' : this.state.countryDetails.countryCode + '' + this.state.mobNo);
        data1.append('username', sessionStorage.getItem("username"));

        var url = '';
        if (sessionStorage.getItem("loginType") == "social") {
            url = constantURL.PROFILER_COMBINED_IP + "/v1/updateUserDetailsWithFileSocial";
        }
        else {
            url = constantURL.PROFILER_COMBINED_IP + "/v1/updateUserDetailsWithFile";
        }

        axios.post(url, data1, { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    sessionStorage.setItem("gender", this.state.gender)
                    sessionStorage.setItem("emailid", this.state.email)
                    sessionStorage.setItem("address", this.state.address)
                    if (sessionStorage.getItem("loginType") == "social") {
                        if (this.state.mobNo == 'null' || this.state.mobNo == null) {
                            sessionStorage.setItem("loginType", "social");
                        } else {
                            sessionStorage.setItem("loginType", "normal");
                            sessionStorage.setItem("username", this.state.mobNo);
                            this.getMetaInfo(this.state.mobNo);
                        }
                        sessionStorage.setItem("mobNo", this.state.mobNo)
                    }
                    sessionStorage.setItem("dob", this.state.dob)

                    this.setState({
                        showPopup: true,
                        msgType: 'S',
                        msg: lables.mobileUpdatedSuccessfully? lables.mobileUpdatedSuccessfully :' Mobile number updated successfully.',
                        alert: lables.profileUpdated
                    })
                } else if (data && (data.code == errorConstantURL.ONLY_CHARACTER_CODE)) {
                    this.setState({
                        showPopup: true,
                        msgType: 'E',
                        msg: data.msg,
                        alert: lables.errorInProcessingRequest
                    })

                    // this.props.closeNameEdit('');
                } else if (data && (data.code == errorConstantURL.OFFENSIVE_CODE)) {
                    this.setState({
                        showPopup: true,
                        msgType: 'E',
                        msg: data.msg,
                        alert: lables.errorInProcessingRequest
                    })
                    // this.props.closeNameEdit('');
                } else {
                    this.setState({
                        showPopup: true,
                        msgType: 'E',
                        msg: lables.errorInProcessingRequest,
                        alert: 'Error!'
                    })
                    // this.props.closeNameEdit('');
                }
                this.setState({ enableLoader: false })
            });
    }

    getMetaInfo(msisdn) {

        const headers = {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'ip': this.state.ipAddress,
            'msisdn': msisdn,
        };

        var countryCode = this.state.countryDetails.countryCode;

        const url = constantURL.PROFILER2_COMBINED_IP + "/v3/getMetaInfo?isWifi=true&gameType=HardyGames&countryCode=" + countryCode + "&isSocialLogin=false";

        axios.get(url, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data);
                let map = new Map();
                if (data && data.code == 2000) {
                    var metaInfoData = data.data.metaKeyValue;
                    console.log((data.data.metaKeyValue));

                    for (var i = 0; i < metaInfoData.length; i++) {
                        map.set(metaInfoData[i].keyName, metaInfoData[i].valueName);
                    }

                    var jsonText = JSON.stringify(Array.from(map.entries()));
                    sessionStorage.setItem('metaInfo', jsonText);

                    this.regenerateSession(msisdn)
                } else {
                    window.location.replace(constantURL.APP_LOGIN_URL);
                }
            })
            .catch(err => {
                window.location.replace(constantURL.APP_LOGIN_URL);
            })

    }

    regenerateSession(msisdn) {

        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

        const cookies = new Cookies();

        var url = constantURL.PROFILER_COMBINED_IP + "/v1/loginbypass";

        var headers = null;
        headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            msisdn: msisdn,
            appid: metaMap.get('APP_ID'),

        };

        axios
            .get(url, {
                headers: headers,
            })
            .then((response) => response.data)
            .then((data) => {
                if (data && data.code == 2000) {
                    var userDetail = data.data;
                    this.createLoginSession(userDetail);
                }
            });
    }

    createLoginSession(data) {
        const cookies = new Cookies();
        const current = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(current.getFullYear() + 1);

        cookies.set("hardyIsLogin", true, { path: "/" });
        cookies.set("hardyAccessToken", data.accessToken, { path: "/" });
        cookies.set("hardyMobileNumber", data.username, { path: "/" });
        cookies.set("hardyOperator", data.operator.id, { path: "/" });
        cookies.set("hardyCountry", data.country.id, { path: "/" });
        cookies.set("hardyUsername", data.username, { path: "/" });

        sessionStorage.setItem("isLogin", 'true');
        sessionStorage.removeItem("isGuestLogin");
        sessionStorage.setItem("accessToken", data.accessToken);
        sessionStorage.setItem("mobileNumber", data.username);
        sessionStorage.setItem("operator", data.operator.id);
        sessionStorage.setItem("country", data.country.id);
        sessionStorage.setItem("username", data.username);
        if (data.dateOfBirth) {
            var dob = data.dateOfBirth;
            var dob1 = dob.split("T");
            var dob2 = dob1[0].split("-");
            sessionStorage.setItem("dob", dob2[2] + "/" + dob2[1] + "/" + dob2[0]);
        }
        sessionStorage.setItem("gender", data.gender);
        sessionStorage.setItem("emailid", data.email);
        sessionStorage.setItem("address", data.address);

        if (data.nickName) {
            sessionStorage.setItem("nameSalutation", data.nickName);
            cookies.set("hardyNameSalutation", data.nickName, { path: "/" });
        } else {
            sessionStorage.setItem("nameSalutation", data.username);
            cookies.set("hardyNameSalutation", data.username, { path: "/" });
        }
        if (data.userImagePath != null) {
            sessionStorage.setItem("profileImg", data.userImagePath);
            cookies.set("hardyProfileImg", data.userImagePath, { path: "/" });
        }
        // this.saveLogs(constantURL.LOGIN_ACTION);


    }



    togglePopup() {
        this.setState({
            showPopup: false
        });
        this.props.closeOtpPopup();

    }

    render() {
        const { lables } = this.state;
        return (
            <div className="modal-e">
                {this.state.showPopup ?
                    <SinglePopup
                        status={this.state.msgType}
                        text={this.state.msg}
                        alertType={this.state.alert}
                        closePopup={this.togglePopup.bind(this)}
                    /> : null
                }

                <div className="edit-info">
                    <div className="cancel" style={{ borderBottom: '1px solid rgba(255,255,255,0.15)', padding: '22px 16px 22px 16px' }}>
                        <p className="cancel-pe" onClick={this.props.closeOtpPopup}>{lables.back ? lables.back : 'Back'}</p>
                        <p className="done-e" onClick={() => this.validateOtp()} >{lables.done}</p>
                    </div>

                    <div className="enter-info">
                        <div className="what-e">
                            <div>
                                <input type="text" ref="searchStringInput" value={this.state.otp} onChange={this.handleKeyUp} maxLength="10" placeholder={lables.enterOtp ? lables.enterOtp : 'Enter OTP'}  ></input>
                            </div>
                        </div>
                    </div>
                    <p className="error-e-new">{this.state.errorMsg}</p>
                </div>
            </div>
        )
    }
}

export default withRouter(EditMobileOTPModal);