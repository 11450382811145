import React from 'react'
import axios from 'axios';
import '../styles/profile.css';

import edit from '../images/editNew.svg';
import back from '../images/back.png';
import profileImage from '../images/profileImage.png';
import help from '../images/help.png';
import share from '../images/share.png';
import faq from '../images/faqNew.png';
import viewWhite from '../images/viewWhite.png';
import SocialMedia from './socialMedia';
import { withRouter } from 'react-router'
import profileImg from '../images/profileImg.png';
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Iframe from 'react-iframe'
import $ from "jquery";


class ProfileCollapsed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sharePopup: false,
            lables : constantURL.getLables()
        }
    }

    componentDidMount() {
        console.log('--=====')

        document.getElementById('spinn').addEventListener('load', function () {

            //   console.log('KK')
            //         var svg = document.getElementById('spinn')
            //         var href = $('.close-popup').attr('href');
            //         // var o = $('object');
            //         // var s = $('svg', o[0].contentDocument);
            //     var s=    $('object').contents().find('.close-popup');

            //         console.log(s)
            //         // do stuff, call functions, etc.
            //       
        })
    }

    closeSpinner() {
        // alert('close')
    }

    render() {

        const {lables} = this.state;
        var msisdn=sessionStorage.getItem("username");
        if(msisdn.length>10){
            msisdn= msisdn.substr(2,msisdn.length+1);
         }

        var url = constantURL.STW_FRONTEND_URL+ "/getWheel?msisdn=" + msisdn +
            "&vendorUniqueKey=" + constantURL.VENDOR_KEY +
            "&campaignId=" + constantURL.CAMPAIGN_ID +
            "&accessToken=" + sessionStorage.getItem("accessToken") +
            "&appId=" + constantURL.APP_ID +
            "&appkey="+constantURL.APPKEY+"&rewardFrom=tambola_win&channel=wap&terminate=false&countryId="+lables.countryId
    //    var url = "//digi.mv1.in/BigWinZainWheel/getWheel?msisdn=" + msisdn +
    //         "&vendorUniqueKey=" + constantURL.VENDOR_KEY +
    //         "&campaignId=" + constantURL.CAMPAIGN_ID +
    //         "&accessToken=" + sessionStorage.getItem("accessToken") +
    //         "&appId=" + constantURL.APP_ID +
    //         "&appkey="+constantURL.COINSAPPKEY+"&rewardFrom=tambola_win&channel=wap&terminate=false"
      
            return (
            <div className="container-profile-1">
                <div className="content"  >
                    {/* <Iframe url={url}
                width="100%"
                height="500px"
                id="spinTheWheel"
                ref="spinner"
                className="fantasyLeague"
                display="initial"
                position="relative"/> */}

                    <object type="text/html" id="spinn" data={url}
                        style={{ width: '100%', height: '500px' }} />
                </div>
            </div>
        )
    }
}

export default withRouter(ProfileCollapsed);
