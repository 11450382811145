import React from 'react'
import axios from 'axios';
import '../styles/coupon.css';
import { withRouter } from 'react-router'
import cancel from '../images/crossWhite.png';
import gameWin from '../images/gameWin.png';
import Copy from '../images/copyWhite.png';

import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import profileImg from '../images/profileImg.png';
import ReactDOM, { findDOMNode } from 'react-dom';
import Loading from './loading'
import LogoutPopup from './logoutPopup'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {FRONTEND_NAME} from '../Constants/constants'

class CouponDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            copied: false,
        }
    }

    back() {
        this.props.history.push(FRONTEND_NAME+"/mywinnings");
    }


    render() {
           return (
            <div className="coupon-div">
                <div className="asc"><img src={cancel} onClick={this.back.bind(this)} className="cancel-coupon"></img></div>
                    <div className="coupon-box">
                     <img src={gameWin} className="c-win"></img>
                     <p className="game-n">{this.props.location.state.couponDetail.gameName}</p>
                     <p className="coupon-c">Coupon Code</p>
                         <CopyToClipboard text={this.props.location.state.couponDetail.couponCode}
                            onCopy={() => this.setState({copied: true})}>
                              <p class="coupon-v"><span>{this.props.location.state.couponDetail.couponCode}</span>
                       
                             <span class="cpn-file-logo">
                                <img src={Copy} />
                             </span></p>

                           </CopyToClipboard>	

                     <p className="coupon-x">Won on {this.props.location.state.couponDetail.wonOn}</p>
                     <p className="coupon-c">{this.props.location.state.couponDetail.couponShortDesc}</p>
                     <p className="coupon-c">{this.props.location.state.couponDetail.couponLongDesc}</p>
                </div>  
                <p className="coupon-d">Valid till {this.props.location.state.couponDetail.validTill}</p>
               {this.state.copied==true?<p class="valid-msg-copied">Copied!</p>: null  } 
            </div>
            )
    }
}

export default withRouter(CouponDetail);