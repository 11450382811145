
import React from 'react'
import axios from 'axios';
import '../styles/home.css';
import Footer from '../Footer/footer';
import Menu from '../Menu/menu';
import profileImg from '../images/profileImage.png';
import games from '../images/games.svg';
import bell from '../images/bell.png';
import back from '../images/backNew.svg';
import yellowStar from '../images/yellowStar.png';
import yellowBatch from '../images/yellowBatch.png';

import yellowHeart from '../images/yellowHeart.png';
import viewAll from '../images/viewAll.png';
import yellowDice from '../images/yellowDice.png';

import popular from '../images/popular.png';
import keepPlaying from '../images/keepPlaying.png';
import yellowView from '../images/yellowView.png';
import instsnt from '../images/instsnt.png';
import winBig from '../images/winBig.png';
import trending from '../images/trending.png';

import circle from '../images/circle.png';
import gods from '../images/gods.png';
import bannerBottom from '../images/bannerBottom.png';
import * as Constant from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Slider from 'infinite-react-carousel';
import ProfileCollapsed from './profileCollapsed';
import { withRouter } from 'react-router';
import PopupNew from './popupNew';
import Popup from './popup';
import Loading from './loading'
import lottie from 'lottie-web';
import animationData from '../lottie/delivery.json';
import clownLogo from '../images/clownLogo.png';
import Cookies from 'universal-cookie';
import SpinTheWheel from './spinTheWheel';
import * as constantURL from '../Constants/constants'
import {FRONTEND_NAME} from '../Constants/constants'

let animObj1 = null;
let animObj2 = null;

class FreeGames extends React.Component {


    constructor(props) {

        super(props);
        this.state = {
            banners: [{ id: '1', title: 'title', bannerName: 'bannerName', imageUrl: 'imageUrl', redirectUrl: 'redirectUrl', gameurl: 'game', bannerType: 'ban', bannerText: 'PLAY NOW' }],
            trendingPaidGames: [],
            isShowMenu: false,
            showPopup: false,
            showPopupOld: false,
            isShowSpin: false,
            msg: '',
            msgType: 'S',
            enableLoader: true,
            isConfirm: 'N',
            loginRedirect: 'N',
            instantGamesId: '',
            bigWinGamesId: '',
            displayButtons: [],
            currentSelectedGame: {},
            gametype: 'FREE',
            isNeedToCheckSTW: false,
            isShowSpin: false,
        }
    }

    showSpinner() {
        const headers = {
            'Content-Type': 'application/json',
            'mode': 'WAP',
        };

        var msisdn=sessionStorage.getItem("username");
        if(msisdn.length>10){
            msisdn= msisdn.substr(2,msisdn.length+1);
         }

        const body = {
            'msisdn': msisdn,
            'campaignId': constantURL.CAMPAIGN_ID,
            'vendorUniqueKey': constantURL.VENDOR_KEY
        }

        axios.post(constantURL.SPINTHEWHEEL_COMBINED_IP + "/v1/spinnerStatus", body, { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    console.log(data.data.status)
                    if (data.data.status) {
                        this.setState({
                            isShowSpin: true
                        })
                    } else {
                        this.setState({
                            isShowSpin: false
                        })
                    }
                } else {
                    this.setState({
                        isShowSpin: false
                    });
                }
            });
    }


    componentDidMount() {

        console.log(' User Entered time --> ' + sessionStorage.getItem('entertime'))
        if (sessionStorage.getItem('entertime')) {

            var enteredTime = sessionStorage.getItem('entertime');
            var d = new Date();
            var currentTime = d.getTime();

            if ((currentTime - enteredTime) / 1000 > constantURL.SPINTHEWHEEL_TIME) {
                this.setState({ isNeedToCheckSTW: true });
                sessionStorage.removeItem('entertime');
           
            } else {
                this.setState({ isNeedToCheckSTW: false });
            }
        } else {
            var d = new Date();
            var n = d.getTime();
            sessionStorage.setItem('entertime', n);
            this.setState({ isNeedToCheckSTW: false });
        }

        this.showSpinner();


        const headers = {
            'Content-Type': Constant.APPLICATION_JSON,
            'appid': Constant.APP_ID,
            'deviceId': Constant.DEVICE_ID,
            'operator': Constant.OPERATOR,
            'Access_token': sessionStorage.getItem("accessToken"),
            'channel': Constant.CHANNEL,
            'Msisdn': sessionStorage.getItem("username")
        };

        const url = Constant.CONTENT_COMBINED_IP + "/v1/fetchCategories?categoryId=" + Constant.FREEGAME_ID;
        console.log('url to call : ', url)

        axios.get(url, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data.data)
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {

                    console.log(data.data)

                    var subCategoriesDetail = data.data.categories[0].subCategories;
                    var popList = [];

                    if (subCategoriesDetail) {
                        var pG = subCategoriesDetail[0].content3;   //Trending Paid Games
                        for (var i = 0; i < pG.length; i++) {
                            popList.push({
                                id: 'c' + i,
                                title: pG[i].title,
                                noOfUsersDynamic: pG[i].noOfUsersDynamic,
                                previewImageUrl: pG[i].previewImageUrl,
                                gameurl: pG[i].secondarygameurl,
                                price: pG[i].chargingcurrency + ' ' + pG[i].chargingvalue,
                                coinsRequired: pG[i].coinsRequired,
                                gametype: pG[i].gametype,
                                chargingKeyword: pG[i].chargingKeyword,
                                checkSubKeyword: pG[i].checkSubKeyword,
                                chargingvalue: pG[i].chargingvalue,
                                chargingcurrency: pG[i].chargingcurrency,
                                replacedynamicparam: pG[i].replacedynamicparam,
                                contentId:pG[i].contentId,
                                contentName:pG[i].contentName,
                     
                     
                            });
                        }
                        this.setState({ trendingPaidGames: popList });
                    }
                    this.setState({ enableLoader: false })

                    this.saveLogs(Constant.FREE_ACTION)
                } else {
                    this.setState({ enableLoader: false })
                    this.togglePopup(Constant.ERROR_MESSAGE, 'E', Constant.ERROR_ALERT, ['1'])
                    this.saveLogs(Constant.FREE_ACTION)
              
                }
            }).catch(error => {
                console.log('There is Error in fetching View All Content for Instant games : ', error)
                // this.setState({
                //     redirectScreen: Constant.PAID_GAMES
                // })
                this.togglePopup(Constant.ERROR_MESSAGE, 'E', Constant.ERROR_ALERT, ['1'])
            })
    }


    saveLogs(category) {
        const cookies = new Cookies();
        var url = Constant.APPLOGGER_COMBINED_IP + "/v1/saveLogs";
        
        const headers = {
            'Content-Type': 'application/json',
            'appid': Constant.APP_ID,
            'operator': Constant.OPERATOR,
            'msisdn': sessionStorage.getItem("username"),
            'channel': 'WAP'
        };

        const requestBody = {
            "category" : category,
            "subCategory" : "",
            "content" : category,
            "url" : "/freegames",
            "packname" :"",
            "appname" :Constant.APPLICATION_NAME,
            "sessionid" : cookies.get('uniquevhgsession')
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == errorConstantURL.SUCCESS_CODE) {
                } else {
                }
            });
    }

    openNotificcation() {
        this.props.history.push({
            pathname: FRONTEND_NAME+'/notification',
            state: {
                gameScreen: 'paidGames',
            }
        })
    }

    performTaskOnClose = (flag) => {
        this.togglePopup()
        console.log("inside performTaskOnClose method, flag is ", flag)
        if (flag === 'confirm') {
            this.submitPaymentSubscription();
        } if (flag === 'ok-' + Constant.LOGIN_REQUIRED_MESSAGE) {
            this.props.history.push(FRONTEND_NAME+"/login")
        } if (flag === 'ok-' + Constant.PAYMENT_SUCCESSFUL_MESSAGE) {
            //window.open(this.state.currentSelectedGame.gameurl, "_blank")
            this.openRequiredGame(this.state.currentSelectedGame)
        } if (flag === 'ok-' + Constant.ERROR_MESSAGE) {
            this.props.history.push(FRONTEND_NAME+"/home")
        }
    }


    redirectToRequiredGame = (gameToPlay) => {

        //Current game is set ti state variable because we need it when payment popup gives confirmation.
        //At that time it cannot be passed through a method
        this.setState({
            currentSelectedGame: gameToPlay
        })
        console.log("inside redirectToRequiredGame : ", gameToPlay.gameurl)
        const isLogin = sessionStorage.getItem("isLogin");
        if (isLogin) {
            this.openRequiredGame(gameToPlay);
        }
        else {
            console.log('User is NOT logged In, showing popup to login')
            this.setState({
                redirectScreen: Constant.LOGIN_SCREEN_TITLE
            })
            this.togglePopup(Constant.LOGIN_REQUIRED_MESSAGE, 'A', Constant.LOGIN_REQUIRED_ALERT, ["1", "2"])
            //If YES, want to login then redirect to login page and after successful login, redirect to respective Game Page
        }
    }

    onDisplayMenu(obj) {
        this.setState({ isShowMenu: !this.state.isShowMenu });
    }


    openProfile() {
        if (sessionStorage.getItem("isLogin")) {
            this.props.history.push(FRONTEND_NAME+"/profile");
        }
        else {
            this.props.history.push(FRONTEND_NAME+"/login");
        }
    }

    togglePopup = (message, msgType, alert, buttonArray) => {
        this.setState({
            showPopup: !this.state.showPopup,
            msg: message,
            msgType: msgType,
            alert: alert,
            displayButtons: buttonArray
        })
    }

    togglePop() {
        this.setState({
            showPopup: !this.state.showPopup,
        });
    }

    togglePopOld() {
        this.setState({
            showPopupOld: !this.state.showPopupOld
        });
    }

    togglePopupOld() {
        this.setState({
            showPopupOld: !this.state.showPopupOld
        });
        if (this.state.loginRedirect == 'Y') {
            this.props.history.push(FRONTEND_NAME+"/logout")
        }
        if (this.state.loginRedirect == 'C') {
            this.deduct()
        }
    }


    openRequiredGame = (gameToPlay) => {
        console.log("inside openRequiredGame method ")
        this.props.history.push({
            pathname: FRONTEND_NAME+'/gameview',
            state: {
                game: gameToPlay,
                gameScreen: 'freeGames'
            }
        })
    }

    

    back() {
        this.props.history.push({
            pathname: FRONTEND_NAME+'/home'
        });
    }

    render() {
        const { showPopup, showPopupOld, msg, msgType, alert, displayButtons, trendingPaidGames } = this.state;

        var displayGames = [];
        trendingPaidGames.forEach(function (list) {
            displayGames.push(
                // onClick={()=> window.open(list.redirectUrl, "_blank")}
                <div key={list.id} className="Coin-games">
                    <div className="gamess" onClick={() => this.redirectToRequiredGame(list)} >
                        <img src={list.previewImageUrl} ></img>
                        <div className="for-line">
                            <div className="coin-p">
                                <p className="coin-g-a">{list.title}</p>
                                <p className="coin-g-b">{list.noOfUsersDynamic} played</p>
                            </div>
                            <div className="coin-amt btn-wid">
                                <p>{list.chargingcurrency.toUpperCase()}</p>
                            </div>
                        </div>
                    </div>

                </div>
            );

        }.bind(this));

        return (
            <div className="container-home"  >
                {this.state.enableLoader ?
                    <Loading />
                    : null
                }

                {showPopup ?
                    <PopupNew
                        status={msgType}
                        text={msg}
                        alertType={alert}
                        closePopup={this.togglePopup}
                        closePopupWithTask={this.performTaskOnClose}
                        displayButtons={displayButtons}
                    /> : null}

                {showPopupOld ?
                    <Popup
                        status={msgType}
                        text={msg}
                        alertType="Login Required!"
                        buttonName={this.state.loginRedirect == 'C' ? 'CONFIRM' : 'OK'}
                        closePopup={this.togglePopupOld.bind(this)}
                        closePopupNotNow={this.togglePopOld.bind(this)}
                        isConfirm={this.state.isConfirm}
                    /> : null}
                <div className="header-leaderboard-1 line-it-1">
                    <img src={back} onClick={this.back.bind(this)} className="float-lft free-back-img"></img>
                    <span className="header-text-free float-lft">Free Games</span>
                </div>
                {/* {this.state.isNeedToCheckSTW ?
                    <div id="revoke-modal" className={this.state.isShowSpin ? 'revokeModalShow' : 'revokeModalHide'} >
                        {(this.state.isShowSpin ? < SpinTheWheel /> : null)} </div>
                    : null} */}

                <div className="content-free"  >
                </div>
                {displayGames}
            </div>
        )
    }
}

export default withRouter(FreeGames);