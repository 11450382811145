import firebase from 'firebase';
import axios from 'axios'
import * as Constant from '../Constants/constants'

export const initializeFirebase = () => {
  console.log("Inside initializeFirebase")
  // var firebaseConfig = {
  //   apiKey: "AIzaSyCz9cEYsA5OXtI_-l5kXQDOYZlVeqQsLdA",
  //   authDomain: "bsnlhardygames.firebaseapp.com",
  //   projectId: "bsnlhardygames",
  //   storageBucket: "bsnlhardygames.appspot.com",
  //   messagingSenderId: "20762119459",
  //   appId: "1:20762119459:web:56f3867219ea05c78ad096",
  //   measurementId: "G-QN4ZQJ9WE0"

  // };

  var firebaseConfig = {
    apiKey: "AIzaSyCtulfUNkiGv-urBzGIfceZIjMIurveS88",
    authDomain: "hardy-games-4eae3.firebaseapp.com",
    projectId: "hardy-games-4eae3",
    storageBucket: "hardy-games-4eae3.appspot.com",
    messagingSenderId: "138033806223",
    appId: "1:138033806223:web:458523cca9e098fcb169fa",
    measurementId: "G-JHCWSH7SH4"
  };

  console.log('INSIDE PUSH NOTIFICATION->>>>>>>');
  console.log(firebaseConfig);
  
 firebase.initializeApp(firebaseConfig);
}

export const askForPermissioToReceiveNotifications = async (successCallbackFn,errCallbackFn) => {
  console.log("Inside askForPermissioToReceiveNotifications")
   try {
      const messaging = firebase.messaging();
      console.log("After messaging")
      await messaging.requestPermission();
      console.log("After requestPermission")
      const token = await messaging.getToken();
      console.log("After token : ", token)
     // console.log('token do usuário:', token);
  if (sessionStorage.getItem("loginType") != "social") {
      await registerdevice(token,successCallbackFn,errCallbackFn);
  }
      return token;
    } catch (error) {
      console.log("Error occured :  notifications"+ error)
     // errCallbackFn("p");
    }
  }

  export const registerdevice = async (token,successCallbackFn,errCallbackFn) =>{
    // registerdevice
    //var tambolaHeaders = common.getCommonHeadersForTambola();
    // {
    //   "appName": "Tambola","appVersionCode": 1.0,"appVersionName": "1.0.7", "deviceId": "50cbd4976aab3c7d","gcmId": "ckPJXp0fPIkbwrgxMRFUXt:APA91bEty-AzT9b99L6VgjsyzMo-DHXhFSVGWeQb2eLqIS6n-HdqHm34Ki4WrqJvRhBtQpq03qPKMm-ZcX2RFY3m4LMN8Y2LfbO_xRQtq_TlQmBI62mQXt-fyb33fv-JfgrstVzcj_7V",
    //       "loginLogoutFlag": false,
    //       "msisdn":7777777775
    //       }
    console.log("inside registerdevice")
    var isSuccess = false;
    var url = `${Constant.USER_REGISTRATION_API_URL}`
    var deviceID = generateDeviceId();
    console.log("deviceID : ", deviceID)
    sessionStorage.setItem("deviceId", deviceID)

    var data = {
      "appName": "Generichardygames",
      // "appName":"Tambola",
      "deviceId": deviceID,
      "gcmId": token,
      "msisdn": sessionStorage.getItem("username"),
      "appVersionName": "1.0",
      "appVersionCode": 1.7,
      "loginLogoutFlag": false
      }

    //'access_token': sessionStorage.getItem("accessToken"),

    var headers = {
        "Content-Type"  :"application/json",
        "Device_id"    	: deviceID,
        "Accept"        :"application/json",
        "channel"       :"web"
        
      };

      console.log("url to hit : ", url)
      await axios.post(Constant.USER_REGISTRATION_API_URL,	data,
        {
          headers: headers
        }
        ).then(response=>{
          console.log("response from notificaation", response.data);
          isSuccess = true
          //successCallbackFn();
          return isSuccess;
          
        }) .catch(error=>{
          console.log("Error occured : while calling registered user API : ", error)
          isSuccess = false;
          return isSuccess;
        });
  }

  function generateDeviceId(){
    var navigator_info = window.navigator;
    var screen_info = window.screen;
    var uid = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, '');
    uid += navigator_info.plugins.length;
    uid += screen_info.height || '';
    uid += screen_info.width || '';
    uid += screen_info.pixelDepth || '';
    console.log("uid : ", uid);
    return uid.toString();
  }
