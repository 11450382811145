import React, { Component, createRef } from 'react'
import { withRouter } from 'react-router'
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import axios from 'axios';
import Loading from './loading'
import $ from 'jquery';
import checked from '../images/checked.png';
import Cookies from 'universal-cookie';
import error from '../images/redError.png';

class EditAddressModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showEditName: false,
            showEditImage: false,
            errorMsg: '',
            errorFlag: 'd',
            name: '',
            address: '',
            userN: '',
            enableLoader: false,
            logoutPopup: false,
            lables: constantURL.getLables()
        }
        this.handleKeyUp = this.handleKeyUp.bind(this);
    }

    componentDidMount() {
        this.setState({
            address: sessionStorage.getItem("address") == 'null' ? '' : sessionStorage.getItem("address"),
        })
    }


    handleKeyUp(event) {

        const { lables } = this.state;
        this.setState({
            address: event.target.value,
            //  name: event.target.value
        });

        if (/^[a-zA-Z0-9\s,.'-]*$/.test(event.target.value)) {
            this.setState({
                errorFlag: 'n',
                address: event.target.value,
                errorMsg: '',
                disable: false
            });

        } else {
            this.setState({
                errorFlag: 'e',
                address: event.target.value,
                errorMsg: lables.addressCantHaveSplChars,
                disable: true
            });
        }
    }

    setAddress() {
        if (!this.state.disable) {
            this.props.setAddress(this.state.address.trim())
            sessionStorage.setItem("address", this.state.address.trim());
        };

    }


    render() {
        const { lables } = this.state;
        return (
            <div className="modal-e">
                <div className="edit-info">
                    <div className="cancel" style={{ borderBottom: '1px solid rgba(255,255,255,0.15)', padding: '22px 16px 22px 16px' }}>
                        <p className="cancel-pe" onClick={this.props.closePopup}>{lables.cancel}</p>
                        <p className="done-e" onClick={() => this.setAddress()}>{lables.done}</p>
                    </div>

                    <div className="enter-info">
                        <div className="what-e">
                            <input type="text" ref="searchStringInput" value={this.state.address} onChange={this.handleKeyUp} maxLength="100" placeholder={lables.enterYourAddress}  ></input>
                        </div>
                    </div>
                    <p className="error-e-new">{this.state.errorMsg}</p>
                </div>
            </div>
        )
    }
}

export default withRouter(EditAddressModal);