// Import the functions you need from the SDKs you need

import firebase, { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var appName=null

export const initializeFirebaseAnalytics = () => {
    console.log('INSIDE INITIALIZE FIREBASE')

    const firebaseConfig = {
        apiKey: "AIzaSyCtulfUNkiGv-urBzGIfceZIjMIurveS88",
        authDomain: "hardy-games-4eae3.firebaseapp.com",
        projectId: "hardy-games-4eae3",
        storageBucket: "hardy-games-4eae3.appspot.com",
        messagingSenderId: "138033806223",
        appId: "1:138033806223:web:458523cca9e098fcb169fa",
        measurementId: "G-JHCWSH7SH4"
    };

    const app = initializeApp(firebaseConfig, "secondary");
    appName = app;
    // firebase.analytics(app)
}

export const logEvents = (event) => {

    console.log('INSIDE LOG EVENTS ->   '+ event )
    firebase.analytics(appName).logEvent(event);
    console.log('DONE ANALYTICS' )

}

