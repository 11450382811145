import axios from 'axios'
import {
    FETCH_HOME_PAGE_START,
    FETCH_HOME_PAGE_SUCCESS,
    FETCH_HOME_PAGE_ERROR,
    FETCH_HOME_PAGE_CLEAR,
} from '../container/Constants/fetchHomePageConstant'

import {
    DEVICE_ID,
    OPERATOR,
    CONTENT_COMBINED_IP
} from '../container/Constants/constants'

import {
    SUCCESS_CODE
} from '../container/Constants/errorConstants'

import localForage from 'localforage'

var protocol = window.location.protocol;

const buildGameObject = (gameType, game) => {
    cachedImageConfigure(game.contentName, (game.savedImageUrl) ? game.savedImageUrl : game.previewImageUrl);

    return {
        id: gameType,
        title: game.title,
        aliasname: game.aliasname,
        noOfUsersDynamic: game.noOfUsersDynamic,
        previewImageUrl: game.previewImageUrl,
        savedImageUrl: game.savedImageUrl,
        gameurl: protocol === "https:" ? game.secondarygameurl : game.gameurl,
        price: game.chargingcurrency + " " + game.chargingvalue,
        coinsRequired: game.coinsRequired,
        gametype: game.gametype,
        checkSubKeyword: game.checkSubKeyword,
        chargingKeyword: game.chargingKeyword,
        replacedynamicparam: game.replacedynamicparam,
        contentId: game.contentId,
        contentName: game.contentName,
        showPlayButton: game.showPlayButton,
        chargingvalue: game.chargingvalue,
        validity: game.validity,

    }
}

const buildHomeBannerObject = (banner) => {
    cachedImageConfigure(banner.contentName, (banner.savedImageUrl) ? banner.savedImageUrl : banner.imageUrl);
    return {
        id: banner.id,
        contentName: banner.contentName,
        title: banner.bannerImageTitle,
        showPlayButton: banner.showPlayButton,

        aliasname: banner.aliasname,
        bannerName: banner.bannerName,
        desciption: banner.desciption,
        imageUrl: banner.imageUrl,
        savedImageUrl: banner.savedImageUrl,
        redirectUrl: banner.redirectUrl,

        gameurl: protocol === "https:" ? banner.secondarygameurl : banner.gameurl,
        bannerType: banner.bannerType,
        buttonText: banner.buttonText,
        coinsRequired: banner.coinsRequired,
        gametype: banner.gametype,
        checkSubKeyword: banner.checkSubKeyword,
        chargingKeyword: banner.chargingKeyword,
        replacedynamicparam: banner.replacedynamicparam,
        price:
            banner.chargingcurrency +
            " " +
            banner.chargingvalue,
        bgcolorcode: banner.bgcolorcode,
        chargingvalue: banner.chargingvalue,
        validity: banner.validity,
        isBattle: banner.isBattle,
    }
}

const buildWinnerBannerObject = (banner) => {
    cachedImageConfigure(banner.bannerId, (banner.savedImageUrl) ? banner.savedImageUrl : banner.imageUrl);
    return {
        id: banner.bannerId,
        contentName: banner.bannerId,
        title: banner.headLine,
        imageUrl: banner.imageUrl,
        savedImageUrl: banner.savedImageUrl
    }
}

const buildFantasyBannerObject = (banner) => {
    cachedImageConfigure(banner.bannerId, (banner.savedImageUrl) ? banner.savedImageUrl : banner.imageUrl);
    return {
        id: banner.bannerId,
        contentName: banner.bannerId,
        title: banner.headLine,
        imageUrl: banner.imageUrl,
        savedImageUrl: banner.savedImageUrl,
    }
}


const buildContestBannerObject = (banner) => {
    cachedImageConfigure(banner.bannerId, (banner.savedImageUrl) ? banner.savedImageUrl : banner.imageUrl);
    return {
        id: banner.bannerId,
        contentName: banner.bannerId,
        title: banner.headLine,
        imageUrl: banner.imageUrl,
        savedImageUrl: banner.savedImageUrl,
        banner: banner
    }
}

const buildMobileVerificationBannerObject = (banner) => {
    cachedImageConfigure(banner.bannerId, (banner.savedImageUrl) ? banner.savedImageUrl : banner.imageUrl);
    return {
        id: banner.bannerId,
        contentName: banner.bannerId,
        title: banner.headLine,
        imageUrl: banner.imageUrl,
        savedImageUrl: banner.savedImageUrl,
        banner: banner
    }
}



const cachedImageConfigure = (contentName, imageUrl) => {
    localForage.getItem(contentName)
        .then(returnData => {
            if (!returnData) {
                convertImgToBase64URL(imageUrl, function (base64Img) {
                    (base64Img) && localForage.setItem(contentName, base64Img)
                });
            }
        })
}

const convertImgToBase64URL = (url, callback, outputFormat) => {
    let img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
        var canvas = document.createElement('CANVAS'),
            ctx = canvas.getContext('2d'), dataURL;
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
        canvas = null;
    };
    img.src = url;
}

export const fetchHomePage = () => async (dispatch, getState) => {

    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

    //if (getState().fetchHomePageReducer)
    console.log(getState().home.isInitialLoad)

    if (!getState().home.isInitialLoad) return;

    try {
        dispatch({
            type: FETCH_HOME_PAGE_START
        });

        const headers = {
            "Content-Type": "application/json",
            appid: sessionStorage["appid"],
            deviceId: DEVICE_ID,
            operator: metaMap.get('OPERATOR'),
            Access_token: sessionStorage.getItem("accessToken"),
            channel: "wap",
            Msisdn: sessionStorage.getItem("username"),
        };

        const { data } = await axios.get(CONTENT_COMBINED_IP + "/v1/fetchHomePage", { headers })

        if (data && data.code === SUCCESS_CODE) {
            const bannerDetail = data.data.categories[0].banners;
            const subCategoriesDetail = data.data.categories[0].subCategories;
            let banList = [];
            let winnerBanner = [];
            let fantasyBanner = [];
            let contestBanner = [];
            let mobileVerificationBanner = [];


            let popularGames = {};
            let keepPlaying = {};
            let arcadeGames = {};

            let popList = [];
            let bundleList = [];
            let keepList = [];
            let arcadeList = [];

            let subCategory;

            if (bannerDetail) {
                bannerDetail.forEach(banner => {
                    if (banner.title === "HomeBanner") {
                        banList.push(
                            buildHomeBannerObject(banner)
                        )
                    } else if (banner.title.trim() === "Contest Banner") {
                        contestBanner.push(
                            buildContestBannerObject(banner)
                        )
                    }
                    else if (banner.title.trim() === "MobileVerificationBanner") {
                        mobileVerificationBanner.push(
                            buildMobileVerificationBannerObject(banner)
                        )
                    }
                    else {
                        if (banner.bannerName === "WinnerBanner")
                            winnerBanner.push(
                                buildWinnerBannerObject(banner)
                            )
                        if (banner.bannerName === "FantasyBanner")
                            fantasyBanner.push(
                                buildFantasyBannerObject(banner)
                            )
                    }
                })
            }


            if (subCategoriesDetail) {

                var pG = subCategoriesDetail[0].content3; //Popular Games
                var pGName = subCategoriesDetail[0].subCategoryName;

                var kpG = subCategoriesDetail[1].content3; //Keep playing Games
                var kpGName = subCategoriesDetail[1].subCategoryName;

                var arcadeG = subCategoriesDetail[2].content3; //Keep playing Games
                var arcadeName = subCategoriesDetail[2].subCategoryName;


                var popGSubCatgId = subCategoriesDetail[0].subCategoryId;

                pG.forEach((game, index) => {
                    if (game.contentId)
                        popList.push(
                            buildGameObject('p' + index, game)
                        )
                })

                popularGames = {
                    categoryName: pGName,
                    gameList: popList,
                    categoryId: subCategoriesDetail[0].subCategoryId,
                    showCount: subCategoriesDetail[0].showCount
                }

                subCategory = popGSubCatgId

                kpG.forEach((game, index) => {
                    if (game.contentId)
                        keepList.push(
                            buildGameObject('k' + index, game)
                        )
                })

                keepPlaying = {
                    categoryName: kpGName,
                    gameList: keepList,
                    showCount: subCategoriesDetail[1].showCount,
                    categoryId: subCategoriesDetail[1].subCategoryId,
                }

                arcadeG.forEach((game, index) => {
                    if (game.contentId)
                        arcadeList.push(
                            buildGameObject('a' + index, game)
                        )
                })

                arcadeGames = {
                    categoryName: arcadeName,
                    gameList: arcadeList,
                    showCount: subCategoriesDetail[2].showCount,
                    categoryId: subCategoriesDetail[2].subCategoryId,
                }
            }
            // if (/^\d+$/.test(sessionStorage.getItem("username"))) {
            //   if (sessionStorage.getItem("showSubscriptionMessageDone")) {
            //     if (sessionStorage.getItem("showSubscriptionMessageDone") == 'false') {
            //       sessionStorage.setItem("showSubscriptionMessageDone", 'true');
            //       this.showSubscriptionMessage();
            //     }
            //   } else {
            //     sessionStorage.setItem("showSubscriptionMessageDone", 'true');
            //     this.showSubscriptionMessage();
            //   }
            // }

            let payload = {
                banners: banList,
                winnerBanner: winnerBanner,
                fantasyBanner: fantasyBanner,
                contestBanner :contestBanner,
                popularGames: popularGames,
                arcadeGames: arcadeGames,
                mobileVerificationBanner:mobileVerificationBanner,
                keepPlaying: keepPlaying,
                subCategory: subCategory
            }

            dispatch({
                type: FETCH_HOME_PAGE_SUCCESS,
                payload: payload
            })

        }
        else {
            sessionStorage.removeItem("isLogin");
            sessionStorage.removeItem("isGuestLogin");

            dispatch({
                type: FETCH_HOME_PAGE_ERROR
            })
        }
        // this.getNotificationStatus();

    }
    catch (error) {
        console.log(error)
        dispatch({
            type: FETCH_HOME_PAGE_ERROR
        })
    }
}

