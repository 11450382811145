import axios from 'axios'

import {
    FETCH_HOME_MENU_START,
    FETCH_HOME_MENU_SUCCESS,
    FETCH_HOME_MENU_ERROR,
    FETCH_HOME_MENU_CLEAR
} from './../container/Constants/homeMenuConstants'

import {
    DEVICE_ID,
    OPERATOR,
    CONTENT_COMBINED_IP
} from '../container/Constants/constants'

import {
    SUCCESS_CODE
} from '../container/Constants/errorConstants'
import localForage from 'localforage'

var protocol = window.location.protocol;

const cachedImageConfigure = (contentName, imageUrl) => {
    localForage.getItem(contentName)
      .then( returnData => {
        if(!returnData){
          convertImgToBase64URL(imageUrl, function(base64Img){
            (base64Img) && localForage.setItem(contentName, base64Img)
          });
        }
      })
}

const convertImgToBase64URL = (url, callback, outputFormat) => {
    let img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function(){
        var canvas = document.createElement('CANVAS'),
        ctx = canvas.getContext('2d'), dataURL;
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
        canvas = null; 
    };
    img.src = url;
}

export const homeMenu = () => async(dispatch, getState) => {
    
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

    if(!getState().menu.isInitialLoad) return;

    try {
        dispatch({
            type: FETCH_HOME_MENU_START
        })
        
        const headers = {
            "Content-Type": "application/json",
            appid: sessionStorage["appid"],
            deviceId: DEVICE_ID,
            operator: metaMap.get('OPERATOR'),
            Access_token: sessionStorage.getItem("accessToken"),
            channel: "wap",
            Msisdn: sessionStorage.getItem("username"),
        };

        const { data } = await axios.get(CONTENT_COMBINED_IP + "/v1/fetchMenu", { headers })

        if(data && data.code === SUCCESS_CODE) {
            const bottomMenu = data.data.categories[0].subCategories;

            bottomMenu.forEach(menu => {
                cachedImageConfigure(menu.itemId, menu.previewImageUrl)
                cachedImageConfigure(menu.itemId + "1", menu.previewImageUrl2)
            })
            // console.log("Bottom menu fetched" + bottomMenu);

            dispatch({
                type: FETCH_HOME_MENU_SUCCESS,
                payload: bottomMenu
            })
            // this.setState({ bottomMenu: bottomMenu })
        }
        else {
            
            sessionStorage.removeItem('isLogin')
            sessionStorage.removeItem('isGuestLogin')

            dispatch({
                type: FETCH_HOME_MENU_ERROR
            })

        }

    }
    catch(error) {
        dispatch({
            type: FETCH_HOME_MENU_ERROR
        })
    }
}