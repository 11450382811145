
export const SUCCESS_CODE=2000;
export const PARAM_MISSING_CODE = 4000
export const OTP_LIMIT_EXHAUSTED = 4012
export const NO_DATA_FOUND = 2004
export const OTP_NOT_MATCH = 4011
export const LOW_BALANCE_CODE=9002;

export const ONLY_CHARACTER_CODE=4000;
export const OFFENSIVE_CODE=4010;

export const SUCCESSFUL_SUBSCRIPTION = 2000
export const REQUIRED_COINS_AVAILABLE = 2009
export const INSUFFICIENT_COINS = 2070
export const SUBCRIPTION_CHECK_ERROR = 5003
