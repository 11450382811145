import React from "react";
import axios from "axios";
import "../styles/home.css";
import Footer from "../Footer/footer";
//import games from "../images/hardy.svg";
//import games from "../images/logo512.png";
import moment from 'moment';
import { logEvents } from "./Analytics";
import coins from "../images/coin3.svg";
import arcadePic from "../images/arcadepic.png";
import bell from "../images/bell.svg";
import bellunread from "../images/messageunread.svg";
import yview from "../images/backYellow.svg";
import yellowBatch from "../images/yellowBatch.png";
import yellowHeart from "../images/yellowHeart.png";
import yellowDice from "../images/yellowDice.png";
import yellowView from "../images/viewAll.png";
import * as constantURL from "../Constants/constants";
import * as errorConstantURL from "../Constants/errorConstants";
import Slider from "infinite-react-carousel";
import { withRouter } from "react-router";
import Popup from "./popup";
import SpinTheWheel from "./spinTheWheel";
import Loading from "./loading";
import loadergif from '../images/loader.gif';
import LogoutPopup from "./logoutPopup";
import PopupNew from "./popupNew";
import clownLogo from "../images/smallClown.svg";
import queryString from "query-string";
import lottie from "lottie-web";
import animationData from "../lottie/freegames.json";
import Cookies from "universal-cookie";
import Flickity from "react-flickity-component";
import * as Notification from "./pushNotification";
// import { Carousel } from '3d-react-carousal';
// import { Carousel } from 'react-multi-carousel';
import nextId from "react-id-generator";
import $ from "jquery";
import * as Constant from "../Constants/constants";
import { games } from "../Constants/constants";
import { FRONTEND_NAME } from "../Constants/constants";
import localForage from 'localforage';
import { connect } from 'react-redux'
import { fetchHomePage } from '../../actions/fetchHomePageActions'

// import Skeleton from 'react-loading-skeleton'
// import 'react-loading-skeleton/dist/skeleton.css'
// import MyLoader from "./Placeholder";

let flickyIndex = 1

let animObj1 = null;
const flickityOptions = {
  initialIndex: flickyIndex,
  wrapAround: true,
  adaptiveHeight: true,
  // freeScroll: true,
  // autoPlay: 4000,
  unreadCount: 0,
  pauseAutoPlayOnHover: true,
};

class HomeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: [
        {
          id: "1",
          title: "title",
          bannerName: "bannerName",
          imageUrl: "imageUrl",
          redirectUrl: "redirectUrl",
          gameurl: "game",
          bannerType: "ban",
          bannerText: "PLAY NOW",
        },
      ],
      popularGames: { categoryName: "", gameList: [] },
      keepPlaying: { categoryName: "", gameList: [] },
      arcadeGames: { categoryName: "", gameList: [] },
      unreadCount: 0,
      showPopupOld: false,
      freeGames: [
        {
          id: "1",
          title: "title",
          bannerName: "bannerName",
          imageUrl: "imageUrl",
          redirectUrl: "redirectUrl",
          gameurl: "game",
          bannerType: "ban",
        },
      ],
      subCategory: "",
      isShowMenu: false,
      winnerBanner: [],
      fantasyBanner: [],
      showPopup: false,
      isShowSpin: false,
      msg: "",
      msgType: "S",
      enableLoader: true,
      notification: false,
      loginRedirect: "N",
      logoutPopup: false,
      displayButtons: [],
      currentSelectedGame: {},
      showPopupNew: false,
      message: "",
      alert: "",
      premiumgamesBanner: "",
      premiumGamesBannerTitle: "",
      premiumGameId: "",
      isNeedToCheckSTW: false,
      bundleGamesBanner: [],
      actionType: "",
      lables: constantURL.getLables(),
      popularImageLoadCount: 0,
      keepImageLoadCount: 0,
      arcadeImageLoadCount: 0,
      cachedImage: new Map(),
    };

    this.loadFlickerTranisition = null;
    //this.props.fetchHomePage();

  }

  componentDidMount() {
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
    // 0: {keyName: 'APPKEY', valueName: 'vihardygames', id: 1}
    // 1: {keyName: 'COINSAPPKEY', valueName: 'BIGWINLOYALTY', id: 2}
    // 2: {keyName: 'APPKEY_BUY_COINS', valueName: 'BIGWINLOYALTYBUYCOINS', id: 3}
    // 3: {keyName: 'COUNTRY_CODE', valueName: '91', id: 4}
    // 4: {keyName: 'OPERATOR', valueName: 'vi', id: 5}
    // 5: {keyName: 'APP_ID', valueName: '102', id: 6}
    // 6: {keyName: 'APP_KEY_SUBS', valueName: 'vihardygames', id: 7}
    // 7: {keyName: 'VENDOR_KEY', valueName: 'hardygames', id: 8}
    // 8: {keyName: 'CAMPAIGN_ID', valueName: '20', id: 9}
    // 9: {keyName: 'SUB_CATEGORY', valueName: 'hardygamebigwin', id: 10}
    // 10: {keyName: 'APPLICATION_NAME', valueName: 'HardyGames', id: 11}
    // 11: {keyName: 'SESSION_ID_TIMEOUT', valueName: '3000', id: 12}
    // 12: {keyName: 'COUNTRY_NAME', valueName: 'India', id: 13}

    // 'operator':  metaMap.get('OPERATOR'),

    // this.setState({metaMap:JSON.parse(sessionStorage.getItem('metaInfo'))});

    // if (document.fullscreenElement != null) {
    //   document.exitFullscreen()
    // }
    // alert(  sessionStorage.getItem("loginType"));
    // logFirebaseEvents('Home')
    //if(Object.keys(this.props.home).length === 0) this.props.fetchHomePage();
    // this.props.fetchHomePage();

    let tempMap = new Map();
    localForage.iterate((value, key) => {
      if (!tempMap.has(key)) {
        tempMap.set(key, value)
      }
    })
      .then(() => {
        this.setState({
          cachedImage: tempMap
        });
      });


    // localForage.iterate((value, key, iterationNumber) => {
    // //console.log([key, value]);
    // //tempCachedImage.set(key, value);
    //   if(!this.state.cachedImage.has(key)) {
    //     this.setState(({cachedImage}) => ({
    //       cachedImage: new Map(cachedImage).set(key, value)
    //     }))
    //   }
    // });


    console.log('KKKKKKKKKKKKKKKKKK111')
    this.createSession();

    console.log(
      " User Entered time --> " + sessionStorage.getItem("entertime")
    );

    if (sessionStorage.getItem("entertime")) {
      //Check if time is after specified time.

      var enteredTime = sessionStorage.getItem("entertime");
      var d = new Date();
      var currentTime = d.getTime();

      console.log("<==>");
      console.log((currentTime - enteredTime) / 1000);
      console.log(
        (currentTime - enteredTime) / 1000 > constantURL.SPINTHEWHEEL_TIME
      );

      if ((currentTime - enteredTime) / 1000 > constantURL.SPINTHEWHEEL_TIME) {
        this.setState({ isNeedToCheckSTW: true });
        sessionStorage.removeItem("entertime");
      } else {
        this.setState({ isNeedToCheckSTW: false });
      }
    } else {
      var d = new Date();
      var n = d.getTime();
      sessionStorage.setItem("entertime", n);
      this.setState({ isNeedToCheckSTW: false });
    }

    console.log("===ENVIRONMENT======>>>" + constantURL.ENVIRONMENT);

    const cookies = new Cookies();
    console.log("=========>>>" + cookies.get("uniquevhgsession"));

    if (!cookies.get("uniquevhgsession")) {
      var date = new Date();
      date.setTime(date.getTime() + 60 * 1000 * parseInt(metaMap.get('SESSION_ID_TIMEOUT')));

      console.log(date);
      var d = new Date();
      var n = d.getMilliseconds();
      let r = Math.random().toString(36).substring(7);
      const uniqueSessionId = nextId("hardygames-sid-" + n + "" + r);

      cookies.set("uniquevhgsession", uniqueSessionId, { path: "/" });
      // this.saveLogs(constantURL.HOME_ACTION);
      console.log("unique Id");
    } else {
      if (cookies.get("uniquevhgsession") == "0") {
        var date = new Date();
        date.setTime(
          date.getTime() + 60 * 1000 * parseInt(metaMap.get('SESSION_ID_TIMEOUT'))
        );

        console.log(date);
        var d = new Date();
        var n = d.getMilliseconds();
        let r = Math.random().toString(36).substring(7);
        const uniqueSessionId = nextId("hardygames-sid-" + n + "" + r);

        cookies.set("uniquevhgsession", uniqueSessionId, { path: "/" });
        console.log("unique Id");
      }

      console.log("Already exists");
    }

    animObj1 = lottie.loadAnimation({
      container: this.animBox1, // the dom element that will contain the animation
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData, // the path to the animation json
    });
  }

  subsHeaders = {
    appKey: new Map(JSON.parse(sessionStorage.getItem('metaInfo'))).get('APP_KEY_SUBS'),
    operator: new Map(JSON.parse(sessionStorage.getItem('metaInfo'))).get('OPERATOR'),
    Msisdn: sessionStorage.getItem("username"),
    channel: constantURL.CHANNEL,
    Access_token: sessionStorage.getItem("accessToken"),
    deviceId: constantURL.DEVICE_ID,
    "Content-Type": constantURL.APPLICATION_JSON,
    Accept: constantURL.APPLICATION_JSON,
  };

  cachedImageConfigure(contentName, imageUrl) {
    localForage.getItem(contentName)
      .then(returnData => {
        if (!returnData) {
          this.convertImgToBase64URL(imageUrl, function (base64Img) {
            (base64Img) && localForage.setItem(contentName, base64Img)
          });
        }
      })
  }

  handlePopularImageLoaded() {
    const { popularImageLoadCount } = this.state;
    this.setState({ popularImageLoadCount: popularImageLoadCount + 1 });
  }

  convertImgToBase64URL(url, callback, outputFormat) {
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      var canvas = document.createElement('CANVAS'),
        ctx = canvas.getContext('2d'), dataURL;
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
      canvas = null;
    };
    img.src = url;
  }

  handleKeepImageLoaded() {
    const { keepImageLoadCount } = this.state;
    this.setState({ keepImageLoadCount: keepImageLoadCount + 1 });
  }

  handleArcadeImageLoaded() {
    const { arcadeImageLoadCount } = this.state;
    this.setState({ arcadeImageLoadCount: arcadeImageLoadCount + 1 });
  }

  showSpinner() {
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

    const headers = {
      "Content-Type": "application/json",
      mode: "WAP",
    };

    var msisdn = sessionStorage.getItem("username");
    if (msisdn.length > 10) {
      msisdn = msisdn.substr(2, msisdn.length + 1);
    }

    const body = {
      msisdn: msisdn,
      campaignId: metaMap.get('CAMPAIGN_ID'),
      vendorUniqueKey: metaMap.get('VENDOR_KEY'),
    };

    axios
      .post(constantURL.SPINTHEWHEEL_COMBINED_IP + "/v1/spinnerStatus", body, {
        headers,
      })
      .then((response) => response.data)
      .then((data) => {
        if (data && data.code == errorConstantURL.SUCCESS_CODE) {
          console.log(data.data.status);
          if (data.data.status) {
            this.setState({
              isShowSpin: true,
            });
          } else {
            this.setState({
              isShowSpin: false,
            });
          }
        } else {
          this.setState({
            isShowSpin: false,
          });
        }
      });
  }

  componentDidUpdate(prevProps) {

    if (prevProps.logoutPopup !== this.props.logoutPopup) {
      this.setState({
        logoutPopup: this.props.logoutPopup
      })
    }

  }

  componentWillUnmount() {
    clearTimeout(this.loadFlickerTranisition);
    if (document.getElementsByTagName("style")[0].innerHTML.includes('.div-color-red')) document.getElementsByTagName("style")[0].remove();
  }

  showSubscriptionMessage() {
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

    // const headers = {
    //   "Content-Type": "application/json",
    //   msisdn: sessionStorage.getItem("mobileNumber"),
    //   deviceId: constantURL.DEVICE_ID,
    //   channel: constantURL.CHANNEL,
    //   operator: constantURL.OPERATOR,
    //   access_token: sessionStorage.getItem("accessToken"),
    //   appid: sessionStorage["appid"],
    // };

    // var url =
    //   constantURL.CONTEST_ENGINE_COMBINED_IP +
    //   constantURL.CONTEST_GET_CONTEST_META_INFO +
    //   "?category=";

    // axios
    //   .get(url, { headers })
    //   .then((response) => response.data)
    //   .then(
    //     (data) => {
    //       if (data && data.data && data.code == 2000) {
    //         let info = data.data.list[0];
    //         if (info.subscriberStatus.toUpperCase() == "EXPIRED" || info.subscriberStatus.toUpperCase() == "GRACE") {
    //           this.setState({
    //             showPopupOld: true,
    //             msgType: 'A',
    //             isConfirm: 'N',
    //             alertMessage: 'Alert!',
    //             msg: "You need to recharge your account",
    //             loginRedirect: 'N'
    //           });

    //         }
    //       }
    //     });

    const headers = {
      'Content-Type': 'application/json',
      'appkey': metaMap.get('APPKEY'),
      'operator': metaMap.get('OPERATOR'),
      'channel': 'wap',
      'access_token': sessionStorage.getItem("accessToken"),
      'msisdn': sessionStorage.getItem("username"),
      'deviceId': constantURL.DEVICE_ID,

    };

    axios.get(constantURL.SPS_COMBINED_IP + "/v1/checkSubscriptionGeneric?appKey=" + metaMap.get('APPKEY') + "&operator=" + metaMap.get('OPERATOR') + "&msisdn=" + sessionStorage.getItem("username"), { headers }).then(response => response.data)
      .then((data) => {
        if (data) {
          if (data.data.subscribtionStatusCode == '6' || data.data.subscribtionStatusCode == '9') {
            this.setState({
              showPopupOld: true,
              msgType: 'A',
              isConfirm: 'N',
              alertMessage: 'Alert!',
              msg: "Oh! Your STV pack got expired. Please rechage your STV pack again for seamless services.",
              loginRedirect: 'N'
            });
          }
        }
      });


  }

  fetchNewHomeData() {
    this.saveLogs(constantURL.HOME_ACTION);
    const notificationToken = Notification.askForPermissioToReceiveNotifications().then(
      function (response) {
        console.log(
          "====>> Token response from  User to know if registered: ",
          notificationToken
        );
      }
    );

    this.signInUser(sessionStorage.getItem("username"));

    this.props.fetchHomePage();
    this.showSpinner();


    if (/^\d+$/.test(sessionStorage.getItem("username"))) {
      if (sessionStorage.getItem("showSubscriptionMessageDone")) {
        if (sessionStorage.getItem("showSubscriptionMessageDone") == 'false') {
          sessionStorage.setItem("showSubscriptionMessageDone", 'true');
          this.showSubscriptionMessage();
        }
      } else {
        sessionStorage.setItem("showSubscriptionMessageDone", 'true');
        this.showSubscriptionMessage();
      }
    }

    this.getNotificationStatus();
  }


  fetchHomeData() {
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

    const { lables } = this.state;
    this.saveLogs(constantURL.HOME_ACTION);
    const notificationToken = Notification.askForPermissioToReceiveNotifications().then(
      function (response) {
        console.log(
          "====>> Token response from  User to know if registered: ",
          notificationToken
        );
      }
    );

    this.signInUser(sessionStorage.getItem("username"));
    this.showSpinner();

    const headers = {
      "Content-Type": "application/json",
      appid: sessionStorage["appid"],
      deviceId: constantURL.DEVICE_ID,
      operator: metaMap.get('OPERATOR'),
      Access_token: sessionStorage.getItem("accessToken"),
      channel: "wap",
      Msisdn: sessionStorage.getItem("username"),
    };

    axios
      .get(constantURL.CONTENT_COMBINED_IP + "/v1/fetchHomePage", { headers })
      .then((response) => response.data)
      .then((data) => {
        if (data && data.code == errorConstantURL.SUCCESS_CODE) {
          var bannerDetail = data.data.categories[0].banners;
          var subCategoriesDetail = data.data.categories[0].subCategories;
          var banList = [];
          var winnerBanner = [];
          var fantasyBanner = [];

          var popList = [];
          var bundleList = [];
          var keepList = [];
          var arcadeList = [];

          let protocol = window.location.protocol;
          if (bannerDetail) {
            for (var i = 0; i < bannerDetail.length; i++) {
              if (bannerDetail[i].title == "HomeBanner") {
                banList.push({
                  id: bannerDetail[i].id,
                  contentName: bannerDetail[i].contentName,
                  title: bannerDetail[i].bannerImageTitle,
                  showPlayButton: bannerDetail[i].showPlayButton,

                  aliasname: bannerDetail[i].aliasname,
                  bannerName: bannerDetail[i].bannerName,
                  desciption: bannerDetail[i].desciption,
                  imageUrl: bannerDetail[i].imageUrl,
                  savedImageUrl: bannerDetail[i].savedImageUrl,
                  redirectUrl: bannerDetail[i].redirectUrl,
                  // gameurl: protocol == "https:" ? bannerDetail[i].secondarygameurl : bannerDetail[i].gameurl,

                  gameurl: protocol == "https:" ? bannerDetail[i].secondarygameurl : bannerDetail[i].gameurl,
                  bannerType: bannerDetail[i].bannerType,
                  buttonText: bannerDetail[i].buttonText,
                  coinsRequired: bannerDetail[i].coinsRequired,
                  gametype: bannerDetail[i].gametype,
                  checkSubKeyword: bannerDetail[i].checkSubKeyword,
                  chargingKeyword: bannerDetail[i].chargingKeyword,
                  replacedynamicparam: bannerDetail[i].replacedynamicparam,
                  price:
                    bannerDetail[i].chargingcurrency +
                    " " +
                    bannerDetail[i].chargingvalue,
                  bgcolorcode: bannerDetail[i].bgcolorcode,
                  chargingvalue: bannerDetail[i].chargingvalue,
                  validity: bannerDetail[i].validity,
                  isBattle: bannerDetail[i].isBattle,
                });

              } else {
                if (bannerDetail[i].bannerName == "WinnerBanner") {
                  console.log('bannerDetail[i]');
                  console.log(bannerDetail[i])
                  winnerBanner.push({
                    id: bannerDetail[i].bannerId,
                    contentName: bannerDetail[i].bannerId,
                    title: bannerDetail[i].headLine,
                    imageUrl: bannerDetail[i].imageUrl,
                    savedImageUrl: bannerDetail[i].savedImageUrl,
                  });
                }
                if (bannerDetail[i].bannerName == "FantasyBanner") {
                  fantasyBanner.push({
                    id: bannerDetail[i].bannerId,
                    contentName: bannerDetail[i].bannerId,
                    title: bannerDetail[i].headLine,
                    imageUrl: bannerDetail[i].imageUrl,
                    savedImageUrl: bannerDetail[i].savedImageUrl,
                  });
                }
                this.setState({
                  winnerBanner: winnerBanner,
                  fantasyBanner: fantasyBanner
                })
              }

              this.cachedImageConfigure(bannerDetail[i].contentName, (bannerDetail[i].savedImageUrl) ? bannerDetail[i].savedImageUrl : bannerDetail.imageUrl);
            }
          }

          if (subCategoriesDetail) {

            var pG = subCategoriesDetail[0].content3; //Popular Games
            var pGName = subCategoriesDetail[0].subCategoryName;

            var kpG = subCategoriesDetail[1].content3; //Keep playing Games
            var kpGName = subCategoriesDetail[1].subCategoryName;

            var arcadeG = subCategoriesDetail[2].content3; //Keep playing Games
            var arcadeName = subCategoriesDetail[2].subCategoryName;


            var popGSubCatgId = subCategoriesDetail[0].subCategoryId;
            for (var i = 0; i < pG.length; i++) {
              if (pG[i].contentId)
                popList.push({
                  id: "p" + i,
                  title: pG[i].title,
                  aliasname: pG[i].aliasname,
                  noOfUsersDynamic: pG[i].noOfUsersDynamic,
                  previewImageUrl: pG[i].previewImageUrl,
                  savedImageUrl: pG[i].savedImageUrl,
                  gameurl: protocol == "https:" ? pG[i].secondarygameurl : pG[i].gameurl,
                  price: pG[i].chargingcurrency + " " + pG[i].chargingvalue,
                  coinsRequired: pG[i].coinsRequired,
                  gametype: pG[i].gametype,
                  checkSubKeyword: pG[i].checkSubKeyword,
                  chargingKeyword: pG[i].chargingKeyword,
                  replacedynamicparam: pG[i].replacedynamicparam,
                  contentId: pG[i].contentId,
                  contentName: pG[i].contentName,
                  showPlayButton: pG[i].showPlayButton,
                  chargingvalue: pG[i].chargingvalue,
                  validity: pG[i].validity,
                });
              this.cachedImageConfigure(pG[i].contentName, (pG[i].savedImageUrl) ? pG[i].savedImageUrl : pG.previewImageUrl);
            }

            this.setState({
              popularGames: {
                categoryName: pGName,
                gameList: popList,
                categoryId: subCategoriesDetail[0].subCategoryId,
                showCount: subCategoriesDetail[0].showCount
              },
              subCategory: popGSubCatgId,
              banners: banList,
              bundleGamesBanner: bundleList,
            });
            if (this.state.banners.length !== banList) {
              this.setState({
                banners: banList
              });

              this.loadFlickerTranisition = setTimeout(() => {
                var style = document.createElement('style');
                style.innerHTML =
                  '.div-color-red{' +
                  'transition: width .3s, height .3s, margin .3s;' +
                  '}'
                  ;
                var ref = document.querySelector('script');
                ref.parentNode.insertBefore(style, ref);
              }, 1000);
            }

            for (var i = 0; i < kpG.length; i++) {
              keepList.push({
                id: "k" + i,
                title: kpG[i].title,
                aliasname: kpG[i].aliasname,
                noOfUsersDynamic: kpG[i].noOfUsersDynamic,
                previewImageUrl: kpG[i].previewImageUrl,
                savedImageUrl: kpG[i].savedImageUrl,
                gameurl: protocol == "https:" ? kpG[i].secondarygameurl : kpG[i].gameurl,
                price: kpG[i].chargingcurrency + " " + kpG[i].chargingvalue,
                coinsRequired: kpG[i].coinsRequired,
                gametype: kpG[i].gametype,
                checkSubKeyword: kpG[i].checkSubKeyword,
                chargingKeyword: kpG[i].chargingKeyword,
                replacedynamicparam: kpG[i].replacedynamicparam,
                contentId: kpG[i].contentId,
                contentName: kpG[i].contentName,
                chargingvalue: kpG[i].chargingvalue,
                validity: kpG[i].validity,
                showPlayButton: kpG[i].showPlayButton,
              });
              this.cachedImageConfigure(kpG[i].contentName, (kpG[i].savedImageUrl) ? kpG[i].savedImageUrl : kpG.previewImageUrl);
            }
            this.setState({
              keepPlaying: {
                categoryName: kpGName,
                gameList: keepList,
                showCount: subCategoriesDetail[1].showCount,
                categoryId: subCategoriesDetail[1].subCategoryId,
              }
            });

            for (var i = 0; i < arcadeG.length; i++) {
              arcadeList.push({
                id: "a" + i,
                title: arcadeG[i].title,
                aliasname: arcadeG[i].aliasname,
                noOfUsersDynamic: arcadeG[i].noOfUsersDynamic,
                previewImageUrl: arcadeG[i].previewImageUrl,
                savedImageUrl: arcadeG[i].savedImageUrl,
                gameurl: protocol == "https:" ? arcadeG[i].secondarygameurl : arcadeG[i].gameurl,
                price: arcadeG[i].chargingcurrency + " " + arcadeG[i].chargingvalue,
                coinsRequired: arcadeG[i].coinsRequired,
                gametype: arcadeG[i].gametype,
                checkSubKeyword: arcadeG[i].checkSubKeyword,
                chargingKeyword: arcadeG[i].chargingKeyword,
                replacedynamicparam: arcadeG[i].replacedynamicparam,
                contentId: arcadeG[i].contentId,
                contentName: arcadeG[i].contentName,
                chargingvalue: arcadeG[i].chargingvalue,
                validity: arcadeG[i].validity,
                showPlayButton: arcadeG[i].showPlayButton,
              });

              this.cachedImageConfigure(arcadeG[i].contentName, (arcadeG[i].savedImageUrl) ? arcadeG[i].savedImageUrl : arcadeG.previewImageUrl);
            }
            this.setState({
              arcadeGames: {
                categoryName: arcadeName,
                gameList: arcadeList,
                showCount: subCategoriesDetail[2].showCount,
                categoryId: subCategoriesDetail[2].subCategoryId,
              }
            });


            this.setState({ enableLoader: false });
          }
          if (/^\d+$/.test(sessionStorage.getItem("username"))) {
            if (sessionStorage.getItem("showSubscriptionMessageDone")) {
              if (sessionStorage.getItem("showSubscriptionMessageDone") == 'false') {
                sessionStorage.setItem("showSubscriptionMessageDone", 'true');
                this.showSubscriptionMessage();
              }
            } else {
              sessionStorage.setItem("showSubscriptionMessageDone", 'true');
              this.showSubscriptionMessage();
            }
          }
        } else {
          sessionStorage.removeItem("isLogin");
          sessionStorage.removeItem("isGuestLogin");
          this.setState({ enableLoader: false });

          this.setState({
            logoutPopup: true,
          });
        }
        this.getNotificationStatus();
      });
  }

  openSubCategory = (category, from) => {
    console.log('category==============');
    console.log(category.categoryName);
    console.log('category==============');

    if (category.categoryName == "Sports Games") {
      logEvents(constantURL.SPOR_G_VIEW_ALL);
    }
    if (category.categoryName == "Casual Games") {
      logEvents(constantURL.CAS_G_VIEW_ALL);
    }
    if (category.categoryName == "Arcade Games") {
      logEvents(constantURL.ARC_G_VIEW_ALL);
    }


    // ;
    category.fromHome = true;
    this.props.history.push({
      pathname: Constant.FRONTEND_NAME + "/paidgames",
      state: {
        currentCategory: category,
        from: from ? from : "home"
      },
    });
  };

  createSession() {


    const isLogin = sessionStorage.getItem("isLogin");
    if (isLogin == null || isLogin == "false") {
      const values = queryString.parse(this.props.location.search);
      var msisdn = values.msisdn;
      if (typeof msisdn === "undefined") {
        sessionStorage.setItem("loginType", "guest")
      } else {
        sessionStorage.setItem("loginType", "normal")
      }
    }
    if (sessionStorage.getItem("loginType") && (sessionStorage.getItem("loginType") != "guest")) {

      console.log('HERE')
      const cookies = new Cookies();
      const isLogin = sessionStorage.getItem("isLogin");
      if (isLogin == null || isLogin == 'false') {
        //Check if msisdn is in URL header. if yes then login by pass else guest login
        // if (constantURL.ENVIRONMENT != 'PROD') {
        const values = queryString.parse(this.props.location.search);
        var msisdn = values.msisdn;

        // if (typeof msisdn === "undefined") {
        //     this.loginByPass('empty');
        // } else {
        this.loginByPass(msisdn);
        // }
        // this.guestUserLogin();
        //    this.props.history.push(FRONTEND_NAME+"/errorlogin")
        // } else {
        //     this.loginByPass('empty');
        // }
      } else {
        //this.fetchHomeData();
        //this.props.fetchHomePage();
        this.fetchNewHomeData();
      }
    } else {
      this.guestUserLogin();
    }
  }

  loginByPass(msisdn) {
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

    console.log('HEE')
    const cookies = new Cookies();

    var url = constantURL.PROFILER_COMBINED_IP + "/v1/loginbypass";

    var headers = null;
    if (msisdn == "empty") {
      headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        appid: metaMap.get('APP_ID'),
      };
    } else {
      headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        msisdn: msisdn,
        appid: metaMap.get('APP_ID'),
      };
    }

    console.log(headers)

    axios
      .get(url, {
        headers: headers,
      })
      .then((response) => response.data)
      .then((data) => {
        if (data && data.code == 2000) {
          console.log("A");
          var userDetail = data.data;
          this.createLoginSession(userDetail);
        } else {
          console.log("B");
          if (cookies.get("hardyIsLogin")) {
            sessionStorage.setItem("isLogin", 'true');
            sessionStorage.setItem(
              "accessToken",
              cookies.get("hardyAccessToken")
            );
            sessionStorage.setItem(
              "mobileNumber",
              cookies.get("hardyMobileNumber")
            );
            sessionStorage.setItem("operator", cookies.get("hardyOperator"));
            sessionStorage.setItem("country", cookies.get("hardyCountry"));
            sessionStorage.setItem("username", cookies.get("hardyUsername"));
            sessionStorage.setItem(
              "nameSalutation",
              cookies.get("hardyNameSalutation")
            );

            this.signInUser(cookies.get("hardyMobileNumber"));
            //this.fetchHomeData();
            //this.props.fetchHomePage();
            this.fetchNewHomeData();
          } else {
            // this.setState({logoutPopup:true})
            //   this.props.history.push(FRONTEND_NAME+"/errorlogin")
            this.props.history.push(FRONTEND_NAME + "/sociallogin");
          }
        }
      });
  }

  getNotificationStatus() {
    const url = `${Constant.GET_USER_NOTIFICATION_API_URL}`;
    const headers = {
      "Content-Type": Constant.APPLICATION_JSON,
      Accept: Constant.APPLICATION_JSON,
      deviceId: sessionStorage.getItem("deviceId"),
      channel: "web",
    };

    const requestBody = {
      msisdn: sessionStorage.getItem("username"),
      deviceId: sessionStorage.getItem("deviceId"),
      adhoc: true,
      appName: "Generichardygames",
      // "appName":"Tambola"
    };

    axios
      .post(url, requestBody, { headers })
      .then((response) => response.data)
      .then((data) => {
        if (data && data.code == 2000) {
          console.log(data.unreadCount);
          this.setState({
            unreadCount: data.unreadCount,
          });
        } else {
          this.setState({
            unreadCount: 0,
          });
        }
      });
  }

  saveLogs(category) {
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

    const cookies = new Cookies();
    var url = constantURL.APPLOGGER_COMBINED_IP + "/v1/saveLogs";

    const headers = {
      "Content-Type": "application/json",
      appid: metaMap.get('APP_ID'),
      operator: metaMap.get('OPERATOR'),
      msisdn: sessionStorage.getItem("username"),
      channel: "WAP",
    };

    const requestBody = {
      category: category,
      subCategory: "",
      content: category,
      url: "/home",
      packname: "",
      appname: metaMap.get('APPLICATION_NAME'),
      sessionid: cookies.get("uniquevhgsession"),
    };

    axios
      .post(url, requestBody, { headers })
      .then((response) => response.data)
      .then((data) => {
        if (data && data.code == errorConstantURL.SUCCESS_CODE) {
        } else {
        }
      });
  }

  createLoginSession(data) {
    console.log("C");

    const cookies = new Cookies();
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 1);

    cookies.set("hardyIsLogin", true, { path: "/" });
    cookies.set("hardyAccessToken", data.accessToken, { path: "/" });
    cookies.set("hardyMobileNumber", data.username, { path: "/" });
    cookies.set("hardyOperator", data.operator.id, { path: "/" });
    cookies.set("hardyCountry", data.country.id, { path: "/" });
    cookies.set("hardyUsername", data.username, { path: "/" });

    sessionStorage.setItem("isLogin", 'true');
    sessionStorage.removeItem("isGuestLogin");
    sessionStorage.setItem("accessToken", data.accessToken);
    sessionStorage.setItem("mobileNumber", data.username);
    sessionStorage.setItem("operator", data.operator.id);
    sessionStorage.setItem("country", data.country.id);
    sessionStorage.setItem("username", data.username);
    if (data.dateOfBirth) {
      var dob = data.dateOfBirth;
      var dob1 = dob.split("T");
      var dob2 = dob1[0].split("-");
      sessionStorage.setItem("dob", dob2[2] + "/" + dob2[1] + "/" + dob2[0]);
    }
    sessionStorage.setItem("gender", data.gender);
    sessionStorage.setItem("emailid", data.email);
    sessionStorage.setItem("address", data.address);

    if (data.nickName) {
      sessionStorage.setItem("nameSalutation", data.nickName);
      cookies.set("hardyNameSalutation", data.nickName, { path: "/" });
    } else {
      sessionStorage.setItem("nameSalutation", data.username);
      cookies.set("hardyNameSalutation", data.username, { path: "/" });
    }
    if (data.userImagePath != null) {
      sessionStorage.setItem("profileImg", data.userImagePath);
      cookies.set("hardyProfileImg", data.userImagePath, { path: "/" });
    }
    // this.saveLogs(constantURL.LOGIN_ACTION);

    console.log("D");

    console.log(
      sessionStorage.getItem("accessToken") +
      " = " +
      sessionStorage.getItem("username")
    );

    //this.fetchHomeData();
    // this.props.fetchHomePage();
    this.fetchNewHomeData();
  }


  getGuestMetaInfo() {

    const headers = {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'ip': "127.0.0.1",
      'msisdn': "",
    };


    const url = constantURL.PROFILER2_COMBINED_IP + "/v3/getMetaInfo?isWifi=false&gameType=HardyGames&countryCode=91&isSocialLogin=true";


    axios.get(url, { headers }).then(response => response.data)
      .then((data) => {
        console.log(data);
        let map = new Map();
        if (data && data.code == 2000) {
          var metaInfoData = data.data.metaKeyValue;
          console.log((data.data.metaKeyValue));

          for (var i = 0; i < metaInfoData.length; i++) {
            map.set(metaInfoData[i].keyName, metaInfoData[i].valueName);
          }

          var jsonText = JSON.stringify(Array.from(map.entries()));
          sessionStorage.setItem('metaInfo', jsonText);

          var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

          const headers = {
            "Content-Type": "application/json",
            appid: metaMap.get('APP_ID'),
            deviceId: constantURL.DEVICE_ID,
            operator: metaMap.get('OPERATOR'),
          };

          axios
            .post(
              constantURL.PROFILER_COMBINED_IP + "/v2/guestLogin",
              {},
              { headers }
            )
            .then((response) => response.data)
            .then((data) => {
              if (data && data.code == errorConstantURL.SUCCESS_CODE) {
                sessionStorage.setItem("loginType", "guest");
                var guestUserDetail = data.data;
                sessionStorage.setItem("isGuestLogin", true);
                sessionStorage.setItem("username", guestUserDetail.user.username);
                sessionStorage.setItem(
                  "accessToken",
                  guestUserDetail.user.accessToken
                );
                sessionStorage.setItem("nameSalutation", "Guest");
                sessionStorage.setItem("mobileNumber", guestUserDetail.user.username);

                //this.fetchHomeData();
                // this.props.fetchHomePage();
                this.fetchNewHomeData();
              } else {
                this.setState({
                  showPopup: true,
                  msgType: "E",
                  msg: "Error in creating guest session!",
                });
              }
            });

        }
      })
      .catch(err => {
      })
  }


  guestUserLogin() {
    this.getGuestMetaInfo();
  }

  signInUser(msisdn) {
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

    const cookies = new Cookies();


    var url = '';

    if (sessionStorage.getItem("loginType") == "social") {
      url = constantURL.PROFILER_COMBINED_IP +
        "/v1/fetchUserDetailsSocial?username=" +
        msisdn;
    }
    else {
      url = constantURL.PROFILER_COMBINED_IP +
        "/v1/editUserDetails?username=" +
        msisdn;
    }

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      msisdn: msisdn,
      access_token: sessionStorage.getItem("accessToken"),
      appid: metaMap.get('APP_ID'),
    };
    axios
      .get(url, {
        headers: headers,
      })
      .then((response) => response.data)
      .then((data) => {
        if (data && data.code == 2000) {
          if (data.data.userImagePath != null) {
            cookies.set("bsnlusername", (data.data.nickName == null || data.data.nickName == "null") ? "" : data.data.nickName, { path: "/" });
            cookies.set("Bsnlprofilepic", data.data.userImagePath, { path: "/" });
            sessionStorage.setItem("profileImg", data.data.userImagePath);
          } else {
            sessionStorage.setItem("profileImg", clownLogo);
            cookies.set("bsnlusername", (data.data.nickName == null || data.data.nickName == "null") ? "" : data.data.nickName, { path: "/" });
            cookies.set("Bsnlprofilepic", "", { path: "/" });
          }
        } else {
          if (data.userImagePath != null) {
            cookies.set("bsnlusername", (data.data.nickName == null || data.data.nickName == "null") ? "" : data.data.nickName, { path: "/" });
            cookies.set("Bsnlprofilepic", "", { path: "/" });
            sessionStorage.setItem("profileImg", clownLogo);
          }
        }
      });
  }

  onDisplayMenu(obj) {
    this.setState({ isShowMenu: !this.state.isShowMenu });
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup,
    });
    // if (this.state.loginRedirect == "Y") {
    //   this.props.history.push(FRONTEND_NAME + "/login");
    // }

    if (this.state.loginRedirect == "C") {
      this.deduct();
    }
    if (this.state.loginRedirect == 'E') {
      this.props.history.push(FRONTEND_NAME + "/editprofilenew")
    }
  }

  togglePop() {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  }
  openProfile() {
    if (sessionStorage.getItem("loginType") && (sessionStorage.getItem("loginType") == "normal" || sessionStorage.getItem("loginType") == "social")) {
      this.props.history.push(FRONTEND_NAME + "/profile");
    } else {
      this.setState({
        showPopupOld: true,
        msgType: 'E',
        isConfirm: 'Y',
        alertMessage: 'Alert!',
        msg: "You need to be logged in to access this feature. Do you wish to proceed?",
        loginRedirect: 'LOGIN'
      });

    }
  }

  // checkUserSubscription = (gameToPlay, headers) => {
  //     console.log("current game is : ", this.state.currentSelectedGame)
  //     const url = `${constantURL.SPS_COMBINED_IP}${constantURL.CHECK_SUBS_API_PARAM}${gameToPlay.checkSubKeyword}`
  //     console.log('Url to hit for checksubs : ', url)
  //     this.setState({ enableLoader: true })

  //     axios.get(url, { headers })
  //         .then(response => response.data)
  //         .then(data => {
  //             if (data && data.code === errorConstantURL.SUCCESS_CODE) {
  //                 console.log(data.data)
  //                 if (data.data.subscriberStausCode == "0" || data.data.subscriberStatus === constantURL.ACTIVE) {
  //                     console.log("User is Subscribed for selected Game, No need for confirmartion, redirecting to game directly")
  //                     this.openRequiredGame(gameToPlay);
  //                 } else if (data.data.subscriberStausCode == "1" || data.data.subscriberStatus === constantURL.NOT_SUBSCRIBED) {
  //                     console.log("User is NOT Subscribed  for selected Game, taking user confirmartion using popup ")
  //                     console.log('If Confirmation is Yes, then call charginKeywordSub API for payment and then redirect to game')
  //                     this.saveGameLogs(gameToPlay);
  //                     this.togglePopupNew(constantURL.CONFIRM_PAYMENT_MESSAGE.replace("<xx>", gameToPlay.price.split(' ')[1]), "A", constantURL.CONFIRM_PAYMENT_ALERT, ['3', '4'])

  //                     // this.togglePopupNew("Note: You will be charged "+gameToPlay.coinsRequired+" INR for playing this game", "A", constantURL.CONFIRM_PAYMENT_ALERT, ['2','3'])
  //                 } else {
  //                     console.log("User status is pending, showing pending popup for other response code")
  //                     this.saveGameLogs(gameToPlay);
  //                     this.togglePopupNew(constantURL.PENDING_SUBSCRIPTION_MESSAGE, "A", constantURL.PENDING_SUBSCRIPTION_ALERT, ['1'])
  //                     // this.togglePopup(Constant.PENDING_SUBSCRIPTION_MESSAGE, "A", Constant.PENDING_SUBSCRIPTION_ALERT, ['1'])
  //                 }
  //             } else {
  //                 console.log('Some failure response : ', data);
  //                 this.saveGameLogs(gameToPlay);
  //                 this.togglePopupNew(constantURL.ERROR_OCCURED, 'E', constantURL.ERROR_TYPE_ALERT, ['1'])
  //             }
  //             this.setState({ enableLoader: false })

  //         })
  //         .catch(error => {
  //             this.setState({ enableLoader: false })
  //             console.log("Error Occured : ", error)
  //             this.saveGameLogs(gameToPlay);
  //             this.togglePopupNew(constantURL.ERROR_OCCURED, 'E', constantURL.ERROR_TYPE_ALERT, ['1'])
  //         })
  // }

  checkUserSubscription = (gameToPlay, headers1, typeOfAction) => {
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

    // this.setState({actionType:typeOfAction});
    const { lables } = this.state;
    sessionStorage.setItem("actionType", typeOfAction);
    var url = Constant.SPS_COMBINED_IP + "/v2/checkCoinsAndSub";
    const headers = {
      appkey: metaMap.get('APP_KEY_SUBS'),
      "Content-Type": Constant.APPLICATION_JSON,
      appid: sessionStorage["appid"],
      deviceId: Constant.DEVICE_ID,
      operator: metaMap.get('OPERATOR'),
      access_token: sessionStorage.getItem("accessToken"),
      channel: Constant.CHANNEL,
      countryCode: metaMap.get('COUNTRY_CODE'),
      msisdn: sessionStorage.getItem("username"),
    };

    const body = {
      msisdn: sessionStorage.getItem("username"),
      countryCode: metaMap.get('COUNTRY_CODE'),
      packName: gameToPlay.checkSubKeyword,
      rewardName: "coin",
      rewardFrom: "ABC",
      operation: "debit",
      rewardCount: 1,
      blockRewardId: "",
      info: "Deducted for " + gameToPlay.title,
      operationSource: "wap",
      appGameName: gameToPlay.title,
      contentName: gameToPlay.contentName,
    };

    axios
      .post(url, body, { headers })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        if (data && data.code == errorConstantURL.SUCCESS_CODE) {
          if (typeOfAction == "bundle") {
            this.openSubCategory(gameToPlay, "bundle");
          } else {
            this.openRequiredGame(gameToPlay);
          }
        } else if (
          data &&
          data.code == errorConstantURL.REQUIRED_COINS_AVAILABLE
        ) {
          this.togglePopupNew(
            lables.yourLoyaltyCoinsWillBeDeducted.replace(
              "<xx>",
              gameToPlay.coinsRequired
            ),
            "A",
            lables.confirmPayment,
            ["3", "4"]
          );
        } else if (data && data.code == errorConstantURL.INSUFFICIENT_COINS) {
          this.togglePopupNew(
            lables.toAccessThisGameYouNeedToPay.replace(
              "<xx>",
              "AED " + gameToPlay.chargingvalue
            ).replace("<yy>", gameToPlay.validity),
            "A",
            lables.confirmPayment,
            ["3", "4"]
          );
        } else {
          this.togglePopupNew(
            lables.errorInOperation,
            "E",
            lables.error,
            ["1"]
          );
        }
      });

    /*
        console.log("current game is : ", this.state.currentSelectedGame)
        const url =`${Constant.SPS_COMBINED_IP}${Constant.CHECK_SUBS_API_PARAM}${gameToPlay.checkSubKeyword}` 
        console.log('Url to hit for checksubs : ', url)

        axios.get(url, {headers})
            .then(response => response.data)
            .then(data => {
                if(data && data.code === errorConstantURL.SUCCESS_CODE ){
                    console.log(data.data)
                    if(data.data.subscriberStausCode == "0" || data.data.subscriberStatus === Constant.ACTIVE){
                        console.log("User is Subscribed for selected Game, No need for confirmartion, redirecting to game directly")
                       // window.open(gameToPlay.gameurl, "_blank")
                       this.openRequiredGame(gameToPlay);
                    }else if(data.data.subscriberStausCode == "1" || data.data.subscriberStatus === Constant.NOT_SUBSCRIBED){
                        this.saveGameLogs(gameToPlay)
                        console.log("User is NOT Subscribed  for selected Game, taking user confirmartion using popup ")
                        console.log('If Confirmation is Yes, then call charginKeywordSub API for payment and then redirect to game')
                        this.togglePopup(Constant.CONFIRM_PAYMENT_MESSAGE.replace("<xx>", gameToPlay.chargingvalue), "A", Constant.CONFIRM_PAYMENT_ALERT, ['3','4'])
                    }else{
                        this.saveGameLogs(gameToPlay)
                        console.log("User status is pending, showing pending popup for other response code")
                        this.togglePopup(Constant.PENDING_SUBSCRIPTION_MESSAGE, "A", Constant.PENDING_SUBSCRIPTION_ALERT, ['1'])
                    }
                }else {
                    this.saveGameLogs(gameToPlay)
                        console.log('Some failure response : ', data);
                        this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
                    }
            })
            .catch(error => {
                console.log("Error Occured : ", error)
                this.saveGameLogs(gameToPlay)
                    
                this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
            })
            */
  };

  openRequiredGame = (gameToPlay) => {

    console.log("inside openRequiredGame method ");
    if (gameToPlay.isBattle) {
      this.props.history.push({
        pathname: FRONTEND_NAME + "/battle",
        state: {
          game: {
            id: "games",
            title: gameToPlay.title,
            previewImageUrl: gameToPlay.imageUrl,
            gameurl: gameToPlay.gameurl,
            replacedynamicparam: gameToPlay.replacedynamicparam,
            secondarygameurl: gameToPlay.gameurl,
          },
        },
      });
    } else {
      this.props.history.push({
        pathname: FRONTEND_NAME + "/gameview",
        state: {
          game: gameToPlay,
          gameScreen: "home",
        },
      });
    }
  };



  saveGameLogs(detail) {
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

    const cookies = new Cookies();
    var url = constantURL.APPLOGGER_COMBINED_IP + "/v1/saveDownloadLogs";

    const headers = {
      "Content-Type": "application/json",
      appid: metaMap.get('APP_ID'),
      operator: metaMap.get('OPERATOR'),
      msisdn: sessionStorage.getItem("username"),
      channel: "WAP",
    };

    const requestBody = {
      content_name: detail.title,
      session_id: cookies.get("uniquevhgsession"),
      country: "India",
      circle: "All",
      packname: detail.chargingKeyword,
      appname: metaMap.get('APPLICATION_NAME'),
      content_type: "game",
      param1: detail.gametype,
      param2: "",
      substatus: "",
      winningStatus: "",
      gameStatus: "FAILURE",
      response: "Success",
      dwn_flag: "0",
    };

    axios
      .post(url, requestBody, { headers })
      .then((response) => response.data)
      .then((data) => {
        if (data && data.code == errorConstantURL.SUCCESS_CODE) {
        } else {
        }
      });
  }

  checkSubscriptionAndMakePayment = (gameToPlay, typeOfAction) => {
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

    // alert('AA')
    var subsHeaders = {
      appKey: metaMap.get('APP_KEY_SUBS'),
      operator: metaMap.get('OPERATOR'),
      Msisdn: sessionStorage.getItem("username"),
      channel: constantURL.CHANNEL,
      Access_token: sessionStorage.getItem("accessToken"),
      deviceId: constantURL.DEVICE_ID,
      "Content-Type": constantURL.APPLICATION_JSON,
      Accept: constantURL.APPLICATION_JSON,
    };

    console.log(subsHeaders);
    this.checkUserSubscription(gameToPlay, subsHeaders, typeOfAction);
  };

  // submitPaymentSubscription = () => {
  //     const url = `${constantURL.SPS_COMBINED_IP}${constantURL.SUBS_PACK_API_PARAM}${this.state.currentSelectedGame.chargingKeyword}`

  //     this.setState({ enableLoader: true })

  //     var headers = {
  //         'appKey': constantURL.APP_KEY_SUBS,
  //         'operator': constantURL.OPERATOR,
  //         'Msisdn': sessionStorage.getItem("mobileNumber"),
  //         'channel': constantURL.CHANNEL,
  //         'Access_token': sessionStorage.getItem("accessToken"),
  //         'deviceId': constantURL.DEVICE_ID,
  //         'Content-Type': constantURL.APPLICATION_JSON,
  //         'Accept': constantURL.APPLICATION_JSON
  //     }

  //     axios.get(url, { headers })
  //         .then(response => response.data)
  //         .then(data => {
  //             if (data && data.code === errorConstantURL.SUCCESS_CODE) {
  //                 console.log(data.data)
  //                 if (data.data.subscriptionStatusCode === 0 || data.data.subscribtionStatus === constantURL.ACTIVE) {
  //                     console.log("User has successfully made payment for selected Game, redirecting to game directly")
  //                     this.togglePopupNew(constantURL.PAYMENT_SUCCESSFUL_MESSAGE, "S", constantURL.PAYMENT_SUCCESSFUL_ALERT, ['1']);
  //                 } else {
  //                     console.log("User status is pending, showing pending popup for other response code")
  //                     this.togglePopupNew(constantURL.PAYMENT_UNSUCCESSFUL_MESSAGE, "E", constantURL.PAYMENT_UNSUCCESSFUL_ALERT, ['1'])
  //                 }
  //             } else {
  //                 console.log('Some failure response : ', data);
  //                 this.togglePopupNew(constantURL.ERROR_OCCURED, 'E', constantURL.ERROR_TYPE_ALERT, ['1'])
  //             }
  //             this.setState({ enableLoader: false })
  //         })
  //         .catch(error => {
  //             this.setState({ enableLoader: false })
  //             console.log("Error Occured : ", error)
  //             this.togglePopupNew(constantURL.ERROR_OCCURED, 'E', constantURL.ERROR_TYPE_ALERT, ['1'])
  //         })

  // }

  deductCoins(data1) {

    sessionStorage.setItem('validity', data1.validity);
    sessionStorage.setItem("rewardCount", data1.coinsRequired);
    sessionStorage.setItem("info", data1.title);
    sessionStorage.setItem("gameurl", data1.gameurl);

    const isLogin = sessionStorage.getItem("isLogin");
    if (!isLogin) {

      this.setState({
        showPopupOld: true,
        msgType: 'A',
        isConfirm: 'Y',
        alertMessage: 'Login Required!',
        msg: "You need to be logged in to access this feature. Do you wish to proceed?",
        loginRedirect: 'Y'
      });
    } else {

      this.setState({
        showPopupOld: true,
        msgType: 'A',
        isConfirm: 'Y',
        // alertMessage: 'Confirm Payment!',
        // msg: "You will be charged " + data1.coinsRequired + " coins for playing this game. Please confirm to play game.",
        alertMessage: 'Confirm Coin Deduction!',
        msg: "You will be charged " + data1.coinsRequired + " coins for " + data1.title,
        loginRedirect: 'C'
      });
    }

  }


  deduct() {
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

    var CurrentDate = moment().add(sessionStorage.getItem('validity'), 'd');
    // var CurrentDate = moment().add(1, 'd');


    var day = CurrentDate.format('DD');
    var month = CurrentDate.format('MM');
    var year = CurrentDate.format('YYYY');
    var hh = CurrentDate.format('HH');
    var mm = CurrentDate.format('mm');
    var ss = CurrentDate.format('ss');

    var validTill = year + '-' + month + '-' + day + 'T' + hh + ':' + mm + ':' + ss + '.000+0530'

    console.log(validTill + '--')


    const headers = {
      'Content-Type': 'application/json',
      'appkey': metaMap.get('APP_KEY_SUBS'),
      'operator': metaMap.get('OPERATOR'),
      'msisdn': sessionStorage.getItem("username")
    };

    const body = {
      "rewardName": "coin",
      "rewardFrom": "tambola_win",
      "operation": "debit",
      "rewardCount": sessionStorage.getItem("rewardCount"),
      "info": "Deducted for " + sessionStorage.getItem("info"),
      "operationSource": "WAP",
      "appGameName": sessionStorage.getItem("info"),
      "appGameValidity": validTill
    }

    this.setState({ enableLoader: true })

    axios.post(constantURL.SPS_COMBINED_IP + "/v1/userOperation", body, { headers }).then(response => response.data)
      .then((data) => {
        if (data && (data.code == 2000)) {

          logEvents(constantURL.SPEND_VIRTUAL_CURRENCY)

          this.setState({
            showPopupOld: false,
          });

          this.openRequiredGame(this.state.currentSelectedGame);

        } else if (data.code == 2004) {

          this.setState({
            showPopupOld: true,
            msgType: 'E',
            isConfirm: 'N',
            alertMessage: 'Sorry!',
            msg: "You don't have sufficent coin balance to play this game! Please add more coins to your account.",
            loginRedirect: 'INC'
          });
        } else {
          this.setState({ logoutPopup: true })
        }
        this.setState({ enableLoader: false })
        sessionStorage.removeItem("rewardCount");
        sessionStorage.removeItem("info");
        sessionStorage.removeItem("validity");

        sessionStorage.removeItem("gameurl");

      });
  }



  submitPaymentSubscription = () => {
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

    const { lables } = this.state;
    var url = Constant.SPS_COMBINED_IP + "/v2/deductCoinsOrSub";
    const headers = {
      appkey: metaMap.get('APP_KEY_SUBS'),
      "Content-Type": Constant.APPLICATION_JSON,
      appid: sessionStorage["appid"],
      deviceId: Constant.DEVICE_ID,
      operator: metaMap.get('OPERATOR'),
      access_token: sessionStorage.getItem("accessToken"),
      channel: Constant.CHANNEL,
      countryCode: metaMap.get('COUNTRY_CODE'),
      msisdn: sessionStorage.getItem("username"),
    };

    const body = {
      msisdn: sessionStorage.getItem("username"),
      countryCode: metaMap.get('COUNTRY_CODE'),
      packName: this.state.currentSelectedGame.checkSubKeyword,
      countryCode: metaMap.get('COUNTRY_CODE'),
      packName: this.state.currentSelectedGame.checkSubKeyword,
      rewardName: "coin",
      rewardFrom: "ABC",
      operation: "debit",
      rewardCount: 1,
      blockRewardId: "",
      info: "Deducted for " + this.state.currentSelectedGame.title,
      operationSource: "wap",
      appGameName: this.state.currentSelectedGame.title,
      contentName: this.state.currentSelectedGame.contentName,
    };

    axios
      .post(url, body, { headers })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        if (data && data.code == errorConstantURL.SUCCESS_CODE) {
          if (sessionStorage.getItem("actionType") == "bundle") {
            this.togglePopupNew(
              lables.yourPaymentIsSuccessfull.replace(
                "<xx>",
                data.data.subscribtionEndDate
              ),
              "S",
              lables.congratulations,
              ["1"]
            );
          } else {
            this.togglePopupNew(
              lables.successfullyChargedClickOkToPlay,
              "S",
              lables.congratulation,
              ["1"]
            );
          }
        } else if (data && data.code == errorConstantURL.LOW_BALANCE_CODE) {
          this.togglePopupNew(
            lables.paymentFailedInsuffBal,
            "E",
            lables.tryAgain,
            ["1"]
          );
        } else {
          // this.togglePopupNew(Constant.PAYMENT_SUCCESSFUL_MESSAGE, "S", Constant.PAYMENT_SUCCESSFUL_ALERT, ['1']);

          this.togglePopupNew(
            lables.paymentFailedTryAfterSometime,
            "E",
            lables.tryAgain,
            ["1"]
          );
        }
      });

    // const url = `${Constant.SPS_COMBINED_IP}${Constant.SUBS_PACK_API_PARAM}${this.state.currentSelectedGame.chargingKeyword}`
    // console.log('Url to hit for chargingKeyword payment : ', url)

    // const headers = this.subsHeaders;

    // axios.get(url, { headers })
    //     .then(response => response.data)
    //     .then(data => {
    //         if (data && data.code === errorConstantURL.SUCCESS_CODE) {
    //             console.log(data.data)
    //             if (data.data.subscriptionStatusCode === 0 || data.data.subscribtionStatus === Constant.ACTIVE) {
    //                 console.log("User has successfully made payment for selected Game, showing con gratulation popup "
    //                     + " and then redirecting to game directly")

    //                 this.togglePopup(Constant.PAYMENT_SUCCESSFUL_MESSAGE, "S", Constant.PAYMENT_SUCCESSFUL_ALERT, ['1']);

    //             }
    //             else {
    //                 console.log("User status is pending, showing pending popup for other response code")
    //                 this.togglePopup(Constant.PAYMENT_UNSUCCESSFUL_MESSAGE, "E", Constant.PAYMENT_UNSUCCESSFUL_ALERT, ['1'])
    //             }

    //         } else if (data && data.code === errorConstantURL.NO_DATA_FOUND) {
    //             if (data.data.requestStatus == '1') {
    //                 this.openRequiredGame(this.state.currentSelectedGame);
    //             } else {
    //                 console.log('Some failure response : ', data);
    //                 this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
    //             }
    //         }
    //         else {
    //             console.log('Some failure response : ', data);
    //             this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
    //         }
    //     })
    //     .catch(error => {
    //         console.log("Error Occured : ", error)
    //         this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
    //     })
  };

  openSocialMessage =() =>{
    sessionStorage.setItem('mobNo', 'null');
    this.props.history.push(FRONTEND_NAME + "/editprofilenew")
   }

  redirectToRequiredGame = (gameToPlay, typeOfAction, index) => {

    if (typeOfAction == 'banner') {
      logEvents(constantURL.CAROUSEL_ACTIVITY_ + (index + 1));
    }

    if (sessionStorage.getItem("loginType") == "social") {
      sessionStorage.setItem('mobNo', 'null');
      this.setState({
        showPopupOld: true,
        msgType: 'A',
        isConfirm: 'Y',
        alertMessage: 'Alert!',
        msg: "Mobile number is mandatory to access this functionality. Please provide your mobile number.",
        // msg: "You will be charged " + data1.coinsRequired + " coins for playing this game. Please confirm to play game.",
        loginRedirect: 'EP'
      });
      return;
    }


    const { lables } = this.state;
    sessionStorage.removeItem("actionType");

    // ==
    // if (typeOfAction == "bundle") {

    //   this.openSubCategory(gameToPlay);
    //   return;
    // }

    // alert(this.flkty.selectedIndex)

    flickyIndex = this.flkty.selectedIndex;

    if (typeOfAction == "bundle") {
      this.setState({
        currentSelectedGame: gameToPlay,
      });
      // sessionStorage.setItem("gameToPlayBundle",gameToPlay);
      this.checkSubscriptionAndMakePayment(gameToPlay, typeOfAction);
    } else {

      if (
        !gameToPlay.gametype ||
        gameToPlay.gametype.toUpperCase() === constantURL.GAME_TYPE_FREE
      ) {
        if (gameToPlay.isBattle) {
          this.props.history.push({
            pathname: FRONTEND_NAME + "/battle",
            state: {
              game: {
                id: "games",
                title: gameToPlay.title,
                previewImageUrl: gameToPlay.imageUrl,
                gameurl: gameToPlay.gameurl,
                replacedynamicparam: gameToPlay.replacedynamicparam,
                secondarygameurl: gameToPlay.gameurl,
              },
            },
          });
        } else {
          this.props.history.push({
            pathname: FRONTEND_NAME + "/gameview",
            state: {
              gameScreen: "home",
              game: gameToPlay,
            },
          });
        }
        return false;
      } else {
        if (sessionStorage.getItem("loginType") && sessionStorage.getItem("loginType") == "normal") {

        } else if (sessionStorage.getItem("loginType") == "social") {
          this.setState({
            showPopupOld: true,
            msgType: "A",
            isConfirm: "Y",
            alertMessage: "Alert!",
            msg:
              "Mobile number is mandatory to access this functionality. Please provide your mobile number.",
            loginRedirect: "E",
          });
          return false;
        } else {
          this.setState({
            showPopupOld: true,
            msgType: 'E',
            isConfirm: 'Y',
            alertMessage: 'Alert!',
            msg: "You need to be logged in to access this feature. Do you wish to proceed?",
            loginRedirect: 'LOGIN'
          });
          return false;
        }
        //   const isLogin = sessionStorage.getItem("isLogin");
        //   if (!isLogin) {
        //     this.setState({
        //       showPopup: true,
        //       msgType: "A",
        //       isConfirm: "Y",
        //       alertMessage: "Login Required!",
        //       msg:
        //         "You need to be logged in to access this feature. Do you wish to proceed?",
        //       loginRedirect: "Y",
        //     });
        //     return false;
        //   }


      }

      //Cureent game is set ti state variable because we need it when payment popup gives confirmation.
      //At that time it cannot be passed through a method
      this.setState({
        currentSelectedGame: gameToPlay,
      });
      console.log("inside redirectToRequiredGame : ", gameToPlay.gameurl);
      const isLogin = sessionStorage.getItem("isLogin");
      if (isLogin) {
        console.log(
          "User is logged In, checking game type to make decision. Game type is ",
          gameToPlay.gametype
        );

        if (gameToPlay.showPlayButton) {
          this.openRequiredGame(gameToPlay);
        }

        if (gameToPlay.gametype.toUpperCase() === constantURL.GAME_TYPE_FREE) {
          this.openRequiredGame(gameToPlay);
        } else {
          // this.togglePopup(Constant.CONFIRM_PAYMENT_MESSAGE.replace("<xx>", gameToPlay.price), "A", Constant.CONFIRM_PAYMENT_ALERT, ['3', '4'])
          // this.checkSubscriptionAndMakePayment(gameToPlay, typeOfAction);
          this.deductCoins(gameToPlay);

        }
      } else {
        console.log("User is NOT logged In, showing popup to login");
        this.setState({
          redirectScreen: constantURL.LOGIN_SCREEN_TITLE,
        });
        this.togglePopup(
          lables.youNeedToLoginForFeature,
          "A",
          lables.loginRequired,
          ["1", "2"]
        );
        //If YES, want to login then redirect to login page and after successful login, redirect to respective Game Page
      }
    }
  };

  togglePopupNew = (message, msgType, alert, buttonArray) => {
    console.log(alert);
    this.setState({
      showPopupNew: !this.state.showPopupNew,
      showPopup: false,
      message: message,
      msgType: msgType,
      alert: alert,
      displayButtons: buttonArray,
    });
  };

  openPopularGames = () => {
    this.props.history.push({
      pathname: FRONTEND_NAME + "/populargames",
      state: {
        pGSubCategory: this.state.subCategory,
      },
    });
  };

  openRecommendedGames = () => {
    this.props.history.push({
      pathname: FRONTEND_NAME + "/recommendedgames",
      state: {
        pGSubCategory: this.state.subCategory,
      },
    });
  };

  performTaskOnClose = (flag) => {
    const { lables } = this.state;
    console.log(
      "sessionStorage.getItem(actionType)==" +
      sessionStorage.getItem("actionType")
    );

    this.togglePopupNew();
    console.log("inside performTaskOnClose method, flag is ", flag);

    if (flag === "confirm") {
      this.submitPaymentSubscription();
    }
    if (flag === "ok-" + lables.youNeedToLoginForFeature) {
      //this.props.history.push(FRONTEND_NAME + "/login");
    }
    if (flag === "ok-" + lables.successfullyChargedClickOkToPlay) {
      if (sessionStorage.getItem("actionType") == "bundle") {
        // this.submitPaymentSubscription();
        this.openBundleGames(this.state.currentSelectedGame);
      } else {
        if (this.state.currentSelectedGame.isBattle) {
          this.props.history.push({
            pathname: FRONTEND_NAME + "/battle",
            state: {
              game: {
                id: "games",
                title: this.state.currentSelectedGame.title,
                previewImageUrl: this.state.currentSelectedGame.imageUrl,
                gameurl: this.state.currentSelectedGame.gameurl,
                replacedynamicparam: this.state.currentSelectedGame
                  .replacedynamicparam,
                secondarygameurl: this.state.currentSelectedGame.gameurl,
              },
            },
          });
        } else {
          this.props.history.push({
            pathname: FRONTEND_NAME + "/gameview",
            state: {
              gameScreen: "home",
              game: this.state.currentSelectedGame,
            },
          });
        }
        // window.open(this.state.currentSelectedGame.gameurl, "_blank")
      }
    }
    console.log(flag + "   ==  ");
    if (flag.indexOf("Your payment is successful.") > 0) {
      if (sessionStorage.getItem("actionType") == "bundle") {
        // this.submitPaymentSubscription();
        this.openBundleGames(this.state.currentSelectedGame);
      } else {
        if (this.state.currentSelectedGame.isBattle) {
          this.props.history.push({
            pathname: FRONTEND_NAME + "/battle",
            state: {
              game: {
                id: "games",
                title: this.state.currentSelectedGame.title,
                previewImageUrl: this.state.currentSelectedGame.imageUrl,
                gameurl: this.state.currentSelectedGame.gameurl,
                replacedynamicparam: this.state.currentSelectedGame
                  .replacedynamicparam,
                secondarygameurl: this.state.currentSelectedGame.gameurl,
              },
            },
          });
        } else {
          this.props.history.push({
            pathname: FRONTEND_NAME + "/gameview",
            state: {
              gameScreen: "home",
              game: this.state.currentSelectedGame,
            },
          });
        }
        // window.open(this.state.currentSelectedGame.gameurl, "_blank")
      }
    }

    if (flag === "ok-" + constantURL.ERROR_MESSAGE) {
      this.props.history.push(FRONTEND_NAME + "/paidgames");
    }
  };

  openNotificcation() {
    if (sessionStorage.getItem("loginType") && sessionStorage.getItem("loginType") == "normal") {
      this.props.history.push({
        pathname: FRONTEND_NAME + "/notification",
        state: {
          gameScreen: "home",
        },
      });
    } else if (sessionStorage.getItem("loginType") == "social") {
      this.setState({
        showPopup: true,
        msgType: "A",
        isConfirm: "Y",
        alertMessage: "Alert!",
        msg:
          "Mobile number is mandatory to access this functionality. Please provide your mobile number.",
        loginRedirect: "E",
      });
    } else {
      this.setState({
        showPopupOld: true,
        msgType: 'E',
        isConfirm: 'Y',
        alertMessage: 'Alert!',
        msg: "You need to be logged in to access this feature. Do you wish to proceed?",
        loginRedirect: 'LOGIN'
      });

    }
  }

  openFreeGames() {
    console.log("FREE GAMES");
    //this.props.history.push(FRONTEND_NAME+"/premiumgames");

    var category = {

      categoryId: this.state.premiumGameId,
      categoryName: this.state.premiumGamesBannerTitle
    }
    this.openSubCategory(category);
  }

  openBundleGames(list) {
    this.props.history.push({
      pathname: FRONTEND_NAME + "/bundlegames",
      state: {
        detail: list,
      },
    });
  }

  openPlayCoins() {

    if (sessionStorage.getItem("loginType") && sessionStorage.getItem("loginType") == "normal") {
      this.props.history.push({
        pathname: FRONTEND_NAME + "/playcoins",
        state: {
          gameScreen: "home",
        },
      });
    } else if (sessionStorage.getItem("loginType") == "social") {
      this.setState({
        showPopup: true,
        msgType: "A",
        isConfirm: "Y",
        alertMessage: "Alert!",
        msg:
          "Mobile number is mandatory to access this functionality. Please provide your mobile number.",
        loginRedirect: "E",
      });
    } else {
      this.setState({
        showPopupOld: true,
        msgType: 'E',
        isConfirm: 'Y',
        alertMessage: 'Alert!',
        msg: "You need to be logged in to access this feature. Do you wish to proceed?",
        loginRedirect: 'LOGIN'
      });

    }




    // const isLogin = sessionStorage.getItem("isLogin");
    // if (!isLogin) {
    //   this.setState({
    //     showPopup: true,
    //     msgType: "A",
    //     isConfirm: "Y",
    //     alertMessage: "Login Required!",
    //     msg:
    //       "You need to be logged in to access this feature. Do you wish to proceed?",
    //     loginRedirect: "Y",
    //   });

    //   //     this.props.history.push({
    //   //         pathname: FRONTEND_NAME+'/login'
    //   //     })
    // } else {
    //   if (sessionStorage.getItem("loginType") == "social") {
    //     this.setState({
    //       showPopup: true,
    //       msgType: "A",
    //       isConfirm: "Y",
    //       alertMessage: "Alert!",
    //       msg:
    //         "Mobile number is mandatory to access this functionality. Please provide your mobile number.",
    //       loginRedirect: "E",
    //     });

    //   } else {
    //     this.props.history.push({
    //       pathname: FRONTEND_NAME + "/playcoins",
    //       state: {
    //         gameScreen: "home",
    //       },
    //     });
    //   }
    // }
  }

  redirectToWinner() {


    if (sessionStorage.getItem("loginType") && sessionStorage.getItem("loginType") == "normal") {
      logEvents(constantURL.BANNER1);
      this.saveLogs('Winners');
      this.props.history.push({
        pathname: FRONTEND_NAME + "/leaderboardmain",
        state: {
          gameScreen: "home",
        },
      });
    } else if (sessionStorage.getItem("loginType") == "social") {
      this.setState({
        showPopup: true,
        msgType: "A",
        isConfirm: "Y",
        alertMessage: "Alert!",
        msg:
          "Mobile number is mandatory to access this functionality. Please provide your mobile number.",
        loginRedirect: "E",
      });
    } else {
      this.setState({
        showPopupOld: true,
        msgType: 'E',
        isConfirm: 'Y',
        alertMessage: 'Alert!',
        msg: "You need to be logged in to access this feature. Do you wish to proceed?",
        loginRedirect: 'LOGIN'
      });

    }
  }

  redirectToFantasy() {


    if (sessionStorage.getItem("loginType") && sessionStorage.getItem("loginType") == "normal") {
      logEvents(constantURL.BANNER2);
      this.saveLogs('Fantasy');
      window.location.href = constantURL.FANTASY_URL + sessionStorage.getItem("username");
    } else if (sessionStorage.getItem("loginType") == "social") {
      this.setState({
        showPopup: true,
        msgType: "A",
        isConfirm: "Y",
        alertMessage: "Alert!",
        msg:
          "Mobile number is mandatory to access this functionality. Please provide your mobile number.",
        loginRedirect: "E",
      });
    } else {
      this.setState({
        showPopupOld: true,
        msgType: 'E',
        isConfirm: 'Y',
        alertMessage: 'Alert!',
        msg: "You need to be logged in to access this feature. Do you wish to proceed?",
        loginRedirect: 'LOGIN'
      });
    }
  }

  sessionOut() {
    this.props.history.push(FRONTEND_NAME + "/logout");
  }

  sliders() {
    return this.state.banners.map((data) => {
      return <div key={data.id}></div>;
    });
  }

  togglePopOld() {
    this.setState({
      showPopupOld: !this.state.showPopupOld
    });
  }

  deductContest() {

    var CurrentDate = moment();


    var day = CurrentDate.format('DD');
    var month = CurrentDate.format('MM');
    var year = CurrentDate.format('YYYY');
    var hh = '23';
    var mm = '59';
    var ss = '00';

    var validTill = year + '-' + month + '-' + day + 'T' + hh + ':' + mm + ':' + ss + '.000+0530'

    console.log(validTill + '--')


    // const headers = {
    //   'Content-Type': 'application/json',
    //   'appkey': Constant.APP_KEY_SUBS,
    //   'operator': "bsnl",
    //   'msisdn': sessionStorage.getItem("username")
    // };

    var url = constantURL.SPS_COMBINED_IP + "/v1/getResponse";
    const headers = {
      'appkey': constantURL.APP_KEY_SUBS,
      'Content-Type': constantURL.APPLICATION_JSON,
      'appid': sessionStorage["appid"],
      'deviceId': constantURL.DEVICE_ID,
      'operator': constantURL.OPERATOR,
      'access_token': sessionStorage.getItem("accessToken"),
      'channel': constantURL.CHANNEL,
      //   'countryCode': constantURL.COUNTRY_CODE,
      'msisdn': sessionStorage.getItem("username"),
    };

    const body = {
      "apiName": "rewardSync",
      "requestBody": {
        "msisdn": sessionStorage.getItem("username"),
        "appName": constantURL.APP_KEY_SUBS,
        "rewardName": "coin",
        "operation": "debit",
        "appGameValidity": validTill,
        "appGameName": sessionStorage.getItem("ccontestName"),
        "rewardCount": sessionStorage.getItem("rewardCount"),
        "info": "Deducted for Contest",
        "requestTransId": CurrentDate + "_" + sessionStorage.getItem("rewardCount") + sessionStorage.getItem("username"),
        "operationSource": "WAP",
        "circle": "all",
        "operator": constantURL.OPERATOR,
        "ceSyncAllowed": true,
        "contestName": "bsnlquizhdgames",
        "groupId": 1,
        "isRewardBalanceAvailable": "true/false",
        "smInfo": {
          "packName": "joke",
          "channel": "wap",
          "shortcode": "53030"
        }
      }
    }

    this.setState({ enableLoader: true })

    axios.post(url, body, { headers }).then(response => response.data)
      .then((data) => {
        console.log('--------=================CONTEST===============');
        console.log(data);

        if (data && (data.code == 2000)) {
          logEvents(constantURL.SPEND_VIRTUAL_CURRENCY)

          this.setState({
            showPopupOld: false,
          });

          var contestUrl = sessionStorage.getItem("redirecturl");
          contestUrl = contestUrl.replace('=MSISDN', sessionStorage.getItem('username'));
          contestUrl = contestUrl.replace('=REDIRECT_URL', constantURL.BIG_WIN_HOME_URL);
          console.log(contestUrl);

          window.location.href = "https://hardygame.com/hardycontest?msisdn=" + sessionStorage.getItem('username');

        } else if (data.code == 2004) {

          this.setState({
            showPopupOld: true,
            msgType: 'E',
            isConfirm: 'N',
            alertMessage: 'Sorry!',
            msg: "You don't have sufficent coin balance to play this game! Please add more coins to your account.",
            loginRedirect: 'INC'
          });
        } else {
          this.setState({
            showPopupOld: true,
            msgType: 'E',
            isConfirm: 'N',
            alertMessage: 'Sorry!',
            msg: "You don't have sufficent coin balance to play this game! Please add more coins to your account.",
            loginRedirect: 'INC'
          });
        }
        this.setState({ enableLoader: false })
        sessionStorage.removeItem("rewardCount");
        sessionStorage.removeItem("info");
        sessionStorage.removeItem("validity");

        sessionStorage.removeItem("gameurl");

      });
  }


  togglePopupOld() {
    this.setState({
      showPopupOld: !this.state.showPopupOld
    });
    if (this.state.loginRedirect == 'Y') {
      this.props.history.push(FRONTEND_NAME + "/logout")
    }
    if (this.state.loginRedirect == 'C') {
      this.deduct()
    }
    if (this.state.loginRedirect == 'CC') {
      this.deductContest()
    }
    if (this.state.loginRedirect == 'INC') {
      this.props.history.push(FRONTEND_NAME + "/buyplaycoins")
    }
    if (this.state.loginRedirect == 'E') {
      this.props.history.push(FRONTEND_NAME + "/editprofilenew")
    }

    if (this.state.loginRedirect == 'EP') {
      this.props.history.push(FRONTEND_NAME + "/editprofilenew")
    }
    if (this.state.loginRedirect == 'LOGIN') {
      this.props.history.push(FRONTEND_NAME + "/sociallogin")
    }


  }

  hasContentId(id) {
    //console.log("Resturning " + this.state.cachedImage.has(id))
    return this.state.cachedImage.has(id);
  }

  getContentId(id) {
    //console.log("Resturning " + this.state.cachedImage.has(id))
    return this.state.cachedImage.get(id);
  }

  onSlideLoad() {
    this.loadFlickerTranisition = setTimeout(() => {
      var style = document.createElement('style');
      style.innerHTML =
        '.div-color-red{' +
        'transition: width .3s, height .3s, margin .3s;' +
        '}'
        ;
      var ref = document.querySelector('script');
      ref.parentNode.insertBefore(style, ref);
    }, 0);
  }

  redirectToContest(data) {

    console.log(data)
    logEvents(constantURL.BANNER3);
    this.saveLogs('Fantasy');
    if (sessionStorage.getItem("loginType") == "social") {
      this.setState({
        showPopup: true,
        msgType: "A",
        isConfirm: "Y",
        alertMessage: "Alert!",
        msg:
          "Mobile number is mandatory to access this functionality. Please provide your mobile number.",
        loginRedirect: "E",
      });
    } else {
      if (data.showPlayButton == true) {
        // var contestUrl= data.redirectUrl;
        // contestUrl= contestUrl.replace('=MSISDN', sessionStorage.getItem('username'));
        // contestUrl= contestUrl.replace('=REDIRECT_URL', constantURL.BIG_WIN_HOME_URL);

        // window.location.href = constantURL.CONTEST_URL+ sessionStorage.getItem("username") ;       
        window.location.href = "https://hardygame.com/hardycontest?msisdn=" + sessionStorage.getItem('username');

        // this.deductCoinsForContest(data);

      } else {
        this.deductCoinsForContest(data);
      }

    }
  }
  deductCoinsForContest(data1) {

    sessionStorage.setItem('validity', data1.validity);
    sessionStorage.setItem("rewardCount", data1.coinsRequired);
    sessionStorage.setItem("info", data1.title);
    sessionStorage.setItem("redirecturl", data1.redirectUrl);
    sessionStorage.setItem("ccontestName", data1.bannerName.trim());

    const isLogin = sessionStorage.getItem("isLogin");
    if (!isLogin) {

      this.setState({
        showPopupOld: true,
        msgType: 'A',
        isConfirm: 'Y',
        alertMessage: 'Login Required!',
        msg: "You need to be logged in to access this feature. Do you wish to proceed?",
        loginRedirect: 'Y'
      });
    } else {

      this.setState({
        showPopupOld: true,
        msgType: 'A',
        isConfirm: 'Y',
        // alertMessage: 'Confirm Payment!',
        // msg: "You will be charged " + data1.coinsRequired + " coins for playing this game. Please confirm to play game.",
        alertMessage: 'Confirm Coin Deduction!',
        msg: "You will be charged " + data1.coinsRequired + " coins for contest.",
        loginRedirect: 'CC'
      });
    }

  }



  render() {

    console.log("=-=-=-=-" + this.state.isNeedToCheckSTW);

    let enableLoadin = this.props.enableLoadin;
    let home = this.props.home;

    console.log("------------------------------------")
    console.log(enableLoadin)
    console.log(home)

    if (sessionStorage.getItem("nameSalutation")) {
      var nameSalutation = sessionStorage.getItem("nameSalutation");
      if (nameSalutation.match(/^\d+$/)) {
        nameSalutation =
          nameSalutation.substring(0, 2) +
          "XXXXXXXX" +
          nameSalutation.substring(10, 13);
        sessionStorage.setItem("nameSalutation", nameSalutation);
      }
    }

    const {
      msgType,
      //banners,
      message,
      //popularGames,
      // keepPlaying,
      // arcadeGames,
      bundleGamesBanner,
      displayButtons,
      lables,
      popularImageLoadCount,
      keepImageLoadCount,
      arcadeImageLoadCount
    } = this.state;

    const {
      banners,
      popularGames,
      keepPlaying,
      arcadeGames,
    } = this.props;

    const settings = {
      autoplay: true,
      arrows: false,
      dots: true,
      autoplaySpeed: 3000,
      dotsClass: "carousel-dots",
    };

    var popularRow = [];
    var popularCount = 0;
    popularGames.gameList.forEach(
      function (list) {
        // if(popularCount<3){openGameView
        popularRow.push(
          <div
            key={list.id}
            className={popularImageLoadCount > 3 ? "keep-d" : "keep-d skeleton-loader"}
            onClick={() => this.redirectToRequiredGame(list, "game")}
          >
            {(this.hasContentId(list.contentName)) ? <img src={this.getContentId(list.contentName)} style={{ display: (popularImageLoadCount > 3 ? "inline" : "none") }} onLoad={this.handlePopularImageLoaded.bind(this)} alt=""></img>
              : <img src={list.savedImageUrl ? list.savedImageUrl : list.previewImageUrl} style={{ display: (popularImageLoadCount > 3 ? "inline" : "none") }} onLoad={this.handlePopularImageLoaded.bind(this)} alt=""></img>}
            <img src={games} style={{ display: (popularImageLoadCount > 3 ? "none" : "inline") }} alt=""></img>
            {(popularImageLoadCount > 3) &&
              <>
                <p className="games-t">{list.aliasname}</p>
                <p className="how-played">{list.noOfUsersDynamic} {lables.played}</p>
              </>
            }
          </div>
        );
        //     popularCount=popularCount+1;
        // }
      }.bind(this)
    );

    var keepPlayingRow = [];
    var keepPlayingCount = 0;
    keepPlaying.gameList.forEach(
      function (list) {
        //    if(keepPlayingCount<4){  onClick={() => this.openGameView(list.gameurl)}
        keepPlayingRow.push(
          <div
            key={list.id}
            className={keepImageLoadCount > 3 ? "keep-d" : "keep-d skeleton-loader"}
            onClick={() => this.redirectToRequiredGame(list, "game")}
          >
            {(this.hasContentId(list.contentName)) ? <img src={this.getContentId(list.contentName)} style={{ display: (keepImageLoadCount > 3 ? "inline" : "none") }} onLoad={this.handleKeepImageLoaded.bind(this)} alt=""></img>
              : <img src={list.savedImageUrl ? list.savedImageUrl : list.previewImageUrl} style={{ display: (keepImageLoadCount > 3 ? "inline" : "none") }} onLoad={this.handleKeepImageLoaded.bind(this)} alt=""></img>}
            <img src={games} style={{ display: (keepImageLoadCount > 3 ? "none" : "inline") }} alt=""></img>
            {(keepImageLoadCount > 3) &&
              <>
                <p className="games-t">{list.aliasname}</p>
                <p className="how-played">{list.noOfUsersDynamic} {lables.played}</p>
              </>
            }

          </div>
        );
        //     keepPlayingCount=keepPlayingCount+1;
        // }
      }.bind(this)
    );

    var arcadeRow = [];
    arcadeGames.gameList.forEach(
      function (list) {
        //    if(keepPlayingCount<4){  onClick={() => this.openGameView(list.gameurl)}
        arcadeRow.push(
          <div
            key={list.id}
            className={arcadeImageLoadCount > 3 ? "keep-d" : "keep-d skeleton-loader"}
            onClick={() => this.redirectToRequiredGame(list, "game")}
          >

            {(this.hasContentId(list.contentName)) ? <img src={this.getContentId(list.contentName)} style={{ display: (arcadeImageLoadCount > 3 ? "inline" : "none") }} onLoad={this.handleArcadeImageLoaded.bind(this)} alt=""></img>
              : <img src={list.savedImageUrl ? list.savedImageUrl : list.previewImageUrl} style={{ display: (arcadeImageLoadCount > 3 ? "inline" : "none") }} onLoad={this.handleArcadeImageLoaded.bind(this)} alt=""></img>}
            <img src={games} style={{ display: (arcadeImageLoadCount > 3 ? "none" : "inline") }} alt=""></img>
            {(arcadeImageLoadCount > 3) &&
              <>
                <p className="games-t">{list.aliasname}</p>
                <p className="how-played">{list.noOfUsersDynamic} {lables.played}</p>
              </>
            }

          </div>
        );
        //     keepPlayingCount=keepPlayingCount+1;
        // }
      }.bind(this)
    );

    let slides = [];
    let hiddenSlide = []
    banners.forEach(
      function (list, index) {
        // let imageUrl = window.location.protocol + list.savedImageUrl;
        // console.log(encodeURI(imageUrl));
        if (index === 1) {
          hiddenSlide.push(
            <img style={{ display: 'none' }} src={list.savedImageUrl} onLoad={this.onSlideLoad.bind(this)} />
          )
        }
        slides.push(
          this.hasContentId(list.contentName) ?
            <div
              className="div-color-red" onClick={() => this.redirectToRequiredGame(list, 'banner', index)}
              style={{ backgroundImage: `url(` + this.getContentId(list.contentName) + `)`, backgroundSize: '100% 100%' }}>
            </div> :
            <div
              className="div-color-red" onClick={() => this.redirectToRequiredGame(list, 'banner', index)}
              style={{ backgroundImage: `url(` + (list.savedImageUrl ? encodeURI(list.savedImageUrl) : list.imageUrl) + `)`, backgroundSize: '100% 100%' }}>
            </div>
        );
      }.bind(this)
    );

    let bundleBanners = [];
    bundleGamesBanner.forEach(
      function (list) {
        bundleBanners.push(
          <div
            className="mini-posters"
            onClick={() => this.redirectToRequiredGame(list, "bundle")}
          >
            <img src={list.savedImageUrl ? list.savedImageUrl : list.imageUrl} alt=""></img>
          </div>
        );
      }.bind(this)
    );


    let winnerBanner = [];
    // this.state.winnerBanner.forEach(
    this.props.winnerBanner.forEach(
      function (list) {
        winnerBanner.push(
          <div style={{ marginTop: '0px' }}
            className="mini-posters"
            onClick={() => this.redirectToWinner(list)}
          >
            {this.hasContentId(list.contentName) ?
              <img src={this.getContentId(list.contentName)} alt=""></img> :
              <img src={list.savedImageUrl ? list.savedImageUrl : list.imageUrl} alt=""></img>
            }
          </div>
          // <div
          //   className="mini-posters"
          //   onClick={() => this.redirectToWinner(list)}
          // >
          //   <img src={list.savedImageUrl ? list.savedImageUrl : list.imageUrl} alt=""></img>
          // </div>
        );
      }.bind(this)
    );

    let fantasyBanner = [];
    this.props.fantasyBanner.forEach(
      //  this.state.fantasyBanner.forEach(
      function (list) {
        fantasyBanner.push(
          <div style={{ marginTop: '-9px' }}
            className="mini-posters"
            onClick={() => this.redirectToFantasy(list)}
          >
            {this.hasContentId(list.contentName) ?
              <img src={this.getContentId(list.contentName)} alt=""></img> :
              <img src={list.savedImageUrl ? list.savedImageUrl : list.imageUrl} alt=""></img>}
            {/* <div style={{ marginTop: '0px' }}
            className="mini-posters"
            onClick={() => this.redirectToFantasy(list)}
          >
          //   <img src={list.savedImageUrl ? list.savedImageUrl : list.imageUrl} alt=""></img> */}
          </div>
        );
      }.bind(this)
    );

    let contestBanner = [];
    this.props.contestBanner.forEach(
      //  this.state.fantasyBanner.forEach(
      function (list) {
        contestBanner.push(
          <div style={{ marginTop: '-9px' }}
            className="mini-posters"
            onClick={() => this.redirectToContest(list.banner)}
          >
            {this.hasContentId(list.contentName) ?
              <img src={this.getContentId(list.contentName)} alt=""></img> :
              <img src={list.savedImageUrl ? list.savedImageUrl : list.imageUrl} alt=""></img>}
            {/* <div style={{ marginTop: '0px' }}
            className="mini-posters"
            onClick={() => this.redirectToFantasy(list)}
          >
          //   <img src={list.savedImageUrl ? list.savedImageUrl : list.imageUrl} alt=""></img> */}
          </div>
        );
      }.bind(this)
    );


    let mobileVerificationBannerRow = [];
    this.props.mobileVerificationBanner.forEach(
      function (list) {
        mobileVerificationBannerRow.push(
          <div style={{ marginTop: '-9px' }}
            className="mini-posters mobileVerificaionImg"
            onClick={() => this.openSocialMessage(list)}
          >
            {this.hasContentId(list.contentName) ?
              <img src={this.getContentId(list.contentName)} alt=""></img> :
              <img src={list.savedImageUrl ? list.savedImageUrl : list.imageUrl} alt=""></img>}
            {/* <div style={{ marginTop: '0px' }}
            className="mini-posters"
            onClick={() => this.redirectToFantasy(list)}
          >
          //   <img src={list.savedImageUrl ? list.savedImageUrl : list.imageUrl} alt=""></img> */}
          </div>
        );
      }.bind(this)
    );

    var placeholder = <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
      <span class="skeleton-loader keep-d placeholdercontent">
        <img src={games} alt=""></img>
      </span>
      <span class="skeleton-loader keep-d placeholdercontent">
        <img src={games} alt=""></img>
      </span>
      <span class="skeleton-loader keep-d placeholdercontent">
        <img src={games} alt=""></img>
      </span>
      <span class="skeleton-loader keep-d placeholdercontent">
        <img src={games} alt=""></img>
      </span>
    </div>


    return (
      <div className="container-home">
        {/* {this.state.enableLoader ? <Loading /> : null} */}
        {this.props.enableLoader ? <Loading /> : null}

        {this.state.showPopup ? (
          <Popup
            status={this.state.msgType}
            text={this.state.msg}
            alertType={this.state.alertMessage}
            buttonName={this.state.loginRedirect == "C" ? "CONFIRM" : "OK"}
            closePopup={this.togglePopup.bind(this)}
            closePopupNotNow={this.togglePop.bind(this)}
            isConfirm={this.state.isConfirm}
          />
        ) : null}
        {
          this.state.logoutPopup ?
            <LogoutPopup
              redirectPopup={this.sessionOut.bind(this)}
            /> : null
        }

        {this.state.showPopupOld ?
          <Popup
            status={this.state.msgType}
            text={this.state.msg}
            alertType={this.state.alertMessage}
            buttonName={this.state.loginRedirect == 'C' ? 'CONFIRM' : 'OK'}
            closePopup={this.togglePopupOld.bind(this)}
            closePopupNotNow={this.togglePopOld.bind(this)}
            isConfirm={this.state.isConfirm}
          /> : null}

        {this.state.showPopupNew ? (
          <PopupNew
            text={message}
            status={msgType}
            alertType={this.state.alert}
            displayButtons={displayButtons}
            closePopupWithTask={this.performTaskOnClose}
            closePopup={this.togglePopupNew}
          />
        ) : null}
        {(this.state.isNeedToCheckSTW && sessionStorage.getItem("loginType") != "social") ? (
          <div
            id="revoke-modal" style={{ display: 'block', zIndex: '99999999999' }}
            className={
              this.state.isShowSpin ? "revokeModalShow" : "revokeModalHide"
            }
          >
            {this.state.isShowSpin ? <SpinTheWheel /> : null}{" "}
          </div>
        ) : null}
        <div className="header">
          <div className="header-l" onClick={this.openProfile.bind(this)}>
            <img
              src={
                sessionStorage.getItem("profileImg")
                  ? sessionStorage.getItem("profileImg") || clownLogo
                  : clownLogo
              }
              alt={clownLogo}
              ref={img => this.img = img} onError={() => this.img.src = clownLogo}
              className="slider"
            ></img>
          </div>
          <div className="games">
            <img src={games} alt=""></img>
          </div>
          <div className="header-r">
            <div>
              <div className="points" onClick={this.openPlayCoins.bind(this)}>
                <img src={coins} alt="" className="wd-cc"></img>
              </div>
              <div
                className="bell-extra"
                onClick={this.openNotificcation.bind(this)}
              >
                <img
                  src={
                    this.state.unreadCount == 0 || this.state.unreadCount == "0"
                      ? bell
                      : bellunread
                  }
                  alt=""
                ></img>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>

        <div className="contentHome">
          {(banners.length > 1) ?
            <Flickity
              flickityRef={c => this.flkty = c}
              className={"carousel"} // default ''
              elementType={"div"} // default 'div'
              options={flickityOptions} // takes flickity options {}
              disableImagesLoaded={false} // default false
              reloadOnUpdate={false} // default false
              static // default false
            >
              {slides}
            </Flickity> :
            <div class="skeleton-slide div-color-red is-selected">
              <img src={games} alt="" />
            </div>
          }
          {hiddenSlide}

          {(sessionStorage.getItem("loginType") == "social") ?
            <div className="" style={{ padding: "0px 0px 6px 0px", marginTop: "47px" }}>
              <div className="free"></div>
              <div>{mobileVerificationBannerRow}</div>
            </div>
            : null}
          <div className="grey-div" style={{ padding: "0px", marginTop: "8px" }}>
            <div className="free"></div>
            <div>{winnerBanner}</div>
          </div>
          <div className="" style={{ padding: "0px 0px 6px 0px", marginTop: "14px" }}>
            <div className="free"></div>
            <div>{fantasyBanner}</div>
          </div>
          <div className="" style={{ padding: "0px 0px 6px 0px", marginTop: "8px" }}>
            <div className="free"></div>
            <div>{contestBanner}</div>
          </div>


          <div className="grey-div">
            <div className="popular">
              <img src={yellowHeart} className="yellow-h" />
              <p className={popularRow.length > 0 ? "popular-games" : "popular-games skeleton-title"}>{popularGames.categoryName}</p>
              <div className="view-all">
                <p onClick={() => this.openSubCategory(popularGames)}>{lables.viewAll}</p>
                <img
                  src={yview}
                  alt=""
                  style={{ width: "10px", height: "10px" }}
                ></img>
              </div>
            </div>
            <div className="scroll-posters">
              <div className="view-posters">{popularRow.length > 0 ? popularRow : placeholder}</div>
            </div>
          </div>

          <div className="grey-div">
            <div className="popular">
              <img src={yellowDice} className="free-h"></img>
              <p className={keepPlayingRow.length > 0 ? "free-games" : "free-games skeleton-title"}>{keepPlaying.categoryName}</p>
              <div className="view-all">
                <p onClick={() => this.openSubCategory(keepPlaying)}>{lables.viewAll}</p>
                <img
                  src={yview}
                  alt=""
                  style={{ width: "10px", height: "10px" }}
                ></img>
              </div>
            </div>
            <div className="scroll-playing">
              <div className="view-posters">{keepPlayingRow.length > 0 ? keepPlayingRow : placeholder}</div>
            </div>
          </div>

          <div className="grey-div">
            <div className="popular">
              <img src={arcadePic} className="free-h"></img>
              <p className={arcadeRow.length > 0 ? "free-games" : "free-games skeleton-title"}>{arcadeGames.categoryName}</p>
              <div className="view-all">
                <p onClick={() => this.openSubCategory(arcadeGames)}>{lables.viewAll}</p>
                <img
                  src={yview}
                  alt=""
                  style={{ width: "10px", height: "10px" }}
                ></img>
              </div>
            </div>
            <div className="scroll-playing">
              <div className="view-posters">{arcadeRow.length > 0 ? arcadeRow : placeholder}</div>
            </div>
          </div>

        </div>
        <Footer currentSelection="home" cachedImage={this.state.cachedImage} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log(state)
  // const enableLoader = state.home.enableLoader;
  const {
    enableLoader,
    logoutPopup
  } = state.home;

  const {
    banners,
    winnerBanner,
    fantasyBanner,
    contestBanner,
    mobileVerificationBanner,
    popularGames,
    arcadeGames,
    keepPlaying,
    subCategory
  } = state.home.homeData;

  //console.log(popularGames)

  return {
    enableLoader,
    logoutPopup,
    banners,
    winnerBanner,
    fantasyBanner,
    contestBanner,
    mobileVerificationBanner,
    popularGames,
    arcadeGames,
    keepPlaying,
    subCategory
  };
}

export default connect(mapStateToProps, { fetchHomePage })(withRouter(HomeScreen));
