import React from 'react'
import axios from 'axios';
import { withRouter } from 'react-router'
import '../styles/style.css'
import timeout from '../images/timeout.svg';
import exit from '../images/exit.svg';
import '../styles/loader.css'


class SessionTimeout extends React.Component {

    back() {
        // this.props.history.push({
        //     pathname: FRONTEND_NAME+'/splash'
        // });
        window.location.replace("https://hardygames/hardygames/home");
                    
    }
    render() {
        return (

            <div className="container-session">
                <img src={exit} className="ee-img"  onClick={this.back.bind(this)}></img>
                <div  className="t-img"><img src={timeout}></img></div>
                <p className="s-inactive">Your session has expired due to inactivity.</p>
                <p className="s-login">Please log on again.</p>
                <button className="lo-btn"  onClick={this.back.bind(this)}>LOGIN</button>
            </div>
        )
    }
}

export default withRouter(SessionTimeout);