import React, { useState } from "react";
import { withRouter } from "react-router";
import back from '../images/backNew.svg';
import group from '../images/group.png';
import * as constantURL from "../Constants/constants";
import * as errorConstantURL from "../Constants/errorConstants";
import axios from 'axios';

class Winner extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          winners:[]
      }}

      componentDidMount(){
// var list1=[];

// list1.push({
//             "appName": "bsnlhardygames",
//             "winnerType": "MONTHLY",
//             "winnerPeriod": "8",
//             "winnerDeclarationDate": "2021-09-01",
//             "winnerCount": 20
//  })
//  list1.push({
//     "appName": "bsnlhardygames",
//     "winnerType": "MONTHLY",
//     "winnerPeriod": "1",
//     "winnerDeclarationDate": "2021-09-01",
//     "winnerCount": 12
// })
// this.setState({winners:list1});
            

      var url = constantURL.SPS_COMBINED_IP + "/v1/getResponse";
      const headers = {
          'appkey': constantURL.APP_KEY_SUBS,
          'Content-Type': constantURL.APPLICATION_JSON,
          'appid': sessionStorage["appid"],
          'deviceId': constantURL.DEVICE_ID,
          'operator': constantURL.OPERATOR,
          'access_token': sessionStorage.getItem("accessToken"),
          'channel': constantURL.CHANNEL,
        //   'countryCode': constantURL.COUNTRY_CODE,
          'msisdn': sessionStorage.getItem("username"),
        };

        const body ={
            "apiName" : "winnerStatusList",
                "requestBody" : {
                    'appName': constantURL.APP_NAME,
                    "winnerType": "MONTHLY"
                }
        }

      axios.post(url, body, { headers }).then(response => response.data)
          .then((data) => {
              console.log(data);
              if (data) {
              
                        var winners= data.data;
                        console.log(winners);
             
                        var list=[];
                for (var i = 0; i < winners.length; i++) {
                       list.push({
                        appName:winners[i].appName,
                        winnerType:winners[i].winnerType,
                        winnerPeriod:winners[i].winnerPeriod,
                        winnerDeclarationDate:winners[i].winnerDeclarationDate,
                        winnerCount:winners[i].winnerCount,
                        winnerProcessed:winners[i].winnerProcessed
                       })
                }

                this.setState({winners:list});
            }
          });
        }

        
    back(){
        this.props.history.push({
            pathname: constantURL.FRONTEND_NAME + "/home",
            state: {
              gameScreen: "home",
            },
          });
    }

    openWinner(list){
        if(list.winnerProcessed){
        console.log(list);
        this.props.history.push({
            pathname: constantURL.FRONTEND_NAME + "/winners",
            state: {
              list:list,
            },
          });
        }
    }

    render(){

        function getMonthName(val) {
            var monthName = '';
            switch (val) {
                 case '1': monthName = "January";
                      break;
                 case '2': monthName = "February";
                      break;
                 case '3': monthName = "March";
                      break;
                 case '4': monthName = "April";
                      break;
                 case '5': monthName = "May";
                      break;
                 case '6': monthName = "June";
                      break;
                 case '7': monthName = "July";
                      break;
                 case '8': monthName = "August";
                      break;
                 case '9': monthName = "September";
                      break;
                 case '10': monthName = "October";
                      break;
                 case '11': monthName = "November";
                      break;
                 case '12': monthName = "December";
                      break;
            }
            return monthName;
       }


        var winnersDisplay = [];

        var count = this.state.winners.length;
            var i=0;
            this.state.winners.forEach(function (list) {
                winnersDisplay.push(
                <div className= {(i==0)?"r1": (i==count-1)?"r3":"r2"   }   onClick={() => this.openWinner(list)}>
                    <p className="name">{getMonthName(list.winnerPeriod)} Winners</p>
                    <p className="link">{list.winnerProcessed?'Check ' +list.winnerCount+ ' winners':null}</p>
                    <p className={list.winnerProcessed?"dec":"dec-new"}>{list.winnerProcessed?'Results declared on '+ list.winnerDeclarationDate: 'To be declared.'} </p>
                </div>
            );
            i++;
                // {
                //     "appName": "bsnlhardygames",
                //     "winnerType": "MONTHLY",
                //     "winnerPeriod": "8",
                //     "winnerDeclarationDate": "2021-09-01",
                //     "winnerCount": 20
                // },
            
        }.bind(this));

    return (
            <div className="container-home">
                <div className="header-leaderboard line-it">
                    <img  onClick={() => this.back()} src={back} className="float-lft"></img>
                    <span className="header-text float-lft">Winners</span>
                </div>
                <div className="content winner" >
                    <img className="group" src={group} />
                    <div className="winner-box">
                        {/* <div className="r1">
                            <p className="name">August Winners</p>
                            <p className="dec">To be declared in Week 1 September</p>
                        </div>
                        <div className="r1">
                            <p className="name">July Winners</p>
                            <p className="link">Check 25 winners</p>
                            <p className="dec">Results declared on 2nd August</p>
                        </div>
                        <div className="r1">
                            <p className="name">June Winners</p>
                            <p className="link">Check 25 winners</p>
                            <p className="dec">Results declared on 4th July</p>
                        </div> */}
                   {winnersDisplay}
                    </div>
                </div>
            </div>
     )
}
}

export default withRouter(Winner);