import React from 'react'
import axios from 'axios';
import '../styles/faq.css';
import { withRouter } from 'react-router'
import back from '../images/backNew.svg';
import profileImage from '../images/profileImage.png';
import viewAll from '../images/viewAll.png';
import plusPlay from '../images/plusPlay.png'
import minusPlay from '../images/minusPlay.png'
import * as constantURL from '../Constants/constants'
import {FRONTEND_NAME}from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Loading from './loading'
import LogoutPopup from './logoutPopup'
class Faq extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            faqList: [],
            enableLoader: true,
            logoutPopup: false,
            lables : constantURL.getLables()
        }
    }

    componentDidMount() {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
        const headers = {
            'Content-Type': 'application/json',
            'appid': sessionStorage["appid"],
            'deviceId': constantURL.DEVICE_ID,
            'operator': metaMap.get('OPERATOR'),
            'Access_token': sessionStorage.getItem("accessToken"),
            'channel': 'wap',
            'Msisdn': sessionStorage.getItem("username")
        };


        axios.get(constantURL.CONTENT_COMBINED_IP + "/v1/fetchSettings?settingKey=faq", { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    var faq = data.data.content;
                    var list = [];
                    for (var i = 0; i < faq.length; i++) {
                        list.push({
                            question: faq[i].question,
                            answer: faq[i].answer,
                            isDisplay: false
                        });
                    }
                }else{
                    this.setState({logoutPopup: true })
                }
                this.setState({ faqList: list })
                this.setState({ enableLoader: false})

            });
    }

    back() {
        this.props.history.push(FRONTEND_NAME+"/profile");
    }

    toggleQuestion(index) {
        const faqList = this.state.faqList;
        faqList[index].isDisplay = !faqList[index].isDisplay;
        this.setState({ faqList: faqList });
    }


    sessionOut() {
        this.props.history.push(FRONTEND_NAME+"/logout");
    }

    render() {
        const {lables} =this.state;
        return (
            <div className="container-leaderboard">
                {this.state.enableLoader ?
                    <Loading />
                    : null
                }

                {
                    this.state.logoutPopup ?
                        <LogoutPopup
                            redirectPopup={this.sessionOut.bind(this)}
                        /> : null
                }
                <div className="header-leaderboard header-no-border edit-pad yee">
                    <img src={back} alt="" onClick={this.back.bind(this)} className="float-lft"></img>
                    <span className="header-text float-lft">{lables.faq}</span>
                </div>
                <div className="content-faq">
                    <div className="faq-line">
                        <div className="how-to-play">

                            {this.state.faqList.map((faq, index) =>

                                <div className="big-it" onClick={() => this.toggleQuestion(index)}>
                                    <div className="plus-minus">
                                        <div >
                                            {faq.isDisplay ?
                                                <img className="minus" src={minusPlay}></img>
                                                :
                                                <img className="plus" src={plusPlay}></img>
                                            }
                                        </div>
                                        <p>{faq.question}</p>
                                    </div>
                                    {faq.isDisplay ?
                                        <p className="play-content">{faq.answer}</p>
                                        :
                                        <p className="play-content"></p>
                                    }
                                </div>
                            )}


                        </div>
                    </div>
                </div>


            </div>


        )
    }
}

export default withRouter(Faq);