import React from 'react'
import Loader from 'react-loader-spinner'
import '../styles/loader.css'
import LoaderGif from '../images/loading.png';  
export default class Loading extends React.Component {
    //other logic
    render() {
        return (

            <div id="apply-modal" className="modal">
                <div className="loaderContainer">
                    <div className="modal-heading">
                        <Loader
                            type="ThreeDots"
                            color="#ffffff"
                            //color="#ffffff" 
                            height={50}
                            width={50}
                            timeout={30000000} //3 secs

                        />
                        {/* <img src={LoaderGif} className="loader_gif" width="32px" height="32px" /> */}
                        {/* <p className="loader_txt">Loading</p> */}
                    </div>
                </div>
            </div>
        );
    }
}