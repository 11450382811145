
import React from 'react'
import axios from 'axios';
import '../styles/home.css';
import Footer from '../Footer/footer';
import Menu from '../Menu/menu';
import profileImg from '../images/profileImage.png';
// import games from '../images/games.svg';
import bell from '../images/bell.png';
import back from '../images/backNew.svg';
import yellowStar from '../images/yellowStar.png';
import yellowBatch from '../images/yellowBatch.png';

import yellowHeart from '../images/yellowHeart.png';
import viewAll from '../images/viewAll.png';
import yellowDice from '../images/yellowDice.png';

import popular from '../images/popular.png';
import keepPlaying from '../images/keepPlaying.png';
import yellowView from '../images/yellowView.png';
import instsnt from '../images/instsnt.png';
import winBig from '../images/winBig.png';
import trending from '../images/trending.png';

import circle from '../images/circle.png';
import gods from '../images/gods.png';
import bannerBottom from '../images/bannerBottom.png';
import * as Constant from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Slider from 'infinite-react-carousel';
import ProfileCollapsed from './profileCollapsed';
import { withRouter } from 'react-router';
import PopupNew from './popupNew';
import Popup from './popup';
import Loading from './loading'
import lottie from 'lottie-web';
import animationData from '../lottie/delivery.json';
import clownLogo from '../images/clownLogo.png';
import Cookies from 'universal-cookie';
import SpinTheWheel from './spinTheWheel';
import * as constantURL from '../Constants/constants'
import {games} from '../Constants/constants'
import localForage from 'localforage'

class BattleHome extends React.Component {


    constructor(props) {

        super(props);
        this.state = {
            banners: [{ id: '1', title: 'title', bannerName: 'bannerName', imageUrl: 'imageUrl', redirectUrl: 'redirectUrl', gameurl: 'game', bannerType: 'ban', bannerText: 'PLAY NOW' }],
            battleGames: [],
            isShowMenu: false,
            showPopup: false,
            showPopupOld: false,
            isShowSpin: false,
            msg: '',
            msgType: 'S',
            enableLoader: true,
            isConfirm: 'N',
            loginRedirect: 'N',
            instantGamesId: '',
            bigWinGamesId: '',
            displayButtons: [],
            currentSelectedGame: {},
            gametype: 'FREE',
            isNeedToCheckSTW: false,
            isShowSpin: false,
            loadedImage: new Set(),
            cachedImage: new Map()
        }
    }

    showSpinner() {
        const headers = {
            'Content-Type': 'application/json',
            'mode': 'WAP',
        };

        var msisdn=sessionStorage.getItem("username");
        if(msisdn.length>10){
            msisdn= msisdn.substr(2,msisdn.length+1);
         }

        const body = {
            'msisdn': msisdn,
            'campaignId': constantURL.CAMPAIGN_ID,
            'vendorUniqueKey': constantURL.VENDOR_KEY
        }

        axios.post(constantURL.SPINTHEWHEEL_COMBINED_IP + "/v1/spinnerStatus", body, { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    console.log(data.data.status)
                    if (data.data.status) {
                        this.setState({
                            isShowSpin: true
                        })
                    } else {
                        this.setState({
                            isShowSpin: false
                        })
                    }
                } else {
                    this.setState({
                        isShowSpin: false
                    });
                }
            });
    }


    componentDidMount() {

        console.log(' User Entered time --> ' + sessionStorage.getItem('entertime'))

        let tempMap = new Map();
        localForage.iterate((value, key) => {
            if(!tempMap.has(key)) {
                tempMap.set(key, value)
            }
        })
        .then(() => {
            this.setState({
                cachedImage: tempMap
            });
        });

        if (sessionStorage.getItem('entertime')) {

            var enteredTime = sessionStorage.getItem('entertime');
            var d = new Date();
            var currentTime = d.getTime();

            if ((currentTime - enteredTime) / 1000 > constantURL.SPINTHEWHEEL_TIME) {
                this.setState({ isNeedToCheckSTW: true });
                sessionStorage.removeItem('entertime');
           
            } else {
                this.setState({ isNeedToCheckSTW: false });
            }
        } else {
            var d = new Date();
            var n = d.getTime();
            sessionStorage.setItem('entertime', n);
            this.setState({ isNeedToCheckSTW: false });
        }

        this.showSpinner();

        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
        const headers = {
            'Content-Type': Constant.APPLICATION_JSON,
            'appid': metaMap.get('APP_ID'),
            'deviceId': Constant.DEVICE_ID,
            'operator': metaMap.get('OPERATOR'),
            'Access_token': sessionStorage.getItem("accessToken"),
            'channel': Constant.CHANNEL,
            'Msisdn': sessionStorage.getItem("username")
        };

        const url = Constant.CONTENT_COMBINED_IP + "/v1/fetchViewAllByCategoryId?categoryId=" + this.props.location.state.battlehomeid;
        console.log('url to call : ', url)

        axios.get(url, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data.data)
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {

                    console.log(data.data.categories[0].contentList);
                    var games=data.data.categories[0].contentList;
                    var gamesList = [];

                    if (games) {
                        for (var i = 0; i < games.length; i++) {
                            gamesList.push({
                                id: 'games' + i,
                                title: games[i].title,
                                previewImageUrl: games[i].previewImageUrl,
                                savedImageUrl: games[i].savedImageUrl,
                                gameurl: games[i].gameurl,
                                replacedynamicparam: games[i].replacedynamicparam,
                                secondarygameurl: games[i].secondarygameurl,
                                contentId: games[i].contentId,
                                contentName: games[i].contentName
                            });
                            this.cachedImageConfigure(games[i].contentName, (games[i].savedImageUrl) ? games[i].savedImageUrl : games.previewImageUrl);
                        }
                        this.setState({ battleGames: gamesList });
                    }
                    this.setState({ enableLoader: false })

                    this.saveLogs(Constant.BATTLE_ACTION)
                } else {
                    this.setState({ enableLoader: false })
                    this.togglePopup(Constant.ERROR_MESSAGE, 'E', Constant.ERROR_ALERT, ['1'])
                    this.saveLogs(Constant.BATTLE_ACTION)
                }
            }).catch(error => {
                console.log('There is Error in fetching View All Content for Instant games : ', error)
                // this.setState({
                //     redirectScreen: Constant.PAID_GAMES
                // })
                this.togglePopup(Constant.ERROR_MESSAGE, 'E', Constant.ERROR_ALERT, ['1'])
            })
    }


    saveLogs(category) {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
      
        const cookies = new Cookies();
        var url = Constant.APPLOGGER_COMBINED_IP + "/v1/saveLogs";
        
        const headers = {
            'Content-Type': 'application/json',
            'appid': metaMap.get('APP_ID'),
            'operator': metaMap.get('OPERATOR'),
            'msisdn': sessionStorage.getItem("username"),
            'channel': 'WAP'
        };

        const requestBody = {
            "category" : category,
            "subCategory" : "",
            "content" : category,
            "url" : "/battlehome",
            "packname" :"",
            "appname" :metaMap.get('APPLICATION_NAME'),
            "sessionid" : cookies.get('uniquevhgsession')
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == errorConstantURL.SUCCESS_CODE) {
                } else {
                }
            });
    }

    openNotificcation() {
        this.props.history.push({
            pathname: '/hardygames/notification',
            state: {
                gameScreen: 'paidGames',
            }
        })
    }

    performTaskOnClose = (flag) => {
        this.togglePopup()
        console.log("inside performTaskOnClose method, flag is ", flag)
        if (flag === 'confirm') {
            this.submitPaymentSubscription();
        } if (flag === 'ok-' + Constant.LOGIN_REQUIRED_MESSAGE) {
            this.props.history.push("/hardygames/login")
        } if (flag === 'ok-' + Constant.PAYMENT_SUCCESSFUL_MESSAGE) {
            //window.open(this.state.currentSelectedGame.gameurl, "_blank")
            this.openRequiredGame(this.state.currentSelectedGame)
        } if (flag === 'ok-' + Constant.ERROR_MESSAGE) {
            this.props.history.push("/hardygames/home")
        }
    }


    redirectToRequiredGame = (gameToPlay) => {
        this.props.history.push({
            pathname: '/hardygames/battle',
            state: {
                game: gameToPlay,
            }
        })

    }

    onDisplayMenu(obj) {
        this.setState({ isShowMenu: !this.state.isShowMenu });
    }


    openProfile() {
        if (sessionStorage.getItem("isLogin")) {
            this.props.history.push("/hardygames/profile");
        }
        else {
            this.props.history.push("/hardygames/login");
        }
    }

    togglePopup = (message, msgType, alert, buttonArray) => {
        this.setState({
            showPopup: !this.state.showPopup,
            msg: message,
            msgType: msgType,
            alert: alert,
            displayButtons: buttonArray
        })
    }

    togglePop() {
        this.setState({
            showPopup: !this.state.showPopup,
        });
    }

    togglePopOld() {
        this.setState({
            showPopupOld: !this.state.showPopupOld
        });
    }

    togglePopupOld() {
        this.setState({
            showPopupOld: !this.state.showPopupOld
        });
        if (this.state.loginRedirect == 'Y') {
            this.props.history.push("/hardygames/logout")
        }
        if (this.state.loginRedirect == 'C') {
            this.deduct()
        }
    }

    back() {
        this.props.history.push({
            pathname: '/hardygames/home'
        });
    }

    hasContentId(id){
        // console.log("Resturning " + this.state.loadedImage.has(id))
        return this.state.loadedImage.has(id);
    }

    hasContentName(id){
        // console.log("Resturning " + this.state.cachedImage.has(id))
        return this.state.cachedImage.has(id);
      }
    
    getContentName(id){
        //console.log("Resturning " + this.state.cachedImage.has(id))
        return this.state.cachedImage.get(id);
    }

    cachedImageConfigure(contentName, imageUrl) {
        localForage.getItem(contentName)
          .then( returnData => {
            if(!returnData){
              this.convertImgToBase64URL(imageUrl, function(base64Img){
                (base64Img) && localForage.setItem(contentName, base64Img)
              });
            }
          })
      }

    convertImgToBase64URL(url, callback, outputFormat){
        var img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function(){
            var canvas = document.createElement('CANVAS'),
            ctx = canvas.getContext('2d'), dataURL;
            canvas.height = img.height;
            canvas.width = img.width;
            ctx.drawImage(img, 0, 0);
            dataURL = canvas.toDataURL(outputFormat);
            callback(dataURL);
            canvas = null; 
        };
        img.src = url;
    }

    addLoadedImage(id) {
        console.log("Hi");
        if (!this.state.loadedImage.has(id)) {
            this.setState(({loadedImage}) => ({
                loadedImage: new Set(loadedImage).add(id)
            }));
        }
    }

    render() {
        const { showPopup, showPopupOld, msg, msgType, alert, displayButtons, battleGames } = this.state;

        var displayGames = [];
        battleGames.forEach(function (list) {
            displayGames.push(
                // onClick={()=> window.open(list.redirectUrl, "_blank")}
                <div key={list.id} className="Coin-games">
                    <div className="gamess" onClick={() => this.redirectToRequiredGame(list)} >
                        {(this.hasContentName(list.contentName)) ? 
                            <img src={this.getContentName(list.contentName)} style={{display : (this.hasContentId(list.contentId) ? "inline" : "none")}} onLoad={this.addLoadedImage.bind(this, list.contentId)} alt=""></img>
                            : <img src={list.savedImageUrl ? list.savedImageUrl : list.previewImageUrl} onLoad={this.addLoadedImage.bind(this, list.contentId)} style={{display : (this.hasContentId(list.contentId) ? "block" : "none")}} ></img>
                        }
                        {/* <img src={list.previewImageUrl} ></img> */}
                        {(!this.hasContentId(list.contentId)) && <img className="skeleton-loader" src={games} alt="" /> }
                        <div className="for-line-new">
                            <div className="coin-p">
                                <p className="coin-g-a">{list.title}</p>
                                <p className="coin-g-b">Knowledge Game</p>
                            </div>
                            <div className="coin-amt btn-wid">
                                <p>PLAY</p>
                            </div>
                        </div>
                    </div>

                </div>
            );

        }.bind(this));

        return (
            <div className="container-home"  >
                {this.state.enableLoader ?
                    <Loading />
                    : null
                }

                {showPopup ?
                    <PopupNew
                        status={msgType}
                        text={msg}
                        alertType={alert}
                        closePopup={this.togglePopup}
                        closePopupWithTask={this.performTaskOnClose}
                        displayButtons={displayButtons}
                    /> : null}

                {showPopupOld ?
                    <Popup
                        status={msgType}
                        text={msg}
                        alertType="Login Required!"
                        buttonName={this.state.loginRedirect == 'C' ? 'CONFIRM' : 'OK'}
                        closePopup={this.togglePopupOld.bind(this)}
                        closePopupNotNow={this.togglePopOld.bind(this)}
                        isConfirm={this.state.isConfirm}
                    /> : null}
                <div className="header-leaderboard-1 line-it-1">
                    <img src={back} onClick={this.back.bind(this)} className="float-lft free-back-img"></img>
                    <span className="header-text-free float-lft">Battle Games</span>
                </div>
                
                <div className="content-free"  >
                </div>
                {displayGames}
            </div>
        )
    }
}

export default withRouter(BattleHome);