import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './store'
import * as Notification from './container/Home/pushNotification'
import * as Analytics from './container/Home/Analytics'
import * as SocialLogin from './container/Home/SocialLogin'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

ReactDOM.render(

  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
  Notification.initializeFirebase(),
  Analytics.initializeFirebaseAnalytics(),
  SocialLogin.initializeFirebase()
);

  
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
