import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import {
    fetchHomePageReducers
} from './reducers/fetchHomePageReducers'

import {
    homeMenuReducer
} from './reducers/homeMenuReducer'

import {
    gameListReducers
} from './reducers/gameListReducers'


const reducer = combineReducers({
    home: fetchHomePageReducers,
    menu: homeMenuReducer,
    gameList: gameListReducers
});

const middleware = [thunk]

const store = createStore(
    reducer,
    applyMiddleware(...middleware)
)

export default store