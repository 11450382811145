import React from 'react'
import '../styles/style.css';
import axios from 'axios';
import { withRouter } from 'react-router'
import close from '../images/closebuy.svg';
import buyCoin from '../images/buycoins.svg';
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import PopupNew from './popupNew';
import Loading from './loading';
import queryString from "query-string";
import { findAllByAltText } from '@testing-library/react';
class BuyPlayCoins extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            showPopup: false,
            showPopupOld: false,
            isShowSpin: false,
            msg: '',
            msgType: 'S',
            enableLoader: true,
            isConfirm: 'N',
            loginRedirect: 'N',
            instantGamesId: '',
            bigWinGamesId: '',
            displayButtons: [],
            currentSelectedGame: {},
            gametype: 'FREE',
            isNeedToCheckSTW: false,
            isShowSpin: false,
            lables: constantURL.getLables()

        }
    }



    componentDidMount() {

        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

        const headers = {
            'Content-Type': 'application/json',
            'appkey': metaMap.get('APPKEY'),
            'operator': metaMap.get('OPERATOR'),
            'channel': 'wap',
            'access_token': sessionStorage.getItem("accessToken"),
            'msisdn': sessionStorage.getItem("username"),
            'deviceId': constantURL.DEVICE_ID
        };

        axios.get(constantURL.SPS_COMBINED_IP + "/v1/checkSubscriptionGeneric?appKey=" + metaMap.get('APPKEY') + "&operator=" + metaMap.get('OPERATOR') + "&msisdn=" + sessionStorage.getItem("username"), { headers }).then(response => response.data)
            .then((data) => {
                if (data && ((data.code == 2004) || (data.code == 2000))) {
                    this.setState({ dataList: data.data.notSubscribedPack, enableLoader: false });
                }
            });
    }

    back() {
        const values = queryString.parse(this.props.location.search);
        var source = values.source;
        var redirecturl = values.redirecturl;

        if (source == "fantasy") {
            window.location.replace(constantURL.FANTASY_URL + sessionStorage.getItem("username"));
        }
        if (source == "bsnlbattle" || source == "bsnltambola" || source == "tambola" || source == "battle") {
            window.location.replace(redirecturl);
        }
        this.props.history.push(constantURL.FRONTEND_NAME + "/playcoins");
    }

    creditCoins = (detail) => {

        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

        this.setState({ enableLoader: true });
                     
        const { lables } = this.state;
        const headers = {
            'Content-Type': 'application/json',
            'appkey': metaMap.get('APPKEY'),
            'operator': metaMap.get('OPERATOR'),
            'msisdn': sessionStorage.getItem("username")
        };

        const body = {
            "rewardName": "coin",
            "rewardFrom": "bigwin",
            "operation": "credit",
            "rewardCount": detail.loyaltyCoins,
            "info": detail.loyaltyCoins + " coins purchased at " + detail.priceString,
            "operationSource": "WAP"

        }

        axios.post(constantURL.SPS_COMBINED_IP + "/v1/userOperation", body, { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    this.togglePopup(detail.loyaltyCoins + lables.coinHasBeenAddedToYourAccount, "S", 'Success', ['1']);
                }
                else {
                    this.togglePopup(lables.notAbletoProcessYourRequest, 'E', lables.tryAgain, ['1'])
                }

            });
        this.setState({ enableLoader: false });
    }

    submitPaymentSubscription = (detail) => {

        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

        const url = `${constantURL.SPS_COMBINED_IP}${constantURL.SUBS_PACK_API_PARAM}` + detail.packName
        console.log('Url to hit for chargingKeyword payment : ', url)
        const { lables } = this.state;
        const headers = {
            'appkey': metaMap.get('APPKEY'),
            'Content-Type': constantURL.APPLICATION_JSON,
            'appid': metaMap.get('APP_ID'),
            'deviceId': constantURL.DEVICE_ID,
            'operator': metaMap.get('OPERATOR'),
            'access_token': sessionStorage.getItem("accessToken"),
            'channel': constantURL.CHANNEL,
            'countryCode': metaMap.get('COUNTRY_CODE'),
            'msisdn': sessionStorage.getItem("username")
        };


        axios.get(url, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code === errorConstantURL.SUCCESS_CODE) {
                    console.log(data.data)
                    if (data.data.subscriptionStatusCode === 0 || data.data.subscribtionStatus === constantURL.ACTIVE) {
                        this.creditCoins(detail);
                    }
                    else {
                        this.setState({ enableLoader: false });
                        console.log("User status is pending, showing pending popup for other response code")
                        if (data.msg.indexOf('sufficient') > -1) {
                            this.togglePopup(lables.insufficient ? lables.insufficient : 'Insufficient balance! Please recharge and try again.', 'E', lables.tryAgain, ['1'])
                        } else {
                            this.togglePopup(data.msg, 'E', lables.tryAgain, ['1'])
                        }
                      }

                }
                else {
                    this.setState({ enableLoader: false });
                    console.log('Some failure response : ', data);
                    // this.togglePopup(constantURL.ERROR_OCCURED, 'E', constantURL.ERROR_TYPE_ALERT, ['1'])
                    if (data.msg.indexOf('sufficient') > -1) {
                        this.togglePopup(lables.insufficient ? lables.insufficient : 'Insufficient balance! Please recharge and try again.', 'E', lables.tryAgain, ['1'])
                    } else {
                        this.togglePopup(data.msg, 'E', lables.tryAgain, ['1'])

                    }
                    // this.creditCoins(detail);

                }
            })
            .catch(error => {
                this.setState({ enableLoader: false });
                console.log("Error Occured : ", error)
                // this.togglePopup(constantURL.ERROR_OCCURED, 'E', constantURL.ERROR_TYPE_ALERT, ['1'])
                this.togglePopup('Due to some issue we are not able to process your request, please try after some time.', 'E', 'Try Again!', ['1'])

            })
    }

    togglePopup = (message, msgType, alert, buttonArray) => {


        this.setState({
            showPopup: !this.state.showPopup,
            msg: message,
            msgType: msgType,
            alert: alert,
            displayButtons: buttonArray
        })
        console.log(alert);


    }

    performTaskOnClose = (flag) => {
        const values = queryString.parse(this.props.location.search);
        var source = values.source;
        var redirecturl = values.redirecturl;

        if (source == "fantasy") {
            if (this.state.alert == "Success") {
                window.location.replace(constantURL.FANTASY_URL + sessionStorage.getItem("username"));
            }
        }
        if (source == "bsnlbattle" || source == "bsnltambola") {
            if (this.state.alert == "Success") {
                window.location.replace(redirecturl);
            }
        }

        this.togglePopup()
    }



    addcoins(data) {
        console.log(data)
        if (data.validity > 0) {
            this.setState({ enableLoader: true });
            this.submitPaymentSubscription(data);
        }
    }

    render() {
        const { lables } = this.state;
        var displayCoins = [];
        this.state.dataList.forEach(function (list) {
            console.log(list);
            displayCoins.push(
                <tr>
                    <td className="td-one"><img src={buyCoin}></img></td>
                    <td className="td-two">{list.description}</td>
                    <td className="td-three" onClick={() => this.addcoins(list)}>
                        <div>{list.priceString}</div>
                    </td>
                </tr>
            );

        }.bind(this));

        return (
            <div class="container-question">
                {this.state.enableLoader ?
                    <Loading />
                    : null
                }

                {this.state.showPopup ?
                    <PopupNew
                        status={this.state.msgType}
                        text={this.state.msg}
                        alertType={this.state.alert}
                        closePopup={this.togglePopup}
                        closePopupWithTask={this.performTaskOnClose}
                        displayButtons={this.state.displayButtons}
                    /> : null}

                <div className="header-b-pc" style={{ marginTop: '20px' }}>
                    <p>{lables.buyPlayCoins}</p>
                    <img src={close} onClick={this.back.bind(this)}></img>
                </div>
                <div className="content-b-pc">
                    <p className="pck-txt">{lables.choosePackOfPreference}</p>
                    <table className="b-pc-table">
                        {/* <tr>
                            <td className="td-one"><img src={buyCoin}></img></td>
                            <td className="td-two">1 Play Coin</td>
                            <td className="td-three">
                                <div>&#8377; 5</div>
                            </td>
                        </tr>
                        <tr>
                            <td className="td-one"><img src={buyCoin}></img></td>
                            <td className="td-two">1 Play Coin</td>
                            <td className="td-three">
                                <div>&#8377; 5</div>
                            </td>
                        </tr>
                        <tr>
                            <td className="td-one"><img src={buyCoin}></img></td>
                            <td className="td-two">1 Play Coin</td>
                            <td className="td-three">
                                <div>&#8377; 5</div>
                            </td>
                        </tr> */}
                        {displayCoins}
                    </table>
                </div>
            </div>

        )
    }
}

export default withRouter(BuyPlayCoins);