
import React from 'react'
import axios from 'axios';
import '../styles/style.css';
import { withRouter, Redirect } from 'react-router'
import * as Constant from '../Constants/constants'
import * as Errorcode from '../Constants/errorConstants'
import Popup from './popup';
import Loading from './loading'
import back from '../images/back.png';
import stop from '../images/eye.svg';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import {FRONTEND_NAME} from '../Constants/constants'

class EnterPassword extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            username: this.props.location.state === undefined ? '' : this.props.location.state.username,
            showPopup: false,
            message: '',
            msgType: '',
            alert: '',
            hideButton: true,
            error: false,
            enableLoader: false,
            password :"",
            showPassword : false,
            errormsg : ""

        }
         ;
        this.msisdnRegex = /^[6-9]{1}[0-9]{9}$/
        this.userRegex  = /^\d+$/
    }

    componentDidMount(){
        const cookies = new Cookies();      
        if(cookies.get('hardyIsLogin')){
            sessionStorage.setItem("isLogin", true);
            sessionStorage.setItem("accessToken", cookies.get('hardyAccessToken'))
            sessionStorage.setItem("mobileNumber", cookies.get('hardyMobileNumber'))
            sessionStorage.setItem("operator", cookies.get('hardyOperator'))
            sessionStorage.setItem("country", cookies.get('hardyCountry'))
            sessionStorage.setItem("username", cookies.get('hardyUsername'))
            sessionStorage.setItem("nameSalutation", cookies.get('hardyNameSalutation'))
            sessionStorage.setItem("profileImg", cookies.get('hardyProfileImg'))   
            this.props.history.push({
                pathname: FRONTEND_NAME+'/home'
            });     
        }
        
    //    $("#mobNum").on("keyup keypress blur keydown",function (event) { 
    //     console.log("inside jqueryListener : key is ", event.key )
    //     console.log("inside jqueryListener : keyCode is ", event.keyCode )

    //     var key = event.keyCode || event.charCode;
    //         var isBackspace = (key==187 || key==189 || key==69 || key==46 || key==43 || key==101 || key==190 || key==158);
    //         if(isBackspace){
    //             return false
    //         }

    //     // let key = Number(event.key) 
    //     // if (event.keyCode !== 8 && (isNaN(key) || event.key === null || event.key === ' ')) {
    //     // console.log("is not numeric")
    //     // return false;
    //     // }
    //    })

    

    }

   
    signInUser(userType) {

        console.log('Inside ' + userType + ' SIGNIN API')
        const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SIGNING_API_PARAM}`
        console.log("calling signin with url " + url);
        this.setState({ enableLoader: true })
        //var password = document.get

        const headers = {
            'Content-Type': Constant.APPLICATION_JSON,
            'Accept': Constant.APPLICATION_JSON,
            'deviceId': Constant.DEVICE_ID,
            'appid': Constant.APP_ID,
        };

        //this.setState({ msisdn: this.props.location.state.mydata.msisdn });

        const requestBody = {
            username: this.state.username,
            password: this.state.password
        }

        axios.post(url, requestBody, {
            headers: headers
        })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                    console.log(data)

                    //alert("user has been validated successfully");
                    const transfer = {
                        msisdn: data.data.username,
                        operator: data.data.operator.id,
                        country: data.data.country.id,
                        username: data.data.username,
                        accessToken: data.data.accessToken,
                        userImagePath:data.data.userImagePath
                    }
                    //After Session is created, user is redirected to Successful HomeScreen 
                    this.createLoginSession(transfer);
                    console.log('Redirecting ' + userType + ' to successful HOME screen... ')
                    this.openHomeScreen()

                } else {
                    // this.setState({
                    //     showModal: true,
                    //     msgType: 'E',
                    //     msg: "Error in login!"
                    // });
                    this.setState({
                        error: true,
                        errormsg:data.msg
                    })
                }
                this.setState({ enableLoader: false })

            });
    }

    createLoginSession = (data) => {

        const cookies = new Cookies();
        const current = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(current.getFullYear() + 1);

        cookies.set('hardyIsLogin', true, { path: '/' });
        cookies.set('hardyAccessToken', data.accessToken, { path: '/' });
        cookies.set('hardyMobileNumber', data.msisdn, { path: '/' });
        cookies.set('hardyOperator', data.operator, { path: '/' });
        cookies.set('hardyCountry', data.country, { path: '/' });
        cookies.set('hardyUsername', data.username, { path: '/' });


        sessionStorage.setItem("isLogin", true);
        sessionStorage.removeItem("isGuestLogin");
        sessionStorage.setItem("accessToken", data.accessToken)
        sessionStorage.setItem("mobileNumber", data.username)
        sessionStorage.setItem("operator", data.operator)
        sessionStorage.setItem("country", data.country)
        sessionStorage.setItem("username", data.username)
        
            cookies.set('hardyNameSalutation', data.username, { path: '/' }); 
            sessionStorage.setItem("nameSalutation", data.username)
        
        console.log(data.userImagePath)
        console.log(data.userImagePath == null)
        console.log(data.userImagePath == 'null')

        if (data.userImagePath != null) {
            sessionStorage.setItem("profileImg", data.userImagePath)
            cookies.set('hardyProfileImg', data.userImagePath, { path: '/' });
     
        }

        this.saveLogs(Constant.LOGIN_ACTION);
     
    }

    saveLogs(category) {
        const cookies = new Cookies();
        var url = Constant.APPLOGGER_COMBINED_IP + "/v1/saveLogs";
        
        const headers = {
            'Content-Type': 'application/json',
            'appid': Constant.APP_ID,
            'operator': Constant.OPERATOR,
            'msisdn': sessionStorage.getItem("mobileNumber"),
            'channel': 'WAP'
        };

        const requestBody = {
            "category" : category,
            "subCategory" : "",
            "content" : category,
            "url" : "/login",
            "packname" :"",
            "appname" :Constant.APPLICATION_NAME,
            "sessionid" : cookies.get('uniquevhgsession')
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                } else {
                }
            });
    }



    signUpUser() {

        console.log('Inside SignUp user')
        const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SIGNUP_API_PARAM}`;
        console.log("calling SignUp with url " + url);
        this.setState({ enableLoader: true })

        const headers = {
            'Content-Type': Constant.APPLICATION_JSON,
            'Accept': Constant.APPLICATION_JSON,
            'deviceId': Constant.DEVICE_ID,
            'appid': Constant.APP_ID,
        };

        const requestBody = {
            username: this.state.username,
            password: this.state.password,
            operatorName: Constant.MOB_OPERATOR
        }

        axios.post(url, requestBody, {
            headers: headers
        })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                    console.log("SignUp is successfull, going for SignIn")
                    this.signInUser('NEW USER')
                } else {
                    this.setState({
                        showModal: true,
                        msgType: 'E',
                        msg: "Error in login!"
                    });
                }
                this.setState({ enableLoader: false })

            });
    }


    checkUserType = (data) => {

        switch (data.screenFlag) {

            case Constant.OTP_SCREEN:
                console.log("Inside OTP_SCREEN section : ")
                if (data.userType === Constant.NEW_USER || data.userType === Constant.EXISTING_USER) {

                    console.log('Redirecting ' + data.userType + ' to OTP screen... ')
                    this.openEnterOtpPage(data.userType)
                }
                break;
            case Constant.SET_PWD_SCREEN:
                console.log("Inside SET_PWD_SCREEN section : ")
                if (data.userType === Constant.NEW_USER) {
                    console.log('Redirecting NEW user to SET PWD screen... Since there is No PWD Validation ' +
                        'Screen, so calling SIGNUP API internally for New user ...')
                    //Here, First User is Signedup and if Successful, Signed Up then User will be signedIN and
                    //session will be created and Then User is Redirected to successfull Home Screen!! 
                    this.signUpUser();

                }
                break;
            case Constant.VALIDATE_PWD_SCREEN:
                console.log("Inside VALIDATE_PWD_SCREEN section : ")
                if (data.userType === Constant.EXISTING_USER) {
                    console.log('Redirecting EXISTING user to VALIDATE PWD screen...Since there is No PWD Validation ' +
                        'Screen, so calling SIGNIN API internally for New user ...')
                    this.signInUser('EXISTING USER');
                }
                break;
            default:
                console.log("Error Fetching userType from DB")
        }
    }


    submitHandler = (event) => {
        event.preventDefault()
        console.log('username : ', this.state.username)
        if (!this.msisdnRegex.test(this.state.username)) {
            this.setState({
                error: true,
                errormsg:'Invalid Phone Number*'
            })
            console.log('from submit after : ', this.state.error)
        } else {
            this.setState({ enableLoader: true })

            const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SUBMIT_LOGIN_OR_SIGNUP_API_PARAM}`;
            console.log("Url to call : ", url);

            const headers = {
                'appid': Constant.APP_ID,
                'Content-Type': Constant.APPLICATION_JSON,
                'Accept': Constant.APPLICATION_JSON
            }

            const requestBody = {
                'username': this.state.username,
                'countryCode': Constant.COUNTRY_CODE,
            }

            // POST call to Submit Login or SignUp
            axios.post(url, requestBody, { headers })
                .then(response => response.data)
                .then(data => {
                    console.log(data)
                    if (data && data.code === Errorcode.SUCCESS_CODE) {
                        console.log("userType is ", data.data.userType)
                        console.log("And screenFlag is ", data.data.screenFlag)

                        this.checkUserType(data.data);

                    } else if (data && data.code === Errorcode.OTP_LIMIT_EXHAUSTED) {
                        //Resend OTP after 15 mins
                        console.log("OTP Limit Exhausted with msg : ", data.msg);
                        this.togglePopup(data.msg, 'A', Constant.OTP_LIMIT_REACHED, true)
                    } else {
                        //Add Modal for Failure Case
                        console.log("Technical Error, Something went Wrong!!");
                    }

                    this.setState({ enableLoader: false })

                })
                .catch(error => {
                    this.setState({ enableLoader: false })

                    //Add Modal for Error Case 
                    console.log("Error Occured : ", error)
                })
        }

    }

    changeHandler = (event) => {
        console.log("inside handleListener : key is ", event.key )
        console.log("inside handleListener : keyCode is ", event.keyCode )
        console.log("input value is ", event.target.value)
        if(event.keyCode === 46){
            console.log("inside changeHandler : ")
            return false
        }

        const userName = event.target.value.trim();
        console.log("username : ", userName)
        console.log("userName.includes(.) : ", userName.includes("."))
        console.log("this.userRegex.test(userName) : ", this.userRegex.test(userName))
        if (!Number(userName) || !this.userRegex.test(userName) || (userName.length >= 10 && this.state.username.length === 10) ) {
            console.log("username inside: ", userName)
            if( userName === ''){
                this.setState({
                    username : ''
                })
            }
            return false;
        }
        console.log("username outside: ", userName)
        this.setState({
            username: userName,
            error: false
        })
    }



    openHomeScreen = () => {
        this.props.history.push({
            pathname: FRONTEND_NAME+'/home',
            state: {
                username: this.state.username
            }
        });
    }

    togglePopup = (msg, msgType, alert, hideBtn) => {
        this.setState({
            showPopup: !this.state.showPopup,
            message: msg,
            msgType: msgType,
            alert: alert,
            hideButton: hideBtn
        })

    }

    back() {
        this.props.history.push(FRONTEND_NAME+"/home");
    }

    showChangePassword() {
        // ;
        sessionStorage.setItem('status','forward');
        sessionStorage.setItem('changePassword','true');   
        this.props.history.push(FRONTEND_NAME+"/resetmsisdn")
    
    }

    render() {

        const { username, message, msgType, alert, showPopup, error, hideButton,password,showPassword } = this.state
        const enabled = username.length === 10
        return (
            <div className="container-login">
                {/* <div className="header-leaderboard edit-pad login-border">
                    <img src={back} alt="" onClick={this.back.bind(this)} className="float-lft"></img>
                </div> */}
                {
                    showPopup ?
                        <Popup text={message} status={msgType} alertType={alert} hideButton={hideButton} closePopup={this.togglePopup, this.openHomeScreen} /> :
                        null
                }
                {this.state.enableLoader ?
                    <Loading />
                    : null
                }
                <p className="loginn">Welcome Back</p>
                <p className="msisdn-p">Enter Password</p>
                
                <div className={enabled ? "enter-mm" : "enter-mm-gry"}>
                    <input maxLength="8" id="mobNum" type={showPassword?"text":"password"} className={enabled ? "enter-p" : "enter-m-gry"} name="msisdnId" value={password}
                        onChange={(e)=>{this.setState({password:e.target.value})}} placeholder="Vi Password" style={{width:'calc(100% - 16px)'}}></input>
                    <span style={{width:'16px'}}><img onClick={()=>{this.setState({showPassword:!showPassword})}} src={stop}></img></span>
                </div>
                <p className="set-reset" onClick={()=>this.showChangePassword()}>Set/Reset Password</p>
                {
                    error ?
                        <p className="error-txt-red" >{this.state.errormsg}</p> :
                        null
                }
                <div className="button-submit" style={{marginTop:'15px'}}>
                    <button type="submit" className={enabled ? "submit-login" : "submit-login disable-gry"} onClick={()=>this.signInUser('EXISTING_USER')} disabled={!enabled}>SUBMIT</button>
                </div>
            </div>
        )
    }
}

export default withRouter(EnterPassword);