import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Switch } from 'react-router-dom';
// import games from "./container/images/hardy.svg";
import Login from './container/Home/enterMsisdn';
import EnterOtp from './container/Home/enterOtp';
import HomeScreen from './container/Home/homeScreen';
import Splash from './container/Home/splash';
import Fantasy from './container/Home/fantasy';
import EditProfile from './container/Home/editProfile';
import Profile from './container/Home/profileCollapsed';
import FAQ from './container/Home/faq';
import Help from './container/Home/help';
import SpinTheWheel from './container/Home/spinTheWheel';
import GameView from './container/Home/gameView';
import MyWinnings from './container/Home/myWinnings';
import Prize from './container/Home/prize';
import EditPhoto from './container/Home/editPhoto';
import Popup from './container/Home/popup';
import PopularGames from './container/Home/popularGames';
import PlayCoins from './container/Home/playCoins';
import CoinGames from './container/Home/coinGames';
import FreeGames from './container/Home/freeGames';
import PaidGames from  './container/Home/paidGames';
import Notification from  './container/Home/notification';
import InstantGames from './container/Home/instantGames';
import WinbigGames from './container/Home/winbigGames';
import Logout from './container/Home/logout';
import CouponDetails from './container/Home/couponDetail';
import HeadToHead from './container/Home/headtohead';
import Battle from './container/Home/battle';
import PremiumGames from './container/Home/premiumGames';
import ErrorLogin from './container/Home/errorLogin';
import RecommendedGames from './container/Home/recommended';
import BundleGames from './container/Home/bundlegames';
import BattleHome from './container/Home/battlehome';
import Redirect from './container/Home/redirect';
import enterPassword from './container/Home/enterPassword';
import setPassword from './container/Home/setPassword';
import ProfileEditNew from './container/Home/profileEditNew';
import RedemptionCenter from './container/Home/rc';

import ResetMsisdn from './container/Home/reset-msisdn';
import ResetOtp from './container/Home/reset-otp';
import ResetPassword from './container/Home/reset-password';
import SessionTimeout from './container/Home/sessionTimeout';
import BuyPlayCoins from './container/Home/buyPlayCoins';
import { FRONTEND_NAME } from './container/Constants/constants';
import winner from './container/Home/winner';
import winners from './container/Home/winners';
import Redemption from './container/Home/redemption';
import mycoupon from './container/Home/mycoupon';
import Tnc from './container/Home/Tnc';
import PrivacyPolicy from './container/Home/privacyPolicy';
import SocialLoginPage from './container/Home/SocialLoginPage';
import loginPhase2 from './container/Home/loginPhase2';
import enterOtpPhase2 from './container/Home/enterOtpPhase2';
import leaderboardphase2 from './container/Home/leaderboardphase2';
import leaderboardMonthlyPhase2 from './container/Home/leaderboardMonthlyPhase2';
import menuPhase2 from './container/Home/menuPhase2';
import searchCountry from './container/Home/searchCountry';
import support from './container/Home/support';
import aboutUs from './container/Home/aboutUs';
class App extends React.Component {

  render() {
    return (
      <div className="App">
        {
          <Switch>
            <Route path={FRONTEND_NAME+"/paidGames"} component={PaidGames}>
            </Route>
            <Route path={FRONTEND_NAME+"/editPhoto"} component={EditPhoto}>
            </Route>
            <Route path={FRONTEND_NAME+"/prize"} component={Prize}>
            </Route>
            <Route path={FRONTEND_NAME+"/login"} component={Login}>
            </Route>
            <Route path={FRONTEND_NAME+"/loginotp"} component={EnterOtp}>
            </Route>
            <Route path={FRONTEND_NAME+"/home"} component={HomeScreen}>
            </Route>
            <Route path={FRONTEND_NAME+"/splash"} component={Splash}>
            </Route>
            <Route path={FRONTEND_NAME+"/fantasy"} component={Fantasy}>
            </Route>
            <Route path={FRONTEND_NAME+"/editprofile"} component={EditProfile}>
            </Route>
            <Route path={FRONTEND_NAME+"/editprofilenew"} component={ProfileEditNew}>
            </Route>
            {/* <Route path={FRONTEND_NAME+"/profile"} component={Profile}>
            </Route> */}
            <Route path={FRONTEND_NAME+"/faq"} component={FAQ}>
            </Route>
            <Route path={FRONTEND_NAME+"/help"} component={Help}>
            </Route>
            <Route path={FRONTEND_NAME+"/spinthewheel"} component={SpinTheWheel}>
            </Route>
            <Route path={FRONTEND_NAME+"/mywinnings"} component={MyWinnings}>
            </Route>
            <Route path={FRONTEND_NAME+"/gameview"} component={GameView}>
            </Route>
             <Route path={FRONTEND_NAME+"/populargames"} component={PopularGames}>
            </Route>
            <Route path={FRONTEND_NAME+"/playcoins"} component={PlayCoins}>
            </Route>
            <Route path={FRONTEND_NAME+"/coingames"} component={CoinGames}>
            </Route>
            <Route path={FRONTEND_NAME+"/freegames"} component={FreeGames}>
            </Route>
            <Route path={FRONTEND_NAME+"/instantgames"} component={InstantGames}>
            </Route>
            <Route path={FRONTEND_NAME+"/winbiggames"} component={WinbigGames}>
            </Route>
            <Route path={FRONTEND_NAME+"/notification"} component={Notification}>
            </Route>
            <Route path={FRONTEND_NAME+"/logout"} component={Logout}>
            </Route>
            <Route path={FRONTEND_NAME+"/coupondetails"} component={CouponDetails}>
            </Route>
            <Route path={FRONTEND_NAME+"/headtohead"} component={HeadToHead}>
            </Route>
            <Route path={FRONTEND_NAME+"/battle"} component={Battle}>
            </Route>
            <Route path={FRONTEND_NAME+"/premiumgames"} component={PremiumGames}>
            </Route>
            <Route path={FRONTEND_NAME+"/errorlogin"} component={ErrorLogin}>
            </Route>
            <Route path={FRONTEND_NAME+"/recommendedgames"} component={RecommendedGames}>
            </Route>
            <Route path={FRONTEND_NAME+"/bundlegames"} component={BundleGames}>
            </Route>
            <Route path={FRONTEND_NAME+"/battlehome"} component={BattleHome}>
            </Route>
            <Route path={FRONTEND_NAME+"/redirect"} component={Redirect}>
            </Route>
            <Route path={FRONTEND_NAME+"/enterpassword"} component={enterPassword}>
            </Route>
            <Route path={FRONTEND_NAME+"/setpassword"} component={setPassword}>
            </Route>
            <Route path={FRONTEND_NAME+"/resetmsisdn"} component={ResetMsisdn}>
            </Route>
            <Route path={FRONTEND_NAME+"/resetotp"} component={ResetOtp}>
            </Route>
            <Route path={FRONTEND_NAME+"/resetpassword"} component={ResetPassword}>
            </Route>
            <Route path={FRONTEND_NAME+"/sessiontimeout"} component={SessionTimeout}>
            </Route>
            <Route path={FRONTEND_NAME+"/buyplaycoins"} component={BuyPlayCoins}>
            </Route>
            <Route path={FRONTEND_NAME+"/redemptionCenter"} component={RedemptionCenter}>
            </Route>
            <Route path={FRONTEND_NAME+"/winner"} component={winner}>
            </Route>
            <Route path={FRONTEND_NAME+"/winners"} component={winners}>
            </Route>
            <Route path={FRONTEND_NAME+"/redemption"} component={Redemption}>
            </Route>
            <Route path={FRONTEND_NAME+"/mycoupon"} component={mycoupon}>
            </Route>
            <Route path={FRONTEND_NAME+"/tnc"} component={Tnc}>
            </Route>            
            <Route path={FRONTEND_NAME+"/privacypolicy"} component={PrivacyPolicy}>
            </Route>
            <Route path={FRONTEND_NAME+"/sociallogin"} component={SocialLoginPage}>
            </Route>
            <Route path={FRONTEND_NAME+"/loginphase2"} component={loginPhase2}>
            </Route>       
            <Route path={FRONTEND_NAME+"/otpphase2"} component={enterOtpPhase2}>
            </Route>     
            <Route path={FRONTEND_NAME+"/leaderboardmain"} component={leaderboardphase2}>
            </Route>      
            <Route path={FRONTEND_NAME+"/leaderboardmonthly"} component={leaderboardMonthlyPhase2}>
            </Route>  
            <Route path={FRONTEND_NAME+"/profile"} component={menuPhase2}>
            </Route>  
            <Route path={FRONTEND_NAME+"/searchCountry"} component={searchCountry}>
            </Route>      
            <Route path={FRONTEND_NAME+"/support"} component={support}>
            </Route>       
            <Route path={FRONTEND_NAME+"/aboutus"} component={aboutUs}>
            </Route>       
          </Switch>
        }
      </div>
    );
  }
}

export default App;
