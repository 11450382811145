import React from 'react'
import axios from 'axios';
import '../styles/popup.css'
//  import  TickGreen from '../images/tick-green.png'
import infoAlert from '../images/infoAlert.png'
import infoError from '../images/infoError.png'
import infoSuccess from '../images/infoSuccess.png'
import { withRouter } from 'react-router'

class SinglePopup extends React.Component {
    render() {

        return (
            <div id="apply-modal" className=  {this.props.orientations=="landscape"? "modal padding-modal" :"modal"}    >
                <div className="modal-content">
                    <div className="modal-heading">
                        <p className="popup-p">{this.props.alertType}</p>
                    </div>
                    <div className="apply-modal-header">
                        <p>{this.props.text}</p>
                    </div>
                    <div className="modal-close">
                    <button className="popup-btn" style={{marginRight:'0px'}} onClick={this.props.closePopup}>OK</button>
                    </div>
                    {/* <div className="modal-close">
                        <button className="popup-btn">CANCEL</button>
                        <button className="popup-btn">CONFIRM</button>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default withRouter(SinglePopup);