import axios from 'axios';

import {
    FETCH_GAME_LIST_START,
    FETCH_GAME_LIST_SUCCESS,
    FETCH_GAME_LIST_ERROR,
    FETCH_GAME_LIST_SESSION_ERROR,
    FETCH_GAME_LIST_REFRESH
} from './../container/Constants/gameListConstant'

import {
    DEVICE_ID,
    OPERATOR,
    CONTENT_COMBINED_IP,
    APPLICATION_JSON,
    CHANNEL
} from '../container/Constants/constants'

import {
    SUCCESS_CODE
} from '../container/Constants/errorConstants';

import localForage from 'localforage'

var protocol = window.location.protocol;



const buildGameObject = (gameType, game) => {

    cachedImageConfigure(game.contentName, (game.savedImageUrl) ? game.savedImageUrl : game.previewImageUrl);

    return {
        id: gameType,
        title: game.title,
        aliasname: game.aliasname,
        noOfUsersDynamic: game.noOfUsersDynamic,
        previewImageUrl: game.previewImageUrl,
        savedImageUrl: game.savedImageUrl,
        gameurl: protocol === "https:" ? game.secondarygameurl : game.gameurl,
        price: game.chargingcurrency + ' ' + game.chargingvalue,
        coinsRequired: game.coinsRequired,
        gametype: game.gametype,
        chargingKeyword: game.chargingKeyword,
        checkSubKeyword: game.checkSubKeyword,
        chargingvalue: game.chargingvalue,
        chargingcurrency: game.chargingcurrency,
        replacedynamicparam: game.replacedynamicparam,
        showPlayButton: game.showPlayButton,
        contentId: game.contentId,
        contentName: game.contentName,
        validity: game.validity
    }
}

const cachedImageConfigure = (contentName, imageUrl) => {
    localForage.getItem(contentName)
        .then(returnData => {
            if (!returnData) {
                convertImgToBase64URL(imageUrl, function (base64Img) {
                    (base64Img) && localForage.setItem(contentName, base64Img)
                });
            }
        })
}

const convertImgToBase64URL = (url, callback, outputFormat) => {
    let img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
        var canvas = document.createElement('CANVAS'),
            ctx = canvas.getContext('2d'), dataURL;
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
        canvas = null;
    };
    img.src = url;
}

export const fetchGameList = (locationFrom, currentCategory, currentItems) => async (dispatch, getState) => {
    try {
        console.log(currentCategory);
        console.log(locationFrom);
        console.log(currentItems);

        const { categories } = getState().gameList;

        if ((
            (categories.has(currentCategory.categoryId)
                || categories.has(currentCategory.id)) && currentItems === 0)
            || ((categories.has(currentCategory.categoryId)
                && !categories.get(currentCategory.categoryId).hasMore))
        ) {
            // console.log(categories.get(currentCategory.categoryId).pageCount + " " + currentItems);
            return;
        }



        dispatch({
            type: FETCH_GAME_LIST_REFRESH
        })

        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
    
        const headers = {
            'Content-Type': APPLICATION_JSON,
            'appid': sessionStorage["appid"],
            'deviceId': DEVICE_ID,
            'operator': metaMap.get('OPERATOR'),
            'Access_token': sessionStorage.getItem("accessToken"),
            'channel': CHANNEL,
            'Msisdn': sessionStorage.getItem("username")
        };

        currentItems = currentItems + 1;

        let url = "";
        if (locationFrom == "bundle") {
            url = CONTENT_COMBINED_IP + "/v1/fetchViewAllByCategoryNameAndDynamicId?categoryName=bundleGames&dynamicId=" + currentCategory.id + "&pageNo=" + currentItems;
        }
        else {
            url = CONTENT_COMBINED_IP + "/v1/fetchCategories?categoryId=" + currentCategory.categoryId + "&pageNo=" + currentItems;//Constant.PAID_ID;
        }

        const { data } = await axios.get(url, { headers })

        if (data && (data.code === SUCCESS_CODE)) {

            let subCategoriesDetail = [];
            let tempList = [];
            //currentItems === 1 ? [] : getState().categories.get(currentCategory.categoryId); 

            if (locationFrom === "bundle") {
                subCategoriesDetail = data.data.categories[0].contentList;
                if (categories.has(currentCategory.id)) tempList = categories.get(currentCategory.id).trendingPaidGames;
            } else {
                subCategoriesDetail = data.data.categories[0].subCategories;
                if (categories.has(currentCategory.categoryId)) tempList = categories.get(currentCategory.categoryId).trendingPaidGames;
            }

            if (subCategoriesDetail) {
                let pG = locationFrom === "bundle" ? subCategoriesDetail : subCategoriesDetail[0].content3;   //Trending Paid Games
                let pageCount = locationFrom === "bundle" ? subCategoriesDetail : subCategoriesDetail[0].pagination.totalPages;   //Trending Paid Games
                //var tempList = this.state.trendingPaidGames;
                // let tempList = currentItems === 1 ? [] : getState().categories.get(currentCategory.categoryId);

                pG.forEach((game, index) => {
                    tempList.push(buildGameObject("c" + index + currentItems, game));
                })

                // console.log(getState())
                let tempMap = getState().gameList.categories;

                let hasMore = true;

                if (currentItems === pageCount) hasMore = false;

                let payload = {
                    categories: tempMap.set(currentCategory.categoryId,
                        {
                            trendingPaidGames: tempList,
                            currentItems: currentItems,
                            pageCount: pageCount,
                            hasMore: hasMore
                        })
                }

                console.log(payload.categories)

                dispatch({
                    type: FETCH_GAME_LIST_SUCCESS,
                    payload: payload.categories
                })
            }
            else {
                dispatch({
                    type: FETCH_GAME_LIST_ERROR
                })
            }
            // this.setState({ enableLoader: false })
            // this.saveLogs(Constant.PAID_ACTION)
        } else if (data.code == 4001 || data.code == '4001') {
            dispatch({
                type: FETCH_GAME_LIST_SESSION_ERROR
            })
        }
        else {
            dispatch({
                type: FETCH_GAME_LIST_ERROR
            })
        }
        // else {
        //     this.saveLogs(Constant.PAID_ACTION)
        //     this.setState({ enableLoader: false, hasMoreItems: false })
        //     this.togglePopup(lables.somethingWentWrongInLoadingData, 'E', lables.error, ['1'])
        // }
    }
    catch (error) {
        // alert("Hi")
        console.log(error)
        dispatch({
            type: FETCH_GAME_LIST_ERROR
        })
    }
}

export const updateGameCount = (id, contentId) => async (dispatch, getState) => {
    const { categories } = getState().gameList;

    console.log(getState())

    if (categories.has(id)) {
        let gameListMap = categories;
        let gameList = gameListMap.get(id);
        gameList.trendingPaidGames.map(game => {
            if (game.contentId === contentId) {
                game.showPlayButton = true;
            }
        })

        await gameListMap.set(id, gameList);

        console.log(gameListMap)

        dispatch({
            type: FETCH_GAME_LIST_SUCCESS,
            payload: gameListMap
        })
    }
}