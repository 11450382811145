import React, { Component } from 'react';
import { withRouter } from 'react-router';
import '../styles/menu.css';
import axios from 'axios';
import backIcon from '../images/backIcon.svg';
import defaultProfile from '../images/defaultProfile.png';
import winningsIcon from '../images/winningsIcon.svg';
import redemptionIcon from '../images/redemptionIcon.svg';
import openIcon from '../images/openIcon.svg';
import faqIcon from '../images/faqIcon.svg';
import shareIcon from '../images/shareIcon.svg';
import referIcon from '../images/referIcon.svg';
import SocialMedia from './socialMedia';

import supportIcon from '../images/supportIcon.svg';
import logoutIcon from '../images/logoutIcon.svg';
import settingsIcon from '../images/settingsIcon.svg';
import logo from '../images/bigClown.svg';
import * as constantURL from '../Constants/constants'
import * as Constant from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Loading from './loading'
import Popup from './popup';
import Cookies from 'universal-cookie';
import { FRONTEND_NAME } from '../Constants/constants'
import { logEvents } from "./Analytics";
import localForage from 'localforage'

class MenuPhase2 extends Component {

  constructor(props) {
    super(props);
    let lablesData = JSON.parse(sessionStorage["lablesData"]);
    this.state = {
      sharePopup: false,
      statFlag: false,
      enableLoader: false,
      showPopup: false,
      lables: constantURL.getLables(),
      activeLang: sessionStorage["lang"],
      activeLangData: lablesData.dialectSupported.filter((item) => item.dialect.languagekey == sessionStorage["lang"])[0]
    }
  }

  componentDidMount() {
    sessionStorage.setItem("mobNo", sessionStorage.getItem("mobileNumber"));
    sessionStorage.setItem('status', 'false');

    this.signInUser();
  }

  signInUser() {
    var url = '';

    if (sessionStorage.getItem("loginType") == "social") {
      url = constantURL.PROFILER_COMBINED_IP +
        "/v1/fetchUserDetailsSocial?username=" +
        sessionStorage.getItem("username");
    }
    else {
      url = constantURL.PROFILER_COMBINED_IP +
        "/v1/editUserDetails?username=" +
        sessionStorage.getItem("username");
    }

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      msisdn: sessionStorage.getItem("username"),
      access_token: sessionStorage.getItem("accessToken"),
      appid: sessionStorage.getItem("appid"),
    };


    axios
      .get(url, {
        headers: headers,
      })
      .then((response) => response.data)
      .then((data) => {
        if (data && data.code == 2000) {
          if (data.data.dateOfBirth) {
            sessionStorage.setItem("dob", data.data.dateOfBirth);
          }
          sessionStorage.setItem("nameSalutation", data.data.nickName);
          sessionStorage.setItem("gender", data.data.gender);
          sessionStorage.setItem("emailid", data.data.email);
          sessionStorage.setItem("address", data.data.address);
        } else {
        }
      });
  }


  saveDownloadLogs = (content, category, subCategory) => {

    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
    
    const cookies = new Cookies();
    var url = Constant.APPLOGGER_COMBINED_IP + "/v1/saveDownloadLogs";

    const headers = {
      'Content-Type': 'application/json',
      'appid': metaMap.get('APP_ID'),
      'operator': metaMap.get('OPERATOR'),
      'msisdn': sessionStorage.getItem("username"),
      'channel': 'WAP'
    };

    const requestBody = {
      "content_name": content,
      "session_id": cookies.get('uniquevhgsession'),
      "country": "India",
      "circle": "All",
      "packname": "A2HS",
      "appname": metaMap.get('APPLICATION_NAME'),
      "content_type": category,
      "param1": subCategory,
      "param2": "",
      "substatus": '',
      "winningStatus": '',
      "gameStatus": "Success",
      "response": "Success",
      "dwn_flag": "0"
    }

    axios.post(url, requestBody, { headers })
      .then(response => response.data)
      .then(data => {
        if (data && data.code == errorConstantURL.SUCCESS_CODE) {
        } else {
        }
      });
  }

  closeSharePopup() {
    this.setState({ sharePopup: false });
  }

  showShare() {
    logEvents(constantURL.SHARE)
    if (navigator.share) {
      navigator.share({
        title: 'Big Win',
        url: constantURL.APP_URL,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      this.setState({ sharePopup: true });
    }
  }


  editProfile() {
    const isLogin = sessionStorage.getItem("isLogin");
    if (isLogin) {
      sessionStorage.setItem('status', 'forward');
      this.props.history.push(FRONTEND_NAME + "/editprofilenew");
      console.log('User already registered')
    } else {
      sessionStorage.setItem('status', 'forward');
      this.props.history.push(FRONTEND_NAME + "/editprofilenew");
      //  this.props.history.push(FRONTEND_NAME+"/login");
    }
  }

  logout() {
    this.setState({ showPopup: true })
  }
  togglePopup() {
    this.setState({ showPopup: false })
  }

  clearSession() {
    
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

    this.setState({ enableLoader: true })
    localForage.clear();
    sessionStorage.setItem('status', 'forward');

    var d = new Date,
      dformat = [d.getFullYear(), ('0' + d.getMonth() + 1).slice(-2),
      ('0' + d.getDate()).slice(-2)].join('-') + ' ' +
        [('0' + d.getHours()).slice(-2),
        ('0' + d.getMinutes()).slice(-2),
        ('0' + d.getSeconds()).slice(-2)
        ].join(':');

    const cookies = new Cookies();
    var url = constantURL.APPLOGGER_COMBINED_IP + "/v1/saveLogs";

    const headers = {
      'Content-Type': 'application/json',
      'appid': metaMap.get('APP_ID'),
      'operator': metaMap.get('OPERATOR'),
      'msisdn': sessionStorage.getItem("username"),
      'channel': 'WAP'
    };

    const requestBody = {
      "category": 'LOGOUT',
      "subCategory": "",
      "content": 'LOGOUT',
      "url": "/menu",
      "packname": "",
      "appname": metaMap.get('APPLICATION_NAME'),
      "sessionid": cookies.get('uniquevhgsession'),
      "operator": metaMap.get('OPERATOR'),
      "country": 'INDIA',
      "endtime": dformat,
      'param4': 'USER_LOGOUT'
    }

    axios.post(url, requestBody, { headers })
      .then(response => response.data)
      .then(data => {

        const headers = {
          'Content-Type': 'application/json',
          'appid': metaMap.get('APP_ID'),
          'deviceId': constantURL.DEVICE_ID,
          'operator': metaMap.get('OPERATOR'),
          'Access_token': sessionStorage.getItem('accessToken'),
          'msisdn': sessionStorage.getItem('mobileNumber')

        };
        const cookies = new Cookies();
        axios.get(constantURL.PROFILER_COMBINED_IP + "/v1/logout?username=" + sessionStorage.getItem('username'), { headers }).then(response => response.data)
          .then((data) => {
            if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
              sessionStorage.removeItem('isLogin')
              sessionStorage.removeItem('isGuestLogin')
              sessionStorage.removeItem('loginType')
              sessionStorage.removeItem("username");
              sessionStorage.removeItem("accessToken");
              sessionStorage.removeItem("nameSalutation");
              sessionStorage.removeItem("mobileNumber");
              sessionStorage.removeItem("profileImg");

              cookies.remove('hardyIsLogin', { path: '/' });
              cookies.remove('hardyAccessToken', { path: '/' });
              cookies.remove('hardyMobileNumber', { path: '/' });
              cookies.remove('hardyOperator', { path: '/' });
              cookies.remove('hardyCountry', { path: '/' });
              cookies.remove('hardyUsername', { path: '/' });
              cookies.remove('hardyNameSalutation', { path: '/' });
              cookies.remove('hardyProfileImg', { path: '/' });
              // cookies.remove('uniquevhgsession',{ path: '/' });
              cookies.set('uniquevhgsession', '0', { path: '/' });

              console.log('COOKIE REMOVED')

              this.props.history.push(FRONTEND_NAME + "/sociallogin");
            } else {
              sessionStorage.removeItem('isLogin')
              sessionStorage.removeItem('isGuestLogin')
              sessionStorage.removeItem("username");
              sessionStorage.removeItem("accessToken");
              sessionStorage.removeItem("nameSalutation");
              sessionStorage.removeItem("mobileNumber");
              sessionStorage.removeItem("profileImg");


              cookies.remove('hardyIsLogin', { path: '/' });
              cookies.remove('hardyAccessToken', { path: '/' });
              cookies.remove('hardyMobileNumber', { path: '/' });
              cookies.remove('hardyOperator', { path: '/' });
              cookies.remove('hardyCountry', { path: '/' });
              cookies.remove('hardyUsername', { path: '/' });
              cookies.remove('hardyNameSalutation', { path: '/' });
              cookies.remove('hardyProfileImg', { path: '/' });
              cookies.remove('uniquevhgsession', { path: '/' });

              console.log('COOKIE REMOVED')

              this.props.history.push(FRONTEND_NAME + "/sociallogin");

            }
            this.setState({ enableLoader: false })

          });
      });
  }


  saveLogs(category) {
        
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
 
    const cookies = new Cookies();
    var url = constantURL.APPLOGGER_COMBINED_IP + "/v1/saveLogs";

    const headers = {
      "Content-Type": "application/json",
      appid: metaMap.get('APP_ID'),
      operator: metaMap.get('OPERATOR'),
      msisdn: sessionStorage.getItem("username"),
      channel: "WAP",
    };

    const requestBody = {
      category: category,
      subCategory: "",
      content: category,
      url: "/menu",
      packname: "",
      appname: metaMap.get('APPLICATION_NAME'),
      sessionid: cookies.get("uniquevhgsession"),
    };

    axios
      .post(url, requestBody, { headers })
      .then((response) => response.data)
      .then((data) => {
        if (data && data.code == errorConstantURL.SUCCESS_CODE) {
        } else {
        }
      });
  }




  componentWillUnmount() {
    if (sessionStorage.getItem('status') == 'false') {
      this.props.history.push(FRONTEND_NAME + "/home");
    }
  }

  showFaq() {
    this.saveLogs('FAQ');
    sessionStorage.setItem('status', 'forward');
    this.props.history.push(FRONTEND_NAME + "/faq");
  }

  showHelp() {
    sessionStorage.setItem('status', 'forward');
    this.props.history.push(FRONTEND_NAME + "/help");
  }

  showSupport() {
    sessionStorage.setItem('status', 'forward');
    this.props.history.push(FRONTEND_NAME + "/support");
  }

  showPrivacyPolicy() {
    this.saveLogs('PRIVACY_POLICY');
    sessionStorage.setItem('status', 'forward');
    this.props.history.push(FRONTEND_NAME + "/privacypolicy");
  }

  showTnc() {
    this.saveLogs('TNC');
    sessionStorage.setItem('status', 'forward');
    this.props.history.push(FRONTEND_NAME + "/tnc");
  }

  showRedemptionCenter() {
    this.saveLogs('REDEMPTION_CENTER');
    sessionStorage.setItem('status', 'forward');
    this.props.history.push(FRONTEND_NAME + "/redemption");

  }

  showWinnings() {
    this.saveLogs('MY_WINNINGS');
    sessionStorage.setItem('status', 'forward');
    this.props.history.push(FRONTEND_NAME + "/mywinnings");
  }

  showChangePassword() {
    sessionStorage.setItem('status', 'forward');
    sessionStorage.setItem('changePassword', 'true');
    this.props.history.push(FRONTEND_NAME + "/resetmsisdn")

  }

  back() {
    sessionStorage.setItem('status', 'forward');
    this.props.history.push(FRONTEND_NAME + "/home");

  }


  statShow() {
    console.log('CC')
    this.setState(prevState => ({
      statFlag: !prevState.statFlag
    }));
  }

  changeLang = (lang) => {
    let lablesData = JSON.parse(sessionStorage["lablesData"]);
    let item = lablesData.dialectSupported.filter((item) => item.dialect.languagekey == lang)[0];

    //let item = 
    sessionStorage.setItem("selectedDialect", JSON.stringify(item));
    sessionStorage["lang"] = item.dialect.languagekey;
    sessionStorage["appid"] = item.appId;
    this.setState({ activeLang: item.dialect.languagekey });
    this.setState({ lables: constantURL.getLables() });

  };



  render() {
    const { lables } = this.state;

    return <div className="menu-container">
      {this.state.sharePopup ?
        <SocialMedia
          closePopup={this.closeSharePopup.bind(this)}
        />
        : null
      }

      {this.state.enableLoader ?
        <Loading />
        : null
      }

      {this.state.showPopup ?

        <Popup
          status='A'
          text={lables.areYouSureToLogout}
          alertType={lables.logout}
          buttonName={lables.yes}
          closePopup={this.clearSession.bind(this)}
          closePopupNotNow={this.togglePopup.bind(this)}
          cancelText={lables.notNow}
          isConfirm='Y'
        /> : null}

      <div className="menu-heading">
        <div className="menu-userProfile">
          <img onClick={this.back.bind(this)} src={backIcon} />
          <img className="profile-pic" src={sessionStorage.getItem("profileImg") ? sessionStorage.getItem("profileImg") || logo : logo} ref={img => this.img = img} onError={() => this.img.src = logo} />
          <div className="profile-details">
            <p>{sessionStorage.getItem("nameSalutation")=="null"? "" :sessionStorage.getItem("nameSalutation")}</p>
            {(sessionStorage.getItem("loginType") == "social") ?
              null :
              <p>{sessionStorage.getItem("mobileNumber")}</p>
            }
          </div>
        </div>
        <div onClick={this.editProfile.bind(this)} className="menu-settings">
          <img src={settingsIcon} />
          <span>Profile & settings</span>
        </div>
      </div>
      <div className="menu-item" onClick={this.showWinnings.bind(this)}>
        <img src={winningsIcon} />
        <span>My Winnings</span>
        <img src={openIcon} />
      </div>
      <div className="menu-item" onClick={this.showRedemptionCenter.bind(this)}>
        <img src={redemptionIcon} />
        <span>Redemption Center</span>
        <img src={openIcon} />
      </div>
      <div className="menu-item" onClick={this.showFaq.bind(this)}>
        <img src={faqIcon} />
        <span>FAQ</span>
        <img src={openIcon} />
      </div>
      <div className="menu-item" onClick={this.showShare.bind(this)}>
        <img src={shareIcon} />
        <span>Share</span>
        <img src={openIcon} />
      </div>
      {/* <div className="menu-item">
        <img src={referIcon} />
        <span>Refer & Earn</span>
        <img src={openIcon} />
      </div> */}
      <div className="menu-item" onClick={this.showSupport.bind(this)}>
        <img src={supportIcon} />
        <span>Support</span>
        <img src={openIcon} />
      </div>
      <div className="menu-item" onClick={this.logout.bind(this)}>
        <img src={logoutIcon} />
        <span>Logout</span>
        <img src={openIcon} />
      </div>
    </div>;
  }
}

export default withRouter(MenuPhase2)