
import React from 'react'
import axios from 'axios';
import '../styles/home.css';
import Footer from '../Footer/footer';
import Menu from '../Menu/menu';
import profileImg from '../images/profileImage.png';
import games from '../images/games.svg';
import bell from '../images/bell.png';
import back from '../images/backNew.svg';
import yellowStar from '../images/yellowStar.png';
import yellowBatch from '../images/yellowBatch.png';

import yellowHeart from '../images/yellowHeart.png';
import viewAll from '../images/viewAll.png';
import yellowDice from '../images/yellowDice.png';

import popular from '../images/popular.png';
import keepPlaying from '../images/keepPlaying.png';
import yellowView from '../images/yellowView.png';
import instsnt from '../images/instsnt.png';
import winBig from '../images/winBig.png';
import trending from '../images/trending.png';

import circle from '../images/circle.png';
import gods from '../images/gods.png';
import bannerBottom from '../images/bannerBottom.png';
import * as Constant from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Slider from 'infinite-react-carousel';
import ProfileCollapsed from './profileCollapsed';
import { withRouter } from 'react-router';
import PopupNew from './popupNew';
import Popup from './popup';
import Loading from './loading'
import lottie from 'lottie-web';
import animationData from '../lottie/delivery.json';
import clownLogo from '../images/clownLogo.png';
import Cookies from 'universal-cookie';
import SpinTheWheel from './spinTheWheel';
import * as constantURL from '../Constants/constants'
import smallstar from '../images/smallstar.svg';
import {FRONTEND_NAME} from '../Constants/constants'

class BundleGames extends React.Component {


    constructor(props) {

        super(props);
        this.state = {
            banners: [{ id: '1', title: 'title', bannerName: 'bannerName', imageUrl: 'imageUrl', redirectUrl: 'redirectUrl', gameurl: 'game', bannerType: 'ban', bannerText: 'PLAY NOW' }],
            trendingPaidGames: [],
            isShowMenu: false,
            showPopup: false,
            showPopupOld: false,
            isShowSpin: false,
            msg: '',
            msgType: 'S',
            enableLoader: true,
            isConfirm: 'N',
            loginRedirect: 'N',
            instantGamesId: '',
            bigWinGamesId: '',
            displayButtons: [],
            currentSelectedGame: {},
            gametype: 'FREE',
            isNeedToCheckSTW: false,
            isShowSpin: false,

            //chargingKeyword:'hardygame10',
            //checkSubKeyword: 'hardygame10',
            //chargingValue: '10'

        }
    }

    subsHeaders = {
        'appKey': Constant.APP_KEY_SUBS,
        'operator': Constant.OPERATOR,
        'Msisdn': sessionStorage.getItem("username"),
        'channel': Constant.CHANNEL,
        'Access_token': sessionStorage.getItem("accessToken"),
        'deviceId': Constant.DEVICE_ID,
        'Content-Type': Constant.APPLICATION_JSON,
        'Accept': Constant.APPLICATION_JSON
    }

    showSpinner() {
        const headers = {
            'Content-Type': 'application/json',
            'mode': 'WAP',
        };

        var msisdn = sessionStorage.getItem("username");
        if (msisdn.length > 10) {
            msisdn = msisdn.substr(2, msisdn.length + 1);
        }

        const body = {
            'msisdn': msisdn,
            'campaignId': constantURL.CAMPAIGN_ID,
            'vendorUniqueKey': constantURL.VENDOR_KEY
        }

        axios.post(constantURL.SPINTHEWHEEL_COMBINED_IP + "/v1/spinnerStatus", body, { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    console.log(data.data.status)
                    if (data.data.status) {
                        this.setState({
                            isShowSpin: true
                        })
                    } else {
                        this.setState({
                            isShowSpin: false
                        })
                    }
                } else {
                    this.setState({
                        isShowSpin: false
                    });
                }
            });
    }




    componentDidMount() {
        console.log(' User Entered time --> ' + sessionStorage.getItem('entertime'))
        if (sessionStorage.getItem('entertime')) {

            var enteredTime = sessionStorage.getItem('entertime');
            var d = new Date();
            var currentTime = d.getTime();

            if ((currentTime - enteredTime) / 1000 > constantURL.SPINTHEWHEEL_TIME) {
                this.setState({ isNeedToCheckSTW: true });
                sessionStorage.removeItem('entertime');
           
            } else {
                this.setState({ isNeedToCheckSTW: false });
            }
        } else {
            var d = new Date();
            var n = d.getTime();
            sessionStorage.setItem('entertime', n);
            this.setState({ isNeedToCheckSTW: false });
        }

        this.showSpinner();

        console.log('this.props.location.state.detail')

        console.log(this.props.location.state.detail)

        //call the loadAnimation to start the animation
        const headers = {
            'Content-Type': Constant.APPLICATION_JSON,
            'appid': Constant.APP_ID,
            'deviceId': Constant.DEVICE_ID,
            'operator': Constant.OPERATOR,
            'Access_token': sessionStorage.getItem("accessToken"),
            'channel': Constant.CHANNEL,
            'Msisdn': sessionStorage.getItem("username")
        };
        const url = Constant.CONTENT_COMBINED_IP + "/v1/fetchViewAllByCategoryNameAndDynamicId?categoryName=bundleGames&dynamicId=" + this.props.location.state.detail.id;
        console.log('url to call : ', url)

        axios.get(url, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data.data)
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {

                    console.log(data.data)

                    var pG = data.data.categories[0].contentList;
                    var popList = [];

                    if (pG) {
                        for (var i = 0; i < pG.length; i++) {
                            popList.push({
                                id: 'c' + i,
                                title: pG[i].title,
                                noOfUsersDynamic: pG[i].noOfUsersDynamic,
                                previewImageUrl: pG[i].previewImageUrl,
                                gameurl: pG[i].secondarygameurl,
                                price: pG[i].chargingcurrency + ' ' + pG[i].chargingvalue,
                                coinsRequired: pG[i].coinsRequired,
                                gametype: pG[i].gametype,
                                chargingKeyword: pG[i].chargingKeyword,
                                checkSubKeyword: pG[i].checkSubKeyword,
                                chargingvalue: pG[i].chargingvalue,
                                chargingcurrency: pG[i].chargingcurrency,
                                replacedynamicparam: pG[i].replacedynamicparam,
                                showPlayButton: pG[i].showPlayButton,
                                contentId: pG[i].contentId,
                                contentName: pG[i].contentName,
                                validity: pG[i].validity,

                            });
                        }
                        this.setState({ trendingPaidGames: popList });
                    }
                    this.setState({ enableLoader: false })
                    this.saveLogs(Constant.BUNDLE_ACTION)

                } else {
                    this.saveLogs(Constant.BUNDLE_ACTION)

                    this.setState({ enableLoader: false })
                    this.togglePopup(Constant.ERROR_MESSAGE, 'E', Constant.ERROR_ALERT, ['1'])
                }
            }).catch(error => {
                console.log('There is Error in fetching View All Content for Instant games : ', error)
                // this.setState({
                //     redirectScreen: Constant.PAID_GAMES
                // })
                this.togglePopup(Constant.ERROR_MESSAGE, 'E', Constant.ERROR_ALERT, ['1'])
            })
    }

    saveLogs(category) {
        const cookies = new Cookies();
        var url = Constant.APPLOGGER_COMBINED_IP + "/v1/saveLogs";

        const headers = {
            'Content-Type': 'application/json',
            'appid': Constant.APP_ID,
            'operator': Constant.OPERATOR,
            'msisdn': sessionStorage.getItem("username"),
            'channel': 'WAP'
        };

        const requestBody = {
            "category": category,
            "subCategory": "",
            "content": category,
            "url": "/bundlegames",
            "packname": "",
            "appname": Constant.APPLICATION_NAME,
            "sessionid": cookies.get('uniquevhgsession')
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == errorConstantURL.SUCCESS_CODE) {
                } else {
                }
            });
    }


    openNotificcation() {
        this.props.history.push({
            pathname: FRONTEND_NAME+'/notification',
            state: {
                gameScreen: 'bundlegames',
            }
        })
    }


    performTaskOnClose = (flag) => {
        this.togglePopup()
        console.log("inside performTaskOnClose method, flag is ", flag)
        if (flag === 'confirm') {
            this.submitPaymentSubscription();
        } if (flag === 'ok-' + Constant.LOGIN_REQUIRED_MESSAGE) {
            this.props.history.push(FRONTEND_NAME+"/login")
        } if (flag === 'ok-' + Constant.PAYMENT_SUCCESSFUL_MESSAGE) {
            //window.open(this.state.currentSelectedGame.gameurl, "_blank")
            this.openRequiredGame(this.state.currentSelectedGame)
        } if (flag === 'ok-' + Constant.ERROR_MESSAGE) {
            this.props.history.push(FRONTEND_NAME+"/home")
        }
    }

    submitPaymentSubscription = () => {
        var url = Constant.SPS_COMBINED_IP + "/v2/deductCoinsOrSub";
        const headers = {
            'appkey': Constant.APP_KEY_SUBS,
            'Content-Type': Constant.APPLICATION_JSON,
            'appid': Constant.APP_ID,
            'deviceId': Constant.DEVICE_ID,
            'operator': Constant.OPERATOR,
            'access_token': sessionStorage.getItem("accessToken"),
            'channel': Constant.CHANNEL,
            'countryCode': Constant.COUNTRY_CODE,
            'msisdn': sessionStorage.getItem("username")
        };


        const body = {
            'msisdn': sessionStorage.getItem("username"),
            'countryCode': Constant.COUNTRY_CODE,
            "packName": this.state.currentSelectedGame.checkSubKeyword,
            "rewardName":"coin",
            "rewardFrom":"ABC",
            "operation":"debit",
            "rewardCount":1,
            "blockRewardId":"",
            "info":this.props.location.state.detail.title,
            "operationSource":"wap",
            "appGameName":this.props.location.state.detail.title,
            "contentName": this.props.location.state.detail.contentName
       
        }

        axios.post(url, body, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data);
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    this.togglePopup(Constant.PAYMENT_SUCCESSFUL_MESSAGE, "S", Constant.PAYMENT_SUCCESSFUL_ALERT, ['1']);
                } else if(data && (data.code == errorConstantURL.LOW_BALANCE_CODE)){
                    this.togglePopup(Constant.LOW_BALANCE_PAYMENT_UNSUCCESSFUL_MESSAGE, "E", Constant.PAYMENT_UNSUCCESSFUL_ALERT, ['1'])
                } else {
                    this.togglePopup(Constant.PAYMENT_UNSUCCESSFUL_MESSAGE, "E", Constant.PAYMENT_UNSUCCESSFUL_ALERT, ['1'])
                }
            });



        // const url = `${Constant.SPS_COMBINED_IP}${Constant.SUBS_PACK_API_PARAM}${this.state.currentSelectedGame.chargingKeyword}`
        // console.log('Url to hit for chargingKeyword payment : ', url)

        // const headers = this.subsHeaders;

        // axios.get(url, { headers })
        //     .then(response => response.data)
        //     .then(data => {
        //         if (data && data.code === errorConstantURL.SUCCESS_CODE) {
        //             console.log(data.data)
        //             if (data.data.subscriptionStatusCode === 0 || data.data.subscribtionStatus === Constant.ACTIVE) {
        //                 console.log("User has successfully made payment for selected Game, showing con gratulation popup "
        //                     + " and then redirecting to game directly")

        //                 this.togglePopup(Constant.PAYMENT_SUCCESSFUL_MESSAGE, "S", Constant.PAYMENT_SUCCESSFUL_ALERT, ['1']);

        //             }
        //             else {
        //                 console.log("User status is pending, showing pending popup for other response code")
        //                 this.togglePopup(Constant.PAYMENT_UNSUCCESSFUL_MESSAGE, "E", Constant.PAYMENT_UNSUCCESSFUL_ALERT, ['1'])
        //             }

        //         } else if (data && data.code === errorConstantURL.NO_DATA_FOUND) {
        //             if (data.data.requestStatus == '1') {
        //                 this.openRequiredGame(this.state.currentSelectedGame);
        //             } else {
        //                 console.log('Some failure response : ', data);
        //                 this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
        //             }
        //         }
        //         else {
        //             console.log('Some failure response : ', data);
        //             this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
        //         }
        //     })
        //     .catch(error => {
        //         console.log("Error Occured : ", error)
        //         this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
        //     })
    }


    checkSubscriptionAndMakePayment = (gameToPlay) => {
        this.checkUserSubscription(gameToPlay, this.subsHeaders)
    }

    checkUserSubscription = (gameToPlay, headers1) => {

        var url = Constant.SPS_COMBINED_IP + "/v2/checkCoinsAndSub";
        const headers = {
            'appkey': Constant.APP_KEY_SUBS,
            'Content-Type': Constant.APPLICATION_JSON,
            'appid': Constant.APP_ID,
            'deviceId': Constant.DEVICE_ID,
            'operator': Constant.OPERATOR,
            'access_token': sessionStorage.getItem("accessToken"),
            'channel': Constant.CHANNEL,
            'countryCode': Constant.COUNTRY_CODE,
            'msisdn': sessionStorage.getItem("username")
        };


        const body = {
            'msisdn': sessionStorage.getItem("username"),
            'countryCode': Constant.COUNTRY_CODE,
            "packName": gameToPlay.checkSubKeyword,
            "rewardName":"coin",
            "rewardFrom":"ABC",
            "operation":"debit",
            "rewardCount":1,
            "blockRewardId":"",
            "info":gameToPlay.title,
            "operationSource":"wap",
            "appGameName":gameToPlay.title,
            "contentName": this.props.location.state.detail.contentName

       
        }

        axios.post(url, body, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data);
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    this.openRequiredGame(gameToPlay);
                } else if (data && (data.code == errorConstantURL.REQUIRED_COINS_AVAILABLE)) {
                    this.togglePopup(Constant.CONFIRM_COINS_DEDUCTION_MESSAGE.replace("<xx>", gameToPlay.coinsRequired), "A", Constant.CONFIRM_PAYMENT_ALERT, ['3', '4'])
                }
                else if (data && (data.code == errorConstantURL.INSUFFICIENT_COINS)) {
                    this.togglePopup(Constant.CONFIRM_MONEY_DEDUCTION_MESSAGE.replace("<xx>", "Rs." + gameToPlay.chargingvalue).replace("<yy>", gameToPlay.validity), "A", Constant.CONFIRM_PAYMENT_ALERT, ['3', '4'])
                } else {
                    this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
                }
            });


        /*
        console.log("current game is : ", this.state.currentSelectedGame)
        const url =`${Constant.SPS_COMBINED_IP}${Constant.CHECK_SUBS_API_PARAM}${gameToPlay.checkSubKeyword}` 
        console.log('Url to hit for checksubs : ', url)

        axios.get(url, {headers})
            .then(response => response.data)
            .then(data => {
                if(data && data.code === errorConstantURL.SUCCESS_CODE ){
                    console.log(data.data)
                    if(data.data.subscriberStausCode == "0" || data.data.subscriberStatus === Constant.ACTIVE){
                        console.log("User is Subscribed for selected Game, No need for confirmartion, redirecting to game directly")
                       // window.open(gameToPlay.gameurl, "_blank")
                       this.openRequiredGame(gameToPlay);
                    }else if(data.data.subscriberStausCode == "1" || data.data.subscriberStatus === Constant.NOT_SUBSCRIBED){
                        this.saveGameLogs(gameToPlay)
                        console.log("User is NOT Subscribed  for selected Game, taking user confirmartion using popup ")
                        console.log('If Confirmation is Yes, then call charginKeywordSub API for payment and then redirect to game')
                        this.togglePopup(Constant.CONFIRM_PAYMENT_MESSAGE.replace("<xx>", gameToPlay.chargingvalue), "A", Constant.CONFIRM_PAYMENT_ALERT, ['3','4'])
                    }else{
                        this.saveGameLogs(gameToPlay)
                        console.log("User status is pending, showing pending popup for other response code")
                        this.togglePopup(Constant.PENDING_SUBSCRIPTION_MESSAGE, "A", Constant.PENDING_SUBSCRIPTION_ALERT, ['1'])
                    }
                }else {
                    this.saveGameLogs(gameToPlay)
                        console.log('Some failure response : ', data);
                        this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
                    }
            })
            .catch(error => {
                console.log("Error Occured : ", error)
                this.saveGameLogs(gameToPlay)
                    
                this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
            })
            */

    }

    redirectToRequiredGame = (gameToPlay) => {
        //Current game is set ti state variable because we need it when payment popup gives confirmation.
        //At that time it cannot be passed through a method
        this.setState({
            currentSelectedGame: gameToPlay
        })
        console.log("inside redirectToRequiredGame : ", gameToPlay.gameurl)
        const isLogin = sessionStorage.getItem("isLogin");
        if (isLogin) {
            console.log('User is logged In, checking game type to make decision. Game type is ', gameToPlay.gametype)

            if (gameToPlay.showPlayButton) {
                this.openRequiredGame(gameToPlay);
            } else {
                if (gameToPlay.gametype.toUpperCase() === Constant.GAME_TYPE_FREE) {
                    this.openRequiredGame(gameToPlay);
                }
                else {
                    // this.togglePopup(Constant.CONFIRM_PAYMENT_MESSAGE.replace("<xx>", gameToPlay.price), "A", Constant.CONFIRM_PAYMENT_ALERT, ['3', '4'])
                    this.checkSubscriptionAndMakePayment(gameToPlay);;
                }
            }
        }
        else {
            console.log('User is NOT logged In, showing popup to login')
            this.setState({
                redirectScreen: Constant.LOGIN_SCREEN_TITLE
            })
            this.togglePopup(Constant.LOGIN_REQUIRED_MESSAGE, 'A', Constant.LOGIN_REQUIRED_ALERT, ["1", "2"])
            //If YES, want to login then redirect to login page and after successful login, redirect to respective Game Page
        }
    }

    onDisplayMenu(obj) {
        this.setState({ isShowMenu: !this.state.isShowMenu });
    }


    openProfile() {
        if (sessionStorage.getItem("isLogin")) {
            this.props.history.push(FRONTEND_NAME+"/profile");
        }
        else {
            this.props.history.push(FRONTEND_NAME+"/login");
        }

    }


    togglePopup = (message, msgType, alert, buttonArray) => {
        this.setState({
            showPopup: !this.state.showPopup,
            msg: message,
            msgType: msgType,
            alert: alert,
            displayButtons: buttonArray
        })

    }

    togglePop() {
        this.setState({
            showPopup: !this.state.showPopup,
        });
    }
    togglePopOld() {
        this.setState({
            showPopupOld: !this.state.showPopupOld
        });
    }

    togglePopupOld() {
        this.setState({
            showPopupOld: !this.state.showPopupOld
        });
        if (this.state.loginRedirect == 'Y') {
            this.props.history.push(FRONTEND_NAME+"/logout")
        }
        if (this.state.loginRedirect == 'C') {
            this.deduct()
        }
    }

    openRequiredGame = (gameToPlay) => {
        console.log("inside openRequiredGame method ")
        this.props.history.push({
            pathname: FRONTEND_NAME+'/gameview',
            state: {
                game: gameToPlay,
                gameScreen: 'bundlegames',
                detail:this.props.location.state.detail
            }
        })
    }

    back() {
        this.props.history.push({
            pathname: FRONTEND_NAME+'/home'
        });
    }

    saveGameLogs(detail) {
        const cookies = new Cookies();
        var url = Constant.APPLOGGER_COMBINED_IP + "/v1/saveDownloadLogs";

        const headers = {
            'Content-Type': 'application/json',
            'appid': Constant.APP_ID,
            'operator': Constant.OPERATOR,
            'msisdn': sessionStorage.getItem("username"),
            'channel': 'WAP'
        };

        const requestBody = {
            "content_name": detail.title,
            "session_id": cookies.get('uniquevhgsession'),
            "country": "India",
            "circle": "All",
            "packname": detail.chargingKeyword,
            "appname": Constant.APPLICATION_NAME,
            "content_type": 'game',
            "param1": detail.gametype,
            "param2": "",
            "substatus": '',
            "winningStatus": '',
            "gameStatus": "FAILURE",
            "response": "Success",
            "dwn_flag": "0"
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == errorConstantURL.SUCCESS_CODE) {
                } else {
                }
            });
    }


    render() {
        const { showPopup, showPopupOld, msg, msgType, alert, displayButtons, trendingPaidGames } = this.state;

        // var displayGames = [];
        // trendingPaidGames.forEach(function (list) {
        //     displayGames.push(
        //         // onClick={()=> window.open(list.redirectUrl, "_blank")}
        //         <div key={list.id} className="Coin-games">
        //             <div className="gamess" onClick={() => this.redirectToRequiredGame(list)} >
        //                 <img src={list.previewImageUrl} ></img>
        //                 <div className="for-line">
        //                     <div className="coin-p">
        //                         <p className="coin-g-a">{list.title}</p>
        //                         <p className="coin-g-b">{list.noOfUsersDynamic} played</p>
        //                     </div>
        //                     <div className="coin-amt btn-wid">
        //                     <p>{list.showPlayButton ? 'PLAY' :  (list.gametype.toUpperCase()=='FREE' ? list.chargingcurrency.toUpperCase():  'PLAY')}</p>
        //                      </div>
        //                 </div>
        //             </div>

        //         </div>
        //     );

        // }.bind(this));



        var displayGames = [];
        trendingPaidGames.forEach(function (list) {
            displayGames.push(
                // onClick={()=> window.open(list.redirectUrl, "_blank")}
                <div key={list.id} className="Coin-games">
                    <div className="gamess" onClick={() => this.redirectToRequiredGame(list)} >
                        <img src={list.previewImageUrl} ></img>
                        <div className="for-line">
                            <div className="coin-ppp">
                                <p className="coin-g-a">{list.title}</p>
                                <p className="coin-g-b">{list.noOfUsersDynamic} played</p>
                            </div>
                            <div className="coins-div">
                                <div className="coin-amt-n ">
                                    <p>{list.showPlayButton ? 'PLAY' : 'PAY'}</p>
                                </div>
                                {!list.showPlayButton ?
                                    <div className="stardiv">
                                        <span className="bluep">{list.chargingcurrency + "" + list.chargingvalue} or</span>
                                        <span><img className="smallStar" src={smallstar} /></span>
                                        <span className="bluep">{list.coinsRequired}</span>
                                    </div> : null}
                            </div>

                        </div>
                    </div>

                </div>
            );

        }.bind(this));

        return (
            <div className="container-home"  >
                {this.state.enableLoader ?
                    <Loading />
                    : null
                }

                {showPopup ?
                    <PopupNew
                        status={msgType}
                        text={msg}
                        alertType={alert}
                        closePopup={this.togglePopup}
                        closePopupWithTask={this.performTaskOnClose}
                        displayButtons={displayButtons}
                    /> : null}

                {showPopupOld ?
                    <Popup
                        status={msgType}
                        text={msg}
                        alertType="Login Required!"
                        buttonName={this.state.loginRedirect == 'C' ? 'CONFIRM' : 'OK'}
                        closePopup={this.togglePopupOld.bind(this)}
                        closePopupNotNow={this.togglePopOld.bind(this)}
                        isConfirm={this.state.isConfirm}
                    /> : null}
                <div className="header-leaderboard line-it">
                    <img src={back} onClick={this.back.bind(this)} className="float-lft"></img>
                    <span className="header-text float-lft">{this.props.location.state.detail.bannerName}</span>
                </div>

                {/* {this.state.isNeedToCheckSTW ?
                    <div id="revoke-modal" className={this.state.isShowSpin ? 'revokeModalShow' : 'revokeModalHide'} >
                        {(this.state.isShowSpin ? < SpinTheWheel /> : null)} </div>
                    : null} */}


                <div className="content"  >
                    {displayGames}
                </div>
            </div>
        )
    }
}

export default withRouter(BundleGames);