import React from 'react'
import axios from 'axios';
import '../styles/home.css';
import { withRouter } from 'react-router'
import back from '../images/backNew.svg';
import games from '../images/games.svg';
import gameWin from '../images/gameWin.png';
import unchecked from '../images/unchecked.png';
import winningCoupon from '../images/winningCoupon.png';
import coinshardy from '../images/coinshardy.svg';

import Loading from './loading'
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'

class MyWinnings extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            myWinningsList: [],
            enableLoader: true,
            msg:'',
            dataList: [],
           
            // lables : constantURL.getLables()
        }
    }


    back() {
        this.props.history.push(constantURL.FRONTEND_NAME+"/profile");
    }

    componentDidMount() {
        
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
        const headers = {
            'Content-Type': 'application/json',
            'appkey': metaMap.get('APPKEY'),
            'operator': metaMap.get('OPERATOR'),
            'access_token': sessionStorage.getItem("accessToken"),
            'msisdn': sessionStorage.getItem("username"),
            'deviceId': constantURL.DEVICE_ID

        };

        this.setState({enableLoader:true});
        axios.get(constantURL.SPS_COMBINED_IP + "/v1/userRewardPassbook?rewardName=Coin&pagingLimit=100&pageNo=1&operation=CREDIT&operationSource=win", { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    let transactions = data.data.passbook;
                    var list = [];
                    if (transactions) {
                        for (var i = 0; i < transactions.length; i++) {
                            list.push({
                                amount: transactions[i].operationRewards,
                                operation: transactions[i].operation,
                                info: transactions[i].info,
                                date: transactions[i].displayTransactionTime
                            });
                        }
                        console.log(list);
                        this.setState({ dataList: list });
                        this.setState({
                            enableLoader: false
                        })
                    }
                    this.setState({
                        enableLoader: false
                    })
                } else {
                    this.setState({
                        enableLoader: false
                    })
                }


            });
        


    }


    // componentDidMount() {
    //     const {lables} = this.state;
    //     const headers = {
    //         'Content-Type': constantURL.APPLICATION_JSON,
    //         'appid': constantURL.APP_ID,
    //         'operator': constantURL.OPERATOR,
    //         'access_token': sessionStorage.getItem("accessToken"),
    //         'msisdn': sessionStorage.getItem("username"),
    //         'countryCode': constantURL.COUNTRY_CODE,
    //     };
    //     const url = constantURL.PROFILER_COMBINED_IP + "/v1/contestWinners?subCategory="+constantURL.SUB_CATEGORY;
    //     axios.get(url, { headers }).then(response => response.data)
    //         .then((data) => {
    //             if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
    //                 console.log('-KKKKK--')
    //                 console.log(data.data.winners)
    //                 var couList = [];
    //                  if(data.data.winners){
    //                 if (data.data.winners.length>0) {
    //                     var contestData = data.data.winners;
    //                     for (var i = 0; i < contestData.length; i++) {
    //                         couList.push({
    //                             id: i,
    //                             gameName: contestData[i].promoCodeDetail.contestName,
    //                             couponCode: contestData[i].promoCode,
    //                             wonOn: contestData[i].promoCodeDetail.uploadDate,
    //                             validTill:contestData[i].promoCodeDetail.validTill,
    //                             couponShortDesc: contestData[i].promoCodeDetail.shortDesc,
    //                             couponLongDesc:contestData[i].promoCodeDetail.longDesc,
    //                             declarationDate: contestData[i].declarationDate,
    //                         });
    //                     }
    //                     this.setState({ myWinningsList: couList,
    //                     msg:'' });
    //                 }else{
    //                     this.setState({
    //                         msg:'No record found.' });
    //                 }
    //             }else{
    //                 this.setState({
    //                     msg:'No record found.'});
               
    //             }

    //             }else{
    //                 this.setState({
    //                     msg:'No record found.' });
    //             }
               
    //             this.setState({enableLoader:false});
    //         });
    // }
    

    getCouponDetail(list) {
        this.props.history.push({
            pathname: constantURL.FRONTEND_NAME+'/coupondetails',
            state: {
                couponDetail: list,
              }
        });
    }


    render() {
        var coinRow = [];
        this.state.dataList.forEach(function (list) {
            if(list.operation.toUpperCase()=="CREDIT"){
            coinRow.push(
                <div key={list.id} className="Coin-games">
                    <div className="games-ww">
                        {/* <img src={gameWin} ></img> */}
                        <div className="for-line line-ss" >
                            <div className="coin-p coin-p-neww">

                                <p className="coin-g-aa">{list.info.replace('?', '₹')}</p>
                                <p className="coin-g-bb ">{list.date}</p>
                            </div>
                            <div className="winnings-right">
                                {/* <img src={coinshardy} onClick={() => this.getCouponDetail(list)} ></img> */}
                                <p>{list.amount}</p>
                                <p>Credited</p>
                            </div>
                        </div>
                    </div>
                </div>
            
            );
        }}.bind(this));

const {lables} = this.state;
        return (
            <div className="container-leaderboard ">
                {this.state.enableLoader ?
                    <Loading />
                    : null
                }
                <div style={{borderBottom : "none"}} className="header-leaderboard edit-pad">
                    <img src={back} className="float-lft" onClick={this.back.bind(this)}></img>
                    <span className="header-text float-lft">My Winnings</span>
                </div>
                <div className="content-leaderboard">
                    <div>
                    <div  className="mywinnings">
                    <div className="winnings-header">
                        <div>
                            <div className="coin-p coin-p-neww">
                                <p className="coin-g-aa">Games</p>
                            </div>
                            <div className="winnings-right">
                                {/* <img src={coinshardy} onClick={() => this.getCouponDetail(list)} ></img> */}
                                <p>Coins</p>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
            
                        { this.state.dataList.length==0?<p className="norecord">{this.state.msg}</p>:  coinRow}
                    </div>
                </div>
            </div>


        )
    }
}

export default withRouter(MyWinnings);