import React from 'react'
import axios from 'axios';
import '../styles/popup.css'
//  import  TickGreen from '../images/tick-green.png'
import infoAlert from '../images/alertyellow.svg'
import infoError from '../images/alertyellow.svg'
import infoSuccess from '../images/infoSuccess.png'
import { withRouter } from 'react-router'

class Popup extends React.Component {
    render() {
        const iconDecision = this.props.status === "A" ?
            infoAlert :
            (this.props.status === "S" ?
                infoSuccess :
                (this.props.status === "E" ?
                    infoError :
                    null)
            );

        return (
            <div id="apply-modal" className=  {this.props.orientations=="landscape"? "modal padding-modal" :"modal"}    >
                <div className="modal-content">
                    <div className="modal-heading">
                        <img src={iconDecision} alt="" id="tick" />
                        <p className="popup-p">{this.props.alertType}</p>
                    </div>
                    <div className="apply-modal-header">
                        <p>{this.props.text}</p>
                    </div>
                    <div className="modal-close">
                         {this.props.isConfirm!='N'? <button className="popup-btn p-lft" onClick={this.props.closePopupNotNow} hidden={this.props.hideButton}>{this.props.cancelText?this.props.cancelText:'CANCEL'}</button>:null}
                    <button className="popup-btn p-ryt" onClick={this.props.closePopup}>{this.props.buttonName?this.props.buttonName:'OK'}</button>
                    </div>
                    {/* <div className="modal-close">
                        <button className="popup-btn">CANCEL</button>
                        <button className="popup-btn">CONFIRM</button>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default withRouter(Popup);