import React, { useState } from "react";

import back from '../images/backNew.svg';
import group from '../images/group.svg';
import { withRouter } from "react-router";
import * as constantURL from "../Constants/constants";
import * as errorConstantURL from "../Constants/errorConstants";
import axios from 'axios';

class Winners extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          winners:[]
      }}

componentDidMount(){
    console.log('=============');
    console.log(this.props.location.state.list);

    var list1=[];

// list1.push({
//     "msisdn": "9999999992",
//     "appName": "bsnlhardygames",
//     "winnerRank": "1",
//     "creditRewards": 120.0,
//     "winnerDeclarationDate": "2021-09-01",
//     "winnerType": "MONTHLY",
//     "winnerPeriod": "8",
//     "operator": "bsnl",
//     "circle": "all"
//  })
//  list1.push({
//     "msisdn": "9999990002",
//     "appName": "bsnlhardygames",
//     "winnerRank": "2",
//     "creditRewards": 121.0,
//     "winnerDeclarationDate": "2021-09-01",
//     "winnerType": "MONTHLY",
//     "winnerPeriod": "8",
//     "operator": "bsnl",
//     "circle": "all"
// })
// this.setState({winners:list1});
            

      var url = constantURL.SPS_COMBINED_IP + "/v1/getResponse";
      const headers = {
       'appkey': constantURL.APP_KEY_SUBS,
          'Content-Type': constantURL.APPLICATION_JSON,
          'appid': sessionStorage["appid"],
          'deviceId': constantURL.DEVICE_ID,
          'operator': constantURL.OPERATOR,
          'access_token': sessionStorage.getItem("accessToken"),
          'channel': constantURL.CHANNEL,
          'countryCode': constantURL.COUNTRY_CODE,
          'msisdn': sessionStorage.getItem("username"),
        };

        const body={
          
            "apiName" : "winnerList",
      "requestBody" : {
        "appName": this.props.location.state.list.appName,
        "winnerDate": this.props.location.state.list.winnerDeclarationDate,
        "winnerType": "MONTHLY", 
        "winnerPeriod": this.props.location.state.list.winnerPeriod
            }
    
            }

      axios.post(url, body, { headers }).then(response => response.data)
          .then((data) => {
              console.log(data);
              if (data) {
              
                var winners= data.data;
                var list=[];
                for (var i = 0; i < winners.length; i++) {
                       list.push({
                        "msisdn": winners[i].msisdn,
                        "msisdn": winners[i].msisdn,
                        "appName": winners[i].appName,
                        "winnerRank": winners[i].winnerRank,
                        "creditRewards": winners[i].creditRewards,
                        "winnerDeclarationDate": winners[i].winnerDeclarationDate,
                        "winnerType": winners[i].winnerType,
                        "winnerPeriod": winners[i].winnerPeriod,
                        "operator": winners[i].operator,
                        "circle": winners[i].circle
                       })
                }

                this.setState({winners:list});
            }
          });
        }

    
back(){
    this.props.history.push({
        pathname: constantURL.FRONTEND_NAME + "/winner",
        state: {
          gameScreen: "home",
        },
      });
}

      render(){

          function maskMsisdn(val){
              var msisdn =
              val.substring(0, 2) +
               "XXXXXX" +
               val.substring(7, 10);
           
               return msisdn;
          }

        function getMonthName(val) {
            var monthName = '';
            switch (val) {
                 case '1': monthName = "January";
                      break;
                 case '2': monthName = "February";
                      break;
                 case '3': monthName = "March";
                      break;
                 case '4': monthName = "April";
                      break;
                 case '5': monthName = "May";
                      break;
                 case '6': monthName = "June";
                      break;
                 case '7': monthName = "July";
                      break;
                 case '8': monthName = "August";
                      break;
                 case '9': monthName = "September";
                      break;
                 case '10': monthName = "October";
                      break;
                 case '11': monthName = "November";
                      break;
                 case '12': monthName = "December";
                      break;
            }
            return monthName;
       }

        var winnersDisplay = [];
          this.state.winners.forEach(function (list) {
            winnersDisplay.push(
            <tr>
                <td className= {sessionStorage.getItem("username")==list.msisdn ? "active":"active1"}>#{list.winnerRank}</td>
               <td className={sessionStorage.getItem("username")==list.msisdn ? "active":"active1"}>{maskMsisdn(list.msisdn)}</td>
               <td className={sessionStorage.getItem("username")==list.msisdn ? "active":"active1"}>{list.creditRewards}</td>
            </tr>
            )});
            

    return (
       
            <div className="container-home">
                <div className="header-leaderboard line-it">
                    <span className="header-text">{getMonthName(this.props.location.state.list.winnerPeriod)} Winners</span>
                    <img onClick={() => this.back()} className="float-ryt win-close" src={group}></img>
                </div>
                <div className="content winners" >
                    <table style={{ borderCollapse: "collapse"}}>
                        <tr className="t-head">
                            <th>Rank</th>
                            <th>Username</th>
                            <th>Coins</th>
                        </tr>
                      {winnersDisplay}
                      </table>
                </div>
            </div>
    )
}
}

export default withRouter(Winners);