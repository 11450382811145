import React from 'react'
import axios from 'axios';
import { withRouter } from 'react-router'
import '../styles/style.css'
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Popup from './popup'
import Iframe from 'react-iframe'
import loading from '../images/loader.gif';
import games from '../images/games.svg';
import '../styles/loader.css'
import LoaderGif from '../images/loading.png';
import queryString from 'query-string'
import Cookies from 'universal-cookie';

import {  logEvents } from "./Analytics";

class Splash extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ipAddress:''
        }
    }

    getIpData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        console.log(res.data);
        this.setState({ ipAddress: res.data.IPv4 });
    }


    componentDidMount() {
        this.getIpData();

        logEvents(constantURL.LOGIN);
        const cookies = new Cookies();
            //Check if msisdn is in URL header. if yes then login by pass else guest login
            // if (constantURL.ENVIRONMENT != 'PROD') {
                const values = queryString.parse(this.props.location.search)
                var msisdn = values.msisdn;
                console.log('MSISDN-> '+msisdn);

                if (typeof msisdn === "undefined") {
                    this.getMetaInfoDetails(false,"");
                }else{
                    this.getMetaInfoDetails(true,msisdn);
                }    
            
                // if (typeof msisdn === "undefined") {
                //     this.loginByPass('empty');
                // }else{
                //     this.loginByPass(msisdn);
                }


     getMetaInfoDetails(flag, msisdn) {
         
        const headers  = {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'ip': this.state.ipAddress,
            'msisdn': msisdn,
           };    
    
        const url = constantURL.PROFILER2_COMBINED_IP + "/v3/getMetaInfo?isWifi="+flag+"&gameType=HardyGames&countryCode=91&isSocialLogin=false";
      
        axios.get(url, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data);
                let map = new Map();
                if (data && data.code == 2000) {
                    var metaInfoData = data.data.metaKeyValue;
                    console.log((data.data.metaKeyValue));

                    for (var i = 0; i < metaInfoData.length; i++) {
                        map.set(metaInfoData[i].keyName, metaInfoData[i].valueName);
                    }

                    var jsonText = JSON.stringify(Array.from(map.entries()));
                    sessionStorage.setItem('metaInfo', jsonText);

                    const values = queryString.parse(this.props.location.search)
                    var msisdn = values.msisdn;
                    console.log('MSISDN-> '+msisdn);
                    if (typeof msisdn === "undefined") {
                        this.loginByPass('empty');
                    }else{
                        this.loginByPass(msisdn);
                    }    
                }else{
                    window.location.replace(constantURL.APP_LOGIN_URL);    
                }
            })
            .catch(err => {
                window.location.replace(constantURL.APP_LOGIN_URL);    
            })

    }


    loginByPass(msisdn) {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
    
        console.log('MSISDN 11-> '+msisdn);
        const cookies = new Cookies();
        sessionStorage.setItem("loginType","normal");
      
        var url = constantURL.PROFILER_COMBINED_IP + "/v1/loginbypass";

        var headers = null;
        if (msisdn == 'empty') {
            headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'appid': metaMap.get('APP_ID'),
            };
        } else {
            headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'msisdn': msisdn,
                'appid': metaMap.get('APP_ID'),
            };
        }

        axios.get(url, {
            headers: headers
        })
            .then(response => response.data)
            .then((data) => {
                if (data && data.code == 2000) {
                    console.log('A')
                    var userDetail = data.data;
                    this.createLoginSession(userDetail);
                } else {
                    console.log('B')
                    if (cookies.get('hardyIsLogin')) {
                      window.location.replace(constantURL.BIG_WIN_HOME_URL+cookies.get('hardyMobileNumber'));
                    } else {
                        // this.props.history.push(FRONTEND_NAME+"/login")
                       window.location.replace(constantURL.APP_LOGIN_URL);
                    //  window.location.replace("//localhost:3000/hardygames/login");
                        
                    }
                }
            });
    }
    createLoginSession(data) {
        sessionStorage.setItem("isLogin",'false');
        console.log('MSISDN 22-> '+data.username);
        window.location.replace(constantURL.BIG_WIN_HOME_URL+data.username);
    }

    render() {
        return (
            <div className="container-splash">
                <div className="splash-banner">
               {/* <h2>Big Win</h2> */}
               <br></br>
                <p style={{color:'white'}}>Redirecting to the portal...</p>
                </div>

                <div className="load-b">
                    <img src={LoaderGif} className="loader_gif" width="32px" height="32px" />
                    <p className="loader_txt">Loading</p>

                    {/* <div className="load-img"><img className="loader-img" src={loading}></img></div>
                    <p>Loading</p>
                 */}
                </div>
            </div>
        )
    }
}

export default withRouter(Splash);