
import React from 'react'
import axios from 'axios';
import '../styles/fantasy.css';
import Footer from '../Footer/footer';
import Menu from '../Menu/menu';
import profileImg from '../images/profileImg.png';
import games from '../images/games.svg';
import bell from '../images/bell.png';
import yellowStar from '../images/yellowStar.png';
import yellowBatch from '../images/yellowBatch.png';
import yellowHeart from '../images/yellowHeart.png';
import viewAll from '../images/viewAll.png';
import yellowDice from '../images/yellowDice.png';

import popular from '../images/popular.png';
import keepPlaying from '../images/keepPlaying.png';
import yellowView from '../images/yellowView.png';
import back from '../images/back.png';
import circle from '../images/circle.png';
import gods from '../images/gods.png';
import bannerBottom from '../images/bannerBottom.png';
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Iframe from 'react-iframe'
import { withRouter } from 'react-router'
import ProfileCollapsed from './profileCollapsed';
import {FRONTEND_NAME} from '../Constants/constants'

class Fantasy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowMenu:false
        }
    }


    onDisplayMenu(obj) {
        this.setState({ isShowMenu: !this.state.isShowMenu });
   }
    back(){
        this.props.history.push(FRONTEND_NAME+"/home");      
    }

    render() {
        const url= constantURL.FANTASY_URL+"?msisdn="+sessionStorage.getItem("username");
        return (
            <div className="container-leaderboard"> 
                
            <div className="header-leaderboard">
                <img src={back} onClick={this.back.bind(this)} className="float-lft"></img>	
            <span className="header-text float-lft">FANTASY</span>		
             </div> 
             <div className="content-leaderboard for-popular">
             <Iframe url={url}
                width="100%"
                height="584px"
                id="fantasyLeague"
                className="fantasyLeague"
                display="initial"
                position="relative"/>
                        </div>
  
       </div>
       
    //    <div className ="container-fantasy"  >
    //          <div className="f-header">
    //                        <img src={back} onClick={this.back.bind(this)} className="f-back"></img>
    //                    </div>
                      
    //         <div className="content"  >

    //             <Iframe url={url}
    //             width="100%"
    //             height="613px"
    //             id="fantasyLeague"
    //             className="fantasyLeague"
    //             display="initial"
    //             position="relative"/>
    //         </div>

    //      </div>  
         )
    }
}

export default withRouter(Fantasy);