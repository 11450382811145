// Import the functions you need from the SDKs you need
import firebase from 'firebase';
import CircularJSON from 'circular-json'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

var socialAppName = "";

export const initializeFirebase = () => {
  console.log("Inside initializeFirebase")
  // const firebaseConfig = {
  //   apiKey: "AIzaSyB0U75z-yqRKxuTpQDLTcnA2FRbVxgFRUk",
  //   authDomain: "social-login-hardy-games.firebaseapp.com",
  //   projectId: "social-login-hardy-games",
  //   storageBucket: "social-login-hardy-games.appspot.com",
  //   messagingSenderId: "1029480032176",
  //   appId: "1:1029480032176:web:b768b5e1c4de0b5b26d875"
  // };


  var firebaseConfig = {
    apiKey: "AIzaSyCtulfUNkiGv-urBzGIfceZIjMIurveS88",
    authDomain: "hardy-games-4eae3.firebaseapp.com",
    projectId: "hardy-games-4eae3",
    storageBucket: "hardy-games-4eae3.appspot.com",
    messagingSenderId: "138033806223",
    appId: "1:138033806223:web:458523cca9e098fcb169fa",
    measurementId: "G-JHCWSH7SH4"
  };

  
  console.log('SOCIAL LOGIN FIREBASE START====>>> ')

  socialAppName = firebase.initializeApp(firebaseConfig, "terniary");

}

export { socialAppName };
