import React, { Component, createRef } from 'react'
import { withRouter } from 'react-router'
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import axios from 'axios';
import Loading from './loading'
import $ from 'jquery';
import checked from '../images/checked.png';
import Cookies from 'universal-cookie';
import error from '../images/redError.png';

class EditGenderModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            gender: sessionStorage.getItem('gender') == 'null' ? 'Male' : sessionStorage.getItem('gender'),
            lables: constantURL.getLables()

        }
        // this.onGenderChange = this.onGenderChange.bind(this); 
    }


    onGenderChange(gender) {
        this.setState({
            gender: gender
        });
    }

    setGender = () => {
        this.props.setGender(this.state.gender)
        sessionStorage.setItem('gender', this.state.gender);
    }

    render() {
        const { lables } = this.state;
        return (
            <div className="modal-e">
                <div className="edit-info">
                    <div className="cancel" style={{ borderBottom: '1px solid rgba(255,255,255,0.15)', padding: '22px 16px 22px 16px' }}>
                        <p className="cancel-pe" onClick={this.props.closePopup}>{lables.cancel}</p>
                        <p className="done-e" onClick={() => this.setGender()} >{lables.done}</p>
                    </div>

                    <div className="enter-info ei-new" style={{ padding: '0px' }}>
                        <div className="what-e">
                            <div className="option-item" onClick={() => this.onGenderChange('Male')}>
                                <span className={this.state.gender == 'Male' ? "o-left selected-radio" : "o-left "}  >{lables.male}</span>
                                <span className="o-right">
                                    <label class="radio-box">
                                        <input type="radio" checked={this.state.gender == 'Male'} onChange={() => this.onGenderChange('Male')} name="gender" value="male" class="default" />
                                        <span class="radio-checkmark"></span>
                                    </label>
                                </span>
                            </div>
                            <div className="option-item" onClick={() => this.onGenderChange('Female')}>
                                <span className={this.state.gender == 'Female' ? "o-left selected-radio" : "o-left "}>{lables.female}</span>
                                <span className="o-right">
                                    <label class="radio-box">
                                        <input type="radio" checked={this.state.gender == 'Female'} name="gender" onChange={() => this.onGenderChange('Female')} value="female" class="default" />
                                        <span class="radio-checkmark"></span>
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(EditGenderModal);