import React from 'react'
import axios from 'axios';
import '../styles/style.css';
import { withRouter, Redirect } from 'react-router'
import * as Constant from '../Constants/constants'
import * as Errorcode from '../Constants/errorConstants'
import Popup from './popup';
import Loading from './loading'
import back from '../images/back.png';
import stop from '../images/stop.svg';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import * as constantURL from '../Constants/constants'
import { FRONTEND_NAME } from '../Constants/constants'
import firebase from 'firebase';
import CircularJSON from 'circular-json'
import { socialAppName} from './SocialLogin'

class EnterMisisdn extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            username: this.props.location.state === undefined ? '' : this.props.location.state.userName,
            showPopup: false,
            message: '',
            msgType: '',
            alert: '',
            hideButton: true,
            error: false,
            enableLoader: false,
            showCross: false,
            lables: Constant.getLables(),
            socialLoginList: []

        }
        this.msisdnRegex = /^[6-9]{1}[0-9]{9}$/
        this.userRegex = /^\d+$/
    }

    componentDidMount() {
        sessionStorage.setItem("isLogin", 'false');
        // const cookies = new Cookies();      
        // if(cookies.get('hardyIsLogin')){
        //     sessionStorage.setItem("isLogin", true);
        //     sessionStorage.setItem("accessToken", cookies.get('hardyAccessToken'))
        //     sessionStorage.setItem("mobileNumber", cookies.get('hardyMobileNumber'))
        //     sessionStorage.setItem("operator", cookies.get('hardyOperator'))
        //     sessionStorage.setItem("country", cookies.get('hardyCountry'))
        //     sessionStorage.setItem("username", cookies.get('hardyUsername'))
        //     sessionStorage.setItem("nameSalutation", cookies.get('hardyNameSalutation'))
        //     sessionStorage.setItem("profileImg", cookies.get('hardyProfileImg'))   
        //     this.props.history.push({
        //         pathname: FRONTEND_NAME+'/home'
        //     });     
        // }
        const cookies = new Cookies();
        var changePassword = sessionStorage.getItem('changePassword');

        // if (cookies.get('hardyIsLogin') && changePassword != "true") {
        //     sessionStorage.setItem("isLogin", true);
        //     sessionStorage.setItem("accessToken", cookies.get('hardyAccessToken'))
        //     sessionStorage.setItem("mobileNumber", cookies.get('hardyMobileNumber'))
        //     sessionStorage.setItem("operator", cookies.get('hardyOperator'))
        //     sessionStorage.setItem("country", cookies.get('hardyCountry'))
        //     sessionStorage.setItem("username", cookies.get('hardyUsername'))
        //     sessionStorage.setItem("nameSalutation", cookies.get('hardyNameSalutation'))
        //     sessionStorage.setItem("profileImg", cookies.get('hardyProfileImg'))
        //     this.props.history.push({
        //         pathname: FRONTEND_NAME + '/home'
        //     });
        // }

        this.saveLogs('Login');

        $("#mobNum").on("keyup keypress blur keydown", function (event) {
            console.log("inside jqueryListener : key is ", event.key)
            console.log("inside jqueryListener : keyCode is ", event.keyCode)

            var key = event.keyCode || event.charCode;
            var isBackspace = (key == 187 || key == 189 || key == 69 || key == 46 || key == 43 || key == 101 || key == 190 || key == 158);
            if (isBackspace) {
                return false
            }

            // let key = Number(event.key) 
            // if (event.keyCode !== 8 && (isNaN(key) || event.key === null || event.key === ' ')) {
            // console.log("is not numeric")
            // return false;
            // }
        })


        this.getSocialLoginList();

    }

    getSocialLoginList() {
        const headers = {
            Appid: 17
        };

        axios.get(constantURL.PROFILER3_COMBINED_IP + "/v1/loginList", { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == 2000)) {
                    console.log(data.data.loginList)
                    this.setState({ socialLoginList: data.data.loginList });
                }
            });

    }

    onLoginClick(authType) {
      
        const googleLogin = new firebase.auth.GoogleAuthProvider();
        const facebookLogin = new firebase.auth.FacebookAuthProvider();
        var twitterLogin = new firebase.auth.TwitterAuthProvider();

        if (authType === "Google")
            this.loginOAuth(googleLogin);
        else if (authType === "Facebook")
            this.loginOAuth(facebookLogin);
        else if (authType === "Twitter")
            this.loginOAuth(twitterLogin);
    }


    loginByPassSocial(msisdn){

            var url = constantURL.PROFILER3_COMBINED_IP + "/v1/socialLogin";
        
            var headers = {
                "Content-Type": "application/json",
                Accept: "application/json",
                'access-token': msisdn,
                appid: constantURL.APP_ID,
              };
            console.log(headers)
       
            axios
              .get(url, {
                headers: headers,
              })
              .then((response) => response.data)
              .then((data) => {
                if (data && data.code == 2000) {
                  var userDetail = data.data.user;
                  console.log(userDetail)
                  this.createLoginSession(userDetail);
                } 
              });
    }

    createLoginSession(data) {
        const cookies = new Cookies();
        const current = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(current.getFullYear() + 1);
    
        sessionStorage.removeItem("isGuestLogin");
        sessionStorage.setItem("accessToken", data.accessToken);
        sessionStorage.setItem("mobileNumber", data.mobileNumber);
        if(data.mobileNumber==null || data.mobileNumber=='null' || data.mobileNumber==''){
            cookies.set("hardyIsLogin", true, { path: "/" });
            cookies.set("hardyAccessToken", data.accessToken, { path: "/" });
            cookies.set("hardyMobileNumber", data.username, { path: "/" });
            cookies.set("hardyUsername", data.username, { path: "/" });
        
            sessionStorage.setItem("isLogin", 'true');
            sessionStorage.setItem("loginType", "social");
            sessionStorage.setItem("username", data.username);
     
        }else{
            cookies.set("hardyIsLogin", false, { path: "/" });
            cookies.set("hardyAccessToken", data.accessToken, { path: "/" });
            cookies.set("hardyMobileNumber", data.mobileNumber, { path: "/" });
            cookies.set("hardyUsername", data.mobileNumber, { path: "/" });
        
            sessionStorage.setItem("isLogin", 'false');
            sessionStorage.setItem("loginType", "normal");
            sessionStorage.setItem("username", data.mobileNumber);
     
        }
       
        if (data.dateOfBirth) {
          var dob = data.dateOfBirth;
          var dob1 = dob.split("T");
          var dob2 = dob1[0].split("-");
          sessionStorage.setItem("dob", dob2[2] + "/" + dob2[1] + "/" + dob2[0]);
        }
        sessionStorage.setItem("gender", data.gender);
        sessionStorage.setItem("emailid", data.email);
        sessionStorage.setItem("address", data.address);
    
        sessionStorage.setItem("nameSalutation", data.firstName+' '+data.lastName)
          cookies.set("hardyNameSalutation", data.firstName+' '+data.lastName, { path: "/" });
     

        // if (data.nickName) {
        //   sessionStorage.setItem("nameSalutation", data.nickName);
        //   cookies.set("hardyNameSalutation", data.nickName, { path: "/" });
        // } else {
        //   sessionStorage.setItem("nameSalutation", data.username);
        //   cookies.set("hardyNameSalutation", data.username, { path: "/" });
        // }
        if (data.userImagePath != null) {
          sessionStorage.setItem("profileImg", data.userImagePath);
          cookies.set("hardyProfileImg", data.userImagePath, { path: "/" });
        }
        if(data.mobileNumber==null || data.mobileNumber=='null' || data.mobileNumber==''){
            this.fetchHomeData(data.username);
        }else{
            window.location.replace(constantURL.BIG_WIN_HOME_URL+cookies.get('hardyMobileNumber'));
        }
      }
    

      fetchHomeData(username){
        //   alert(username)
            this.props.history.push({
                pathname: FRONTEND_NAME+'/home',
                state: {
                    username: username
                }
            });
       }


    loginOAuth(loginProvider) {
       
        firebase.auth(socialAppName).signInWithPopup(loginProvider)
            .then((r) => {
                console.log(r);
                firebase.auth(socialAppName).currentUser.getIdToken().then(res => {
                    console.log(res);
                    sessionStorage.setItem("socialLogin-token", res);
                    sessionStorage.setItem("user", JSON.stringify(r.additionalUserInfo.profile));
                    sessionStorage.setItem("isLogin", 'true');
                    sessionStorage.setItem("loginType","social");
                    this.loginByPassSocial(res);
                
                })
                    .catch(e => {
                        console.log(e);
                    });
            })
            .catch((c) => {
                console.log(c);
            });
    }

    socialLoginButtons() {
        // alert('A')
        return this.state.socialLoginList.map(data => {
            if (data.enable) {
                return (
                    <img className= "socialLoginButton"
                     src={data.imageUrl} alt={data.serviceProvider} onClick={this.onLoginClick.bind(this, data.serviceProvider)}  />
                )
            }
        });
    }


    saveLogs(category) {

        var d = new Date();
        var n = d.getMilliseconds();

        var url = constantURL.APPLOGGER_COMBINED_IP + "/v1/saveLogs";

        const headers = {
            'Content-Type': 'application/json',
            'appid': constantURL.APP_ID,
            'operator': constantURL.OPERATOR,
            'msisdn': '',
            'channel': 'WAP'
        };

        const requestBody = {
            "category": category,
            "subCategory": "",
            "content": category,
            "url": "/login",
            "packname": "",
            "appname": constantURL.APPLICATION_NAME,
            "sessionid": "login_user" + n + "-" + Math.random(),
            "operator": "",
            "circle": "",
            "country": "INDIA"

        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == 2000) {
                } else {
                }
            });
    }



    signInUser(userType) {

        console.log('Inside ' + userType + ' SIGNIN API')
        const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SIGNING_API_PARAM}`
        console.log("calling signin with url " + url);
        this.setState({ enableLoader: true })


        const headers = {
            'Content-Type': Constant.APPLICATION_JSON,
            'Accept': Constant.APPLICATION_JSON,
            'deviceId': Constant.DEVICE_ID,
            'appid': Constant.APP_ID,
        };

        //this.setState({ msisdn: this.props.location.state.mydata.msisdn });

        const requestBody = {
            username: this.state.username,
            password: Constant.PASSWORD
        }

        axios.post(url, requestBody, {
            headers: headers
        })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                    console.log(data)

                    //alert("user has been validated successfully");
                    const transfer = {
                        msisdn: data.data.username,
                        operator: data.data.operator.id,
                        country: data.data.country.id,
                        userName: data.data.username,
                        accessToken: data.data.accessToken
                    }
                    //After Session is created, user is redirected to Successful HomeScreen 
                    this.createLoginSession(transfer, data.data);
                    console.log('Redirecting ' + userType + ' to successful HOME screen... ')
                    this.openHomeScreen()

                } else {
                    this.setState({
                        showModal: true,
                        msgType: 'E',
                        msg: "Error in login!"
                    });
                }
                this.setState({ enableLoader: false })

            });
    }


    signUpUser() {

        console.log('Inside SignUp user')
        const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SIGNUP_API_PARAM}`;
        console.log("calling SignUp with url " + url);
        this.setState({ enableLoader: true })

        const headers = {
            'Content-Type': Constant.APPLICATION_JSON,
            'Accept': Constant.APPLICATION_JSON,
            'deviceId': Constant.DEVICE_ID,
            'appid': Constant.APP_ID,
        };

        const requestBody = {
            username: this.state.username,
            password: Constant.PASSWORD,
            operatorName: Constant.MOB_OPERATOR
        }

        axios.post(url, requestBody, {
            headers: headers
        })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                    console.log("SignUp is successfull, going for SignIn")
                    this.signInUser('NEW USER')
                } else {
                    this.setState({
                        showModal: true,
                        msgType: 'E',
                        msg: "Error in login!"
                    });
                }
                this.setState({ enableLoader: false })

            });
    }


    checkUserType = (data) => {
        //  ;
        var changePassword = sessionStorage.getItem('changePassword');
        if (changePassword != "true") {
            switch (data.screenFlag) {
                case Constant.OTP_SCREEN:
                    console.log("Inside OTP_SCREEN section : ")
                    if (data.userType === Constant.NEW_USER) {

                        console.log('Redirecting ' + data.userType + ' to OTP screen... ')
                        this.openEnterOtpPage(data.userType)
                    }
                    else if (data.userType === Constant.EXISTING_USER) {
                        // this.openEnterPassPage(data.userType);
                        this.openEnterOtpPage(data.userType)

                    }
                    break;
                case Constant.SET_PWD_SCREEN:
                    console.log("Inside SET_PWD_SCREEN section : ")
                    if (data.userType === Constant.NEW_USER) {
                        console.log('Redirecting NEW user to SET PWD screen... Since there is No PWD Validation ' +
                            'Screen, so calling SIGNUP API internally for New user ...')
                        //  this.openSetPassPage(data.userType)
                        this.openEnterOtpPage(data.userType)

                    }
                    else if (data.userType === Constant.EXISTING_USER) {
                        this.openEnterOtpPage(data.userType)

                        // this.openEnterPassPage(data.userType);
                    }
                    break;
                case Constant.VALIDATE_PWD_SCREEN:
                    console.log("Inside VALIDATE_PWD_SCREEN section : ")
                    if (data.userType === Constant.EXISTING_USER) {
                        console.log('Redirecting EXISTING user to VALIDATE PWD screen...Since there is No PWD Validation ' +
                            'Screen, so calling SIGNIN API internally for New user ...')
                        //this.signInUser('EXISTING USER');
                        // this.openEnterPassPage(data.userType);
                        this.openEnterOtpPage(data.userType)

                    }
                    break;
                default:
                    console.log("Error Fetching userType from DB")
            }
        }
        else {

            console.log("go to otp page");
            this.openEnterOtpPage(data.userType);

        }




    }


    submitHandler = (event) => {
        event.preventDefault()
        console.log('username : ', this.state.username)
        if (!this.msisdnRegex.test(this.state.username)) {
            this.setState({
                error: true
            })
            console.log('from submit after : ', this.state.error)
        } else {
            this.setState({ enableLoader: true })

            const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SUBMIT_LOGIN_OR_SIGNUP_API_PARAM}`;
            console.log("Url to call : ", url);

            const headers = {
                'appid': Constant.APP_ID,
                'Content-Type': Constant.APPLICATION_JSON,
                'Accept': Constant.APPLICATION_JSON
            }

            const requestBody = {
                'username': this.state.username,
                'countryCode': Constant.COUNTRY_CODE,
            }

            // POST call to Submit Login or SignUp
            axios.post(url, requestBody, { headers })
                .then(response => response.data)
                .then(data => {
                    console.log(data)
                    if (data && data.code === Errorcode.SUCCESS_CODE) {
                        //  ;
                        console.log("output ", data);
                        console.log("userType is ", data.data.userType)
                        console.log("And screenFlag is ", data.data.screenFlag)

                        this.checkUserType(data.data);

                    } else if (data && data.code === Errorcode.OTP_LIMIT_EXHAUSTED) {
                        //Resend OTP after 15 mins
                        console.log("OTP Limit Exhausted with msg : ", data.msg);
                        this.togglePopup(this.state.lables.otpLimitExhausted, 'A', Constant.OTP_LIMIT_REACHED, true)
                    } else {
                        //Add Modal for Failure Case
                        console.log("Technical Error, Something went Wrong!!");
                    }

                    this.setState({ enableLoader: false })

                })
                .catch(error => {
                    this.setState({ enableLoader: false })

                    //Add Modal for Error Case 
                    console.log("Error Occured : ", error)
                })
        }

    }

    changeHandler = (event) => {
        console.log("inside handleListener : key is ", event.key)
        console.log("inside handleListener : keyCode is ", event.keyCode)
        console.log("input value is ", event.target.value)
        if (event.keyCode === 46) {
            console.log("inside changeHandler : ")
            return false
        }

        const userName = event.target.value.trim();
        console.log("username : ", userName)
        console.log("userName.includes(.) : ", userName.includes("."))
        console.log("this.userRegex.test(userName) : ", this.userRegex.test(userName))
        if (!Number(userName) || !this.userRegex.test(userName) || (userName.length >= 10 && this.state.username.length === 10)) {
            console.log("username inside: ", userName)
            if (userName === '') {
                this.setState({
                    username: ''
                })
            }
            return false;
        }
        console.log("username outside: ", userName)
        this.setState({
            username: userName,
            error: false,
            showCross: userName.length > 0
        })

    }

    openEnterOtpPage = (type) => {

        this.props.history.push({
            pathname: FRONTEND_NAME + '/loginotp',
            state: {
                username: this.state.username,
                usertype: type
            }
        });
    }

    openSetPassPage = (type) => {

        this.props.history.push({
            pathname: FRONTEND_NAME + '/setpassword',
            state: {
                username: this.state.username,
                usertype: type
            }
        });
    }
    openEnterPassPage = (type) => {
        //  ;
        this.props.history.push({
            pathname: FRONTEND_NAME + '/enterpassword',
            state: {
                username: this.state.username,
                usertype: type
            }
        });
    }


    openHomeScreen = () => {
        this.props.history.push({
            pathname: FRONTEND_NAME + '/home',
            state: {
                username: this.state.username
            }
        });
    }

    togglePopup = (msg, msgType, alert, hideBtn) => {
        this.setState({
            showPopup: !this.state.showPopup,
            message: msg,
            msgType: msgType,
            alert: alert,
            hideButton: hideBtn
        })

    }

    back() {
        this.props.history.push(FRONTEND_NAME + "/home");
    }

    render() {

        const { username, message, msgType, alert, showPopup, error, hideButton, showCross, lables } = this.state
        const enabled = username.length === 10
        return (
            <div className="container-login">
                {/* <div className="header-leaderboard edit-pad login-border">
                    <img src={back} alt="" onClick={this.back.bind(this)} className="float-lft"></img>
                </div> */}
                {
                    showPopup ?
                        <Popup text={message} status={msgType} alertType={alert} hideButton={hideButton} closePopup={this.togglePopup, this.openHomeScreen} /> :
                        null
                }
                {this.state.enableLoader ?
                    <Loading />
                    : null
                }
                <p className="loginn">{lables.login}</p>
                <p className="msisdn-p">{lables.enterMobileNo}</p>
                <p className="msisdn-sub-p">{lables.mobileNo}</p>
                <div className={enabled ? "enter-mm" : "enter-mm-gry"}>
                    {/* <div className="enter-mm"> */}
                    <span>+91 </span>

                    <input id="mobNum" type="number" className={enabled ? "enter-m" : "enter-m-gry"} name="msisdnId" value={username}
                        onChange={this.changeHandler} placeholder={lables.mobileNo}></input>
                    <span style={{ width: '16px' }} onClick={() => { console.log("abcdef"); this.setState({ username: "", showCross: false }) }}>{showCross && <img src={stop} ></img>}</span>
                </div>
                {
                    error ?
                        <p className="error-txt-red" >{lables.invalidPhoneNo}*</p> :
                        null
                }
                <div className="button-submit">
                    <button type="submit" className={enabled ? "submit-login" : "submit-login disable-gry"} onClick={this.submitHandler} disabled={!enabled}>{lables.proceed}</button>
                </div>
                {this.socialLoginButtons()}
            </div>
        )
    }
}

export default withRouter(EnterMisisdn);