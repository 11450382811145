import {
    FETCH_GAME_LIST_START,
    FETCH_GAME_LIST_SUCCESS,
    FETCH_GAME_LIST_ERROR,
    FETCH_GAME_LIST_REFRESH,
    FETCH_GAME_LIST_SESSION_ERROR
} from './../container/Constants/gameListConstant'


import * as Constant from '../container/Constants/constants'


const lables = Constant.getLables()

const initialState = {
    enableLoader: true,
    showPopup: false,
    msg: lables.somethingWentWrongInLoadingData,
    msgType: 'E',
    alert: lables.error,
    displayButtons: ['1'],
    logoutPopup: false,
    isInitialLoad: true,
    loginRedirect:'N',
    categories: new Map(),
}


export const gameListReducers = (state = initialState, action) => {
    console.log(action)
    try{
        switch(action.type){
            case FETCH_GAME_LIST_START:
                return initialState;
            case FETCH_GAME_LIST_REFRESH:
                    return {
                        ...state, 
                        isInitialLoad: false,
                        showPopup: false
                    };
            case FETCH_GAME_LIST_SUCCESS:
                return {
                    showPopup: false,
                    enableLoader: false, 
                    isInitialLoad: false, 
                    logoutPopup: false, 
                    categories: action.payload
                }
            case FETCH_GAME_LIST_ERROR:
                return {
                    ...state,
                    enableLoader: false, 
                    isInitialLoad: true, 
                    logoutPopup: true,
                    showPopup: true,
                    loginRedirect:'N'
                }

                case FETCH_GAME_LIST_SESSION_ERROR:
                return {
                    ...state,
                    enableLoader: false, 
                    isInitialLoad: true, 
                    logoutPopup: true,
                    showPopup: true,
                    msg:lables.sessionIsExpired,
                    loginRedirect:'Y'
                }

                
            default:
                return state
        }
    }
    catch(error){
        console.log(error)
    }
}