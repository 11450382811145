import React from 'react'
import axios from 'axios';
import '../styles/popup.css'
//  import  TickGreen from '../images/tick-green.png'
import infoAlert from '../images/alertyellow.svg'
import infoError from '../images/alertyellow.svg'
import infoSuccess from '../images/infoSuccess.png'
import { withRouter } from 'react-router'
import { getLables } from '../Constants/constants';

class PopupNew extends React.Component {
    
    constructor(props) {
            super(props)
        
            this.state = {
                 hideOK: true,
                 hideOKRedirect: true,
                 hideNotNow: true,
                 hideConfirm: true,
                 hideCancel: true,
                 hideCancelHome:true,
                 iconDecision: '',
                 lables : getLables()
            }
        }
 
        //NOTE, SEND displayButtons array AS PROPS FROM CALLING COMPONENT AND WHATEVER TO DISPLAY, 
        //JUST SEND BUTTON CODE 
        // hideOK: "1",
        // hideNotNow: "2",
        // hideConfirm: "3",
        // hideCancel: "4",

        componentDidMount(){

            this.state.iconDecision = 
            this.props.status === "A" ?
            infoAlert :
            (this.props.status === "S" ?
                infoSuccess :
                (this.props.status === "E" ?
                    infoError :
                    null)
            );

            this.props.displayButtons.map(button => {
                if(button === '1'){
                    this.setState({ 
                        hideOK: false
                    })
                }else if(button === '2'){
                    this.setState({ 
                        hideNotNow: false
                    })
                }else if(button === '3'){
                    this.setState({ 
                        hideConfirm: false
                    })
                }else if(button === '4'){
                    this.setState({ 
                        hideCancel: false
                    })
                }else if(button === '5'){
                    this.setState({ 
                        hideOKRedirect: false
                    })
                }else if(button === '6'){
                    this.setState({ 
                        hideCancelHome: false
                    })
                }

            });
        }

        // handleTask = () => {
            
        // }

    render() {

        const { hideOK, hideNotNow, hideConfirm, hideOKRedirect, hideCancel, iconDecision, hideCancelHome, lables } = this.state

        return (
            <div id="apply-modal" className="modal">
                <div className="modal-content">
                    <div className="modal-heading">
                        <img src={iconDecision} alt="" id="tick" />
                        <p className="popup-p">{this.props.alertType}</p>
                    </div>
                    <div className="apply-modal-header">
                        <p>{this.props.text}</p>
                    </div>
                    <div className="modal-close">
                   
                    <button className="popup-btn" onClick={() => this.props.closePopupWithTask('home')} hidden={hideOKRedirect}>{lables.ok?lables.ok:"OK"}</button>
                    <button className="popup-btn" onClick={this.props.closePopup} hidden={hideNotNow}>{lables.notNow?lables.notNow:"NOT NOW"}</button>
                    <button className="popup-btn" onClick={() => this.props.closePopupWithTask('ok-'+this.props.text)} hidden={hideOK}>{lables.ok?lables.ok:"OK"}</button>
                    <button className="popup-btn" onClick={() => this.props.closePopupWithTask('home')} hidden={hideCancelHome}>{lables.cancel?lables.cancel:"CANCEL"}</button>
                    <button className="popup-btn" onClick={this.props.closePopup} hidden={hideCancel}>{lables.cancel?lables.cancel:"CANCEL"}</button>
                    <button className="popup-btn" onClick={() => this.props.closePopupWithTask('confirm')} hidden={hideConfirm}>{lables.confirm?lables.confirm:"CONFIRM"}</button>
                  
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PopupNew);