
import React from 'react'
import axios from 'axios';
import '../styles/leaderboard.css';
import { withRouter } from 'react-router'
import back from '../images/back.png';
import LeaderboardBanner from '../images/leaderBanner.png';
import * as constantURL from "../Constants/constants";
import { FRONTEND_NAME } from "../Constants/constants";
import Loading from './loading'

class LeaderboardPhase2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dailyWinners: [],
            enableLoader: true,

            weeklyWinners: [],
            monthlyWinners: [],
        }
    }


    componentDidMount() {
        document.getElementById("weekly").classList.remove("active")
        document.getElementById("monthly").classList.remove("active")
        document.getElementById("daily").classList.add("active")
        document.getElementById("weeklyboard").style.display = "none";
        document.getElementById("monthlyboard").style.display = "none";
        document.getElementById("dailyboard").style.display = "block";

        this.getLeaderboardData('DAILY');
        this.getLeaderboardData('WEEKLY');
        this.getLeaderboardData('MONTHLY');
        if (sessionStorage.getItem('activetab')) {
            if (sessionStorage.getItem('activetab') == 'monthly') {
                this.monthly();
            }
            else if (sessionStorage.getItem('activetab') == 'weekly') {
                this.weekly();
            } else {
                this.daily();
            }
        } else {
            sessionStorage.setItem('activetab', 'daily');
        }
    }


    daily = () => {
        sessionStorage.setItem('activetab', 'daily');
        document.getElementById("weekly").classList.remove("active")
        document.getElementById("monthly").classList.remove("active")
        document.getElementById("daily").classList.add("active")
        document.getElementById("weeklyboard").style.display = "none";
        document.getElementById("monthlyboard").style.display = "none";
        document.getElementById("dailyboard").style.display = "block";
    }
    weekly = () => {
        sessionStorage.setItem('activetab', 'weekly');
        document.getElementById("weekly").classList.add("active")
        document.getElementById("monthly").classList.remove("active")
        document.getElementById("daily").classList.remove("active")
        document.getElementById("dailyboard").style.display = "none";
        document.getElementById("monthlyboard").style.display = "none";
        document.getElementById("weeklyboard").style.display = "block";
    }
    monthly = () => {
        sessionStorage.setItem('activetab', 'monthly');
        document.getElementById("weekly").classList.remove("active")
        document.getElementById("monthly").classList.add("active")
        document.getElementById("daily").classList.remove("active")
        document.getElementById("dailyboard").style.display = "none";
        document.getElementById("weeklyboard").style.display = "none";
        document.getElementById("monthlyboard").style.display = "block";
    }

    openDetail = (list) => {

        if (list.winnerProcessed) {
            console.log(list);
            this.props.history.push({
                pathname: constantURL.FRONTEND_NAME + "/leaderboardmonthly",
                state: {
                    list: list,
                },
            });
        }
    }

    back = () => {
        this.props.history.push({
            pathname: FRONTEND_NAME + "/home",
        });
    }


    getLeaderboardDataForDailyAndWeekly(appName, winnerDate, winnerPeriod, winnerType) {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
        var url = constantURL.SPS_COMBINED_IP + "/v1/getResponse";
        const headers = {
            'appkey': metaMap.get('APP_KEY_SUBS'),
            'Content-Type': constantURL.APPLICATION_JSON,
            'appid': sessionStorage["appid"],
            'deviceId': constantURL.DEVICE_ID,
            'operator': metaMap.get('OPERATOR'),
            'access_token': sessionStorage.getItem("accessToken"),
            'channel': constantURL.CHANNEL,
            'countryCode': metaMap.get('COUNTRY_CODE'),
            'msisdn': sessionStorage.getItem("username"),
        };

        const body = {

            "apiName": "winnerList",
            "requestBody": {
                "appName": appName,
                "winnerDate": winnerDate,
                "winnerType": winnerType,
                "winnerPeriod": winnerPeriod
            }

        }

        axios.post(url, body, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data);
                if (data) {

                    var winners = data.data;
                    var list = [];
                    for (var i = 0; i < winners.length; i++) {
                        list.push({
                            "msisdn": winners[i].msisdn,
                            "msisdn": winners[i].msisdn,
                            "appName": winners[i].appName,
                            "winnerRank": winners[i].winnerRank,
                            "creditRewards": winners[i].creditRewards,
                            "winnerDeclarationDate": winners[i].winnerDeclarationDate,
                            "winnerType": winners[i].winnerType,
                            "winnerPeriod": winners[i].winnerPeriod,
                            "operator": winners[i].operator,
                            "circle": winners[i].circle
                        })
                    }
                    if (winnerType == "DAILY") {
                        this.setState({ dailyWinners: list });
                    }
                    if (winnerType == "WEEKLY") {
                        this.setState({ weeklyWinners: list });
                    }
                }
                this.setState({ enableLoader: false });
            });

    }

    getLeaderboardData = (type) => {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
      
        this.setState({ enableLoader: true });
        var url = constantURL.SPS_COMBINED_IP + "/v1/getResponse";
        const headers = {
            'appkey': metaMap.get('APP_KEY_SUBS'),
            'Content-Type': constantURL.APPLICATION_JSON,
            'appid': sessionStorage["appid"],
            'deviceId': constantURL.DEVICE_ID,
            'operator': metaMap.get('OPERATOR'),
            'access_token': sessionStorage.getItem("accessToken"),
            'channel': constantURL.CHANNEL,
            //   'countryCode': constantURL.COUNTRY_CODE,
            'msisdn': sessionStorage.getItem("username"),
        };

        const body = {
            "apiName": "winnerStatusList",
            "requestBody": {
                'appName': constantURL.LEADERBOARD_APP_NAME,
                "winnerType": type
            }
        }

        axios.post(url, body, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data);
                if (data) {

                    var winners = data.data;
                    console.log(winners);

                    var list = [];
                    if (winners) {
                        for (var i = 0; i < winners.length; i++) {
                            list.push({
                                appName: winners[i].appName,
                                winnerType: winners[i].winnerType,
                                winnerPeriod: winners[i].winnerPeriod,
                                winnerDeclarationDate: winners[i].winnerDeclarationDate,
                                winnerCount: winners[i].winnerCount,
                                winnerProcessed: winners[i].winnerProcessed
                            })
                        }

                        if (type == "DAILY") {
                            if (list.length > 1) {
                                this.getLeaderboardDataForDailyAndWeekly(list[1].appName, list[1].winnerDeclarationDate,
                                    list[1].winnerPeriod, "DAILY");
                            }
                        }
                        if (type == "WEEKLY") {
                            if (list.length > 1) {
                                this.getLeaderboardDataForDailyAndWeekly(list[1].appName, list[1].winnerDeclarationDate,
                                    list[1].winnerPeriod, "WEEKLY");
                                    // this.getLeaderboardDataForDailyAndWeekly(list[1].appName, "2022-01-23",
                                    //     "4", "WEEKLY");
                                    
                            }
                        }
                        if (type == "MONTHLY") {
                            this.setState({ monthlyWinners: list });
                        }
                    }
                }
                this.setState({ enableLoader: false });
            });
    }

    render() {

        function getFormattedDate(creationDate) {
            var dateValSplit = creationDate.split("-");
            var day = dateValSplit[2];
            var month = dateValSplit[1];

            var monthName = "";
            switch (month) {
                case '01': monthName = "Jan";
                    break;
                case '02': monthName = "Feb";
                    break;
                case '03': monthName = "Mar";
                    break;
                case '04': monthName = "Apr";
                    break;
                case '05': monthName = "May";
                    break;
                case '06': monthName = "June";
                    break;
                case '07': monthName = "July";
                    break;
                case '08': monthName = "Aug";
                    break;
                case '09': monthName = "Sep";
                    break;
                case '10': monthName = "Oct";
                    break;
                case '11': monthName = "Nov";
                    break;
                case '12': monthName = "Dec";
                    break;
            }

            var suffix = getSuffix(parseInt(day));

            return day + "" + suffix + " " + monthName;
        }

        const getSuffix = (d) => {

            if (d > 3 && d < 21) return 'th';
            switch (d % 10) {
                case 1: return "st";
                case 2: return "nd";
                case 3: return "rd";
                default: return "th";
            }
        }
        function getMonthName(val) {
            var monthName = '';
            switch (val) {
                case '1': monthName = "January";
                    break;
                case '2': monthName = "February";
                    break;
                case '3': monthName = "March";
                    break;
                case '4': monthName = "April";
                    break;
                case '5': monthName = "May";
                    break;
                case '6': monthName = "June";
                    break;
                case '7': monthName = "July";
                    break;
                case '8': monthName = "August";
                    break;
                case '9': monthName = "September";
                    break;
                case '10': monthName = "October";
                    break;
                case '11': monthName = "November";
                    break;
                case '12': monthName = "December";
                    break;
            }
            return monthName;
        }

        function maskMsisdn(val) {
            var msisdn =
                val.substring(0, 2) +
                "XXXXXX" +
                val.substring(7, 12);

            return msisdn;
        }


        var winnersDailyDisplay = [];

        this.state.dailyWinners.forEach(function (list) {
            winnersDailyDisplay.push(
                <tr>
                    <td className={sessionStorage.getItem("username") == list.msisdn ? "active" : "active1"}>{maskMsisdn(list.msisdn)}</td>
                    <td className={sessionStorage.getItem("username") == list.msisdn ? "active" : "active1"}>{list.creditRewards}</td>
                    <td className={sessionStorage.getItem("username") == list.msisdn ? "active" : "active1"}>#{list.winnerRank}</td>
                </tr>
            );
        }.bind(this));

        var winnersWeeklyDisplay = [];

        this.state.weeklyWinners.forEach(function (list) {
            winnersWeeklyDisplay.push(
                <tr>
                    <td className={sessionStorage.getItem("username") == list.msisdn ? "active" : "active1"}>{maskMsisdn(list.msisdn)}</td>
                    <td className={sessionStorage.getItem("username") == list.msisdn ? "active" : "active1"}>{list.creditRewards}</td>
                    <td className={sessionStorage.getItem("username") == list.msisdn ? "active" : "active1"}>#{list.winnerRank}</td>
                </tr>
            );
        }.bind(this));

        var winnersMonthlyDisplay = [];
        // winnersMonthlyDisplay.push(
        //     <tr onClick={()=>this.openDetail('list')}>
        //         <td>March</td>
        //         <td>20th Jan</td>
        //     </tr>
        // );
        // winnersMonthlyDisplay.push(
        //     <tr onClick={()=>this.openDetail('list')}>
        //         <td>March</td>
        //         <td>20th Jan</td>
        //     </tr>
        // );

        this.state.monthlyWinners.forEach(function (list) {
            winnersMonthlyDisplay.push(
                <tr onClick={() => this.openDetail(list)}>
                    <td>{getMonthName(list.winnerPeriod)}</td>
                    <td>{getFormattedDate(list.winnerDeclarationDate)}</td>
                </tr>
            );
        }.bind(this));

        return (
            <div className="container-leaderboard">
                {this.state.enableLoader ?
                    <Loading />
                    : null
                }

                <div class="leader-head">
                    <span><img src={back} onClick={this.back} alt="" /></span>
                    <span class="head">Leaderboard</span>
                </div>
                <div class="leader-container">
                    <img class="leaderBanner" src={LeaderboardBanner} alt="" />

                    <div class="leader-tab">
                        <span id="daily" onClick={this.daily}>Daily</span>
                        <span id="weekly" onClick={this.weekly} >Weekly</span>
                        <span id="monthly" onClick={this.monthly}>Monthly</span>
                    </div>
                    <div class="leader-tbl" id="dailyboard">
                        <table>
                            <tr>
                                <th>User</th>
                                <th>Points</th>
                                <th>Rank</th>
                            </tr>
                            {winnersDailyDisplay}
                        </table>
                    </div>
                    <div class="leader-tbl" id="weeklyboard">
                        <table>
                            <tr>
                                <th>User</th>
                                <th>Points</th>
                                <th>Rank</th>
                            </tr>
                            {winnersWeeklyDisplay}
                        </table>
                    </div>
                    <div class="leader-tbl" id="monthlyboard">
                        <table>
                            <tr>
                                <th>Month</th>
                                <th>Results Declared Date</th>
                            </tr>
                            {winnersMonthlyDisplay}
                        </table>
                    </div>
                </div>

            </div>
        )
    }
}

export default withRouter(LeaderboardPhase2);