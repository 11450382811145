import React, { Component, createRef } from 'react'
import { withRouter } from 'react-router'
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import axios from 'axios';
import Loading from './loading'
import $ from 'jquery';
import checked from '../images/checked.png';
import Cookies from 'universal-cookie';
import error from '../images/redError.png';
import DatePicker from 'react-mobile-datepicker';
import { convertDate } from './time.js';

class EditAddressModal extends Component {

    

    constructor(props) {
        super(props)
        this.state = {
			time: undefined,
            isOpen: false,
            lables : constantURL.getLables()
            }
    }


    handleCancel = () => {
        this.setState({ isOpen: false });
        this.props.closePopup();
    
    }

    handleSelect = (time) => {


        console.log('TIME->  '+convertDate(time, 'DD-MM-YYYY') );
        this.setState({ time: time, isOpen: false });
        this.setState({ dob: convertDate(time, 'DD/MM/YYYY') });
        this.props.setDate(convertDate(time, 'DD/MM/YYYY'));
    }


    pickDate(){
        console.log('KK')
        $( ".datepicker-navbar a:first" ).click();
        this.props.closePopup();
    }

    render() {

        const {lables} = this.state;

        const monthMap = {
            '1': 'Jan',
            '2': 'Feb',
            '3': 'Mar',
            '4': 'Apr',
            '5': 'May',
            '6': 'Jun',
            '7': 'Jul',
            '8': 'Aug',
            '9': 'Sep',
            '10': 'Oct',
            '11': 'Nov',
            '12': 'Dec',
        };

        const dateConfig = {
            'date': {
                format: 'DD',
                caption: 'Day',
                step: 1,
            },

            'month': {
                format: value => monthMap[value.getMonth() + 1],
                caption: 'Mon',
                step: 1,
            },
            'year': {
                format: 'YYYY',
                caption: 'Year',
                step: 1,
            },

        };

        return (
            <div className="modal-e" style={{backgroundColor:'rgba(0, 0, 0, .6)',zIndex:'999'}}>
                <div className="edit-info eeeee">
                    <div className="cancel" style={{ borderBottom: '1px solid rgba(255,255,255,0.15)', padding: '22px 16px 22px 16px' }}>
                        <p className="cancel-pe" onClick={this.props.closePopup}>{lables.cancel}</p>
                        <p className="done-e" onClick={() => this.pickDate()}  >{lables.done}</p>
                    </div>

                    <div className="enter-info">
                        <div className="what-e"> 

                            <DatePicker

                                min={new Date(1970, 0, 1)}
                                max={new Date()}
                                value={this.state.time}
                                isOpen={true}
                                onSelect={this.handleSelect}
                                onCancel={this.handleCancel}
                                dateConfig={dateConfig}
                                confirmText={lables.ok}
                                cancelText={lables.cancel}
                            
                            />

                         </div>
                    </div> 
                    {/* <p className="error-e">{this.state.errorMsg}</p> */}
                </div>
            </div>
        )
    }
}

export default withRouter(EditAddressModal);