import React from 'react'
import axios from 'axios';
import '../styles/prize.css';
import { withRouter } from 'react-router'
import back from '../images/back.png';
import bullet from '../images/bullet.png';

class Prize extends React.Component {


    /*back(){
        this.props.history.push(FRONTEND_NAME+"/profile");       
    }*/
    
    render() {

        return (
            <div className="container-prize"> 
			<div className="header-leaderboard copy-every">
				<img src={back} className="float-lft"></img>	
				<span className="header-text float-lft">PRIZE</span>		
			</div>
            <div className="prize-table">
                <table>
                    <tbody>
                        <tr className="table-header">
                            <td>Rank</td>    
                            <td>Prize Amount</td>    
                        </tr>
                        <tr>
                            <td className="rank">
                                <span><img src={bullet}></img></span>
                                <span>1</span>
                            </td>    
                            <td className="amount">{'\u20B9'}200</td>    
                        </tr>
                        <tr>
                            <td className="rank">
                                <span><img src={bullet}></img></span>
                                <span>2</span>
                            </td>    
                            <td className="amount">{'\u20B9'}195</td>    
                        </tr>    
                        <tr>
                            <td className="rank">
                                <span><img src={bullet}></img></span>
                                <span>4-5</span>
                            </td>    
                            <td className="amount">{'\u20B9'}190</td>      
                        </tr>
                        <tr>
                            <td className="rank">
                                <span><img src={bullet}></img></span>
                                <span>5-10</span>
                            </td>    
                            <td className="amount">{'\u20B9'}185</td>     
                        </tr>
                        <tr>
                            <td className="rank">
                                <span><img src={bullet}></img></span>
                                <span>10-100</span>
                            </td>    
                            <td className="amount">{'\u20B9'}185</td>     
                        </tr>
                    </tbody>
                </table>    
            </div> 
			</div>
)
}
}

export default withRouter(Prize);