
import React from 'react'
import axios from 'axios';
import '../styles/home.css';
import Footer from '../Footer/footer';
import profileImg from '../images/profileImage.png';
import games from '../images/games.svg';
import bell from '../images/bell.png';
import yellowStar from '../images/yellowStar.png';
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Slider from 'infinite-react-carousel';
import { withRouter } from 'react-router';
import Popup from './popup';
import Loading from './loading'
import LogoutPopup from './logoutPopup';
import PopupNew from './popupNew'
import clownLogo from '../images/clownLogo.png';
import {FRONTEND_NAME} from '../Constants/constants'

class HeadToHead extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            banners: [{ id: '1', title: 'title', bannerName: 'bannerName', imageUrl: 'imageUrl', redirectUrl: 'redirectUrl', gameurl: 'game', bannerType: 'ban', bannerText: 'PLAY NOW' }],
            coinsGames: [],
            isShowMenu: false,
            showPopup: false,
            isShowSpin: false,
            msg: '',
            msgType: 'S',
            enableLoader: true,
            isConfirm: 'N',
            loginRedirect: 'N',
            alertMessage: '',
            logoutPopup: false,
            displayButtons: [],
            currentSelectedGame: {},
            showPopupNew: false
        }

        this.deductCoins = this.deductCoins.bind(this);
    }

    subsHeaders = {
        'appKey': constantURL.APP_KEY_SUBS,
        'operator': constantURL.OPERATOR,
        'Msisdn': sessionStorage.getItem("username"),
        'channel': constantURL.CHANNEL,
        'Access_token': sessionStorage.getItem("accessToken"),
        'deviceId': constantURL.DEVICE_ID,
        'Content-Type': constantURL.APPLICATION_JSON,
        'Accept': constantURL.APPLICATION_JSON
    }

    componentDidMount() {
        sessionStorage.removeItem("rewardCount");
        sessionStorage.removeItem("info");
        sessionStorage.removeItem("gameurl");

        const headers = {
            'Content-Type': constantURL.APPLICATION_JSON,
            'appid': constantURL.APP_ID,
            'deviceId': constantURL.DEVICE_ID,
            'operator': constantURL.OPERATOR,
            'Access_token': sessionStorage.getItem("accessToken"),
            'channel': constantURL.CHANNEL,
            'Msisdn': sessionStorage.getItem("username")
        };

        const url = constantURL.CONTENT_COMBINED_IP + "/v1/fetchCategories?categoryId=" + constantURL.COINS_ID;
        console.log('url to call : ', url)


        axios.get(url, { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {

                    console.log(data.data)

                    var bannerDetail = data.data.categories[0].banners;
                    var subCategoriesDetail = data.data.categories[0].subCategories;
                    var banList = [];
                    var popList = [];
                    var keepList = [];
                    var freeList = [];

                    if (bannerDetail) {
                        for (var i = 0; i < bannerDetail.length; i++) {
                            banList.push({
                                id: bannerDetail[i].id,
                                title: bannerDetail[i].bannerImageTitle,
                                bannerName: bannerDetail[i].bannerName,
                                desciption: bannerDetail[i].desciption,
                                imageUrl: bannerDetail[i].imageUrl,
                                redirectUrl: bannerDetail[i].redirectUrl,
                                gameurl: bannerDetail[i].secondarygameurl,
                                bannerType: bannerDetail[i].bannerType,
                                buttonText: bannerDetail[i].buttonText,
                                coinsRequired: bannerDetail[i].chargingvalue,
                                gametype: bannerDetail[i].gametype,
                                checkSubKeyword: bannerDetail[i].checkSubKeyword,
                                chargingKeyword: bannerDetail[i].chargingKeyword,
                                replacedynamicparam: bannerDetail[i].replacedynamicparam,
                                price:bannerDetail[i].chargingcurrency+' '+bannerDetail[i].chargingvalue
    

                            });
                        }
                        this.setState({ banners: banList });
                    }


                    if (subCategoriesDetail) {
                        var pG = subCategoriesDetail[0].content3;   //Coins Games

                        for (var i = 0; i < pG.length; i++) {
                            popList.push({
                                id: 'c' + i,
                                title: pG[i].title,
                                noOfUsersDynamic: pG[i].noOfUsersDynamic,
                                previewImageUrl: pG[i].previewImageUrl,
                                gameurl: pG[i].secondarygameurl,
                                price:pG[i].chargingcurrency+' '+pG[i].chargingvalue,
                                coinsRequired: pG[i].chargingvalue,
                                gametype: pG[i].gametype,
                                replacedynamicparam: pG[i].replacedynamicparam,
                                currency: pG[i].chargingcurrency,
                                checkSubKeyword: pG[i].checkSubKeyword,
                                chargingKeyword:pG[i].chargingKeyword,
                               
                            });
                        }
                        this.setState({ coinsGames: popList });
                    }
                    this.setState({ enableLoader: false })

                } else {
                    this.setState({ enableLoader: false })

                    this.setState({
                        logoutPopup: true,
                    });
                }
            });
    }

    sliders() {
        return this.state.banners.map(data => {
            return (
                <div key={data.id}>
                    <img src={data.imageUrl}  className="banner-top"></img>
                    <p className="message-t">{data.title}</p>
                    <p className="message-p">{data.desciption}</p>
                    <button className="play-n" onClick={() => this.redirectToRequiredGame(data)}>{data.buttonText ? data.buttonText : 'PLAY NOW'}</button>
                </div>
            )
        });
    }

    deductCoins(data1) {

        sessionStorage.setItem("rewardCount", data1.coinsRequired);
        sessionStorage.setItem("info", data1.title);
        sessionStorage.setItem("gameurl", data1.gameurl);

        const isLogin = sessionStorage.getItem("isLogin");
        if (!isLogin) {

            this.setState({
                showPopup: true,
                msgType: 'A',
                isConfirm: 'Y',
                alertMessage: 'Login Required!',
                msg: "You need to be logged in to access this feature. Do you wish to proceed?",
                loginRedirect: 'Y'
            });
        } else {

            this.setState({
                showPopup: true,
                msgType: 'A',
                isConfirm: 'Y',
                alertMessage: 'Confirm Payment!',
                msg: "You will be charged " + data1.coinsRequired + " coins for playing this game. Please confirm to play game.",
                loginRedirect: 'C'
            });
        }

    }

    onDisplayMenu(obj) {
        this.setState({ isShowMenu: !this.state.isShowMenu });
    }

    checkUserSubscription = (gameToPlay, headers) => {

        console.log("current game is : ", this.state.currentSelectedGame)
        const url = `${constantURL.SPS_COMBINED_IP}${constantURL.CHECK_SUBS_API_PARAM}${gameToPlay.checkSubKeyword}`
        console.log('Url to hit for checksubs : ', url)
        this.setState({ enableLoader: true })

        axios.get(url, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code === errorConstantURL.SUCCESS_CODE) {
                    console.log(data.data)
                    if (data.data.subscriberStausCode == "0" || data.data.subscriberStatus === constantURL.ACTIVE) {
                        console.log("User is Subscribed for selected Game, No need for confirmartion, redirecting to game directly")
                        //   window.open(gameToPlay.gameurl, "_blank")



                    } else if (data.data.subscriberStausCode == "1" || data.data.subscriberStatus === constantURL.NOT_SUBSCRIBED) {
                        console.log("User is NOT Subscribed  for selected Game, taking user confirmartion using popup ")
                        console.log('If Confirmation is Yes, then call charginKeywordSub API for payment and then redirect to game')
                        this.togglePopupNew("Note: You will be charged " + gameToPlay.coinsRequired + " INR for playing this game", "A", constantURL.CONFIRM_PAYMENT_ALERT, ['2', '3'])
                    } else {
                        console.log("User status is pending, showing pending popup for other response code")
                        this.togglePopupNew(constantURL.PENDING_SUBSCRIPTION_MESSAGE, "A", constantURL.PENDING_SUBSCRIPTION_ALERT, ['1'])
                    }

                } else {
                    console.log('Some failure response : ', data);
                    this.togglePopupNew(constantURL.ERROR_OCCURED, 'E', constantURL.ERROR_TYPE_ALERT, ['1'])
                }
                this.setState({ enableLoader: false })

            })
            .catch(error => {
                this.setState({ enableLoader: false })

                console.log("Error Occured : ", error)
                this.togglePopupNew(constantURL.ERROR_OCCURED, 'E', constantURL.ERROR_TYPE_ALERT, ['1'])
            })

    }

    checkSubscriptionAndMakePayment = (gameToPlay) => {

        this.checkUserSubscription(gameToPlay, this.subsHeaders)
    }

    submitPaymentSubscription = () => {
        console.log("SUB")
        console.log(this.state.currentSelectedGame)
        const url = `${constantURL.SPS_COMBINED_IP}${constantURL.SUBS_PACK_API_PARAM}${this.state.currentSelectedGame.chargingKeyword}`
        console.log('Url to hit for chargingKeyword payment : ', url)
        this.setState({ enableLoader: true })

        const headers = this.subsHeaders;

        axios.get(url, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code === errorConstantURL.SUCCESS_CODE) {
                    console.log(data.data)
                    if (data.data.subscriptionStatusCode === 0 || data.data.subscribtionStatus === constantURL.ACTIVE) {
                        console.log("User has successfully made payment for selected Game, redirecting to game directly")
                        this.props.history.push({
                            pathname: FRONTEND_NAME+'/gameview',
                            state: {
                                name: this.state.currentSelectedGame.title,
                                gameurl: this.state.currentSelectedGame.gameurl,
                                gameScreen:'coinGames',
                                replaceParam:this.state.currentSelectedGame.replacedynamicparam,
                            }
                        })
                        //  this.togglePopup(constantURL.CONFIRM_PAYMENT_MESSAGE, "A", constantURL.CONFIRM_PAYMENT_ALERT, ['3', '4'])
                    } else {
                        console.log("User status is pending, showing pending popup for other response code")
                        this.togglePopupNew(constantURL.PENDING_SUBSCRIPTION_MESSAGE, "A", constantURL.PENDING_SUBSCRIPTION_ALERT, ['1'])
                    }

                } else {
                    console.log('Some failure response : ', data);
                    this.togglePopupNew(constantURL.ERROR_OCCURED, 'E', constantURL.ERROR_TYPE_ALERT, ['1'])
                }
                this.setState({ enableLoader: false })

            })
            .catch(error => {
                this.setState({ enableLoader: false })

                console.log("Error Occured : ", error)
                this.togglePopupNew(constantURL.ERROR_OCCURED, 'E', constantURL.ERROR_TYPE_ALERT, ['1'])
            })

    }

    redirectToRequiredGame = (gameToPlay) => {
        console.log(gameToPlay)
        if (!gameToPlay.gametype || gameToPlay.gametype.toUpperCase() === constantURL.GAME_TYPE_FREE) {
            console.log(gameToPlay.coinsRequired)
            this.props.history.push({
                pathname: FRONTEND_NAME+'/gameview',
                state: {
                    name: gameToPlay.title,
                    gameurl: gameToPlay.gameurl,
                    gameScreen:'coinGames',
                    replaceParam:gameToPlay.replacedynamicparam,
                }
            })
            return false;
        }else{
            const isLogin = sessionStorage.getItem("isLogin");
            if (!isLogin) {
                this.setState({
                    showPopup: true,
                    msgType: 'A',
                    isConfirm: 'Y',
                    alertMessage: 'Login Required!',
                    msg: "You need to be logged in to access this feature. Do you wish to proceed?",
                    loginRedirect: 'Y'
                });
                return false;
            }
        }


            //Cureent game is set ti state variable because we need it when payment popup gives confirmation.
            //At that time it cannot be passed through a method
            this.setState({
                currentSelectedGame: gameToPlay
            })
            console.log("inside redirectToRequiredGame : ", gameToPlay.gameurl)
            const isLogin = sessionStorage.getItem("isLogin");
            if (isLogin) {
                console.log('User is logged In, checking game type to make decision. Game type is ', gameToPlay.gametype)

                if (gameToPlay.gametype.toUpperCase() === constantURL.GAME_TYPE_SUB ||
                gameToPlay.gametype.toUpperCase() === constantURL.GAME_TYPE_ONE_SUB ) {
                    this.checkSubscriptionAndMakePayment(gameToPlay)
                }
                else if (gameToPlay.gametype.toUpperCase() === constantURL.GAME_TYPE_PPP ||
                gameToPlay.gametype.toUpperCase() === constantURL.GAME_TYPE_ONE97PPP) {
                    console.log("INSI")
                    this.togglePopupNew("Note: You will be charged " + gameToPlay.coinsRequired + " INR for playing this game", "A", constantURL.CONFIRM_PAYMENT_ALERT, ['2', '3'])
                    //this.submitPaymentSubscription()
                }
                else if (gameToPlay.gametype.toUpperCase() === constantURL.GAME_TYPE_COIN) {
                    console.log('Since game type is COIN GAME so calling API to deduct User Coins')
                    this.deductCoins(gameToPlay)
                }
                else if (gameToPlay.gametype.toUpperCase() === constantURL.GAME_TYPE_FREE) {
                    console.log('Since game type is FREE GAME so redirecting user to selected game screen')
                    // window.open(gameToPlay.gameurl, "_blank")

                    this.props.history.push({
                        pathname: FRONTEND_NAME+'/gameview',
                        state: {
                            name: gameToPlay.title,
                            gameurl: gameToPlay.gameurl,
                            gameScreen:'coinGames',
                            replaceParam:gameToPlay.replacedynamicparam,
                        }
                    })
                }

            }
            else {
                console.log('User is NOT logged In, showing popup to login')
                this.setState({
                    redirectScreen: constantURL.LOGIN_SCREEN_TITLE
                })
                this.togglePopupNew(constantURL.LOGIN_REQUIRED_MESSAGE, 'A', constantURL.LOGIN_REQUIRED_ALERT)
                //If YES, want to login then redirect to login page and after successful login, redirect to respective Game Page
            }
        }
    


    togglePopupNew = (message, msgType, alert, buttonArray) => {

        this.setState({
            showPopupNew: !this.state.showPopupNew,
            showPopup: false,
            message: message,
            msgType: msgType,
            alert: alert,
            displayButtons: buttonArray
        })
    }

    performTaskOnClose = (flag) => {
        this.togglePopupNew()
        console.log("inside performTaskOnClose method, flag is ", flag)
        if (flag === 'confirm') {
            this.submitPaymentSubscription();
        }
    }


    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
        if (this.state.loginRedirect == 'Y') {
            this.props.history.push(FRONTEND_NAME+"/login")
        }

        if (this.state.loginRedirect == 'C') {
            this.deduct()
        }

        if (this.state.loginRedirect == 'H') {
          this.props.history.push(FRONTEND_NAME+"/home")
        }
    }

    deduct() {

        const headers = {
            'Content-Type': 'application/json',
            'appkey': constantURL.APPKEY,
            'operator': constantURL.OPERATOR,
            'msisdn': sessionStorage.getItem("username")
        };

        const body = {
            "rewardName": "coin",
            "rewardFrom": "tambola_win",
            "operation": "debit",
            "rewardCount": sessionStorage.getItem("rewardCount"),
            "info": sessionStorage.getItem("info"),
            "operationSource": "WAP"

        }
        this.setState({ enableLoader: true })

        axios.post(constantURL.SPS_COMBINED_IP + "/v1/userOperation", body, { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    this.setState({
                        showPopup: false,
                    });
                    this.props.history.push({
                        pathname: FRONTEND_NAME+'/gameview',
                        state: {
                            name: sessionStorage.getItem("info"),
                            gameurl: sessionStorage.getItem("gameurl"),
                            gameScreen:'coinGames',
                            replaceParam:'',
            
                        }
                    })
                } else if (data.code == 2004) {
                    this.setState({
                        showPopup: true,
                        msgType: 'E',
                        isConfirm: 'N',
                        alertMessage: 'Sorry!',
                        msg: "You don't have sufficient coins. Please try after sometime.!",
                        loginRedirect: 'H'
                    });
                } else {
                    this.setState({ logoutPopup: true })
                }
                this.setState({ enableLoader: false })
                sessionStorage.removeItem("rewardCount");
                sessionStorage.removeItem("info");
                sessionStorage.removeItem("gameurl");

            });
    }

    togglePop() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    openNotificcation() {

        this.props.history.push({
            pathname: FRONTEND_NAME+'/notification',
            state: {
                gameScreen:'coinGames',
            }
        })
    }

    openPlayCoins() {

        const isLogin = sessionStorage.getItem("isLogin");
        if (!isLogin) {

            this.setState({
                showPopup: true,
                msgType: 'A',
                isConfirm: 'Y',
                alertMessage:'Login Required!',
                msg: "You need to login to view coins.",
                loginRedirect: 'Y'
            });

        } else {
            this.props.history.push({
                pathname: FRONTEND_NAME+'/playcoins',
                state: {
                    gameScreen:'coinGames',
                }
            })
        }
    }

    openProfile() {
        if (sessionStorage.getItem("isLogin")) {
            this.props.history.push(FRONTEND_NAME+"/profile");
        }
        else {
            this.props.history.push(FRONTEND_NAME+"/login");
        }

    }

    sessionOut() {
        this.props.history.push(FRONTEND_NAME+"/logout");
    }

    render() {
        const { msgType, message, coinsGames, displayButtons } = this.state;
        const settings = {
            autoplay: true,
            arrows: false,
            dots: true
        };

        var coinRow = [];
        coinsGames.forEach(function (list) {
            coinRow.push(
                // onClick={()=> window.open(list.redirectUrl, "_blank")}
                <div key={list.id} className="Coin-games">
                    <div className="gamess" onClick={() => this.redirectToRequiredGame(list)}>
                        <img src={list.previewImageUrl} ></img>
                        <div className="for-line">
                            <div className="coin-p">
                                <p className="coin-g-a">{list.title}</p>
                                <p className="coin-g-b">{list.noOfUsersDynamic} played</p>
                            </div>
                            <div className="coin-amt">
                                <p>{list.coinsRequired + " "+ list.currency.toUpperCase()}</p>
                            </div>
                        </div>
                    </div>

                </div>
            );

        }.bind(this));


        // onClick={this.onDisplayMenu.bind(this)} 
        return (
            <div className="container-home"  >
                {this.state.enableLoader ?
                    <Loading />
                    : null
                }
                {
                    this.state.logoutPopup ?
                        <LogoutPopup
                            redirectPopup={this.sessionOut.bind(this)}
                        /> : null
                }

                {this.state.showPopup ?
                    <Popup
                        status={this.state.msgType}
                        text={this.state.msg}
                        alertType={this.state.alertMessage}
                        buttonName={this.state.loginRedirect == 'C' ? 'CONFIRM' : 'OK'}
                        closePopup={this.togglePopup.bind(this)}
                        closePopupNotNow={this.togglePop.bind(this)}
                        isConfirm={this.state.isConfirm}
                    /> : null}

                {this.state.showPopupNew ?
                    <PopupNew text={message} status={msgType} alertType={this.state.alert} displayButtons={displayButtons}
                        closePopupWithTask={this.performTaskOnClose} closePopup={this.togglePopupNew} /> :
                    null
                }

                {/* <div id="revoke-modal"   className={this.state.isShowMenu ? 'revokeModalShow' : 'revokeModalHide'} >
                    <ProfileCollapsed closeMenu={this.onDisplayMenu.bind(this)} />
                 </div> */}
                <div className="header">
                    <div className="header-l" onClick={this.openProfile.bind(this)}>
                        <img src={sessionStorage.getItem("profileImg") ? sessionStorage.getItem("profileImg") : clownLogo} alt="" className="slider"></img>
                        <p className="user-name"> {sessionStorage.getItem("isLogin") ? 'Hi ' + sessionStorage.getItem("nameSalutation").split(' ')[0] + '!' : 'Login'}     </p>

                    </div>
                    {/* <div className="games"><img src={games} alt=""  ></img></div>
                    <div className="header-r" >
                        <div className="points" onClick={this.openPlayCoins.bind(this)}>
                            <img src={yellowStar} alt=""></img>
                        </div>
                        <img src={bell} alt="" className="bell" onClick={this.openNotificcation.bind(this)}></img>
                    </div> */}
                </div>

                <div className="content"  >
                    <Slider {...settings}>
                        {this.sliders()}
                    </Slider>
                </div>
                {coinRow}
                <Footer currentSelection={constantURL.HEAD_TO_HEAD} />
            </div>
        )
    }
}

export default withRouter(HeadToHead);