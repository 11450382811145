import React from 'react'
import axios from 'axios';
import '../styles/playCoins.css';
import { withRouter } from 'react-router'
import back from '../images/backNew.svg';
import plus from '../images/plus.svg';
import bigPlus from '../images/bigPlus.png';
//import playWin from '../images/bigWin.svg';
import playWin from '../images/black.svg';
import addition from '../images/addCoinsNew.svg';
import gameWin from '../images/gameWin.svg';
import coinMinus from '../images/reduce.png';
import coinPlus from '../images/add.svg';
import unchecked from '../images/unchecked.png';
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Loading from './loading'
import LogoutPopup from './logoutPopup'
import { FRONTEND_NAME } from "../Constants/constants";
class PlayCoins extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            currentTab: 'all',
            totalBalance: '0',
            dataList: [],
            enableLoader: true,
            logoutPopup: false,
            lables: constantURL.getLables(),
            message: ''

        }
        this.filterTab = this.filterTab.bind(this);
    }

    buycoins() {
        this.props.history.push(FRONTEND_NAME + "/buyplaycoins");

    }

    back() {
        // var name = this.props.location.state.gameScreen;
        this.props.history.push(FRONTEND_NAME + "/home");
    }

    filterTab(tab) {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
        this.setState({
            currentTab: tab
        })
        const headers = {
            'Content-Type': 'application/json',
            'appkey': metaMap.get('APPKEY'),
            'operator': metaMap.get('OPERATOR'),
            'access_token': sessionStorage.getItem("accessToken"),
            'msisdn': sessionStorage.getItem("username"),
            'deviceId': constantURL.DEVICE_ID
        };
        this.setState({ enableLoader: true })

        axios.get(constantURL.SPS_COMBINED_IP + "/v1/userRewardPassbook?rewardName=Coin&pagingLimit=100&pageNo=1", { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    var newuniqueDatesSet = new Set()

                    if (data.data.balance) {
                        this.setState({ totalBalance: data.data.balance.availableRewards })
                    }
                    let transactions = data.data.passbook;

                    var list = [];
                    if (transactions) {
                        for (var i = 0; i < transactions.length; i++) {
                            if (tab == "earned") {
                                if (transactions[i].operation === 'CREDIT') {
                                    list.push({
                                        amount: transactions[i].operationRewards,
                                        operation: transactions[i].operation,
                                        info: transactions[i].info,
                                        date: transactions[i].displayTransactionTime
                                    });
                                }
                            }
                            else if (tab == "spent") {
                                if (transactions[i].operation === 'DEBIT') {
                                    list.push({
                                        amount: transactions[i].operationRewards,
                                        operation: transactions[i].operation,
                                        info: transactions[i].info,
                                        date: transactions[i].displayTransactionTime
                                    });
                                }
                            } else {
                                list.push({
                                    amount: transactions[i].operationRewards,
                                    operation: transactions[i].operation,
                                    info: transactions[i].info,
                                    date: transactions[i].displayTransactionTime
                                });
                            }
                        }
                        if (list.length > 0) {
                            this.setState({ message: '', enableLoader: false, dataList: list });
                        } else {
                            this.setState({ message: this.state.lables.yourAccountHasntBeenCredited, enableLoader: false, dataList: list });
                        }
                    }
                } else {
                    this.setState({
                        message: this.state.lables.errorInFetchingDetails? this.state.lables.errorInFetchingDetails : 'Error in fetching details.',
                        enableLoader: false
                    });
                }
            });



    }

    componentDidMount() {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

        const headers = {
            'Content-Type': 'application/json',
            'appkey': metaMap.get('APPKEY'),
            'operator': metaMap.get('OPERATOR'),
            'access_token': sessionStorage.getItem("accessToken"),
            'msisdn': sessionStorage.getItem("username"),
            'deviceId': constantURL.DEVICE_ID

        };

        this.setState({ enableLoader: true });
        axios.get(constantURL.SPS_COMBINED_IP + "/v1/userRewardPassbook?rewardName=Coin&pagingLimit=100&pageNo=1", { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    if (data.data.balance) {
                        this.setState({ totalBalance: data.data.balance.availableRewards })
                    }
                    let transactions = data.data.passbook;

                    var list = [];
                    if (transactions) {
                        for (var i = 0; i < transactions.length; i++) {
                            list.push({
                                amount: transactions[i].operationRewards,
                                operation: transactions[i].operation,
                                info: transactions[i].info,
                                date: transactions[i].displayTransactionTime
                            });
                        }
                        if (list.length > 0) {
                            this.setState({
                                message: '',
                                enableLoader: false,
                                dataList: list
                            });
                        } else {
                            this.setState({ message: this.state.lables.yourAccountHasntBeenCredited, enableLoader: false, dataList: list });
                        }

                    }
                } else {
                    this.setState({
                        message: this.state.lables.errorInFetchingDetails? this.state.lables.errorInFetchingDetails : 'Error in fetching details.',
                        enableLoader: false
                    });
                }
            });
    }



    sessionOut() {
        this.props.history.push(FRONTEND_NAME + "/logout");
    }


    render() {
        const { lables } = this.state;
        var uniqueDatesSet = new Set();
        var row = [];
        for (let i = 0; i < this.state.dataList.length; i++) {
            uniqueDatesSet.add(this.state.dataList[i].date);
        }
        let datesArray = Array.from(uniqueDatesSet);


        var childList = [];

        for (let i = 0; i < datesArray.length; i++) {
            for (let j = 0; j < this.state.dataList.length; j++) {
                if (this.state.dataList[j].date === datesArray[i]) {

                    childList.push(
                        <div className="games-www">
                            <img src={this.state.dataList[j].operation == 'DEBIT' ? coinMinus : coinPlus} ></img>
                            <div className="for-line fl">
                                <div className="coin-p">
                                    <p className="coin-g-aaa">{this.state.dataList[j].info.replace('?', '₹')}</p>
                                </div>
                                <div className="winnings-coins">
                                    <p className={this.state.dataList[j].operation == 'DEBIT' ? 'red' : 'green'} >{this.state.dataList[j].operation == 'DEBIT' ? '-' + this.state.dataList[j].amount : '+' + this.state.dataList[j].amount}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
            }

            row.push(<div className="dates-div">
                <div className="dte-div">
                    <p className="show-d">{datesArray[i]}</p>
                    {childList}
                </div>
            </div>
            )

            childList = []
        }

        return (
            <div className="container-leaderboard overflow-auto">
                {this.state.enableLoader ?
                    <Loading />
                    : null
                }
                {
                    this.state.logoutPopup ?
                        <LogoutPopup
                            redirectPopup={this.sessionOut.bind(this)}
                        /> : null
                }


                <div className="header-leaderboard header-no-border copy-every pd-18">
                    <img src={back} className="float-lft" onClick={this.back.bind(this)} ></img>
                    <span className="header-text float-lft">Loyalty Coins</span>
                </div>
                <div className="content-leaderboard leader-no">
                    <div className="winnings-divs">

                        <div className="play-o">
                            <div className="bigPlus-left">

                                <div className="play-hh">
                                    <div className="playwindiv-1">
                                        <img className="imageplaywin" src={playWin} ></img>
                                    </div>
                                    <div className="playwindiv-2">
                                        <p className="hh-1">{this.state.totalBalance}</p>
                                        <p className="play-h">{lables.playCoinBalance}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="bigPlus-right">
                                <img onClick={this.buycoins.bind(this)} src={addition}></img>
                                <p className="addcoins-p" style={{ marginTop: "-4px", fontSize: '12px', color: '#e2b841' }}>Add Coins</p>
                            </div>
                        </div>
                        <p className="coin-history">{lables.coinsHistory}</p>

                        {/* <p className="coin-his">Coin History</p>

                        <div className="choose-play">
                            <div onClick={() => this.filterTab('all')} className={this.state.currentTab === "all" ? "choose-catg f" : "choose-catg-b f"}     >
                                <p>All</p>
                            </div>
                            <div onClick={() => this.filterTab('earned')} className={this.state.currentTab === "earned" ? "choose-catg s" : "choose-catg-b s"} >
                                <p>Earned</p>
                            </div>
                            <div onClick={() => this.filterTab('spent')} className={this.state.currentTab === "spent" ? "choose-catg t" : "choose-catg-b t"} >
                                <p>Spent</p>
                            </div>
                        </div> */}
                        {
                            this.state.dataList.length > 0 ?
                                row : <p className="norecord-coins">{this.state.message}</p>}

                    </div>
                </div>
            </div>


        )
    }
}

export default withRouter(PlayCoins);