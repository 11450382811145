import React, { createRef } from 'react'
import axios from 'axios';
import '../styles/style.css';
import { withRouter } from 'react-router'
import * as Constant from '../Constants/constants'
import * as Errorcode from '../Constants/errorConstants'
import Modal from 'react-modal';
import PopUp from './popup'
import back from '../images/back.png';
import Loading from './loading'
import $ from 'jquery';
import Cookies from 'universal-cookie';
import { FRONTEND_NAME } from '../Constants/constants';
import lottie from "lottie-web";
import animationData from "../lottie/login_otp_black_theme.json";

let animObj1 = null;

Modal.setAppElement('#root')

class EnterOtpPhase2 extends React.Component {

    constructor(props) {
        super(props)

        this.inputRef1 = createRef();
        this.inputRef2 = createRef();
        this.inputRef3 = createRef();
        this.inputRef4 = createRef();
        this.inputRef5 = createRef();
        this.inputRef6 = createRef();

        sessionStorage.setItem("otpCounts", 0)

        this.state = {
            username: this.props.location.state.username,
            userType: this.props.location.state.usertype,
            otpvalue:'',
            digit1: '',
            digit2: '',
            digit3: '',
            digit4: '',
            digit5: '',
            digit6: '',
            counter: 59,
            enableCounter: true,
            invalidOTP: false,
            showPopup: false,
            message: '',
            msgType: '',
            alert: '',
            hideButton: true,
            enableLoader: false,
            lables: Constant.getLables()
        }
        this.userRegex = /^\d+$/
    }

    back = () =>{
        this.props.history.push({
            pathname: FRONTEND_NAME + '/loginphase2',
            state: {
                userName: this.state.username,
                usertype: this.state.userType
            }
        });
    }

    componentDidMount() {

        if (sessionStorage.getItem('isLogin') == 'true') {
            this.props.history.push({
                pathname: FRONTEND_NAME + "/home"
            })
        }

        console.log('mob no. is ', this.state.username)
        this.startCountDownTimer()
        $("#1, #2, #3, #4, #5, #6").on("keyup keypress blur", function (event) {

            var elem = this;

            $(this).val($(this).val().replace(/[^0-9]/gi, ''));
            if (this.value.length > 1) {
                this.value = this.value.slice(0, 1);
            } setTimeout(function () {
                var key = event.keyCode || event.charCode;
                var isBackspace = (key == 8 || key == 46);
                if (isBackspace) {
                    if (elem.id == '1') {


                    }
                    else {
                        $("#" + (parseInt(elem.id) - 1)).focus();
                    }
                    return false;
                }
            }, 100);
        });


        animObj1 = lottie.loadAnimation({
            container: document.querySelector("#otp_lottie"), // the dom element that will contain the animation
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: animationData, // the path to the animation json
        });
    }

    startCountDownTimer = () => {
        this.otpInterval = setInterval(() => {
            this.setState(previousState => ({
                counter: previousState.counter - 1
            }))
        }, 1000)
    }

    togglePopup = (msg, msgType, alert, hideBtn) => {
        this.setState({
            showPopup: !this.state.showPopup,
            message: msg,
            msgType: msgType,
            alert: alert,
            hideButton: hideBtn
        })

    }

    handleChange = (prevInputRef, nextInputRef, event) => {
        console.log('-->>>')
        console.log(event)
        const fieldName = event.target.name

        var fieldValue = event.target.value
        console.log(fieldValue)

        if (fieldValue.length > 1) {
            fieldValue = fieldValue.slice(0, 1);
        }

        // if (!Number(fieldValue) && fieldValue != "") {
        //     return;
        // }
        this.setState({
            [fieldName]: fieldValue,
            invalidOTP: false
        }, () => {
            if (fieldValue === '') {
                //  console.log('KKAK')
                //  prevInputRef.current.focus()
            } else {
                nextInputRef.current.focus()
            }
        })
    }

    signInUser(userType) {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
    
        this.setState({ enableLoader: true })

        console.log('Inside ' + userType + ' SIGNIN API')
        const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SIGNING_API_PARAM}`
        console.log("calling signin with url " + url);


        const headers = {
            'Content-Type': Constant.APPLICATION_JSON,
            'Accept': Constant.APPLICATION_JSON,
            'deviceId': Constant.DEVICE_ID,
            'appid': metaMap.get('APP_ID'),
        };

        //this.setState({ msisdn: this.props.location.state.mydata.msisdn });

        const requestBody = {
            username: this.state.username,
            password: Constant.PASSWORD
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                console.log("signInUser response is : " + { data });
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                    console.log(data)
                    //alert("user has been validated successfully");
                    //After Session is created, user is redirected to Successful HomeScreen 
                    this.createLoginSession(data.data);
                    console.log('Redirecting ' + userType + ' to successful HOME screen... ')
                    this.openHomeScreen()
                    //this.openSetPassScreen();
                } else {
                    this.setState({
                        showPopup: true,
                        msgType: 'E',
                        msg: "Error in login!"
                    });
                }
                this.setState({ enableLoader: false })

            });
    }


    signUpUser(userType) {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
    
        this.setState({ enableLoader: true })

        console.log('Inside SignUp user')
        const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SIGNUP_API_PARAM}`;
        console.log("calling SignUp with url " + url);

        const headers = {
            'Content-Type': Constant.APPLICATION_JSON,
            'Accept': Constant.APPLICATION_JSON,
            'deviceId': Constant.DEVICE_ID,
            'appid': metaMap.get('APP_ID'),
        };

        const requestBody = {
            username: this.state.username,
            password: Constant.PASSWORD,
            operatorName: metaMap.get('OPERATOR')
        }

        axios.post(url, requestBody, {
            headers: headers
        })
            .then(response => response.data)
            .then(data => {

                console.log("SignUp Response : " + { data })
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                    console.log("SignUp is successfull, going for SignIn")
                    this.signInUser(userType)
                } else {
                    this.setState({
                        showPopup: true,
                        msgType: 'E',
                        msg: "Error in login!"
                    });
                }
                this.setState({ enableLoader: false })

            });
    }

    createLoginSession = (data) => {
        const cookies = new Cookies();
        const current = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(current.getFullYear() + 1);

        cookies.set('hardyIsLogin', true, { path: '/' });
        cookies.set('hardyAccessToken', data.user.accessToken, { path: '/' });
        cookies.set('hardyMobileNumber', data.user.username, { path: '/' });
        cookies.set('hardyOperator', data.user.operator.id, { path: '/' });
        cookies.set('hardyCountry', data.user.country.id, { path: '/' });
        cookies.set('hardyUsername', data.user.username, { path: '/' });


        sessionStorage.setItem("isLogin", 'true');
        sessionStorage.removeItem("isGuestLogin");
        sessionStorage.setItem("accessToken", data.user.accessToken)
        sessionStorage.setItem("mobileNumber", data.user.username)
        sessionStorage.setItem("operator", data.user.operator.id)
        sessionStorage.setItem("country", data.user.country.id)
        sessionStorage.setItem("username", data.user.username)
        if (data.user.nickName) {
            sessionStorage.setItem("nameSalutation", data.user.nickName)
            cookies.set('hardyNameSalutation', data.user.nickName, { path: '/' });

        }
        else {
            cookies.set('hardyNameSalutation', data.user.username, { path: '/' });
            sessionStorage.setItem("nameSalutation", data.user.username)
        }
        console.log(data.user.userImagePath)
        console.log(data.user.userImagePath == null)
        console.log(data.user.userImagePath == 'null')

        if (data.user.userImagePath != null) {
            sessionStorage.setItem("profileImg", data.user.userImagePath)
            cookies.set('hardyProfileImg', data.user.userImagePath, { path: '/' });

        }

        this.saveLogs(Constant.LOGIN_ACTION);

    }

    saveLogs(category) {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
    
        const cookies = new Cookies();
        var url = Constant.APPLOGGER_COMBINED_IP + "/v1/saveLogs";

        const headers = {
            'Content-Type': 'application/json',
            'appid': metaMap.get('APP_ID'),
            'operator': metaMap.get('OPERATOR'),
            'msisdn': sessionStorage.getItem("mobileNumber"),
            'channel': 'WAP'
        };

        const requestBody = {
            "category": category,
            "subCategory": "",
            "content": category,
            "url": "/login",
            "packname": "",
            "appname": Constant.APPLICATION_NAME,
            "sessionid": cookies.get('uniquevhgsession')
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                } else {
                }
            });
    }




    checkUserType = (data) => {
        const { lables } = this.state;
        var changePassword = sessionStorage.getItem('changePassword');
        if (changePassword != "true") {

            switch (data.screenFlag) {

                case Constant.HOME_SCREEN:
                    console.log("Inside HOME_SCREEN section : ")
                    // if (data.userType === Constant.NEW_USER || data.userType === Constant.EXISTING_USER) {

                        //After Session is created, user is redirected to Successful HomeScreen 
                        this.createLoginSession(data);
                        // if (this.state.userType === Constant.NEW_USER) {
                        //     this.togglePopup(Constant.OTP_VALIDATION_SUCCESS, 'S', Constant.REGISTRATION_SUCCESS_ALERT, true)
                        // }
                        // if (this.state.userType === Constant.EXISTING_USER) {
                        this.togglePopup(lables.changeYourAvatarInProfileSection, 'S', lables.loginSuccessfull, true)
                        // }
                        console.log('Popup has been toggled according to : ', this.state.userType)
                    // }
                    break;
                case Constant.SET_PWD_SCREEN:
                    console.log("Inside SET_PWD_SCREEN section : ")
                    if (data.userType === Constant.NEW_USER) {
                        console.log('Redirecting NEW user to SET PWD screen... Since there is No PWD Validation ' +
                            'Screen, so calling SIGNUP API internally for New user ...')
                        //Here, First User is Signedup and if Successful, Signed Up then User will be signedIN and
                        //session will be created and Then User is Redirected to successfull Home Screen!! 
                        //this.signUpUser(Constant.NEW_USER);

                        // this.openSetPassScreen();

                        this.createLoginSession(data);
                        this.togglePopup(lables.changeYourAvatarInProfileSection, 'S', lables.loginSuccessfull, true)


                    }
                    break;
                case Constant.VALIDATE_PWD_SCREEN:
                    console.log("Inside VALIDATE_PWD_SCREEN section : ")
                    if (data.userType === Constant.EXISTING_USER) {
                        console.log('Redirecting EXISTING user to VALIDATE PWD screen...Since there is No PWD Validation ' +
                            'Screen, so calling SIGNIN API internally for New user ...')
                        // this.signUpUser(Constant.EXISTING_USER);
                        //this.signInUser(Constant.EXISTING_USER);
                        this.openHomeScreen();
                    }
                    break;
                default:
                    console.log("Error Fetching userType from DB")
            }

        }
        else {
            this.openSetPassScreen();
        }


    }

    submitOtp = () => {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
        // 0: {keyName: 'APPKEY', valueName: 'vihardygames', id: 1}
        // 1: {keyName: 'COINSAPPKEY', valueName: 'BIGWINLOYALTY', id: 2}
        // 2: {keyName: 'APPKEY_BUY_COINS', valueName: 'BIGWINLOYALTYBUYCOINS', id: 3}
        // 3: {keyName: 'COUNTRY_CODE', valueName: '91', id: 4}
        // 4: {keyName: 'OPERATOR', valueName: 'vi', id: 5}
        // 5: {keyName: 'APP_ID', valueName: '102', id: 6}
        // 6: {keyName: 'APP_KEY_SUBS', valueName: 'vihardygames', id: 7}
        // 7: {keyName: 'VENDOR_KEY', valueName: 'hardygames', id: 8}
        // 8: {keyName: 'CAMPAIGN_ID', valueName: '20', id: 9}
        // 9: {keyName: 'SUB_CATEGORY', valueName: 'hardygamebigwin', id: 10}
        // 10: {keyName: 'APPLICATION_NAME', valueName: 'HardyGames', id: 11}
        // 11: {keyName: 'SESSION_ID_TIMEOUT', valueName: '3000', id: 12}
        // 12: {keyName: 'COUNTRY_NAME', valueName: 'India', id: 13}
    
        // 'operator':  metaMap.get('OPERATOR'),
    
        const { lables } = this.state;
        this.setState({ enableLoader: true })

        console.log('Inside submitOtp method')
        const url = `${Constant.PROFILER_COMBINED_IP}${Constant.VALIDATE_LOGIN_OTP_API_PARAM}`;
        console.log("calling VALIDATE_OTP with url " + url);


        const headers = {
            'appid':  metaMap.get('APP_ID'),
            'Content-Type': Constant.APPLICATION_JSON,
            'Accept': Constant.APPLICATION_JSON,
            'channel': 'wap',
            'operator':  metaMap.get('OPERATOR'),
        };

        const requestBody = {
            "username": this.state.username,
            "otp": this.state.otpvalue,
            "countryCode":  metaMap.get('COUNTRY_ID')
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                console.log("Response : " + { data })
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                    this.checkUserType(data.data)
                } else if (data && data.code === Errorcode.OTP_NOT_MATCH) {
                    console.log(data.msg);
                    this.setState({
                        invalidOTP: true
                    })
                } else {
                    //  console.log("Something went wrong with code : ", data.code)
                    this.togglePopup(lables.pleaseTryAfterSometime, 'E', lables.error, true)
                }
                this.setState({ enableLoader: false })

            });
    }

    changeHandler = (event) => {
        if (event.keyCode === 46) {
            console.log("inside changeHandler : ")
            return false
        }

        const otp = event.target.value.trim();
        if (!Number(otp) || !this.userRegex.test(otp)) {
            console.log("username inside: ", otp)
            if (otp === '') {
                this.setState({
                    otpvalue: ''
                })
            }
            return false;
        }
        console.log("username outside: ", otp)
        this.setState({
            otpvalue: otp,
        })
    }


    openLoginScreen = () => {
        this.props.history.push({
            pathname: FRONTEND_NAME + "/login",
            state: {
                userName: this.state.username
            }
        })
    }

    openHomeScreen = () => {
        sessionStorage.setItem("loginType", "normal");
        this.props.history.push({
            pathname: FRONTEND_NAME + '/home',
            state: {
                username: this.state.username
            }
        });
    }

    openSetPassScreen = () => {
        this.props.history.push({
            pathname: FRONTEND_NAME + '/setpassword',
            state: {
                username: this.state.username
            }
        });
    }

    resetTimer = (flag) => {
        clearInterval(this.otpInterval)
        this.setState({
            counter: 59,
            enableCounter: flag
        })
    }

    back() {
        this.props.history.push(FRONTEND_NAME + "/login");
    }



    resendOtp = (event) => {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
        
        const { lables } = this.state;
        if (!this.state.enableCounter) {

            this.setState({ enableLoader: true })

            console.log('Resend OTP username : ', this.state.username)

            const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SEND_OTP_API_PARAM}${this.state.username}&type=${Constant.RESEND_OTP}`;
            console.log("Url to call : ", url);

            const headers = {
                'appid': metaMap.get('APP_ID'),
                'deviceId': Constant.DEVICE_ID,
                'Content-Type': Constant.APPLICATION_JSON,
                'Accept': Constant.APPLICATION_JSON
            }

            // GET call to Submit Login or SignUp
            axios.get(url, { headers })
                .then(response => response.data)
                .then(data => {
                    console.log(data)
                    if (data && data.code === Errorcode.SUCCESS_CODE) {
                        console.log("OTP resend successfully with response ", data.msg)
                        this.resetTimer(true)
                        this.startCountDownTimer()

                    } else if (data && data.code === Errorcode.OTP_LIMIT_EXHAUSTED) {
                        //Resend OTP after 15 mins
                        console.log(data.msg);
                        this.togglePopup(this.state.lables.otpLimitExhausted, 'A', this.state.lables.otpLimitReached, true)
                    } else {
                        console.log(Constant.ERROR_OCCURED);
                        this.togglePopup(lables.errorInProcessingRequest, 'E', lables.error, true)
                    }
                    this.setState({ enableLoader: false })
                })
                .catch(error => {
                    console.log("Error Occured : ", error)
                    this.setState({ enableLoader: false })
                    this.togglePopup(lables.errorInProcessingRequest, 'E', lables.error, true)
                })
        }

    }

    // handleKeyPress(event){
    //     console.log('jkjkj')
    //     console.log(event.value)
    // }

    handleKeyUp(event) {
        var fieldValue = event.target.value;
        console.log(fieldValue)



        if (fieldValue.length > 1) {
            fieldValue = fieldValue.slice(0, 1);
        }

        if (!Number(fieldValue) && fieldValue != "") {
            return;
        }
    }

    render() {

        const { username, counter, otpvalue,  showPopup, message, msgType, enableCounter, alert,
            digit1, digit2, digit3, digit4, digit5, digit6, hideButton, invalidOTP, lables } = this.state

        // const enabled = (digit1 != '' && digit2 != '' && digit3 != '' &&
        //     digit4 != '')

        const enabled = (digit1 != '' && digit2 != '' && digit3 != '' &&
            digit4 != '')

        if (counter === 0 && enableCounter === true) {
            this.resetTimer(false)
        }

        return (
            <div className="container-login">
                    {
                    showPopup ?
                        <PopUp text={message} status={msgType} alertType={alert} hideButton={hideButton} closePopup={this.togglePopup, this.openHomeScreen} /> :
                        null
                }
                {this.state.enableLoader ?
                    <Loading />
                    : null
                }
            
                <div class="login-top">
                    <p class="back" onClick={this.back}>Back</p>
                    <div class="login-ani" id="otp_lottie">

                    </div>
                </div>
                <p class="login-msg">Please enter the OTP</p>
                <p class="login-note">As received on your shared mobile number</p>

                <div class="otp-input-section">
                    <input type="text" value={otpvalue}    onChange={this.changeHandler}  placeholder="Enter Code" />
                </div>
                {/* <p class="resend">Resend Code</p> */}
                { this.state.enableCounter?<p className="resend">{lables.resendOTPIn} {this.state.counter} {lables.sec? lables.sec:'secs'}</p>:<p className="resend" onClick={this.resendOtp}>{lables.sendAgainOTP}</p>}
                <p className="error-txt-otp" hidden={!invalidOTP}>{lables.invalidOtp}</p>
            
                <button onClick={this.submitOtp} class="login-btn">Log In</button>

            </div>
        )
    }
}

export default withRouter(EnterOtpPhase2);