import React from 'react'
import axios from 'axios';
import '../styles/profile.css';
import back from '../images/backNew.svg';
import logo from '../images/bigClown.svg';
import next from '../images/next.svg';
import SocialMedia from './socialMedia';
import { withRouter } from 'react-router'
import profileImg from '../images/profileImg.png';
import * as constantURL from '../Constants/constants'
import { FRONTEND_NAME } from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Loading from './loading'
import Popup from './popup';
import Cookies from 'universal-cookie';
import EditGenderModal from './editGenderModal';
import EditAddressModal from './editAddressModal';
import EditEmailModal from './editEmailModal';
import SinglePopup from './singlePopup';
import EditDobModal from './editDOBModal';
import EditMobileModal from './editMobileModal';


class ProfileEditNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: 'false',
            showGender: false,
            showAddress: false,
            showEmail: false,
            showDob: false,
            showPopup: false,
            msg: '', msgType: '', alert: '',
            gender: sessionStorage.getItem("gender"),
            address: sessionStorage.getItem("address"),
            email: sessionStorage.getItem("emailid"),
            dob: sessionStorage.getItem("dob"),
            mobNo: sessionStorage.getItem("username"),
            lables: constantURL.getLables(),
            countryDetails: {
                "alpha2code": "IN",
                "alpha3code": "en",
                "countryCode": "91",
                "countryFlag": "flags",
                "displayName": "India",
                "id": 1,
                "name": "India"
            },
                }

    }

    componentDidMount() {
        // alert(sessionStorage.getItem('mobileNumber'))
        sessionStorage.setItem('status', 'false');
        this.signInUser();
    }

    signInUser() {
        var url = '';

        if (sessionStorage.getItem("loginType") == "social") {
            url = constantURL.PROFILER_COMBINED_IP +
                "/v1/fetchUserDetailsSocial?username=" +
                sessionStorage.getItem("username");
        }
        else {
            url = constantURL.PROFILER_COMBINED_IP +
                "/v1/editUserDetails?username=" +
                sessionStorage.getItem("username");
        }

        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            msisdn: sessionStorage.getItem("username"),
            access_token: sessionStorage.getItem("accessToken"),
            appid: sessionStorage.getItem("appid"),
        };


        axios
            .get(url, {
                headers: headers,
            })
            .then((response) => response.data)
            .then((data) => {
                if (data && data.code == 2000) {
                    if (data.data.dateOfBirth) {
                        // var dob = data.data.dateOfBirth;
                        // var dob1 = dob.split("T");
                        // var dob2 = dob1[0].split("-");
                        sessionStorage.setItem("dob", data.data.dateOfBirth);
                    }
                    sessionStorage.setItem("gender", data.data.gender);
                    sessionStorage.setItem("emailid", data.data.email);
                    sessionStorage.setItem("address", data.data.address);
                } else {
                }
            });
    }




    saveDetails() {
        const { lables } = this.state;
        this.setState({ enableLoader: true })

        const headers = {
            'Content-Type': 'application/json',
            'appid': sessionStorage["appid"],
            'access_token': sessionStorage.getItem("accessToken"),

        };
        const data1 = new FormData();

        console.log('-=-=-=-');
        console.log(this.state.dob);
        console.log('-=-=-=-');
        // alert(this.state.mobNo)
        data1.append('email', this.state.email == 'null' || this.state.email == null ? '' : this.state.email);
        data1.append('address', this.state.address == 'null' || this.state.address == null ? '' : this.state.address);
        data1.append('gender', this.state.gender == 'null' || this.state.gender == null ? '' : this.state.gender);
        data1.append('dateOfBirth', this.state.dob == 'null' || this.state.dob == null ? '' : this.state.dob);
        // if (sessionStorage.getItem("loginType") == "social") {
        //     data1.append('mobileNumber', this.state.mobNo == 'null' || this.state.mobNo == null ? '' : this.state.countryDetails.countryCode + '' + this.state.mobNo);
        // }
        data1.append('username', sessionStorage.getItem("username"));

        console.log(this.state.dob)
        console.log(sessionStorage.getItem("username"));
        // console.log(this.state.mobNo)
        console.log(this.state.email)
        // const body={
        //     name:this.state.name,
        //     username:sessionStorage.getItem("mobileNumber")
        // }

        var url = '';
        if (sessionStorage.getItem("loginType") == "social") {
            url = constantURL.PROFILER_COMBINED_IP + "/v1/updateUserDetailsWithFileSocial";
        }
        else {
            url = constantURL.PROFILER_COMBINED_IP + "/v1/updateUserDetailsWithFile";
        }

        axios.post(url, data1, { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    this.saveLogs('EDIT_PROFILE')
                    sessionStorage.setItem("gender", this.state.gender)
                    sessionStorage.setItem("emailid", this.state.email)
                    sessionStorage.setItem("address", this.state.address)
                    if (sessionStorage.getItem("loginType") == "social") {

                        if (this.state.mobNo == 'null' || this.state.mobNo == null) {
                            sessionStorage.setItem("loginType", "social");
                        } else {
                            sessionStorage.setItem("loginType", "social");
                            sessionStorage.setItem("username", this.state.mobNo);
                            // this.regenerateSession(this.state.mobNo);
                        }
                        // sessionStorage.setItem("mobNo", this.state.mobNo)
                    }
                    sessionStorage.setItem("dob", this.state.dob)

                    this.setState({
                        showPopup: true,
                        msgType: 'S',
                        msg: lables.dataUpdatedSuccessfully,
                        alert: lables.profileUpdated
                    })



                } else if (data && (data.code == errorConstantURL.ONLY_CHARACTER_CODE)) {

                    this.setState({
                        showPopup: true,
                        msgType: 'E',
                        msg: data.msg,
                        alert: lables.errorInProcessingRequest
                    })

                    // this.props.closeNameEdit('');
                } else if (data && (data.code == errorConstantURL.OFFENSIVE_CODE)) {
                    this.setState({
                        showPopup: true,
                        msgType: 'E',
                        msg: data.msg,
                        alert: lables.errorInProcessingRequest
                    })
                    // this.props.closeNameEdit('');
                } else {
                    this.setState({
                        showPopup: true,
                        msgType: 'E',
                        msg: lables.errorInProcessingRequest,
                        alert: 'Error!'
                    })
                    // this.props.closeNameEdit('');
                }
                this.setState({ enableLoader: false })

            });
    }

    regenerateSession(msisdn) {

        const cookies = new Cookies();

        var url = constantURL.PROFILER_COMBINED_IP + "/v1/loginbypass";

        var headers = null;
        headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            msisdn: msisdn,
            appid: constantURL.APP_ID,
        };

        axios
            .get(url, {
                headers: headers,
            })
            .then((response) => response.data)
            .then((data) => {
                if (data && data.code == 2000) {
                    var userDetail = data.data;
                    this.createLoginSession(userDetail);
                }
            });
    }

    createLoginSession(data) {
        const cookies = new Cookies();
        const current = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(current.getFullYear() + 1);

        cookies.set("hardyIsLogin", true, { path: "/" });
        cookies.set("hardyAccessToken", data.accessToken, { path: "/" });
        cookies.set("hardyMobileNumber", data.username, { path: "/" });
        cookies.set("hardyOperator", data.operator.id, { path: "/" });
        cookies.set("hardyCountry", data.country.id, { path: "/" });
        cookies.set("hardyUsername", data.username, { path: "/" });

        sessionStorage.setItem("isLogin", 'true');
        sessionStorage.removeItem("isGuestLogin");
        sessionStorage.setItem("accessToken", data.accessToken);
        sessionStorage.setItem("mobileNumber", data.username);
        sessionStorage.setItem("operator", data.operator.id);
        sessionStorage.setItem("country", data.country.id);
        sessionStorage.setItem("username", data.username);
        if (data.dateOfBirth) {
            var dob = data.dateOfBirth;
            var dob1 = dob.split("T");
            var dob2 = dob1[0].split("-");
            sessionStorage.setItem("dob", dob2[2] + "/" + dob2[1] + "/" + dob2[0]);
        }
        sessionStorage.setItem("gender", data.gender);
        sessionStorage.setItem("emailid", data.email);
        sessionStorage.setItem("address", data.address);

        if (data.nickName) {
            sessionStorage.setItem("nameSalutation", data.nickName);
            cookies.set("hardyNameSalutation", data.nickName, { path: "/" });
        } else {
            sessionStorage.setItem("nameSalutation", data.username);
            cookies.set("hardyNameSalutation", data.username, { path: "/" });
        }
        if (data.userImagePath != null) {
            sessionStorage.setItem("profileImg", data.userImagePath);
            cookies.set("hardyProfileImg", data.userImagePath, { path: "/" });
        }
        // this.saveLogs(constantURL.LOGIN_ACTION);
    }



    saveLogs(category) {
        const cookies = new Cookies();
        var url = constantURL.APPLOGGER_COMBINED_IP + "/v1/saveLogs";

        const headers = {
            "Content-Type": "application/json",
            appid: constantURL.APP_ID,
            operator: constantURL.OPERATOR,
            msisdn: sessionStorage.getItem("username"),
            channel: "WAP",
        };

        const requestBody = {
            category: category,
            subCategory: "",
            content: category,
            url: "/editprofile",
            packname: "",
            appname: constantURL.APPLICATION_NAME,
            sessionid: cookies.get("uniquevhgsession"),
        };

        axios
            .post(url, requestBody, { headers })
            .then((response) => response.data)
            .then((data) => {
                if (data && data.code == errorConstantURL.SUCCESS_CODE) {
                } else {
                }
            });
    }


    editProfile() {
        const isLogin = sessionStorage.getItem("isLogin");
        console.log('EDIT')
        if (isLogin) {
            this.setState({ status: 'forward' });
            sessionStorage.setItem('status', 'forward');
            this.props.history.push(FRONTEND_NAME + "/editprofile");
            console.log('User already registered')
        } else {
            this.setState({ status: 'forward' });
            sessionStorage.setItem('status', 'forward');
            this.props.history.push(FRONTEND_NAME + "/editprofile");
            //  this.props.history.push(FRONTEND_NAME+"/login");
        }
    }

    editAddress() {
        this.setState({ showAddress: true });
    }

    editMobileNumber() {
        if (sessionStorage.getItem("loginType") == "social") {
            this.setState({ showMobileNo: true });
        }
    }

    editDob() {
        this.setState({ showDob: true });
    }

    editEmail() {
        this.setState({ showEmail: true });
    }

    editGender() {
        this.setState({ showGender: true });
    }


    togglePopup() {
        this.setState({
            showAddress: false,
            showDob: false,
            showMobileNo: false,
            showEmail: false,
            showGender: false,
            showPopup: false
        });

    }

    setGender(gender) {
        console.log('KK-> ' + gender)
        this.setState({ gender: gender, showGender: false })
    }

    setEmail(email) {
        this.setState({ email: email, showEmail: false })
    }


    setAddress(address) {
        console.log('KK-> ' + address)
        this.setState({ address: address, showAddress: false })
    }

    setMobNo(mobNo) {
        console.log('KK-> ' + mobNo)
        sessionStorage.setItem('mobNo', mobNo);
        // this.setState({ mobNo: mobNo, showMobileNo: false })
    }

    setCountryDetails(countryDetails) {
        console.log(countryDetails)
        this.setState({ countryDetails: countryDetails });
    }


    setDate(date) {
        console.log('PARENT: ' + date)
        this.setState({ dob: date, showDob: false })
    }

    componentWillUnmount() {
        if (sessionStorage.getItem('status') == 'false') {
            this.props.history.push(FRONTEND_NAME + "/profile");
        }
    }

    back() {
        if (sessionStorage.getItem('status') == 'false') {
            this.props.history.push(FRONTEND_NAME + "/profile");
        }
    }

    render() {
        const { lables } = this.state;
        return (
            <div className="container-profile" style={{ padding: '0px' }}>
                <img src={back} onClick={this.back.bind(this)} className="p-back-1"></img>
                <span className="header-text float-lft mt14">Edit Profile</span>

                {this.state.enableLoader ?
                    <Loading /> : null
                }
                {this.state.showPopup ?
                    <SinglePopup
                        status={this.state.msgType}
                        text={this.state.msg}
                        alertType={this.state.alert}
                        closePopup={this.togglePopup.bind(this)}
                    /> : null
                }

                {this.state.showEmail ?
                    <EditEmailModal setEmail={this.setEmail.bind(this)} closePopup={this.togglePopup.bind(this)}
                    /> : null}

                {this.state.showDob ?
                    <EditDobModal setDate={this.setDate.bind(this)} closePopup={this.togglePopup.bind(this)} /> : null}

                {this.state.showMobileNo ?
                    <EditMobileModal countryDetails={this.state.countryDetails} setCountryDetails={this.setCountryDetails.bind(this)} setMobNo={this.setMobNo.bind(this)} closePopup={this.togglePopup.bind(this)} /> : null}

                {this.state.showAddress ?
                    <EditAddressModal setAddress={this.setAddress.bind(this)} closePopup={this.togglePopup.bind(this)} /> : null}

                {this.state.showGender ?
                    <EditGenderModal setGender={this.setGender.bind(this)} closePopup={this.togglePopup.bind(this)} /> : null}

                <div className="menu-new" style={{ padding: '16px 16px', height: '60px', clear: 'both', marginTop: "52px" }} onClick={this.editProfile.bind(this)}>
                    <div className="mn-left">
                        <span style={{ marginTop: '4px' }}>{lables.avatar}</span>
                        <span><img src={sessionStorage.getItem("profileImg") ? sessionStorage.getItem("profileImg") || logo : logo} ref={img => this.img = img} onError={() => this.img.src = logo} style={{ marginTop: '-7px', width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover' }}></img></span>
                    </div>
                    <div className="mn-right mn-pd">
                        <img src={next}></img>
                    </div>
                </div>
                <div style={{ height: '10px' }}></div>
                <div className="menu-new" onClick={this.editEmail.bind(this)}>
                    <div className="mn-left">

                        <span>{lables.email}</span>
                        <span>{
                            this.state.email != 'null' ? this.state.email : (sessionStorage.getItem("emailid") == 'null' ? '' : sessionStorage.getItem("emailid"))}</span>
                    </div>
                    <div className="mn-right">
                        <img src={next}></img>
                    </div>
                </div>
                <div className="menu-new" onClick={this.editGender.bind(this)}>
                    <div className="mn-left">
                        <span>{lables.gender}</span>
                        <span>{
                            this.state.gender != 'null' ? this.state.gender : (
                                sessionStorage.getItem("gender") == 'null' ? '' : sessionStorage.getItem("gender"))}</span>
                    </div>
                    <div className="mn-right">
                        <img src={next}></img>
                    </div>
                </div>
                <div className="menu-new" onClick={this.editDob.bind(this)}>
                    <div className="mn-left">
                        <span>{lables.birthday}</span>
                        <span>{this.state.dob != 'null' ? this.state.dob : (sessionStorage.getItem("dob") == 'null' ? '' : sessionStorage.getItem("dob"))}</span>
                    </div>
                    <div className="mn-right">
                        <img src={next}></img>
                    </div>
                </div>
                <div className="menu-new" onClick={this.editAddress.bind(this)}>
                    <div className="mn-left">
                        <span>{lables.address}</span>
                        <span className="address-span">{this.state.address != 'null' ? this.state.address : (sessionStorage.getItem("address") == 'null' ? '' : sessionStorage.getItem("address"))}</span>
                    </div>
                    <div className="mn-right">
                        <img src={next}></img>
                    </div>
                </div>

                <div className="menu-new" onClick={this.editMobileNumber.bind(this)} >
                    <div className="mn-left">
                        <span>{lables.mobile ? lables.mobile : 'Mobile'}</span>
                        <span className="address-span">{
                            (sessionStorage.getItem('mobNo') && sessionStorage.getItem('mobNo') != 'null') ? sessionStorage.getItem('mobNo').substring(0, 2) +
                                "XXXXXXXX" +
                                sessionStorage.getItem('mobNo').substring(10, 13)
                                :
                                sessionStorage.getItem('mobNo') != 'null' ? sessionStorage.getItem('mobNo') : null
                        }</span>
                    </div>
                    <div className="mn-right">
                        {(sessionStorage.getItem("loginType") == "social") ? <img src={next}></img> : null}
                    </div>
                </div>

                <button className="sd-btn" onClick={this.saveDetails.bind(this)}>{lables.saveDetails}</button>
            </div>
        )
    }
}

export default withRouter(ProfileEditNew);
