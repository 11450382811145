import React, { Component, createRef } from 'react'
import { withRouter } from 'react-router'
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import axios from 'axios';
import Loading from './loading'
import $ from 'jquery';
import checked from '../images/checked.png';
import Cookies from 'universal-cookie';
import error from '../images/redError.png';

class EditEmailModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showEditName: false,
            showEditImage: false,
            errorMsg: '',
            errorFlag: 'd',
            name: '',
            email: '',
            userN: '',
            enableLoader: false,
            logoutPopup: false,
            disable: true,
            lables: constantURL.getLables()
        }
        this.handleKeyUp = this.handleKeyUp.bind(this);
    }

    componentDidMount() {
        this.setState({
            email: sessionStorage.getItem("emailid") == 'null' ? '' : sessionStorage.getItem("emailid"),
        })
    }


    handleKeyUp(event) {

        this.setState({
            email: event.target.value,
            //  name: event.target.value
        });

        const { lables } = this.state;

        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value)) {
            this.setState({
                errorFlag: 'n',
                email: event.target.value,
                errorMsg: '',
                disable: false
            });

        } else {
            this.setState({
                errorFlag: 'e',
                email: event.target.value,
                errorMsg: lables.enterValidEmailId,
                disable: true
            });
        }
    }

    setEmail() {
        if (!this.state.disable) {
            this.props.setEmail(this.state.email)
            sessionStorage.setItem("emailid", this.state.email);
        };
    }

    render() {
        const { lables } = this.state;
        return (
            <div className="modal-e">
                <div className="edit-info">
                    <div className="cancel" style={{ borderBottom: '1px solid rgba(255,255,255,0.15)', padding: '22px 16px 22px 16px' }}>
                        <p className="cancel-pe" onClick={this.props.closePopup}>{lables.cancel}</p>
                        <p className="done-e" onClick={() => this.setEmail()}>{lables.done}</p>
                    </div>

                    <div className="enter-info">
                        <div className="what-e">
                            <input type="text" ref="searchStringInput" value={this.state.email} onChange={this.handleKeyUp} maxLength="60" placeholder={lables.enterYourEmail}  ></input>
                        </div>
                    </div>
                    <p className="error-e-new">{this.state.errorMsg}</p>
                </div>
            </div>
        )
    }
}

export default withRouter(EditEmailModal);