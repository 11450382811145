import React from 'react'
import axios from 'axios';
import '../styles/popup.css'
//  import  TickGreen from '../images/tick-green.png'
import infoAlert from '../images/alertyellow.svg'
import infoError from '../images/alertyellow.svg'
import infoSuccess from '../images/infoSuccess.png'
import { withRouter } from 'react-router'
import { getLables } from '../Constants/constants';
import {FRONTEND_NAME} from '../Constants/constants'

class LogoutPopup extends React.Component {

    constructor(props){
        super(props);
        this.state={
            lables : getLables()
        }
    }

    render() {
        const {lables} = this.state;
        return (
            <div id="apply-modal" className="modal">
                <div className="modal-content">
                    <div className="modal-heading">
                        <img src={infoError} alt="" id="tick" />
                        <p className="popup-p">{lables.error}</p>
                    </div>
                    <div className="apply-modal-header">
                        <p>{lables.sessionIsExpired}</p>
                    </div>
                    <div className="modal-close">
                    <button className="popup-btn" onClick={this.props.redirectPopup}>{lables.ok}</button>
                    </div>
                    {/* <div className="modal-close">
                        <button className="popup-btn">CANCEL</button>
                        <button className="popup-btn">CONFIRM</button>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default withRouter(LogoutPopup);