import React from 'react'
import axios from 'axios';
import '../styles/help.css';
import { withRouter } from 'react-router'
import back from '../images/back.png';
import profileImage from '../images/profileImage.png';
import viewAll from '../images/viewAll.png';
import plusPlay from '../images/plusPlay.png'
import minusPlay from '../images/minusPlay.png'
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Loading from './loading'
import LogoutPopup from './logoutPopup'
import {FRONTEND_NAME} from '../Constants/constants'

class Help extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			header1: '',
			header2: '',
			header3: '',
			header4: '',
			header4Tel:'',
			header5: '',
			helpList: [],
			enableLoader: true,
			logoutPopup: false
		}
	}

	componentDidMount() {

		const headers = {
			'Content-Type': 'application/json',
			'appid': constantURL.APP_ID,
			'deviceId': constantURL.DEVICE_ID,
			'operator': constantURL.OPERATOR,
			'Access_token': sessionStorage.getItem("accessToken"),
			'channel': 'wap',
			'Msisdn': sessionStorage.getItem("username")
		};


		axios.get(constantURL.CONTENT_COMBINED_IP + "/v1/fetchSettings?settingKey=help", { headers }).then(response => response.data)
			.then((data) => {
				if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
					var section1 = data.data.categories[0];
					var section2 = data.data.categories[1];
					this.setState({
						header1: section1.title,
						header2: section1.content[0].keyString,
						header3: section1.content[1].keyString,
						header4: section1.content[1].keyValue,
						header5: section2.title,
						header4Tel: "tel:"+section1.content[1].keyValue
					})
					var list = [];
					console.log(section2)
					for (var i = 0; i < section2.content.length; i++) {
						list.push({
							keyString: section2.content[i].keyString,
							keyValue: section2.content[i].keyValue,
							keyAction: section2.content[i].action,
						});
					}
				}else{
                    this.setState({logoutPopup: true })
                }
				this.setState({ helpList: list })
				this.setState({enableLoader:false})

			});
	}

	back() {
		this.props.history.push(FRONTEND_NAME+"/profile");
	}

	sessionOut() {
        this.props.history.push(FRONTEND_NAME+"/logout");
    }


	render() {
		var helpRow = [];
		this.state.helpList.forEach(function (help) {
			var hrefUrl="mailto:"+help.keyValue
		
			helpRow.push(<div>
				<p className="write-black">{help.keyString}</p>
				<p className="write-blue">{(help.keyAction=='email')? <a href={hrefUrl}>{help.keyValue}</a> : help.keyValue}</p>
			
			</div>)
		}.bind(this));

		return (
			<div class="container-leaderboard">
				{this.state.enableLoader ?
					<Loading />
					: null
				}
				{
                    this.state.logoutPopup ?
                        <LogoutPopup
                            redirectPopup={this.sessionOut.bind(this)}
                        /> : null
                }

				<div className="header-leaderboard">
					<img src={back} alt="" onClick={this.back.bind(this)} className="float-lft"></img>
					<span className="header-text float-lft">HELP</span>
				</div>
				<div className="content-faq">
					<div className="help-div">


						<div class="contact">

							<p className="contact-heading">{this.state.header1}</p>
							<div className="contact-content">
								<p className="black">{this.state.header2}</p>
								<p className="blue"><span class="customer_care">{this.state.header3}</span><a href={this.state.header4Tel}>{this.state.header4}</a></p>
								</div>
						</div>


						<div className="write-us">
							<p className="write-heading">{this.state.header5}</p>
							<div className="write-content">

								{helpRow}

							</div>
						</div>
					</div>
				</div>
			</div>

		)
	}
}

export default withRouter(Help);