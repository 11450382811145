import React, { Component, PureComponent } from 'react';
import { withRouter } from 'react-router';
import backIcon from '../images/backIcon.svg';
import aboutIcon from '../images/aboutIcon.svg'
import openIcon from '../images/openIcon.svg';
import termsIcon from '../images/termsIcon.svg';
import contactIcon from '../images/contactIcon.svg';
import createHomeIcon from '../images/createHomeIcon.svg';
import expandIcon from '../images/expandIcon.svg';
import collapse from '../images/collapse.svg';

import axios from 'axios';
import * as constantURL from '../Constants/constants'
import * as Constant from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Loading from './loading'
import Popup from './popup';
import Cookies from 'universal-cookie';
import { FRONTEND_NAME } from '../Constants/constants'
import { logEvents } from "./Analytics";
import localForage from 'localforage'


class Support extends PureComponent {

    constructor(props) {
        super(props);
        let lablesData = JSON.parse(sessionStorage["lablesData"]);
        this.state = {
            expandContact: false,
            statFlag: false,
            enableLoader: false,
            showPopup: false,
            lables: constantURL.getLables(),
            activeLang: sessionStorage["lang"],
            activeLangData: lablesData.dialectSupported.filter((item) => item.dialect.languagekey == sessionStorage["lang"])[0]
        }
    }


    componentDidMount() {
        sessionStorage.setItem("mobNo", sessionStorage.getItem("mobileNumber"));

        // if('serviceWorker' in navigator) {
        //     window.addEventListener('load', () => {
        //         navigator.serviceWorker.register('../serviceworker-bigwin.js')
        //             .then((reg) => console.log('Success: ', reg.scope))
        //             .catch((err) => console.log('Failure: ', err));
        //     })
        // }

        // window.location.reload();
        if(!window.location.hash) {
            window.location = window.location + '#loaded';
            window.location.reload();
        }
        sessionStorage.setItem('status', 'false');

        let deferredPrompt;
        const addBtn = document.querySelector('#createicon');
        addBtn.style.display = 'none';
        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            deferredPrompt = e;
            // Update UI to notify the user they can add to home screen
            addBtn.style.display = 'block';

            addBtn.addEventListener('click', (e) => {
                // hide our user interface that shows our A2HS button
                addBtn.style.display = 'none';
                // Show the prompt
                deferredPrompt.prompt();
                // Wait for the user to respond to the prompt
                deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                        console.log('User accepted the A2HS prompt');
                        this.saveDownloadLogs("A2HS", "CUSTOM", "ADDED");
                    } else {
                        this.saveDownloadLogs("A2HS", "CUSTOM", "DISMISSED");
                    }
                    deferredPrompt = null;
                });
            });
        });

    }

    saveDownloadLogs = (content, category, subCategory) => {
        const cookies = new Cookies();
        var url = Constant.APPLOGGER_COMBINED_IP + "/v1/saveDownloadLogs";

        const headers = {
            'Content-Type': 'application/json',
            'appid': Constant.APP_ID,
            'operator': Constant.OPERATOR,
            'msisdn': sessionStorage.getItem("username"),
            'channel': 'WAP'
        };

        const requestBody = {
            "content_name": content,
            "session_id": cookies.get('uniquevhgsession'),
            "country": "India",
            "circle": "All",
            "packname": "A2HS",
            "appname": Constant.APPLICATION_NAME,
            "content_type": category,
            "param1": subCategory,
            "param2": "",
            "substatus": '',
            "winningStatus": '',
            "gameStatus": "Success",
            "response": "Success",
            "dwn_flag": "0"
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == errorConstantURL.SUCCESS_CODE) {
                } else {
                }
            });
    }


    back = () => {
        sessionStorage.setItem('status', 'forward');
        this.props.history.push(FRONTEND_NAME + "/profile");
    }

    expandContact = () => {
        this.setState({
            expandContact: !this.state.expandContact
        })
    }

    showTnc(){
        this.saveLogs('TNC');
        sessionStorage.setItem('status', 'forward');
        this.props.history.push(FRONTEND_NAME + "/tnc");
    }

    openAboutUs(){
        this.saveLogs('ABOUT_US');
        sessionStorage.setItem('status', 'forward');
        this.props.history.push(FRONTEND_NAME + "/aboutUs");
        
    }


    saveLogs(category) {
        const cookies = new Cookies();
        var url = constantURL.APPLOGGER_COMBINED_IP + "/v1/saveLogs";
    
        const headers = {
          "Content-Type": "application/json",
          appid: constantURL.APP_ID,
          operator: constantURL.OPERATOR,
          msisdn: sessionStorage.getItem("username"),
          channel: "WAP",
        };
    
        const requestBody = {
          category: category,
          subCategory: "",
          content: category,
          url: "/menu",
          packname: "",
          appname: constantURL.APPLICATION_NAME,
          sessionid: cookies.get("uniquevhgsession"),
        };
    
        axios
          .post(url, requestBody, { headers })
          .then((response) => response.data)
          .then((data) => {
            if (data && data.code == errorConstantURL.SUCCESS_CODE) {
            } else {
            }
          });
      }
    
      openContactUs(){
        this.saveLogs('CONTACT_US');
        window.open('mailto:hardygame@paytm.com?subject=Hardy Games');
        
    }



    render() {
        return <div className="support-container">
            <div className="support-head">
                <img src={backIcon} onClick={this.back} alt="" />
                <p>Support</p>
            </div>
            <div className="support-item" onClick={this.openAboutUs.bind(this)}>
                <img src={aboutIcon} />
                <span>About</span>
                <img src={openIcon} />
            </div>
            <div className="support-item" onClick={this.showTnc.bind(this)}>
                <img src={termsIcon} />
                <span>Terms of service</span>
                <img src={openIcon} />
            </div>
            <div onClick={this.expandContact} className={this.state.expandContact ? 'support-item support-expandedItem' : 'support-item'}>
                <img src={contactIcon} />
                <span>Contact us</span>
                <img  src={this.state.expandContact ? collapse : expandIcon} />
                {this.state.expandContact && <div className="contact-details">
                    <p>For any query or suggestions you can mail us at <a href="#" onClick={this.openContactUs.bind(this)}>hardygame@paytm.com</a></p>
                </div>}
            </div>
            <div className="support-item" id="createicon">
                <img src={createHomeIcon} />
                <span>Create Home Icon</span>
                <img src={openIcon} />
            </div>
            <div className="support-item">
                <span style={{ marginLeft: '0px' }}>Version 1.0.1</span>
                <span>
                    {/* Check for updates */}
                    </span>
            </div>
        </div>;
    }
}
export default withRouter(Support)

