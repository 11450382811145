import React from 'react'
import axios from 'axios';
import '../styles/phase2.css';
import { withRouter, Redirect } from 'react-router'
import * as Constant from '../Constants/constants'
import * as Errorcode from '../Constants/errorConstants'
import Loading from "./loading";
// import Carousel from "react-simply-carousel";
import $ from 'jquery';
import Cookies from 'universal-cookie';
import * as constantURL from '../Constants/constants'
import { FRONTEND_NAME } from '../Constants/constants'
import firebase from 'firebase';
import { socialAppName } from './SocialLogin'
import games from "../images/hardy.svg";
import lottie from "lottie-web";
import animationData from "../lottie/Pingpong_black_theme.json";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
 
import MagicSliderDots from 'react-magic-slider-dots';
import 'react-magic-slider-dots/dist/magic-dots.css';

let animObj1 = null;

class SocialLoginPage extends React.Component {


    constructor(props) {
        super(props)

        this.state = {
            username: this.props.location.state === undefined ? '' : this.props.location.state.userName,
            showPopup: false,
            message: '',
            msgType: '',
            alert: '',
            hideButton: true,
            error: false,
            enableLoader: false,
            showCross: false,
            lables: Constant.getLables(),
            socialLoginList: [],
            socialBanner: [],
            activeSlideIndex: 0,
  

        }

    }

    componentDidMount() {
        this.getMetaInfo('999999999', '91', false, true, false);       //IS_WIFI // SOCIAL_LOGIN
        sessionStorage.removeItem('loginType')
        sessionStorage.setItem("isLogin", "false");
        this.getSocialLoginList();

        animObj1 = lottie.loadAnimation({
            container: document.querySelector("#bbat_lottie"), // the dom element that will contain the animation
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: animationData, // the path to the animation json
        });
    }


    setActiveSlideIndex = (newActiveSlideIndex) => {
        this.setState({
            activeSlideIndex: newActiveSlideIndex,
        });
    };

    getBanners() {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

        const headers = {
            'Content-Type': 'application/json',
            'appid': metaMap.get('APP_ID'),
            'operator': metaMap.get('OPERATOR'),
            'channel': 'wap',
            'access_token': sessionStorage.getItem("accessToken"),
            'msisdn': sessionStorage.getItem("username"),
            'deviceId': constantURL.DEVICE_ID,

        };

        axios.get(constantURL.CONTENT_COMBINED_IP + "/v1/getBanners?bannerType=loginBanner", { headers }).then(response => response.data)
            .then((data) => {
                if (data && data.code == 2000) {
                    console.log(data);
                    this.setState({ socialBanner: data.data.contentFetched })
                }
            });

    }

    openManualLoginScreen = () => {
        this.props.history.push({
            pathname: FRONTEND_NAME + "/loginphase2"
        })
    }


    getMetaInfo(msisdn, countrCode, isWifi, isSocialLogin, isRedirect) {
        this.setState({ enableLoader: true });
        const headers = {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'msisdn': msisdn,
            'ip': '127.0.0.1',
        };
        const cookies = new Cookies();

        const url = constantURL.PROFILER2_COMBINED_IP + "/v3/getMetaInfo?isWifi=" + isWifi + "&gameType=HardyGames&countryCode=" + countrCode + "&isSocialLogin=" + isSocialLogin;
        axios.get(url, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data);
                let map = new Map();
                if (data && data.code == 2000) {
                    this.setState({ enableLoader: false });

                    var metaInfoData = data.data.metaKeyValue;
                    console.log((data.data.metaKeyValue));

                    for (var i = 0; i < metaInfoData.length; i++) {
                        map.set(metaInfoData[i].keyName, metaInfoData[i].valueName);
                    }

                    var jsonText = JSON.stringify(Array.from(map.entries()));
                    sessionStorage.setItem('metaInfo', jsonText);

                    this.getBanners();

                    if (isRedirect) {
                        window.location.replace(constantURL.BIG_WIN_HOME_URL + cookies.get('hardyMobileNumber'));
                    }
                }
                this.setState({ enableLoader: false });

            })
            .catch(err => {
                this.setState({ enableLoader: false });

            })
    }

    getSocialLoginList() {
        const headers = {
            Appid: 17
        };

        axios.get(constantURL.PROFILER3_COMBINED_IP + "/v1/loginList", { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == 2000)) {
                    console.log(data.data.loginList)
                    this.setState({ socialLoginList: data.data.loginList });
                }
            });

    }

    onLoginClick(authType) {

        const googleLogin = new firebase.auth.GoogleAuthProvider();
        const facebookLogin = new firebase.auth.FacebookAuthProvider();
        var twitterLogin = new firebase.auth.TwitterAuthProvider();

        if (authType === "Google")
            this.loginOAuth(googleLogin);
        else if (authType === "Facebook")
            this.loginOAuth(facebookLogin);
        else if (authType === "Twitter")
            this.loginOAuth(twitterLogin);
    }


    loginByPassSocial(msisdn) {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
        var url = constantURL.PROFILER3_COMBINED_IP + "/v1/socialLogin";

        var headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            'access-token': msisdn,
            appid: metaMap.get('APP_ID'),
        };
        console.log(headers)

        axios
            .get(url, {
                headers: headers,
            })
            .then((response) => response.data)
            .then((data) => {
                if (data && data.code == 2000) {
                    var userDetail = data.data.user;
                    console.log(userDetail)
                    this.createLoginSession(userDetail);
                }
            });
    }

    createLoginSession(data) {
        const cookies = new Cookies();
        const current = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(current.getFullYear() + 1);

        sessionStorage.removeItem("isGuestLogin");
        sessionStorage.setItem("accessToken", data.accessToken);
        sessionStorage.setItem("mobileNumber", data.mobileNumber);

        if (data.dateOfBirth) {
            var dob = data.dateOfBirth;
            var dob1 = dob.split("T");
            var dob2 = dob1[0].split("-");
            sessionStorage.setItem("dob", dob2[2] + "/" + dob2[1] + "/" + dob2[0]);
        }
        sessionStorage.setItem("gender", data.gender);
        sessionStorage.setItem("emailid", data.email);
        sessionStorage.setItem("address", data.address);

        sessionStorage.setItem("nameSalutation", data.firstName + ' ' + data.lastName)
        cookies.set("hardyNameSalutation", data.firstName + ' ' + data.lastName, { path: "/" });

        if (data.userImagePath != null) {
            sessionStorage.setItem("profileImg", data.userImagePath);
            cookies.set("hardyProfileImg", data.userImagePath, { path: "/" });
        }


        if (data.mobileNumber == null || data.mobileNumber == 'null' || data.mobileNumber == '') {
            cookies.set("hardyIsLogin", true, { path: "/" });
            cookies.set("hardyAccessToken", data.accessToken, { path: "/" });
            cookies.set("hardyMobileNumber", data.username, { path: "/" });
            cookies.set("hardyUsername", data.username, { path: "/" });

            sessionStorage.setItem("isLogin", "true");
            sessionStorage.setItem("loginType", "social");
            sessionStorage.setItem("username", data.username);

            this.fetchHomeData(data.username);

        } else {
            cookies.set("hardyIsLogin", false, { path: "/" });
            cookies.set("hardyAccessToken", data.accessToken, { path: "/" });
            cookies.set("hardyMobileNumber", data.mobileNumber, { path: "/" });
            cookies.set("hardyUsername", data.mobileNumber, { path: "/" });

            sessionStorage.setItem("isLogin", "true");
            sessionStorage.setItem("loginType", "normal");
            sessionStorage.setItem("username", data.mobileNumber);

            this.getMetaInfo(data.mobileNumber, data.operator.country.countryCode, true, false, true);
        }
    }


    fetchHomeData(username) {
        this.props.history.push({
            pathname: FRONTEND_NAME + '/home',
            state: {
                username: username
            }
        });
    }


    loginOAuth(loginProvider) {

        firebase.auth(socialAppName).signInWithPopup(loginProvider)
            .then((r) => {
                console.log(r);
                firebase.auth(socialAppName).currentUser.getIdToken().then(res => {
                    console.log(res);
                    sessionStorage.setItem("socialLogin-token", res);
                    sessionStorage.setItem("user", JSON.stringify(r.additionalUserInfo.profile));
                    sessionStorage.setItem("isLogin", "true");
                    sessionStorage.setItem("loginType", "social");
                    this.loginByPassSocial(res);

                })
                    .catch(e => {
                        console.log(e);
                    });
            })
            .catch((c) => {
                console.log(c);
            });
    }

    socialLoginButtons() {
        // alert('A')
        return this.state.socialLoginList.map(data => {
            if (data.enable) {
                return (
                    <img className="socialLoginButton"
                        src={data.imageUrl} alt={data.serviceProvider} onClick={this.onLoginClick.bind(this, data.serviceProvider)} />
                )
            }
        });
    }


    saveLogs(category) {

        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
        var d = new Date();
        var n = d.getMilliseconds();

        var url = constantURL.APPLOGGER_COMBINED_IP + "/v1/saveLogs";

        const headers = {
            'Content-Type': 'application/json',
            'appid': metaMap.get('APP_ID'),
            'operator': metaMap.get('OPERATOR'),
            'msisdn': '',
            'channel': 'WAP'
        };

        const requestBody = {
            "category": category,
            "subCategory": "",
            "content": category,
            "url": "/login",
            "packname": "",
            "appname": metaMap.get('APPLICATION_NAME'),
            "sessionid": "login_user" + n + "-" + Math.random(),
            "operator": "",
            "circle": "",
            "country": "INDIA"

        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == 2000) {
                } else {
                }
            });
    }



    signInUser(userType) {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

        console.log('Inside ' + userType + ' SIGNIN API')
        const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SIGNING_API_PARAM}`
        console.log("calling signin with url " + url);
        this.setState({ enableLoader: true })

        const headers = {
            'Content-Type': Constant.APPLICATION_JSON,
            'Accept': Constant.APPLICATION_JSON,
            'deviceId': Constant.DEVICE_ID,
            'appid': metaMap.get('APP_ID'),
        };

        //this.setState({ msisdn: this.props.location.state.mydata.msisdn });

        const requestBody = {
            username: this.state.username,
            password: Constant.PASSWORD
        }

        axios.post(url, requestBody, {
            headers: headers
        })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                    console.log(data)

                    //alert("user has been validated successfully");
                    const transfer = {
                        msisdn: data.data.username,
                        operator: data.data.operator.id,
                        country: data.data.country.id,
                        userName: data.data.username,
                        accessToken: data.data.accessToken
                    }
                    //After Session is created, user is redirected to Successful HomeScreen 
                    this.createLoginSession(transfer, data.data);
                    console.log('Redirecting ' + userType + ' to successful HOME screen... ')
                    this.openHomeScreen()

                } else {
                    this.setState({
                        showModal: true,
                        msgType: 'E',
                        msg: "Error in login!"
                    });
                }
                this.setState({ enableLoader: false })

            });
    }


    signUpUser() {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

        console.log('Inside SignUp user')
        const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SIGNUP_API_PARAM}`;
        console.log("calling SignUp with url " + url);
        this.setState({ enableLoader: true })

        const headers = {
            'Content-Type': Constant.APPLICATION_JSON,
            'Accept': Constant.APPLICATION_JSON,
            'deviceId': Constant.DEVICE_ID,
            'appid': metaMap.get('APP_ID'),
        };

        const requestBody = {
            username: this.state.username,
            password: Constant.PASSWORD,
            operatorName: metaMap.get('MOB_OPERATOR')
        }

        axios.post(url, requestBody, {
            headers: headers
        })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                    console.log("SignUp is successfull, going for SignIn")
                    this.signInUser('NEW USER')
                } else {
                    this.setState({
                        showModal: true,
                        msgType: 'E',
                        msg: "Error in login!"
                    });
                }
                this.setState({ enableLoader: false })

            });
    }


    checkUserType = (data) => {
        //  ;
        var changePassword = sessionStorage.getItem('changePassword');
        if (changePassword != "true") {
            switch (data.screenFlag) {
                case Constant.OTP_SCREEN:
                    console.log("Inside OTP_SCREEN section : ")
                    if (data.userType === Constant.NEW_USER) {

                        console.log('Redirecting ' + data.userType + ' to OTP screen... ')
                        this.openEnterOtpPage(data.userType)
                    }
                    else if (data.userType === Constant.EXISTING_USER) {
                        // this.openEnterPassPage(data.userType);
                        this.openEnterOtpPage(data.userType)

                    }
                    break;
                case Constant.SET_PWD_SCREEN:
                    console.log("Inside SET_PWD_SCREEN section : ")
                    if (data.userType === Constant.NEW_USER) {
                        console.log('Redirecting NEW user to SET PWD screen... Since there is No PWD Validation ' +
                            'Screen, so calling SIGNUP API internally for New user ...')
                        //  this.openSetPassPage(data.userType)
                        this.openEnterOtpPage(data.userType)

                    }
                    else if (data.userType === Constant.EXISTING_USER) {
                        this.openEnterOtpPage(data.userType)

                        // this.openEnterPassPage(data.userType);
                    }
                    break;
                case Constant.VALIDATE_PWD_SCREEN:
                    console.log("Inside VALIDATE_PWD_SCREEN section : ")
                    if (data.userType === Constant.EXISTING_USER) {
                        console.log('Redirecting EXISTING user to VALIDATE PWD screen...Since there is No PWD Validation ' +
                            'Screen, so calling SIGNIN API internally for New user ...')
                        //this.signInUser('EXISTING USER');
                        // this.openEnterPassPage(data.userType);
                        this.openEnterOtpPage(data.userType)

                    }
                    break;
                default:
                    console.log("Error Fetching userType from DB")
            }
        }
        else {

            console.log("go to otp page");
            this.openEnterOtpPage(data.userType);

        }




    }


    submitHandler = (event) => {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

        event.preventDefault()
        console.log('username : ', this.state.username)
        if (!this.msisdnRegex.test(this.state.username)) {
            this.setState({
                error: true
            })
            console.log('from submit after : ', this.state.error)
        } else {
            this.setState({ enableLoader: true })

            const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SUBMIT_LOGIN_OR_SIGNUP_API_PARAM}`;
            console.log("Url to call : ", url);

            const headers = {
                'appid': metaMap.get('APP_ID'),
                'Content-Type': Constant.APPLICATION_JSON,
                'Accept': Constant.APPLICATION_JSON
            }

            const requestBody = {
                'username': this.state.username,
                'countryCode': metaMap.get('COUNTRY_CODE'),
            }

            // POST call to Submit Login or SignUp
            axios.post(url, requestBody, { headers })
                .then(response => response.data)
                .then(data => {
                    console.log(data)
                    if (data && data.code === Errorcode.SUCCESS_CODE) {
                        //  ;
                        console.log("output ", data);
                        console.log("userType is ", data.data.userType)
                        console.log("And screenFlag is ", data.data.screenFlag)

                        this.checkUserType(data.data);

                    } else if (data && data.code === Errorcode.OTP_LIMIT_EXHAUSTED) {
                        //Resend OTP after 15 mins
                        console.log("OTP Limit Exhausted with msg : ", data.msg);
                        this.togglePopup(this.state.lables.otpLimitExhausted, 'A', Constant.OTP_LIMIT_REACHED, true)
                    } else {
                        //Add Modal for Failure Case
                        console.log("Technical Error, Something went Wrong!!");
                    }

                    this.setState({ enableLoader: false })

                })
                .catch(error => {
                    this.setState({ enableLoader: false })

                    //Add Modal for Error Case 
                    console.log("Error Occured : ", error)
                })
        }

    }

    changeHandler = (event) => {
        console.log("inside handleListener : key is ", event.key)
        console.log("inside handleListener : keyCode is ", event.keyCode)
        console.log("input value is ", event.target.value)
        if (event.keyCode === 46) {
            console.log("inside changeHandler : ")
            return false
        }

        const userName = event.target.value.trim();
        console.log("username : ", userName)
        console.log("userName.includes(.) : ", userName.includes("."))
        console.log("this.userRegex.test(userName) : ", this.userRegex.test(userName))
        if (!Number(userName) || !this.userRegex.test(userName) || (userName.length >= 10 && this.state.username.length === 10)) {
            console.log("username inside: ", userName)
            if (userName === '') {
                this.setState({
                    username: ''
                })
            }
            return false;
        }
        console.log("username outside: ", userName)
        this.setState({
            username: userName,
            error: false,
            showCross: userName.length > 0
        })

    }

    openEnterOtpPage = (type) => {

        this.props.history.push({
            pathname: FRONTEND_NAME + '/loginotp',
            state: {
                username: this.state.username,
                usertype: type
            }
        });
    }

    openSetPassPage = (type) => {

        this.props.history.push({
            pathname: FRONTEND_NAME + '/setpassword',
            state: {
                username: this.state.username,
                usertype: type
            }
        });
    }
    openEnterPassPage = (type) => {
        //  ;
        this.props.history.push({
            pathname: FRONTEND_NAME + '/enterpassword',
            state: {
                username: this.state.username,
                usertype: type
            }
        });
    }


    openHomeScreen = () => {
        this.props.history.push({
            pathname: FRONTEND_NAME + '/home',
            state: {
                username: this.state.username
            }
        });
    }

    togglePopup = (msg, msgType, alert, hideBtn) => {
        this.setState({
            showPopup: !this.state.showPopup,
            message: msg,
            msgType: msgType,
            alert: alert,
            hideButton: hideBtn
        })

    }

    back() {
        this.props.history.push(FRONTEND_NAME + "/home");
    }

    render() {
        
        const settings = {
            dots: true,
            arrows: false,
            autoplay:true,
            infinite: true,
            speed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            appendDots: dots => {
              return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />;
            }
          };

          
        let socialBannerRow = [];
        this.state.socialBanner.forEach(
            function (list) {
                socialBannerRow.push(
                    <p class="ads-txt">{list.desciption}</p>
                );
            }.bind(this)
        );


        return (
            <div className="container-login">
                {this.state.enableLoader ? <Loading /> : null}

                <div class="logo">
                    <img src={games} alt="" />
                </div>
                <div class="pingpong" id="bbat_lottie">

                </div>
                
                <div>
                {/* <Carousel
                    activeSlideIndex={this.state.activeSlideIndex}
                    onRequestChange={this.setActiveSlideIndex}
                    itemsToShow={1}
                    itemsToScroll={1}
                >
                  {socialBannerRow}
                </Carousel> */}
                 <Slider {...settings}>
                 {socialBannerRow}
                 </Slider>
                </div>
                {/* <p class="dots">
                    <span class="dot1 dot active"></span>
                    <span class="dot2 dot"></span>
                    <span class="dot3 dot"></span>
                    <span class="dot4 dot"></span>
                </p> */}
                <div class="social-btns">
                    <button class="ggl-btn" onClick={this.onLoginClick.bind(this, "Google")}>Sign in with Google</button>
                    <button class="fb-btn" onClick={this.onLoginClick.bind(this, "Facebook")}>Sign in with Facebook</button>
                    <button class="phn-btn" onClick={this.openManualLoginScreen}><span>Or use </span>Phone</button>
                </div>

                {/* {this.socialLoginButtons()} */}
            </div>
        )
    }
}

export default withRouter(SocialLoginPage);