import React from 'react'
import '../styles/style.css';
import { withRouter } from 'react-router'
import close from '../images/back.svg';
import buyCoin from '../images/globe.svg';
import sm from '../images/coinsiconredemption.svg';
import back from '../images/backNew.svg';
import {FRONTEND_NAME, getLables} from '../Constants/constants'
class RedemptionCenter extends React.Component {

    constructor (props){
        super(props);
        this.state={
            lables:getLables()
        }
    }
    back() {
        this.props.history.push(FRONTEND_NAME+"/profile");

    }

    openpaidpage(){
         var category = {

            categoryId :1346,
            categoryName : "Paid Games"
          }

          if(sessionStorage["lang"] == "ar"){
            category = {

                categoryId :1346,
                categoryName : "الألعاب المدفوعة"
              }
          }

          category.fromHome = true;
          this.props.history.push({
            pathname: FRONTEND_NAME + "/paidgames",
            state: {
              currentCategory: category,
            },
          });
        

    }

    render() {
        const {lables} = this.state;
        return (
            <div class="container-question">
                <div className="header-b-pc" style={{ marginTop: '14px' }}>
                    <img src={close} onClick={this.back.bind(this)} style={{ float: 'left', marginRight: '22px', marginTop: '6px' }}></img>
                    <p>{lables.redemptionCenter}</p>
                </div>

                <div className="content-b-pc" style={{ marginTop: '10%', paddingTop: '0px' }}>
                    <p className="pck-txt">{lables.choosePackOfPref}</p>
                    <table className="b-pc-table">
                        <tr className="tdd">
                            <td className="td-one"><img src={buyCoin}></img></td>
                            <td className="td-two">100 {lables.mbData}</td>
                            <td className="td-three">
                                <div><img src={sm}></img> 100</div>
                            </td>
                        </tr>
                        <tr className="tdd">
                            <td className="td-one"><img src={buyCoin}></img></td>
                            <td className="td-two">200 {lables.mbData}</td>
                            <td className="td-three">
                                <div><img src={sm}></img> 200</div>
                            </td>
                        </tr>

                        <tr className="tdd">
                            <td className="td-one" colSpan='3' style={{ paddingRight: '16px' }}><img onClick={this.openpaidpage.bind(this)} src={lables.rcStrip} style={{ width: '100%', height: '64px' }}></img></td>
                        </tr>

                        <tr className="tdd">
                            <td className="td-one"><img src={buyCoin}></img></td>
                            <td className="td-two b-top" style={{ marginTop: '-1px' }}>400 {lables.mbData}</td>
                            <td className="td-three b-top">
                                <div><img src={sm}></img> 300</div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

        )
    }
}

export default withRouter(RedemptionCenter);