import React from 'react'
import axios from 'axios';
import '../styles/notification.css';
import { withRouter } from 'react-router'
import back from '../images/backNew.svg';
import notify from '../images/notify.png';
import * as Constant from '../Constants/constants';
import * as Errorcode from '../Constants/errorConstants'
class Notification extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            username: sessionStorage.getItem("username"),
            notificationData : [],
            showModal: false,
            enableShare: false,
            enableLoader : true,
            showPopup: false,
			message: '',
			msgType: '',
			msgTitle: '',
            lables : Constant.getLables()
        }

        this.notificationIndex = 0
    }

     componentDidMount(){

        const url = `${Constant.GET_USER_NOTIFICATION_API_URL}`;
		const headers = {
			'Content-Type': Constant.APPLICATION_JSON,
			'Accept': Constant.APPLICATION_JSON,
			'deviceId': sessionStorage.getItem("deviceId"),
			'channel': 'web'
        }
        
        const requestBody = {
            "msisdn":sessionStorage.getItem("username"),   
            "deviceId":sessionStorage.getItem("deviceId"),
            "adhoc":true, 
            "appName":"Generichardygames"
            // "appName":"Tambola"

        } 



        axios.post(url, requestBody, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data)
                this.processNotificationResponse(data);
		    });

    }

    processNotificationResponse = (data) => {

		if (data && (data.code == Errorcode.SUCCESS_CODE)) {
            console.log("Notification API success response with message : ", data.message)
            if(data.data.response.length !== 0){
                this.setState({
                    notificationData : data.data.response,
                    enableLoader : false
                })

            }else if(data.data.response.length === 0){
                console.log("No records found for notification, showing no notification screen")
                this.setState({
                    enableLoader: false
                })
                // this.openNoNotificationScreen()
            }
			//this.setUpdateUserDetails()
		} else {
            console.log("Error code from API : ", data.code, "with message : ", data.message)
            this.setState({
				enableLoader: false
			})
			// this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT)
		}
    }

    updateNotification = (notification) => {
        const url = `${Constant.GET_USER_NOTIFICATION_UPDATE_API_URL}`;
		const headers = {
			'Content-Type': Constant.APPLICATION_JSON,
			'Accept': Constant.APPLICATION_JSON,
			'deviceId': sessionStorage.getItem("deviceId"),
            'channel': 'web',
            'access_token': sessionStorage.getItem("accessToken"),
        
        }

        const requestBody = {
            'deviceId': sessionStorage.getItem("deviceId"),
            "msisdn":sessionStorage.getItem("username"),   
            "adhoc":true, 
            "appName":"Generichardygames",
            "id":notification.id,
            "action":"seen"
            // "appName":"Tambola"

        } 



        axios.post(url, requestBody, { headers }).then(response => response.data)
            .then((data) => {
                
                const url = `${Constant.GET_USER_NOTIFICATION_API_URL}`;
                const headers = {
                    'Content-Type': Constant.APPLICATION_JSON,
                    'Accept': Constant.APPLICATION_JSON,
                    'deviceId': sessionStorage.getItem("deviceId"),
                    'channel': 'web'
                }
                
                const requestBody = {
                    "msisdn":sessionStorage.getItem("username"),   
                    "deviceId":sessionStorage.getItem("deviceId"),
                    "adhoc":true, 
                    "appName":"Generichardygames"
                    // "appName":"Tambola"
        
                } 
        
        
        
                axios.post(url, requestBody, { headers }).then(response => response.data)
                    .then((data) => {
                        console.log(data)
                        this.processNotificationResponse(data);
                    });
        
		    });



    };

    alterDate(timestamp){
        console.log(timestamp+' <<<')

        var todate=new Date(timestamp).getDate();
        var tomonth=new Date(timestamp).getMonth()+1;
        var toyear=new Date(timestamp).getFullYear();
        var toHour=new Date(timestamp).getHours();
        var toMin=new Date(timestamp).getMinutes();
        var original_date=todate+'-'+tomonth+'-'+toyear +' '+ toHour+':'+ toMin;
        return original_date;
    }

    back(){
        var name = this.props.location.state.gameScreen;
        this.props.history.push(Constant.FRONTEND_NAME+"/"+name);     
    }
    
    render() {

        if(this.state.notificationData && this.state.notificationData.length !==0){
            var displayAllNotifications = this.state.notificationData.map((notification) => {
                return (
                    <div key={this.notificationIndex ++} onClick={() => this.updateNotification(notification)}  className=  {notification.isSeen==0? "n-ticket mar-top n-ticket-unread": "n-ticket   mar-top "}>
                    <div className="n-head">{notification.title}</div>
                    <div className="n-detail">
                        <p>{notification.message}</p>
                    {notification.imagePath?<img src={notification.imagePath}></img>:null }       
                    </div>
                    <div className="n-time">{this.alterDate(notification.scheduleDate)} </div>
                </div>
                )
            })
        }

        const {lables} = this.state;
        return (
            <div className="container-prize overflow-noti"> 
			    <div className="header-leaderboard notification-header copy-every h-extra-it">
				    <img src={back} onClick={this.back.bind(this)}  className="float-lft"></img>	
				    <span className="header-text float-lft">{lables.notification}</span>		
			    </div>
                {/*  */}
                <div className="content-notification">
                    {displayAllNotifications && displayAllNotifications.length !==0 ? displayAllNotifications: <p class="no-notification">{lables.noNotifications?lables.noNotifications:'No notifications.'}</p>}
                     {/* <div className="n-ticket">
                        <div className="n-head">Hardy Games (OTP)</div>
                        <p className="n-detail">
                            Your One Time Password(OTP) is 4535. Please don’t share it with anyone. 
                            We don’t call/email you to verify OTP. OTP is valid for 15mins.     
                        </p>
                        <div className="n-time">Now</div>
                       
                    </div> 
                     <div className="n-ticket mar-top ">
                        <div className="n-head">Gods of Boom</div>
                        <div className="n-detail">
                            <p>Register for free and win coins. Hurry Now!</p>
                            <img src={notify}></img>   
                        </div>
                        <div className="n-time">41m ago</div>
                    </div> */}

                    {/* <div className="n-ticket mar-top ">
                        <div className="n-head">Gods of Boom</div>
                        <div className="n-detail">
                            <p>Register for free and win coins. Hurry Now!</p>
                            <img src={notify}></img>   
                        </div>
                        <div className="n-time">41m ago</div>
                    </div>  */}

                </div> 
			</div>
)
}
}

export default withRouter(Notification);