import React, { Component, createRef } from 'react'
import { withRouter } from 'react-router'
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import axios from 'axios';
import Loading from './loading'
import $ from 'jquery';
import checked from '../images/checked.png';
import Cookies from 'universal-cookie';
import error from '../images/redError.png';

class EditNameModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showEditName: false,
            showEditImage: false,
            errorMsg: '',
            errorFlag: 'd',
            name: '',
            userNTemp: '',
            userN: '',
            enableLoader: false,
            logoutPopup: false
        }
        this.handleKeyUp = this.handleKeyUp.bind(this);
    }

    componentDidMount() {
        this.setState({
            userN: sessionStorage.getItem("nameSalutation")=="null"? "":sessionStorage.getItem("nameSalutation"),
            userNTemp: sessionStorage.getItem("nameSalutation")=="null"? "":sessionStorage.getItem("nameSalutation"),
            name: sessionStorage.getItem("nameSalutation")=="null"? "":sessionStorage.getItem("nameSalutation"),
        })
    }

    handleKeyUp(event) {

        this.setState({
            userNTemp: event.target.value,
            //  name: event.target.value
        });

        if (event.target.value.length >= 4) {
            this.setState({
                errorFlag: 'n',
                name: event.target.value,
                errorMsg: ''
            });
        } else {
            this.setState({
                errorFlag: 'e',
                name: event.target.value,
                errorMsg: 'Minimum 4 characters required'
            });
        }
    }


  saveLogs(category) {
      
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

    const cookies = new Cookies();
    var url = constantURL.APPLOGGER_COMBINED_IP + "/v1/saveLogs";

    const headers = {
      "Content-Type": "application/json",
      appid: metaMap.get('APP_ID'),
      operator: metaMap.get('OPERATOR'),
      msisdn: sessionStorage.getItem("username"),
      channel: "WAP",
    };

    const requestBody = {
      category: category,
      subCategory: "",
      content: category,
      url: "/editname",
      packname: "",
      appname: metaMap.get('APPLICATION_NAME'),
      sessionid: cookies.get("uniquevhgsession"),
    };

    axios
      .post(url, requestBody, { headers })
      .then((response) => response.data)
      .then((data) => {
        if (data && data.code == errorConstantURL.SUCCESS_CODE) {
        } else {
        }
      });
  }



    updateName() {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
    
        const cookies = new Cookies();
        const current = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(current.getFullYear() + 1);
        console.log('=-==')
        this.setState({ enableLoader: true })

        if (this.state.name.length < 4) {
            this.setState({ enableLoader: false })
            return false;
        } else {
            const headers = {
                'Content-Type': 'application/json',
                'appid': metaMap.get('APP_ID'),
                'access_token': sessionStorage.getItem("accessToken"),

            };

            const data1 = new FormData();
            data1.append('username', sessionStorage.getItem("username"));
            data1.append('nickName', this.state.name);


            var url = '';
            if (sessionStorage.getItem("loginType") == "social") {
                url = constantURL.PROFILER_COMBINED_IP + "/v1/updateUserDetailsWithFileSocial";
            }
            else {
                url = constantURL.PROFILER_COMBINED_IP + "/v1/updateUserDetailsWithFile";
            }

            axios.post(url, data1, { headers }).then(response => response.data)
                .then((data) => {
                    if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                        cookies.set('hardyNameSalutation', this.state.name, { path: '/' });
                        this.saveLogs('EDIT_NAME');
                        sessionStorage.setItem("nameSalutation", this.state.name)
                        this.setState({
                            userN: this.state.name,
                            showEditName: false
                        })

                        this.props.closeNameEdit(this.state.name);


                    } else if (data && (data.code == errorConstantURL.ONLY_CHARACTER_CODE)) {
                        this.setState({
                            errorFlag: 'e',
                            errorMsg: 'Only characters are allowed.'
                        })
                        // this.props.closeNameEdit('');
                    } else if (data && (data.code == errorConstantURL.OFFENSIVE_CODE)) {
                        if (data.msg.trim() == 'Phone number is not valid') {
                            this.props.logoutUser();
                        } else {
                            this.setState({
                                errorFlag: 'e',
                                errorMsg: data.msg
                            })
                            this.props.closeNameEdit('');
                        }
                    } else {
                        this.setState({
                            errorFlag: 'e',
                            errorMsg: 'Error in operation.'
                        })
                        // this.props.closeNameEdit('');
                    }
                    this.setState({ enableLoader: false })

                });

        }
    }

    close() {
        this.props.closeNameEdit('');

    }

    render() {
        return (
            <div className="edit-info">
                <div className="cancel">
                    <p className="cancel-p" onClick={this.close.bind(this)}>Cancel</p>
                    <p className="done" onClick={this.updateName.bind(this)}>Done</p>
                </div>

                <div className="enter-info">
                    <div className="what-e">
                        <input type="text" ref="searchStringInput" value={this.state.userNTemp} onChange={this.handleKeyUp} maxLength="25" placeholder="Enter Your Nickname"  ></input>
                        {/* <p className="max-e">25/25</p> */}
                        {this.state.errorFlag == 'n' ? <img src={checked} ></img> : this.state.errorFlag == 'e' ? <img src={error} ></img> : null}
                    </div>
                    <p className="error-e">{this.state.errorMsg}</p>
                </div>
            </div>
        )
    }
}

export default withRouter(EditNameModal);