import React from 'react'
import axios from 'axios';
import { withRouter } from 'react-router'
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import queryString from 'query-string'
import Cookies from 'universal-cookie';
import '../styles/home.css';
import PopupNew from './popupNew';
import Popup from './popup';
import {FRONTEND_NAME, APPLICATIONS_IP_ADRESS} from '../Constants/constants'
class Redirect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bundleGamesBanner: [],
            gamesDetails: [],
            showPopup: false,
            showPopupOld: false,
            isShowSpin: false,
            msg: '',
            msgType: 'S',
            enableLoader: true,
            isConfirm: 'N',
            loginRedirect: 'N',
            instantGamesId: '',
            bigWinGamesId: '',
            displayButtons: [],
            currentSelectedGame: {},
            isNeedToCheckSTW: false,
            isShowSpin: false,
            gametype: ''

        }
    }

    subsHeaders = {
        'appKey': constantURL.APP_KEY_SUBS,
        'operator': constantURL.OPERATOR,
        'Msisdn': sessionStorage.getItem("username"),
        'channel': constantURL.CHANNEL,
        'Access_token': sessionStorage.getItem("accessToken"),
        'deviceId': constantURL.DEVICE_ID,
        'Content-Type': constantURL.APPLICATION_JSON,
        'Accept': constantURL.APPLICATION_JSON
    }
    componentDidMount() {
        this.createSession();
    }

    createSession() {
        const values = queryString.parse(this.props.location.search)
        const msisdn = values.msisdn;
        const type = values.type;
        const contentid = values.contentid;

        const cookies = new Cookies();
        const isLogin = sessionStorage.getItem("isLogin");
        if (isLogin == null || isLogin == false) {
            //Check if msisdn is in URL header. if yes then login by pass else guest login
            // if (constantURL.ENVIRONMENT != 'PROD') {
            // const values = queryString.parse(this.props.location.search)
            // var msisdn = values.msisdn;

            if (typeof msisdn === "undefined") {
                this.loginByPass('empty', type);
            } else {
                this.loginByPass(msisdn, type);
            }



            // if (msisdn) {
            //     this.loginByPass(msisdn, type);
            // }
            // else {
            //     if (cookies.get('hardyIsLogin')) {
            //         sessionStorage.setItem("isLogin", true);
            //         sessionStorage.setItem("accessToken", cookies.get('hardyAccessToken'))
            //         sessionStorage.setItem("mobileNumber", cookies.get('hardyMobileNumber'))
            //         sessionStorage.setItem("operator", cookies.get('hardyOperator'))
            //         sessionStorage.setItem("country", cookies.get('hardyCountry'))
            //         sessionStorage.setItem("username", cookies.get('hardyUsername'))
            //         sessionStorage.setItem("nameSalutation", cookies.get('hardyNameSalutation'))
            //         this.redirect(type);
            //     } else {
            //         this.props.history.push(FRONTEND_NAME+"/login")
            //     }
            // }
            // } else {
            //     this.loginByPass('empty', type);
            // }
        } else {
            this.redirect(type);
        }
    }

    loginByPass(msisdn, type) {
        const cookies = new Cookies();

        var url = constantURL.PROFILER_COMBINED_IP + "/v1/loginbypass";

        var headers = null;
        if (msisdn == 'empty') {
            headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'appid': constantURL.APP_ID,
            };
        } else {
            headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'msisdn': msisdn,
                'appid': constantURL.APP_ID,
            };
        }

        axios.get(url, {
            headers: headers
        })
            .then(response => response.data)
            .then((data) => {
                if (data && data.code == 2000) {
                    var userDetail = data.data;
                    this.createLoginSession(userDetail, type);
                } else {
                    if (cookies.get('hardyIsLogin')) {
                        sessionStorage.setItem("isLogin", 'true');
                        sessionStorage.setItem("accessToken", cookies.get('hardyAccessToken'))
                        sessionStorage.setItem("mobileNumber", cookies.get('hardyMobileNumber'))
                        sessionStorage.setItem("operator", cookies.get('hardyOperator'))
                        sessionStorage.setItem("country", cookies.get('hardyCountry'))
                        sessionStorage.setItem("username", cookies.get('hardyUsername'))
                        sessionStorage.setItem("nameSalutation", cookies.get('hardyNameSalutation'))

                        this.redirect(type);
                    } else {
                        this.props.history.push(FRONTEND_NAME+"/login")
                    }
                }
            });
    }

    createLoginSession(data, type) {

        const cookies = new Cookies();
        const current = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(current.getFullYear() + 1);

        cookies.set('hardyIsLogin', true, { path: '/' });
        cookies.set('hardyAccessToken', data.accessToken, { path: '/' });
        cookies.set('hardyMobileNumber', data.username, { path: '/' });
        cookies.set('hardyOperator', data.operator.id, { path: '/' });
        cookies.set('hardyCountry', data.country.id, { path: '/' });
        cookies.set('hardyUsername', data.username, { path: '/' });

        sessionStorage.setItem("isLogin", 'true');
        sessionStorage.removeItem("isGuestLogin");
        sessionStorage.setItem("accessToken", data.accessToken)
        sessionStorage.setItem("mobileNumber", data.username)
        sessionStorage.setItem("operator", data.operator.id)
        sessionStorage.setItem("country", data.country.id)
        sessionStorage.setItem("username", data.username)
        if (data.nickName) {
            sessionStorage.setItem("nameSalutation", data.nickName)
            cookies.set('hardyNameSalutation', data.nickName, { path: '/' });
        }
        else {
            sessionStorage.setItem("nameSalutation", data.username)
            cookies.set('hardyNameSalutation', data.username, { path: '/' });
        }
        if (data.userImagePath != null) {
            sessionStorage.setItem("profileImg", data.userImagePath)
            cookies.set('hardyProfileImg', data.userImagePath, { path: '/' });
        }
        this.redirect(type);
    }


    redirect(type) {
        console.log('Destinatation: ->   ' + type);

        if (type.toLowerCase() == "bundle") {
            this.fetchBundleInfo();
        }
        else if (type.toLowerCase() == "quiz") {
            this.openInFrame(type.toLowerCase());
        }
        else if (type.toLowerCase() == "tambola") {
            this.openInFrame(type.toLowerCase());
        }
        else if (type.toLowerCase() == "battle") {
            this.props.history.push({
                pathname: FRONTEND_NAME+'/battlehome'
            })
        }
        else if (type.toLowerCase() == "freegames") {
            this.props.history.push({
                pathname: FRONTEND_NAME+'/freegames'
            })
        }
        else if (type.toLowerCase() == "paidgames") {
            this.props.history.push({
                pathname: FRONTEND_NAME+'/paidgames'
            })
        }
        else if (type.toLowerCase() == "profile") {
            this.props.history.push({
                pathname: FRONTEND_NAME+'/profile'
            })
        }
        else if (type.toLowerCase() == "casualgames") {
            this.props.history.push({
                pathname: FRONTEND_NAME+'/recommendedgames'
            })
        }
        else if (type.toLowerCase() == "premiumgames") {
            this.props.history.push({
                pathname: FRONTEND_NAME+'/premiumgames'
            })
        }
        else if (type.toLowerCase() == "sportsgames") {
            this.props.history.push({
                pathname: FRONTEND_NAME+'/populargames'
            })
        }

        else if (type.toLowerCase() == "content") {
            this.openGame();
        }


        else {
            this.props.history.push(FRONTEND_NAME+"/home")
        }
    }

    openGame() {
        const values = queryString.parse(this.props.location.search)
        const contentid = values.contentid;
        // const categoryid = values.categoryid;

        if (typeof contentid === "undefined") {
            this.props.history.push(FRONTEND_NAME+"/home")
        } else {
            const headers = {
                'Content-Type': constantURL.APPLICATION_JSON,
                'appid': constantURL.APP_ID,
                'deviceId': constantURL.DEVICE_ID,
                'operator': constantURL.OPERATOR,
                'Access_token': sessionStorage.getItem("accessToken"),
                'channel': constantURL.CHANNEL,
                'Msisdn': sessionStorage.getItem("username")
            };

            const url = constantURL.CONTENT_COMBINED_IP + "/v1/fetchViewAllByCategoryTypeAndDynamicId?categoryType=game&dynamicId=" + contentid;
            console.log('url to call : ', url)

            axios.get(url, { headers }).then(response => response.data)
                .then((data) => {
                    console.log(data.data.categories[0].contentList[0])
                    if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                        var details = data.data.categories[0];
                        var gametype = details.subCategoryName;
                        if (details) {
                            var gameDetail = details.contentList[0];

                            this.setState({
                                currentSelectedGame: gameDetail,
                                gametype: gametype
                            })

                            // Check if its Free or paid

                            if (gameDetail.gametype.toUpperCase() === constantURL.GAME_TYPE_FREE) {
                            this.openRequiredGame(gameDetail, gametype);
                            }
                            else {
                                this.checkSubscriptionAndMakePayment(gameDetail,gametype);;
                            }


                        }

                    }
                     else {
                        // alert('Invalid content id');
                        this.togglePopup('Invalid content id.', 'E', constantURL.ERROR_TYPE_ALERT, ['5'])
               
                        // this.props.history.push(FRONTEND_NAME+"/home")
                     }
                })
            .catch(error => {
                console.log('There is Error in fetching View All Content for Instant games : ', error)
                // this.setState({
                //     redirectScreen: constantURL.PAID_GAMES
                // })
                this.togglePopup('Invalid content id.', 'E', constantURL.ERROR_TYPE_ALERT, ['5'])
               
                // alert('Invalid content id');
                // this.props.history.push(FRONTEND_NAME+"/home")
            })
        }
    }

    openRequiredGame(gameDetail, gametype) {

        console.log('-=--=' + gametype)

        var gamescreen = '';
        if (gametype.toLowerCase().indexOf('casual') > -1) {
            gamescreen = 'recommendedgames'
        }
        else if (gametype.toLowerCase().indexOf('paid') > -1) {
            gamescreen = 'paidgames'
        }
        else if (gametype.toLowerCase().indexOf('free') > -1) {
            gamescreen = 'freegames'
        }
        else if (gametype.toLowerCase().indexOf('sports') > -1) {
            gamescreen = 'populargames'
        }
        else if (gametype.toLowerCase().indexOf('premium') > -1) {
            gamescreen = 'premiumgames'
        }
        else {
            gamescreen = 'home'
        }

        console.log("inside openRequiredGame method ")
        this.props.history.push({
            pathname: FRONTEND_NAME+'/gameview',
            state: {
                game: {
                    title: gameDetail.title,
                    noOfUsersDynamic: gameDetail.noOfUsersDynamic,
                    previewImageUrl: gameDetail.previewImageUrl,
                    gameurl: gameDetail.secondarygameurl,
                    price: gameDetail.chargingcurrency + ' ' + gameDetail.chargingvalue,
                    coinsRequired: gameDetail.coinsRequired,
                    gametype: gameDetail.gametype,
                    chargingKeyword: gameDetail.chargingKeyword,
                    checkSubKeyword: gameDetail.checkSubKeyword,
                    chargingvalue: gameDetail.chargingvalue,
                    chargingcurrency: gameDetail.chargingcurrency,
                    replacedynamicparam: gameDetail.replacedynamicparam,
                    showPlayButton: gameDetail.showPlayButton,
                    contentId: gameDetail.contentId,
                    contentName: gameDetail.contentName,
                    validity: gameDetail.validity,
                  
                },
                gameScreen: gamescreen
            }
        })
    }


    checkSubscriptionAndMakePayment = (gameToPlay, gametype) => {
        this.checkUserSubscription(gameToPlay, this.subsHeaders, gametype)
    }

    checkUserSubscription = (gameToPlay, headers1, gametype) => {

        var url = constantURL.SPS_COMBINED_IP + "/v2/checkCoinsAndSub";
        const headers = {
            'appkey': constantURL.APP_KEY_SUBS,
            'Content-Type': constantURL.APPLICATION_JSON,
            'appid': constantURL.APP_ID,
            'deviceId': constantURL.DEVICE_ID,
            'operator': constantURL.OPERATOR,
            'access_token': sessionStorage.getItem("accessToken"),
            'channel': constantURL.CHANNEL,
            'countryCode': constantURL.COUNTRY_CODE,
            'msisdn': sessionStorage.getItem("username")
        };


        const body = {
            'msisdn': sessionStorage.getItem("username"),
            'countryCode': constantURL.COUNTRY_CODE,
            "packName": gameToPlay.checkSubKeyword,
            "rewardName": "coin",
            "rewardFrom": "ABC",
            "operation": "debit",
            "rewardCount": 1,
            "blockRewardId": "",
            "info": gameToPlay.title,
            "operationSource": "wap",
            "appGameName": gameToPlay.title,
            "contentName": gameToPlay.contentName
        }

        axios.post(url, body, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data);
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    this.openRequiredGame(gameToPlay, gametype);
                } else if (data && (data.code == errorConstantURL.REQUIRED_COINS_AVAILABLE)) {
                    this.togglePopup(constantURL.CONFIRM_COINS_DEDUCTION_MESSAGE.replace("<xx>", gameToPlay.coinsRequired), "A", constantURL.CONFIRM_PAYMENT_ALERT, ['3', '6'])
                }
                else if (data && (data.code == errorConstantURL.INSUFFICIENT_COINS)) {
                    this.togglePopup(constantURL.CONFIRM_MONEY_DEDUCTION_MESSAGE.replace("<xx>", "Rs." + gameToPlay.chargingvalue).replace("<yy>", gameToPlay.validity), "A", constantURL.CONFIRM_PAYMENT_ALERT, ['3', '6'])
                } else {
                    this.togglePopup(constantURL.ERROR_OCCURED, 'E', constantURL.ERROR_TYPE_ALERT, ['5'])
                }
            });

    }

    openInFrame(type){
        if(type.toLowerCase() == "quiz"){
            this.props.history.push({
                pathname: FRONTEND_NAME+'/battle',
                state: {
                    game: {
                        gameurl:APPLICATIONS_IP_ADRESS+'/bigwinbattle?msisdn=<msisdn>',
                        secondarygameurl:APPLICATIONS_IP_ADRESS+'/bigwinbattle?msisdn=<msisdn>' 
                    }
                }
            })
        }
        if(type.toLowerCase() == "tambola"){
            this.props.history.push({
                pathname: FRONTEND_NAME+'/battle',
                state: {
                    game: {
                        gameurl:APPLICATIONS_IP_ADRESS+'/tambolabattle/otp?msisdn=<msisdn>',
                        secondarygameurl:APPLICATIONS_IP_ADRESS+'/tambolabattle/otp?msisdn=<msisdn>' 
                    }
                }
            })
        }
        
    }

    fetchBundleInfo() {

        const headers = {
            'Content-Type': 'application/json',
            'appid': constantURL.APP_ID,
            'deviceId': constantURL.DEVICE_ID,
            'operator': constantURL.OPERATOR,
            'Access_token': sessionStorage.getItem("accessToken"),
            'channel': 'wap',
            'Msisdn': sessionStorage.getItem("username")
        };


        axios.get(constantURL.CONTENT_COMBINED_IP + "/v1/fetchHomePage", { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    var bannerDetail = data.data.categories[0].banners;
                    var bundleList = [];
                    if (bannerDetail) {
                        for (var i = 0; i < bannerDetail.length; i++) {
                            if (bannerDetail[i].title != 'HomeBanner') {

                                bundleList.push({
                                    id: bannerDetail[i].contentName,
                                    contentName: bannerDetail[i].contentName,
                                    title: bannerDetail[i].bannerImageTitle,
                                    bannerName: bannerDetail[i].bannerName,
                                    desciption: bannerDetail[i].desciption,
                                    imageUrl: bannerDetail[i].imageUrl,
                                    redirectUrl: bannerDetail[i].redirectUrl,
                                    gameurl: bannerDetail[i].secondarygameurl,
                                    bannerType: bannerDetail[i].bannerType,
                                    buttonText: bannerDetail[i].buttonText,
                                    coinsRequired: bannerDetail[i].coinsRequired,
                                    gametype: bannerDetail[i].gametype,
                                    checkSubKeyword: bannerDetail[i].checkSubKeyword,
                                    chargingKeyword: bannerDetail[i].chargingKeyword,
                                    replacedynamicparam: bannerDetail[i].replacedynamicparam,
                                    price: bannerDetail[i].chargingcurrency + ' ' + bannerDetail[i].chargingvalue,
                                    bgcolorcode: bannerDetail[i].bgcolorcode,
                                    chargingvalue: bannerDetail[i].chargingvalue,
                                    validity: bannerDetail[i].validity,
                                });

                                this.props.history.push({
                                    pathname: FRONTEND_NAME+'/bundlegames',
                                    state: {
                                        detail: bundleList[0],
                                    }
                                })

                            }
                        }
                    } else {
                        this.togglePopup('No record found. Redirecting to home page.', 'E', constantURL.ERROR_TYPE_ALERT, ['5'])
               
                        // alert('No record found. Redirecting to home page.');
                        // this.props.history.push(FRONTEND_NAME+"/home")
                    }

                } else {
                    // alert('No record found. Redirecting to home page.');
                    this.togglePopup('No record found. Redirecting to home page.', 'E', constantURL.ERROR_TYPE_ALERT, ['5'])
               
                    // this.props.history.push(FRONTEND_NAME+"/home")
                }
            });

    }

    submitPaymentSubscription = () => {
        var url = constantURL.SPS_COMBINED_IP + "/v2/deductCoinsOrSub";
        const headers = {
            'appkey': constantURL.APP_KEY_SUBS,
            'Content-Type': constantURL.APPLICATION_JSON,
            'appid': constantURL.APP_ID,
            'deviceId': constantURL.DEVICE_ID,
            'operator': constantURL.OPERATOR,
            'access_token': sessionStorage.getItem("accessToken"),
            'channel': constantURL.CHANNEL,
            'countryCode': constantURL.COUNTRY_CODE,
            'msisdn': sessionStorage.getItem("username")
        };


        const body = {
            'msisdn': sessionStorage.getItem("username"),
            'countryCode': constantURL.COUNTRY_CODE,
            "packName": this.state.currentSelectedGame.checkSubKeyword,
            "rewardName": "coin",
            "rewardFrom": "ABC",
            "operation": "debit",
            "rewardCount": 1,
            "blockRewardId": "",
            "info": this.state.currentSelectedGame.title,
            "operationSource": "wap",
            "appGameName": this.state.currentSelectedGame.title,
            "contentName": this.state.currentSelectedGame.contentName

        }

        axios.post(url, body, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data);
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    this.togglePopup(constantURL.PAYMENT_SUCCESSFUL_MESSAGE, "S", constantURL.PAYMENT_SUCCESSFUL_ALERT, ['1']);
                } else if (data && (data.code == errorConstantURL.LOW_BALANCE_CODE)) {
                    this.togglePopup(constantURL.LOW_BALANCE_PAYMENT_UNSUCCESSFUL_MESSAGE, "E", constantURL.PAYMENT_UNSUCCESSFUL_ALERT, ['5'])
                } else {
                    this.togglePopup(constantURL.PAYMENT_UNSUCCESSFUL_MESSAGE, "E", constantURL.PAYMENT_UNSUCCESSFUL_ALERT, ['5'])
                }
            });
    }

    performTaskOnClose = (flag) => {

        console.log('====='+flag)

        this.togglePopup()
        console.log("inside performTaskOnClose method, flag is ", flag)
        if (flag === 'confirm') {
            this.submitPaymentSubscription();
        } if (flag === 'ok-' + constantURL.LOGIN_REQUIRED_MESSAGE) {
            this.props.history.push(FRONTEND_NAME+"/login")
        } if (flag === 'ok-' + constantURL.PAYMENT_SUCCESSFUL_MESSAGE) {
            //window.open(this.state.currentSelectedGame.gameurl, "_blank")
            this.openRequiredGame(this.state.currentSelectedGame, this.state.gametype)
        } if (flag === 'ok-' + constantURL.ERROR_MESSAGE) {
            this.props.history.push(FRONTEND_NAME+"/home")
        }
        if (flag === 'home') {
            this.props.history.push(FRONTEND_NAME+"/home")
        }


    }


    togglePopup = (message, msgType, alert, buttonArray) => {
        this.setState({
            showPopup: !this.state.showPopup,
            msg: message,
            msgType: msgType,
            alert: alert,
            displayButtons: buttonArray
        })

    }

    togglePop() {
        this.setState({
            showPopup: !this.state.showPopup,
        });
    }

    togglePopupToHome = () => {
        this.props.history.push(FRONTEND_NAME+"/home")
    }



    render() {
        const { showPopup, showPopupOld, msg, msgType, alert, displayButtons, trendingPaidGames } = this.state;

        return (<div>
            {showPopup ?
                <PopupNew
                    status={msgType}
                    text={msg}
                    alertType={alert}
                    closePopup={this.togglePopup}
                    closePopupWithTask={this.performTaskOnClose}
                    displayButtons={displayButtons}
                /> : null}

            <p className="redirecting1">Redirecting...</p>
            <p className="redirecting2">Please wait while we process your request.</p></div>)
    }
}


export default withRouter(Redirect);