import React from 'react'
import axios from 'axios';
import '../styles/profile.css';

import edit from '../images/editNew.svg';
import back from '../images/back.png';
import profileImage from '../images/profileImage.png';
import help from '../images/help.png';
import share from '../images/share.png';
import faq from '../images/faqNew.png';
import viewWhite from '../images/viewWhite.png';
import SocialMedia from './socialMedia';
import { withRouter } from 'react-router'
import profileImg from '../images/profileImg.png';
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Iframe from 'react-iframe'
import $ from "jquery";
import Cookies from 'universal-cookie';


class Battle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
      console.log(this.props.location.state.game)
      this.saveLogs(this.props.location.state.game.title)
    }


    saveLogs(category) {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
        const cookies = new Cookies();
        var url = constantURL.APPLOGGER_COMBINED_IP + "/v1/saveLogs";
        
        const headers = {
            'Content-Type': 'application/json',
            'appid': metaMap.get('APP_ID'),
            'operator': metaMap.get('OPERATOR'),
            'msisdn': sessionStorage.getItem("username"),
            'channel': 'WAP'
        };

        const requestBody = {
            "category" : category,
            "subCategory" : "",
            "content" : category,
            "url" : "/battle",
            "packname" :"",
            "appname" :metaMap.get('APPLICATION_NAME'),
            "sessionid" : cookies.get('uniquevhgsession')
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == errorConstantURL.SUCCESS_CODE) {
                } else {
                }
            });
    }

    render() {
        var url = '';
        // alert(constantURL.ENVIRONMENT)
        console.log(this.props.location.state.game);       
        if(constantURL.ENVIRONMENT=="PROD"){
           url= this.props.location.state.game.secondarygameurl.replace('=MSISDN','='+sessionStorage.getItem("username")).replace('<lang>',sessionStorage.getItem("lang"));
           url=url+'&redirecturl='+constantURL.APPLICATIONS_NEW_IP_ADRESS+'/hardygames/home';
     
           if(this.props.location.state.game.title.toUpperCase()=='TAMBOLA'){
                window.location.href=url;
           }
        }else{
            console.log(this.props.location.state.game.gameurl)
            url= this.props.location.state.game.gameurl.replace('=MSISDN','='+sessionStorage.getItem("username")).replace('<lang>',sessionStorage.getItem("lang"));
            url=url+'&redirecturl=//digital.mv1.in/hardygames/home';
            if(this.props.location.state.game.gameurl.indexOf("viservices.in") != -1){
                let msisdn = sessionStorage["mobileNumber"].substring(3,sessionStorage["username"].length);
                url = "https://viservices.in/tambolaloyalty/otp?msisdn=<msisdn>&redirecturl=https://digital.mv1.in/hardygames/home";
                url = url.replace("<msisdn>",msisdn+9);
            }
            if(this.props.location.state.game.title.toUpperCase()=='TAMBOLA'){
                window.location.href=url;
            }
        
        }

        // var url = this.props.location.state.game.gameurl +  sessionStorage.getItem("mobileNumber")
        return (
            <div>
                <div >
                    <object type="text/html" id="spinn" data={url}
                        style={{ width: '100%', height: '100vh' }} />
                </div>
            </div>
        )
    }
}

export default withRouter(Battle);
