import {
    FETCH_HOME_MENU_START,
    FETCH_HOME_MENU_SUCCESS,
    FETCH_HOME_MENU_ERROR,
    FETCH_HOME_MENU_CLEAR
} from './../container/Constants/homeMenuConstants'

const initialState = {
    enableLoader: true, 
    isInitialLoad: true, 
    logoutPopup: false,
    bottomMenu: []
};

export const homeMenuReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_HOME_MENU_START:
            return initialState;
        case FETCH_HOME_MENU_SUCCESS:
            return {
                enableLoader: false, 
                isInitialLoad: false, 
                logoutPopup: false, 
                bottomMenu: action.payload
            }
        case FETCH_HOME_MENU_ERROR:
            return {
                ...state,
                enableLoader: false, 
                isInitialLoad: true, 
                logoutPopup: true, 
            }
        default:
            return state;
    }
}

