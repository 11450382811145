import React from 'react'
import axios from 'axios';
import '../styles/style.css';
import { withRouter, Redirect } from 'react-router'
import * as Constant from '../Constants/constants'
import * as Errorcode from '../Constants/errorConstants'
import Popup from './popup';
import Loading from './loading'
import back from '../images/back.png';
import downarrow from '../images/downarrow.svg';

import stop from '../images/stop.svg';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import * as constantURL from '../Constants/constants'
import { FRONTEND_NAME } from '../Constants/constants'
import firebase from 'firebase';
import CircularJSON from 'circular-json'
import { socialAppName } from './SocialLogin'
import lottie from "lottie-web";
import animationData from "../lottie/login_input_number_black_theme.json";
import Searchcountry from './searchCountry'

let animObj1 = null;

class LoginPhase2 extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            username: this.props.location.state === undefined ? '' : this.props.location.state.userName,
            showPopup: false,
            message: '',
            msgType: '',
            alert: '',
            hideButton: true,
            error: false,
            enableLoader: false,
            showCross: false,
            lables: Constant.getLables(),
            socialLoginList: [],
            countryDetails: {},
            countryCode: 91,
            countryName: 'IN',
            ipAddress: '127.0.0.1',
            showChooseCountry: false
        }
        this.msisdnRegex = /^[6-9]{1}[0-9]{9}$/
        this.userRegex = /^\d+$/
    }

    openSocialLoginScreen = () => {
        this.props.history.push({
            pathname: FRONTEND_NAME + "/sociallogin"
        })
    }

    openCountrySelect = () => {
        this.setState({ showChooseCountry: true })
    }


    getIpData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        console.log(res.data);
        this.setState({ ipAddress: res.data.IPv4 });
    }

    componentDidMount() {
        this.getIpData();

        sessionStorage.setItem("isLogin", 'false');


        const cookies = new Cookies();
        var changePassword = sessionStorage.getItem('changePassword');

        this.saveLogs('Login');

        $("#mobNum").on("keyup keypress blur keydown", function (event) {
            console.log("inside jqueryListener : key is ", event.key)
            console.log("inside jqueryListener : keyCode is ", event.keyCode)

            var key = event.keyCode || event.charCode;
            var isBackspace = (key == 187 || key == 189 || key == 69 || key == 46 || key == 43 || key == 101 || key == 190 || key == 158);
            if (isBackspace) {
                return false
            }
        })

        animObj1 = lottie.loadAnimation({
            container: document.querySelector("#login_lottie"), // the dom element that will contain the animation
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: animationData, // the path to the animation json
        });
    }
    createLoginSession(data) {
        const cookies = new Cookies();
        const current = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(current.getFullYear() + 1);

        sessionStorage.removeItem("isGuestLogin");
        sessionStorage.setItem("accessToken", data.accessToken);
        sessionStorage.setItem("mobileNumber", data.mobileNumber);
        if (data.mobileNumber == null || data.mobileNumber == 'null' || data.mobileNumber == '') {
            cookies.set("hardyIsLogin", true, { path: "/" });
            cookies.set("hardyAccessToken", data.accessToken, { path: "/" });
            cookies.set("hardyMobileNumber", data.username, { path: "/" });
            cookies.set("hardyUsername", data.username, { path: "/" });

            sessionStorage.setItem("isLogin", 'true');
            sessionStorage.setItem("loginType", "social");
            sessionStorage.setItem("username", data.username);

        } else {
            cookies.set("hardyIsLogin", false, { path: "/" });
            cookies.set("hardyAccessToken", data.accessToken, { path: "/" });
            cookies.set("hardyMobileNumber", data.mobileNumber, { path: "/" });
            cookies.set("hardyUsername", data.mobileNumber, { path: "/" });

            sessionStorage.setItem("isLogin", 'false');
            sessionStorage.setItem("loginType", "normal");
            sessionStorage.setItem("username", data.mobileNumber);

        }

        if (data.dateOfBirth) {
            var dob = data.dateOfBirth;
            var dob1 = dob.split("T");
            var dob2 = dob1[0].split("-");
            sessionStorage.setItem("dob", dob2[2] + "/" + dob2[1] + "/" + dob2[0]);
        }
        sessionStorage.setItem("gender", data.gender);
        sessionStorage.setItem("emailid", data.email);
        sessionStorage.setItem("address", data.address);

        sessionStorage.setItem("nameSalutation", data.firstName + ' ' + data.lastName)
        cookies.set("hardyNameSalutation", data.firstName + ' ' + data.lastName, { path: "/" });


        // if (data.nickName) {
        //   sessionStorage.setItem("nameSalutation", data.nickName);
        //   cookies.set("hardyNameSalutation", data.nickName, { path: "/" });
        // } else {
        //   sessionStorage.setItem("nameSalutation", data.username);
        //   cookies.set("hardyNameSalutation", data.username, { path: "/" });
        // }
        if (data.userImagePath != null) {
            sessionStorage.setItem("profileImg", data.userImagePath);
            cookies.set("hardyProfileImg", data.userImagePath, { path: "/" });
        }
        if (data.mobileNumber == null || data.mobileNumber == 'null' || data.mobileNumber == '') {
            this.fetchHomeData(data.username);
        } else {
            window.location.replace(constantURL.BIG_WIN_HOME_URL + cookies.get('hardyMobileNumber'));
        }
    }


    fetchHomeData(username) {
        //   alert(username)
        this.props.history.push({
            pathname: FRONTEND_NAME + '/home',
            state: {
                username: username
            }
        });
    }

    saveLogs(category) {

        var d = new Date();
        var n = d.getMilliseconds();

        var url = constantURL.APPLOGGER_COMBINED_IP + "/v1/saveLogs";

        const headers = {
            'Content-Type': 'application/json',
            'appid': constantURL.APP_ID,
            'operator': constantURL.OPERATOR,
            'msisdn': '',
            'channel': 'WAP'
        };

        const requestBody = {
            "category": category,
            "subCategory": "",
            "content": category,
            "url": "/login",
            "packname": "",
            "appname": constantURL.APPLICATION_NAME,
            "sessionid": "login_user" + n + "-" + Math.random(),
            "operator": "",
            "circle": "",
            "country": "INDIA"

        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == 2000) {
                } else {
                }
            });
    }



    signInUser(userType) {

        console.log('Inside ' + userType + ' SIGNIN API')
        const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SIGNING_API_PARAM}`
        console.log("calling signin with url " + url);
        this.setState({ enableLoader: true })


        const headers = {
            'Content-Type': Constant.APPLICATION_JSON,
            'Accept': Constant.APPLICATION_JSON,
            'deviceId': Constant.DEVICE_ID,
            'appid': Constant.APP_ID,
        };

        //this.setState({ msisdn: this.props.location.state.mydata.msisdn });

        const requestBody = {
            username: this.state.username,
            password: Constant.PASSWORD
        }

        axios.post(url, requestBody, {
            headers: headers
        })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                    console.log(data)

                    //alert("user has been validated successfully");
                    const transfer = {
                        msisdn: data.data.username,
                        operator: data.data.operator.id,
                        country: data.data.country.id,
                        userName: data.data.username,
                        accessToken: data.data.accessToken
                    }
                    //After Session is created, user is redirected to Successful HomeScreen 
                    this.createLoginSession(transfer, data.data);
                    console.log('Redirecting ' + userType + ' to successful HOME screen... ')
                    this.openHomeScreen()

                } else {
                    this.setState({
                        showModal: true,
                        msgType: 'E',
                        msg: "Error in login!"
                    });
                }
                this.setState({ enableLoader: false })

            });
    }


    signUpUser() {

        console.log('Inside SignUp user')
        const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SIGNUP_API_PARAM}`;
        console.log("calling SignUp with url " + url);
        this.setState({ enableLoader: true })

        const headers = {
            'Content-Type': Constant.APPLICATION_JSON,
            'Accept': Constant.APPLICATION_JSON,
            'deviceId': Constant.DEVICE_ID,
            'appid': Constant.APP_ID,
        };

        const requestBody = {
            username: this.state.username,
            password: Constant.PASSWORD,
            operatorName: Constant.MOB_OPERATOR
        }

        axios.post(url, requestBody, {
            headers: headers
        })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                    console.log("SignUp is successfull, going for SignIn")
                    this.signInUser('NEW USER')
                } else {
                    this.setState({
                        showModal: true,
                        msgType: 'E',
                        msg: "Error in login!"
                    });
                }
                this.setState({ enableLoader: false })

            });
    }


    checkUserType = (data) => {
        //  ;
        var changePassword = sessionStorage.getItem('changePassword');
        if (changePassword != "true") {
            switch (data.screenFlag) {
                case Constant.OTP_SCREEN:
                    console.log("Inside OTP_SCREEN section : ")
                    if (data.userType === Constant.NEW_USER) {

                        console.log('Redirecting ' + data.userType + ' to OTP screen... ')
                        this.openEnterOtpPage(data.userType)
                    }
                    else if (data.userType === Constant.EXISTING_USER) {
                        // this.openEnterPassPage(data.userType);
                        this.openEnterOtpPage(data.userType)

                    }
                    break;
                case Constant.SET_PWD_SCREEN:
                    console.log("Inside SET_PWD_SCREEN section : ")
                    if (data.userType === Constant.NEW_USER) {
                        console.log('Redirecting NEW user to SET PWD screen... Since there is No PWD Validation ' +
                            'Screen, so calling SIGNUP API internally for New user ...')
                        //  this.openSetPassPage(data.userType)
                        this.openEnterOtpPage(data.userType)

                    }
                    else if (data.userType === Constant.EXISTING_USER) {
                        this.openEnterOtpPage(data.userType)

                        // this.openEnterPassPage(data.userType);
                    }
                    break;
                case Constant.VALIDATE_PWD_SCREEN:
                    console.log("Inside VALIDATE_PWD_SCREEN section : ")
                    if (data.userType === Constant.EXISTING_USER) {
                        console.log('Redirecting EXISTING user to VALIDATE PWD screen...Since there is No PWD Validation ' +
                            'Screen, so calling SIGNIN API internally for New user ...')
                        //this.signInUser('EXISTING USER');
                        // this.openEnterPassPage(data.userType);
                        this.openEnterOtpPage(data.userType)

                    }
                    break;
                default:
                    console.log("Error Fetching userType from DB")
            }
        }
        else {

            console.log("go to otp page");
            this.openEnterOtpPage(data.userType);

        }




    }


    submitHandler = (event) => {


        event.preventDefault()
        console.log('username : ', this.state.username)
        if (!this.msisdnRegex.test(this.state.username)) {
            this.setState({
                error: true
            })
            console.log('from submit after : ', this.state.error)
        } else {
            this.setState({ enableLoader: true })

            this.getMetaInfoDetails()

             }

    }

signInUserAfterMetaInfo(){
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
    // 0: {keyName: 'APPKEY', valueName: 'vihardygames', id: 1}
    // 1: {keyName: 'COINSAPPKEY', valueName: 'BIGWINLOYALTY', id: 2}
    // 2: {keyName: 'APPKEY_BUY_COINS', valueName: 'BIGWINLOYALTYBUYCOINS', id: 3}
    // 3: {keyName: 'COUNTRY_CODE', valueName: '91', id: 4}
    // 4: {keyName: 'OPERATOR', valueName: 'vi', id: 5}
    // 5: {keyName: 'APP_ID', valueName: '102', id: 6}
    // 6: {keyName: 'APP_KEY_SUBS', valueName: 'vihardygames', id: 7}
    // 7: {keyName: 'VENDOR_KEY', valueName: 'hardygames', id: 8}
    // 8: {keyName: 'CAMPAIGN_ID', valueName: '20', id: 9}
    // 9: {keyName: 'SUB_CATEGORY', valueName: 'hardygamebigwin', id: 10}
    // 10: {keyName: 'APPLICATION_NAME', valueName: 'HardyGames', id: 11}
    // 11: {keyName: 'SESSION_ID_TIMEOUT', valueName: '3000', id: 12}
    // 12: {keyName: 'COUNTRY_NAME', valueName: 'India', id: 13}

    
    const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SUBMIT_LOGIN_OR_SIGNUP_API_PARAM}`;
            console.log("Url to call : ", url);

            const headers = {
                'appid': metaMap.get('APP_ID'),
                'Content-Type': Constant.APPLICATION_JSON,
                'Accept': Constant.APPLICATION_JSON
            }

            const requestBody = {
                'username': this.state.username,
                'countryCode': metaMap.get('COUNTRY_CODE'),
            }

            // POST call to Submit Login or SignUp
            axios.post(url, requestBody, { headers })
                .then(response => response.data)
                .then(data => {
                    console.log(data)
                    if (data && data.code === Errorcode.SUCCESS_CODE) {
                        //  ;
                        console.log("output ", data);
                        console.log("userType is ", data.data.userType)
                        console.log("And screenFlag is ", data.data.screenFlag)

                        this.checkUserType(data.data);

                    } else if (data && data.code === Errorcode.OTP_LIMIT_EXHAUSTED) {
                        //Resend OTP after 15 mins
                        console.log("OTP Limit Exhausted with msg : ", data.msg);
                        this.togglePopup(this.state.lables.otpLimitExhausted, 'A', Constant.OTP_LIMIT_REACHED, true)
                    } else {
                        //Add Modal for Failure Case
                        console.log("Technical Error, Something went Wrong!!");
                    }

                    this.setState({ enableLoader: false })

                })
                .catch(error => {
                    this.setState({ enableLoader: false })

                    //Add Modal for Error Case 
                    console.log("Error Occured : ", error)
                })
      
}

    changeHandler = (event) => {
        console.log("inside handleListener : key is ", event.key)
        console.log("inside handleListener : keyCode is ", event.keyCode)
        console.log("input value is ", event.target.value)
        if (event.keyCode === 46) {
            console.log("inside changeHandler : ")
            return false
        }

        const userName = event.target.value.trim();
        console.log("username : ", userName)
        console.log("userName.includes(.) : ", userName.includes("."))
        console.log("this.userRegex.test(userName) : ", this.userRegex.test(userName))
        if (!Number(userName) || !this.userRegex.test(userName) || (userName.length >= 10 && this.state.username.length === 10)) {
            console.log("username inside: ", userName)
            if (userName === '') {
                this.setState({
                    username: ''
                })
            }
            return false;
        }
        console.log("username outside: ", userName)
        this.setState({
            username: userName,
            error: false,
            showCross: userName.length > 0
        })

    }

    openEnterOtpPage = (type) => {

        this.props.history.push({
            pathname: FRONTEND_NAME + '/otpphase2',
            state: {
                username: this.state.username,
                usertype: type
            }
        });
    }

    openSetPassPage = (type) => {

        this.props.history.push({
            pathname: FRONTEND_NAME + '/setpassword',
            state: {
                username: this.state.username,
                usertype: type
            }
        });
    }
    openEnterPassPage = (type) => {
        //  ;
        this.props.history.push({
            pathname: FRONTEND_NAME + '/enterpassword',
            state: {
                username: this.state.username,
                usertype: type
            }
        });
    }


    openHomeScreen = () => {
        this.props.history.push({
            pathname: FRONTEND_NAME + '/home',
            state: {
                username: this.state.username
            }
        });
    }

    hideSearchCountry = () => {
        this.setState({ showChooseCountry: false })
    }

    chooseCounry = (country) => {
        this.setState({
            showChooseCountry: false,
            countryName: country.alpha2code,
            countryCode: country.countryCode,
            countryDetails: country
        })

    }


    getMetaInfoDetails() {

        const headers  = {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'ip': this.state.ipAddress,
            'msisdn': this.state.username,
           };    
    
        var countryCode= this.state.countryDetails.countryCode?this.state.countryDetails.countryCode:'91'

        const url = constantURL.PROFILER2_COMBINED_IP + "/v3/getMetaInfo?isWifi=true&gameType=HardyGames&countryCode="+countryCode+"&isSocialLogin=false";
        
        axios.get(url, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data);
                let map = new Map();
                if (data && data.code == 2000) {
                    var metaInfoData = data.data.metaKeyValue;
                    console.log((data.data.metaKeyValue));

                    for (var i = 0; i < metaInfoData.length; i++) {
                        map.set(metaInfoData[i].keyName, metaInfoData[i].valueName);
                    }

                    var jsonText = JSON.stringify(Array.from(map.entries()));
                    sessionStorage.setItem('metaInfo', jsonText);

                  
                     this.signInUserAfterMetaInfo();
                }
            })
            .catch(err => {
            })

    }

    togglePopup = (msg, msgType, alert, hideBtn) => {
        this.setState({
            showPopup: !this.state.showPopup,
            message: msg,
            msgType: msgType,
            alert: alert,
            hideButton: hideBtn
        })

    }

    back() {
        this.props.history.push(FRONTEND_NAME + "/home");
    }

    render() {

        const { username, message, msgType, alert, showPopup, error, hideButton, showCross, lables } = this.state
        const enabled = username.length === 10
        return (
            <div className="container-login">

                {this.state.showChooseCountry ?
                    <Searchcountry cancel={this.hideSearchCountry}
                        chooseCounry={this.chooseCounry} />
                    : null
                }
                <div class="login-top">
                    <p onClick={this.openSocialLoginScreen} class="back">Back</p>
                    <div class="login-ani" id="login_lottie">

                    </div>
                </div>
                <p class="login-msg">Please enter your mobile number</p>
                <p class="login-note">You can trust us to keep it safe</p>

                <div class="login-input-section" >
                    <div class="lang-select lang-select-text" onClick={this.openCountrySelect}>
                        <span> {this.state.countryName}</span> <img src={downarrow} style={{ verticalAlign: 'middle' }}></img> <span style={{ opacity: 0.6 }}> +{this.state.countryCode} </span>
                    </div>
                    <div class="login-input">
                        <input id="mobNum" type="text" value={username}
                            onChange={this.changeHandler} Placeholder="Phone Number" />
                    </div>
                </div>
                <button onClick={this.submitHandler} class="login-btn">Log In</button>
                <p class="login-link">By using our services you are agreeing to our <br /><span>Terms & Privacy</span> statement</p>

            </div>
        )
    }
}

export default withRouter(LoginPhase2);