
import React from 'react'
import axios from 'axios';
import '../styles/home.css';
import Footer from '../Footer/footer';
import Menu from '../Menu/menu';
import profileImg from '../images/profileImage.png';
import games from "../images/hardy.svg";
import bell from '../images/bell.png';
import coinshardy from '../images/coinshardy.svg';
import LogoutPopup from './logoutPopup'
import moment from 'moment';
import back from '../images/backNew.svg';
//import smallstar from '../images/smallstar.svg';
import smallstar from '../images/black.svg';
import yellowStar from '../images/yellowStar.png';
import yellowBatch from '../images/yellowBatch.png';

import yellowHeart from '../images/yellowHeart.png';
import viewAll from '../images/viewAll.png';
import yellowDice from '../images/yellowDice.png';

import popular from '../images/popular.png';
import keepPlaying from '../images/keepPlaying.png';
import yellowView from '../images/yellowView.png';
import instsnt from '../images/instsnt.png';
import winBig from '../images/winBig.png';
import trending from '../images/trending.png';

import circle from '../images/circle.png';
import gods from '../images/gods.png';
import bannerBottom from '../images/bannerBottom.png';
import * as Constant from '../Constants/constants'
import { FRONTEND_NAME } from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Slider from 'infinite-react-carousel';
import ProfileCollapsed from './profileCollapsed';
import { withRouter } from 'react-router';
import PopupNew from './popupNew';
import Popup from './popup';
import Loading from './loading'
import lottie from 'lottie-web';
import animationData from '../lottie/delivery.json';
import clownLogo from '../images/clownLogo.png';
import Cookies from 'universal-cookie';
import SpinTheWheel from './spinTheWheel';
import * as constantURL from '../Constants/constants'
import InfiniteScroll from "react-infinite-scroll-component";
import localForage from 'localforage';
import { connect } from 'react-redux'
import { fetchHomePage } from '../../actions/fetchHomePageActions'
import {
    fetchGameList,
    updateGameCount
} from '../../actions/gameListActions'


import { logEvents } from "./Analytics";


class PaidGames extends React.Component {


    constructor(props) {

        super(props);
        this.state = {
            banners: [{ id: '1', title: 'title', bannerName: 'bannerName', imageUrl: 'imageUrl', redirectUrl: 'redirectUrl', gameurl: 'game', bannerType: 'ban', bannerText: 'PLAY NOW' }],
            trendingPaidGames: [],
            OriginalTrendingPaidGames: [],

            isShowMenu: false,
            showPopup: false,
            showPopupOld: false,
            isShowSpin: false,
            msg: '',
            logoutPopup: false,
            msgType: 'S',
            enableLoader: true,
            isConfirm: 'N',
            loginRedirect: 'N',
            instantGamesId: '',
            bigWinGamesId: '',
            displayButtons: [],
            currentSelectedGame: {},
            gametype: 'FREE',
            isNeedToCheckSTW: false,
            hasMoreItems: true,
            currentItems: 0,
            isShowSpin: false,
            currentCategory: this.props.location.state.currentCategory,
            lables: Constant.getLables(),
            loadedImage: new Set(),
            cachedImage: new Map()
            //chargingKeyword:'hardygame10',
            //checkSubKeyword: 'hardygame10',
            //chargingValue: '10'

        }
    }


    subsHeaders = {
        'appKey': new Map(JSON.parse(sessionStorage.getItem('metaInfo'))).get('APP_KEY_SUBS'),
        'operator': new Map(JSON.parse(sessionStorage.getItem('metaInfo'))).get('OPERATOR'),
        'Msisdn': sessionStorage.getItem("username"),
        'channel': Constant.CHANNEL,
        'Access_token': sessionStorage.getItem("accessToken"),
        'deviceId': Constant.DEVICE_ID,
        'Content-Type': Constant.APPLICATION_JSON,
        'Accept': Constant.APPLICATION_JSON
    }
    showSpinner() {
        const headers = {
            'Content-Type': 'application/json',
            'mode': 'WAP',
        };

        var msisdn = sessionStorage.getItem("username");
        if (msisdn.length > 10) {
            msisdn = msisdn.substr(2, msisdn.length + 1);
        }

        const body = {
            'msisdn': msisdn,
            'campaignId': constantURL.CAMPAIGN_ID,
            'vendorUniqueKey': constantURL.VENDOR_KEY
        }

        axios.post(constantURL.SPINTHEWHEEL_COMBINED_IP + "/v1/spinnerStatus", body, { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    console.log(data.data.status)
                    if (data.data.status) {
                        this.setState({
                            isShowSpin: true
                        })
                    } else {
                        this.setState({
                            isShowSpin: false
                        })
                    }
                } else {
                    this.setState({
                        isShowSpin: false
                    });
                }
            });
    }

    sessionOut() {
        this.props.history.push(FRONTEND_NAME + "/logout")
    }


    componentDidMount() {
        this.setState({ loginRedirect: 'N' });
        // if (document.fullscreenElement != null) {
        // document.exitFullscreen()
        // }

        // if(Object.keys(this.props.home).length === 0) this.props.fetchHomePage();
        // this.props.fetchGameList(this.props.location.state.from, this.state.currentCategory, 0);

        const { lables } = this.state;

        let tempMap = new Map();
        localForage.iterate((value, key) => {
            if (!tempMap.has(key)) {
                tempMap.set(key, value)
            }
        })
            .then(() => {
                this.setState({
                    cachedImage: tempMap
                });
            });

        console.log(' User Entered time --> ' + sessionStorage.getItem('entertime'))
        if (sessionStorage.getItem('entertime')) {

            var enteredTime = sessionStorage.getItem('entertime');
            var d = new Date();
            var currentTime = d.getTime();

            if ((currentTime - enteredTime) / 1000 > constantURL.SPINTHEWHEEL_TIME) {
                this.setState({ isNeedToCheckSTW: true });
                sessionStorage.removeItem('entertime');

            } else {
                this.setState({ isNeedToCheckSTW: false });
            }
        } else {
            var d = new Date();
            var n = d.getTime();
            sessionStorage.setItem('entertime', n);
            this.setState({ isNeedToCheckSTW: false });
        }

        this.showSpinner();

        // this.getContent();

        //Added after API Caching
        this.getAPICachedContent();
    }

    componentDidUpdate(prevProps) {
        //alert("HiHiHi")
        // console.log(prevProps)

        if (prevProps.showPopup !== this.props.showPopup) {
            this.setState({
                showPopup: this.props.showPopup,
                msg: this.props.msg,
                msgType: this.props.msgType,
                alert: this.props.alert,
                displayButtons: this.props.displayButtons,
                loginRedirect: this.props.loginRedirect
            })
        }

    }

    saveLogs(category) {

    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
    
        const cookies = new Cookies();
        var url = Constant.APPLOGGER_COMBINED_IP + "/v1/saveLogs";

        const headers = {
            'Content-Type': 'application/json',
            'appid': metaMap.get('APP_ID'),
            'operator': metaMap.get('OPERATOR'),
            'msisdn': sessionStorage.getItem("username"),
            'channel': 'WAP'
        };

        const requestBody = {
            "category": category,
            "subCategory": "",
            "content": category,
            "url": "/paidgames",
            "packname": "",
            "appname": metaMap.get('APPLICATION_NAME'),
            "sessionid": cookies.get('uniquevhgsession')
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == errorConstantURL.SUCCESS_CODE) {
                } else {
                }
            });
    }


    openNotificcation() {
        this.props.history.push({
            pathname: FRONTEND_NAME + '/notification',
            state: {
                gameScreen: 'paidGames',
            }
        })
    }


    performTaskOnClose = (flag) => {
        const { lables } = this.state;
        console.log("inside performTaskOnClose method, flag is ", flag)
        if (this.state.loginRedirect === 'EP') {
            this.props.history.push(FRONTEND_NAME + "/editprofilenew")
        }
        else if (this.state.loginRedirect === 'LOGIN') {
            this.props.history.push(FRONTEND_NAME + "/sociallogin")
        }
        else if (this.state.loginRedirect === 'Y') {
            this.props.history.push(FRONTEND_NAME + "/logout")
        } else {
            this.togglePopup();

            if (flag === 'confirm') {
                this.submitPaymentSubscription();
            } if (flag === 'ok-' + lables.youNeedToLoginForFeature) {
                this.props.history.push(FRONTEND_NAME + "/sociallogin")
            } if (flag === 'ok-' + lables.successfullyChargedClickOkToPlay) {
                //window.open(this.state.currentSelectedGame.gameurl, "_blank")
                this.openRequiredGame(this.state.currentSelectedGame)
            } if (flag === 'ok-' + lables.somethingWentWrongInLoadingData) {
                this.props.history.push(FRONTEND_NAME + "/home")
            }
        }
    }

    submitPaymentSubscription = () => {

    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
    // 0: {keyName: 'APPKEY', valueName: 'vihardygames', id: 1}
   // 1: {keyName: 'COINSAPPKEY', valueName: 'BIGWINLOYALTY', id: 2}
   // 2: {keyName: 'APPKEY_BUY_COINS', valueName: 'BIGWINLOYALTYBUYCOINS', id: 3}
   // 3: {keyName: 'COUNTRY_CODE', valueName: '91', id: 4}
   // 4: {keyName: 'OPERATOR', valueName: 'vi', id: 5}
   // 5: {keyName: 'APP_ID', valueName: '102', id: 6}
   // 6: {keyName: 'APP_KEY_SUBS', valueName: 'vihardygames', id: 7}
   // 7: {keyName: 'VENDOR_KEY', valueName: 'hardygames', id: 8}
   // 8: {keyName: 'CAMPAIGN_ID', valueName: '20', id: 9}
   // 9: {keyName: 'SUB_CATEGORY', valueName: 'hardygamebigwin', id: 10}
   // 10: {keyName: 'APPLICATION_NAME', valueName: 'HardyGames', id: 11}
   // 11: {keyName: 'SESSION_ID_TIMEOUT', valueName: '3000', id: 12}
   // 12: {keyName: 'COUNTRY_NAME', valueName: 'India', id: 13}

   // 'operator':  metaMap.get('OPERATOR'),
        const { lables } = this.state;
        var url = Constant.SPS_COMBINED_IP + "/v2/deductCoinsOrSub";
        const headers = {
            'appkey': metaMap.get('APP_KEY_SUBS'),
            'Content-Type': Constant.APPLICATION_JSON,
            'appid': sessionStorage["appid"],
            // 'appid': metaMap.get('APP_ID'),
            'deviceId': Constant.DEVICE_ID,
            'operator': metaMap.get('OPERATOR'),
            'access_token': sessionStorage.getItem("accessToken"),
            'channel': Constant.CHANNEL,
            'countryCode': metaMap.get('COUNTRY_CODE'),
            'msisdn': sessionStorage.getItem("username")
        };


        const body = {
            'msisdn': sessionStorage.getItem("username"),
            'countryCode': metaMap.get('COUNTRY_CODE'),
            "packName": this.state.currentSelectedGame.checkSubKeyword,
            "rewardName": "coin",
            "rewardFrom": "ABC",
            "operation": "debit",
            "rewardCount": 1,
            "blockRewardId": "",
            "info": "Deducted for " + this.state.currentSelectedGame.title,
            "operationSource": "wap",
            "appGameName": this.state.currentSelectedGame.title,
            "contentName": this.state.currentSelectedGame.contentName

        }

        axios.post(url, body, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data);
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    // this.setState({
                    //     isSuccessfullyPurchased: true
                    // })
                    this.togglePopup(lables.successfullyChargedClickOkToPlay, "S", lables.congratulations, ['1']);
                } else if (data && (data.code == errorConstantURL.LOW_BALANCE_CODE)) {
                    this.togglePopup(lables.paymentFailedInsuffBal, "E", lables.tryAgain, ['1'])
                } else {
                    this.togglePopup(lables.paymentFailedTryAfterSometimeE, "E", lables.tryAgain, ['1'])
                }
            });
        this.props.updateGameCount(this.state.currentCategory.categoryId, this.state.currentSelectedGame.contentId);


        // const url = `${Constant.SPS_COMBINED_IP}${Constant.SUBS_PACK_API_PARAM}${this.state.currentSelectedGame.chargingKeyword}`
        // console.log('Url to hit for chargingKeyword payment : ', url)

        // const headers = this.subsHeaders;

        // axios.get(url, { headers })
        //     .then(response => response.data)
        //     .then(data => {
        //         if (data && data.code === errorConstantURL.SUCCESS_CODE) {
        //             console.log(data.data)
        //             if (data.data.subscriptionStatusCode === 0 || data.data.subscribtionStatus === Constant.ACTIVE) {
        //                 console.log("User has successfully made payment for selected Game, showing con gratulation popup "
        //                     + " and then redirecting to game directly")

        //                 this.togglePopup(Constant.PAYMENT_SUCCESSFUL_MESSAGE, "S", Constant.PAYMENT_SUCCESSFUL_ALERT, ['1']);

        //             }
        //             else {
        //                 console.log("User status is pending, showing pending popup for other response code")
        //                 this.togglePopup(Constant.PAYMENT_UNSUCCESSFUL_MESSAGE, "E", Constant.PAYMENT_UNSUCCESSFUL_ALERT, ['1'])
        //             }

        //         } else if (data && data.code === errorConstantURL.NO_DATA_FOUND) {
        //             if (data.data.requestStatus == '1') {
        //                 this.openRequiredGame(this.state.currentSelectedGame);
        //             } else {
        //                 console.log('Some failure response : ', data);
        //                 this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
        //             }
        //         }
        //         else {
        //             console.log('Some failure response : ', data);
        //             this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
        //         }
        //     })
        //     .catch(error => {
        //         console.log("Error Occured : ", error)
        //         this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
        //     })
    }


    checkSubscriptionAndMakePayment = (gameToPlay) => {
        this.checkUserSubscription(gameToPlay, this.subsHeaders)
    }

    checkUserSubscription = (gameToPlay, headers1) => {

    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

        const { lables } = this.state;
        var url = Constant.SPS_COMBINED_IP + "/v2/checkCoinsAndSub";
        const headers = {
            'appkey': metaMap.get('APP_KEY_SUBS'),
            'Content-Type': Constant.APPLICATION_JSON,
            'appid': sessionStorage["appid"],
            'deviceId': Constant.DEVICE_ID,
            'operator': metaMap.get('OPERATOR'),
            'access_token': sessionStorage.getItem("accessToken"),
            'channel': Constant.CHANNEL,
            'countryCode': metaMap.get('COUNTRY_CODE'),
            'msisdn': sessionStorage.getItem("username")
        };


        const body = {
            'msisdn': sessionStorage.getItem("username"),
            'countryCode': metaMap.get('COUNTRY_CODE'),
            "packName": gameToPlay.checkSubKeyword,
            "rewardName": "coin",
            "rewardFrom": "ABC",
            "operation": "debit",
            "rewardCount": 1,
            "blockRewardId": "",
            "info": "Deducted for " + gameToPlay.title,
            "operationSource": "wap",
            "appGameName": gameToPlay.title,
            "contentName": gameToPlay.contentName


        }

        axios.post(url, body, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data);
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                    this.openRequiredGame(gameToPlay);
                } else if (data && (data.code == errorConstantURL.REQUIRED_COINS_AVAILABLE)) {
                    this.togglePopup(lables.yourLoyaltyCoinsWillBeDeducted.replace("<xx>", gameToPlay.coinsRequired), "A", lables.confirmPayment, ['3', '4'])
                }
                else if (data && (data.code == errorConstantURL.INSUFFICIENT_COINS)) {
                    this.togglePopup(lables.toAccessThisGameYouNeedToPay.replace("<xx>", "Rs." + gameToPlay.chargingvalue).replace("<yy>", gameToPlay.validity), "A", lables.confirmPayment, ['3', '4'])
                } else {
                    this.togglePopup(lables.errorInProcessingRequest, 'E', lables.error, ['1'])
                }
            });


        /*
        console.log("current game is : ", this.state.currentSelectedGame)
        const url =`${Constant.SPS_COMBINED_IP}${Constant.CHECK_SUBS_API_PARAM}${gameToPlay.checkSubKeyword}` 
        console.log('Url to hit for checksubs : ', url)

        axios.get(url, {headers})
            .then(response => response.data)
            .then(data => {
                if(data && data.code === errorConstantURL.SUCCESS_CODE ){
                    console.log(data.data)
                    if(data.data.subscriberStausCode == "0" || data.data.subscriberStatus === Constant.ACTIVE){
                        console.log("User is Subscribed for selected Game, No need for confirmartion, redirecting to game directly")
                       // window.open(gameToPlay.gameurl, "_blank")
                       this.openRequiredGame(gameToPlay);
                    }else if(data.data.subscriberStausCode == "1" || data.data.subscriberStatus === Constant.NOT_SUBSCRIBED){
                        this.saveGameLogs(gameToPlay)
                        console.log("User is NOT Subscribed  for selected Game, taking user confirmartion using popup ")
                        console.log('If Confirmation is Yes, then call charginKeywordSub API for payment and then redirect to game')
                        this.togglePopup(Constant.CONFIRM_PAYMENT_MESSAGE.replace("<xx>", gameToPlay.chargingvalue), "A", Constant.CONFIRM_PAYMENT_ALERT, ['3','4'])
                    }else{
                        this.saveGameLogs(gameToPlay)
                        console.log("User status is pending, showing pending popup for other response code")
                        this.togglePopup(Constant.PENDING_SUBSCRIPTION_MESSAGE, "A", Constant.PENDING_SUBSCRIPTION_ALERT, ['1'])
                    }
                }else {
                    this.saveGameLogs(gameToPlay)
                        console.log('Some failure response : ', data);
                        this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
                    }
            })
            .catch(error => {
                console.log("Error Occured : ", error)
                this.saveGameLogs(gameToPlay)
                    
                this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
            })
            */

    }


    deductCoins(data1) {

        sessionStorage.setItem('validity', data1.validity);
        sessionStorage.setItem("rewardCount", data1.coinsRequired);
        sessionStorage.setItem("info", data1.title);
        sessionStorage.setItem("gameurl", data1.gameurl);

        const isLogin = sessionStorage.getItem("isLogin");
        if (!isLogin) {

            this.setState({
                showPopupOld: true,
                msgType: 'A',
                isConfirm: 'Y',
                alertMessage: 'Login Required!',
                msg: "You need to be logged in to access this feature. Do you wish to proceed?",
                loginRedirect: 'Y'
            });
        } else {

            this.setState({
                showPopupOld: true,
                msgType: 'A',
                isConfirm: 'Y',
                alertMessage: 'Confirm Coin Deduction!',
                msg: "You will be charged " + data1.coinsRequired + " coins for " + data1.title,
                // msg: "You will be charged " + data1.coinsRequired + " coins for playing this game. Please confirm to play game.",
                loginRedirect: 'C'
            });
        }

    }


    deduct() {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
        //   console.log(sessionStorage.getItem('validity'));

        var CurrentDate = moment().add(sessionStorage.getItem('validity'), 'd');
        // var CurrentDate = moment().add(1, 'd');


        var day = CurrentDate.format('DD');
        var month = CurrentDate.format('MM');
        var year = CurrentDate.format('YYYY');
        var hh = CurrentDate.format('HH');
        var mm = CurrentDate.format('mm');
        var ss = CurrentDate.format('ss');

        var validTill = year + '-' + month + '-' + day + 'T' + hh + ':' + mm + ':' + ss + '.000+0530'

        console.log(validTill + '--')


        const headers = {
            'Content-Type': 'application/json',
            'appkey': metaMap.get('APP_KEY_SUBS'),
            'operator': metaMap.get('OPERATOR'),
            'msisdn': sessionStorage.getItem("username")
        };

        const body = {
            "rewardName": "coin",
            "rewardFrom": "tambola_win",
            "operation": "debit",
            "rewardCount": sessionStorage.getItem("rewardCount"),
            "info": "Deducted for " + sessionStorage.getItem("info"),
            "operationSource": "WAP",
            "appGameName": sessionStorage.getItem("info"),
            "appGameValidity": validTill

        }
        this.setState({ enableLoader: true })

        axios.post(constantURL.SPS_COMBINED_IP + "/v1/userOperation", body, { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == 2000)) {

                    logEvents(constantURL.SPEND_VIRTUAL_CURRENCY)

                    this.setState({
                        showPopupOld: false,
                    });

                    this.props.updateGameCount(this.state.currentCategory.categoryId, this.state.currentSelectedGame.contentId);

                    this.openRequiredGame(this.state.currentSelectedGame);

                } else if (data.code == 2004) {
                    this.setState({
                        showPopupOld: true,
                        msgType: 'E',
                        isConfirm: 'N',
                        alertMessage: 'Sorry!',
                        msg: "You don't have sufficent coin balance to play this game! Please add more coins to your account.",
                        loginRedirect: 'INC'
                    });
                } else {
                    this.setState({ logoutPopup: true })
                }
                this.setState({ enableLoader: false })
                sessionStorage.removeItem("rewardCount");
                sessionStorage.removeItem("validity");
                sessionStorage.removeItem("info");
                sessionStorage.removeItem("gameurl");

            });
    }


    redirectToRequiredGame = (gameToPlay) => {

     
        const { lables } = this.state;
        //Current game is set ti state variable because we need it when payment popup gives confirmation.
        //At that time it cannot be passed through a method
        // this.props.updateGameCount(this.state.currentCategory.categoryId, gameToPlay.contentId);

        this.setState({
            currentSelectedGame: gameToPlay
        })
        console.log("inside redirectToRequiredGame : ", gameToPlay.gameurl)
        const isLogin = sessionStorage.getItem("isLogin");
        if (isLogin) {
            console.log('User is logged In, checking game type to make decision. Game type is ', gameToPlay.gametype)

            if (gameToPlay.showPlayButton) { //hardcode
                this.openRequiredGame(gameToPlay);
            } else {
                if (gameToPlay.gametype.toUpperCase() === Constant.GAME_TYPE_FREE) {
                    this.openRequiredGame(gameToPlay);
                }
                else {
                    if (sessionStorage.getItem("loginType") && sessionStorage.getItem("loginType") == "normal") {
                        this.deductCoins(gameToPlay);

                    } else if (sessionStorage.getItem("loginType") == "social") {
                      this.setState({
                        showPopupOld: true,
                        msgType: "A",
                        isConfirm: "Y",
                        alertMessage: "Alert!",
                        msg:
                          "Mobile number is mandatory to access this functionality. Please provide your mobile number.",
                        loginRedirect: "EP",
                      });
                      return false;
                    } else {
                      this.setState({
                        showPopupOld: true,
                        msgType: 'E',
                        isConfirm: 'Y',
                        alertMessage: 'Alert!',
                        msg: "You need to be logged in to access this feature. Do you wish to proceed?",
                        loginRedirect: 'LOGIN'
                      });
                      return false;
                    }
            
                    // if (sessionStorage.getItem("loginType") == "social") {
                    //     sessionStorage.setItem('mobNo', 'null');
       
                    //     this.setState({
                    //         showPopupOld: true,
                    //         msgType: 'A',
                    //         isConfirm: 'Y',
                    //         alertMessage: 'Alert!',
                    //         msg: "You need to update your mobile number to access this feature. ",
                    //         // msg: "You will be charged " + data1.coinsRequired + " coins for playing this game. Please confirm to play game.",
                    //         loginRedirect: 'EP'
                    //     });
                    //     return;
                    // }
            
                    // this.togglePopup(Constant.CONFIRM_PAYMENT_MESSAGE.replace("<xx>", gameToPlay.price), "A", Constant.CONFIRM_PAYMENT_ALERT, ['3', '4'])
                    // this.checkSubscriptionAndMakePayment(gameToPlay);;
                    //    alert('AA')
                    // this.deductCoins(gameToPlay);
                }
            }
        }
        else {
            console.log('User is NOT logged In, showing popup to login')
            this.setState({
                redirectScreen: Constant.LOGIN_SCREEN_TITLE
            })
            this.togglePopup(lables.youNeedToLoginForFeature, 'A', lables.loginRequired, ["1", "2"])
            //If YES, want to login then redirect to login page and after successful login, redirect to respective Game Page
        }
    }

    onDisplayMenu(obj) {
        this.setState({ isShowMenu: !this.state.isShowMenu });
    }


    openProfile() {
        if (sessionStorage.getItem("isLogin")) {
            this.props.history.push(FRONTEND_NAME + "/profile");
        }
        else {
            this.props.history.push(FRONTEND_NAME + "/sociallogin");
        }

    }


    togglePopup = (message, msgType, alert, buttonArray) => {

        console.log('==>>KAKAKAKA>>' + this.state.loginRedirect)
        this.setState({
            showPopup: !this.state.showPopup,
            msg: message,
            msgType: msgType,
            alert: alert,
            displayButtons: buttonArray
        })

    }

    togglePop() {

        this.setState({
            showPopup: !this.state.showPopup,
        });
    }
    togglePopOld() {

        if (this.state.loginRedirect == 'Y') {
            this.props.history.push(FRONTEND_NAME + "/logout")
        }
        // if (this.state.loginRedirect === 'EP') {
        //     this.props.history.push(FRONTEND_NAME + "/editprofilenew")
        // }
        // if (this.state.loginRedirect === 'LOGIN') {
        //     this.props.history.push(FRONTEND_NAME + "/sociallogin")
        // }
        
        
        this.setState({
            showPopupOld: !this.state.showPopupOld
        });
    }

    togglePopupOld() {
        console.log('MAGGGI')
        this.setState({
            showPopupOld: !this.state.showPopupOld
        });
        if (this.state.loginRedirect === 'EP') {
            this.props.history.push(FRONTEND_NAME + "/editprofilenew")
        }
        if (this.state.loginRedirect === 'LOGIN') {
            this.props.history.push(FRONTEND_NAME + "/sociallogin")
        }
        
        if (this.state.loginRedirect == 'Y') {
            this.props.history.push(FRONTEND_NAME + "/logout")
        }
        if (this.state.loginRedirect == 'INC') {
            this.props.history.push(FRONTEND_NAME + "/buyplaycoins")
        }

        if (this.state.loginRedirect == 'C') {
            this.deduct()
        }
    }

    openRequiredGame = (gameToPlay) => {
        // alert('A')
        console.log("inside openRequiredGame method ")
        this.props.history.push({
            pathname: FRONTEND_NAME + '/gameview',
            //state: 
            state: {
                game: gameToPlay,
                gameScreen: 'paidgames',
                currentCategory: this.props.location.state.currentCategory,
                from: this.props.location.state.from
            }
        })
    }

    back() {
        this.props.history.push({
            pathname: FRONTEND_NAME + '/home'
        });
    }

    getAPICachedContent = () => {

        this.props.fetchGameList(this.props.location.state.from, this.state.currentCategory, 0);

        this.saveLogs(this.state.currentCategory.categoryName)
    }

    getContent = () => {
        console.log('HIT==>>>>>')
        var currentItems = this.state.currentItems + 1;

        const { lables } = this.state;

        const headers = {
            'Content-Type': Constant.APPLICATION_JSON,
            'appid': sessionStorage["appid"],
            'deviceId': Constant.DEVICE_ID,
            'operator': Constant.OPERATOR,
            'Access_token': sessionStorage.getItem("accessToken"),
            'channel': Constant.CHANNEL,
            'Msisdn': sessionStorage.getItem("username")
        };

        let url = "";
        if (this.props.location.state.from == "bundle") {
            url = Constant.CONTENT_COMBINED_IP + "/v1/fetchViewAllByCategoryNameAndDynamicId?categoryName=bundleGames&dynamicId=" + this.props.location.state.currentCategory.id + "&pageNo=" + currentItems;
        }
        else {
            url = Constant.CONTENT_COMBINED_IP + "/v1/fetchCategories?categoryId=" + this.state.currentCategory.categoryId + "&pageNo=" + currentItems;;//Constant.PAID_ID;
        }

        axios.get(url, { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {

                    var subCategoriesDetail = [];
                    if (this.props.location.state.from == "bundle") {
                        subCategoriesDetail = data.data.categories[0].contentList;
                    } else {
                        subCategoriesDetail = data.data.categories[0].subCategories;
                    }
                    var popList = [];

                    if (subCategoriesDetail) {
                        var pG = this.props.location.state.from == "bundle" ? subCategoriesDetail : subCategoriesDetail[0].content3;   //Trending Paid Games
                        let protocol = window.location.protocol;
                        var tempList = this.state.trendingPaidGames;

                        for (var i = 0; i < pG.length; i++) {
                            tempList.push({
                                id: 'c' + i,
                                title: pG[i].title,
                                aliasname: pG[i].aliasname,
                                noOfUsersDynamic: pG[i].noOfUsersDynamic,
                                previewImageUrl: pG[i].previewImageUrl,
                                savedImageUrl: pG[i].savedImageUrl,
                                gameurl: protocol == "https:" ? pG[i].secondarygameurl : pG[i].gameurl,
                                price: pG[i].chargingcurrency + ' ' + pG[i].chargingvalue,
                                coinsRequired: pG[i].coinsRequired,
                                // coinsRequired: 50,
                                gametype: pG[i].gametype,
                                chargingKeyword: pG[i].chargingKeyword,
                                checkSubKeyword: pG[i].checkSubKeyword,
                                chargingvalue: pG[i].chargingvalue,
                                chargingcurrency: pG[i].chargingcurrency,
                                replacedynamicparam: pG[i].replacedynamicparam,
                                showPlayButton: pG[i].showPlayButton,
                                contentId: pG[i].contentId,
                                contentName: pG[i].contentName,
                                validity: pG[i].validity,

                                // contentName: pG[i].contentName,

                            });
                            this.cachedImageConfigure(pG[i].contentName, (pG[i].savedImageUrl) ? pG[i].savedImageUrl : pG.previewImageUrl);
                        }

                        //    tempList.push(popList);
                        console.log(tempList)

                        this.setState({
                            trendingPaidGames: tempList,
                            currentItems: currentItems
                        });
                    }
                    this.setState({ enableLoader: false })
                    this.saveLogs(this.state.currentCategory.categoryName)

                } else {
                    this.saveLogs(this.state.currentCategory.categoryName)
                    this.setState({ enableLoader: false, hasMoreItems: false })
                    this.togglePopup(lables.somethingWentWrongInLoadingData, 'E', lables.error, ['1'])
                }
            }).catch(error => {
                console.log('There is Error in fetching View All Content for Instant games : ', error)
                this.togglePopup(lables.somethingWentWrongInLoadingData, 'E', lables.error, ['1'])
            })

    }

    hasContentId(id) {
        // console.log("Resturning " + this.state.loadedImage.has(id))
        return this.state.loadedImage.has(id);
    }

    hasContentName(id) {
        //console.log("Resturning " + this.state.cachedImage.has(id))
        return this.state.cachedImage.has(id);
    }

    getContentName(id) {
        // console.log("Resturning " + this.state.cachedImage.has(id))
        return this.state.cachedImage.get(id);
    }

    cachedImageConfigure(contentName, imageUrl) {
        localForage.getItem(contentName)
            .then(returnData => {
                if (!returnData) {
                    this.convertImgToBase64URL(imageUrl, function (base64Img) {
                        (base64Img) && localForage.setItem(contentName, base64Img)
                    });
                }
            })
    }

    convertImgToBase64URL(url, callback, outputFormat) {
        var img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
            var canvas = document.createElement('CANVAS'),
                ctx = canvas.getContext('2d'), dataURL;
            canvas.height = img.height;
            canvas.width = img.width;
            ctx.drawImage(img, 0, 0);
            dataURL = canvas.toDataURL(outputFormat);
            callback(dataURL);
            canvas = null;
        };
        img.src = url;
    }

    addLoadedImage(id) {
        //        console.log("Hi");
        if (!this.state.loadedImage.has(id)) {
            // console.log("Adding " + id)
            this.setState(({ loadedImage }) => ({
                loadedImage: new Set(loadedImage).add(id)
            }));
        }
    }

    saveGameLogs(detail) {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
        const cookies = new Cookies();
        var url = Constant.APPLOGGER_COMBINED_IP + "/v1/saveDownloadLogs";

        const headers = {
            'Content-Type': 'application/json',
            'appid': metaMap.get('APP_ID'),
            'operator': metaMap.get('OPERATOR'),
            'msisdn': sessionStorage.getItem("username"),
            'channel': 'WAP'
        };

        const requestBody = {
            "content_name": detail.title,
            "session_id": cookies.get('uniquevhgsession'),
            "country": "India",
            "circle": "All",
            "packname": detail.chargingKeyword,
            "appname": metaMap.get('APPLICATION_NAME'),
            "content_type": 'game',
            "param1": detail.gametype,
            "param2": "",
            "substatus": '',
            "winningStatus": '',
            "gameStatus": "FAILURE",
            "response": "Success",
            "dwn_flag": "0"
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == errorConstantURL.SUCCESS_CODE) {
                } else {
                }
            });
    }


    render() {
        const { loadedImage, showPopup, showPopupOld, msg, msgType, alert, displayButtons,
            //trendingPaidGames, 
            currentCategory, lables } = this.state;
        let trendingPaidGames = [];
        let currentItems = 0;
        let hasMore = true;
        const {
            enableLoader,
            categories
        } = this.props.gameList;

        if (categories.has(currentCategory.categoryId)) {
            const fetchedCategories = categories.get(currentCategory.categoryId);
            trendingPaidGames = fetchedCategories.trendingPaidGames;
            currentItems = fetchedCategories.currentItems;
            hasMore = fetchedCategories.hasMore;
        }

        if (categories.has(currentCategory.id)) {
            const fetchedCategories = categories.get(currentCategory.id);
            trendingPaidGames = fetchedCategories.trendingPaidGames;
            currentItems = fetchedCategories.currentItems;
            hasMore = fetchedCategories.hasMore;
        }

        var displayGames = [];
        // console.log(loadedImage);
        trendingPaidGames.forEach(function (list) {
            //console.log(list);

            displayGames.push(
                <div key={list.id} className="Coin-games">
                    <div className="gamess" onClick={() => this.redirectToRequiredGame(list)} >
                        {(this.hasContentName(list.contentName)) ?
                            <img src={this.getContentName(list.contentName)} style={{ display: (this.hasContentId(list.contentId) ? "inline" : "none") }} onLoad={this.addLoadedImage.bind(this, list.contentId)} alt=""></img>
                            : <img src={list.savedImageUrl ? list.savedImageUrl : list.previewImageUrl} onLoad={this.addLoadedImage.bind(this, list.contentId)} style={{ display: (this.hasContentId(list.contentId) ? "block" : "none") }} ></img>
                        }
                        {/* <img src={list.savedImageUrl ? list.savedImageUrl : list.previewImageUrl} onLoad={this.addLoadedImage.bind(this, list.contentId)} style={{display : (this.hasContentId(list.contentId) ? "block" : "none")}} ></img> */}

                        {(!this.hasContentId(list.contentId)) && <img className="skeleton-loader" src={games} alt="" />}

                        <div className="for-line-new">
                            <div className="coin-ppp">
                                <p className="coin-g-a">{list.aliasname.length > 19 ? list.aliasname.substr(0, 19) + '...' : list.aliasname}</p>
                                <p className="coin-g-b">{list.noOfUsersDynamic} {lables.played}</p>
                            </div>
                            <div className={list.showPlayButton ? "coins-div" : "coins-div-new"}>
                                <div className={list.showPlayButton ? "coin-amt-n" : "coin-amt-n1"}>
                                    {/* <a href="#">{!list.showPlayButton ? lables.play?lables.play:"PLAY" : 
                                        <img src={coinshardy} /> <span> {list.coinsRequired}</span>
                                        <p>Pay Coins</p>
                                        }</a>  */}
                                    {list.showPlayButton ? <p className="paycoins">PLAY</p> :
                                        <>
                                            <div className="coin-value">
                                                <img src={coinshardy} /><span className="paycoins"><b>{list.coinsRequired}</b></span>
                                            </div>
                                            <p className="coin-label paycoins">Pay Coins</p>
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );

        }.bind(this));

        // console.log("_-------------------------------------------------_---------------------------_");
        // console.log(this.props);
        // console.log(this.props.gameList);
        return (
            <div className="container-home" style={{ overflow: 'hidden' }} id="container-home"  >
                {/* {this.state.enableLoader ? */}
                {this.props.enableLoader ?
                    <Loading />
                    : null
                }
                {
                    this.state.logoutPopup ?
                        <LogoutPopup
                            redirectPopup={this.sessionOut.bind(this)}
                        /> : null
                }




                {showPopup ?
                    <PopupNew
                        status={msgType}
                        text={msg}
                        alertType={alert}
                        closePopup={this.togglePopup}
                        closePopupWithTask={this.performTaskOnClose}
                        displayButtons={displayButtons}
                        isConfirm={this.state.isConfirm}

                    /> : null}

                {showPopupOld ?
                    <Popup
                        status={msgType}
                        text={msg}
                        alertType={this.state.alertMessage}
                        buttonName={this.state.loginRedirect == 'C' ? 'CONFIRM' : 'OK'}
                        closePopup={this.togglePopupOld.bind(this)}
                        closePopupNotNow={this.togglePopOld.bind(this)}
                        isConfirm={this.state.isConfirm}
                    /> : null}
                <div className="header-leaderboard line-it">
                    <img src={back} onClick={this.back.bind(this)} className="float-lft"></img>
                    <span className="header-text float-lft">{this.props.location.state.from == "bundle" ? currentCategory.bannerName : currentCategory.categoryName}</span>
                </div>
                {/* {this.state.isNeedToCheckSTW ?
                    <div id="revoke-modal" className={this.state.isShowSpin ? 'revokeModalShow' : 'revokeModalHide'} >
                        {(this.state.isShowSpin ? < SpinTheWheel /> : null)} </div>
                    : null} */}

                <div className="content" id="content" style={{ overflow: 'auto' }} >
                    <InfiniteScroll
                        // dataLength={this.state.trendingPaidGames.length} //This is important field to render the next data
                        dataLength={trendingPaidGames.length} //This is important field to render the next data
                        //next={() => this.getContent()}
                        next={() => this.props.fetchGameList(this.props.location.state.from, this.state.currentCategory, currentItems)}
                        // hasMore={this.state.hasMoreItems}
                        hasMore={hasMore}
                        scrollableTarget="content"
                    // loader={<h4 className="loadingDetails">Loading...</h4>}
                    >
                        {displayGames}
                    </InfiniteScroll>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const gameList = state.gameList

    const {
        categories,
        enableLoader,
        showPopup,
        msg,
        msgType,
        loginRedirect,
        alert,
        displayButtons
    } = state.gameList

    // console.log(state);

    return {
        enableLoader,
        gameList,
        categories,
        showPopup,
        msg,
        msgType,
        alert,
        loginRedirect,
        displayButtons
    };
}

export default connect(mapStateToProps, { fetchGameList, updateGameCount })(withRouter(PaidGames));