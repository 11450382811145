import React from 'react'
import axios from 'axios';
import '../styles/profile.css';
import back from '../images/backNew.svg';
import RedemptionCenter from '../images/redemptioncenter.svg';
import One97Poweredby from '../images/one97Poweredby.png';
import logouticon from '../images/logouticon.png';
import langicon from '../images/languageicon.png';
import next from '../images/next.svg';
import m1 from '../images/m1.svg';
import m2 from '../images/m2.svg';
import m3 from '../images/m3.svg';
import m4 from '../images/m4.svg';
import m5 from '../images/m5.svg';

import PrivacyPolicy from '../images/privacyPolicy.svg';
import Tnc from '../images/tnc.svg';

import logo from '../images/bigClown.svg';
import SocialMedia from './socialMedia';
import { withRouter } from 'react-router'
import profileImg from '../images/profileImg.png';
import * as constantURL from '../Constants/constants'
import * as Constant from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Loading from './loading'
import Popup from './popup';
import Cookies from 'universal-cookie';
import { FRONTEND_NAME } from '../Constants/constants'
import toggleArrow from '../images/languageToggle.png'
import { logEvents } from "./Analytics";
import localForage from 'localforage'

class ProfileCollapsed extends React.Component {
    constructor(props) {
        super(props);
        let lablesData = JSON.parse(sessionStorage["lablesData"]);
        this.state = {
            sharePopup: false,
            statFlag: false,
            enableLoader: false,
            showPopup: false,
            lables: constantURL.getLables(),
            activeLang: sessionStorage["lang"],
            activeLangData: lablesData.dialectSupported.filter((item) => item.dialect.languagekey == sessionStorage["lang"])[0]
        }
    }

    componentDidMount() {
        sessionStorage.setItem("mobNo", sessionStorage.getItem("mobileNumber"));

        // if('serviceWorker' in navigator) {
        //     window.addEventListener('load', () => {
        //         navigator.serviceWorker.register('../serviceworker-bigwin.js')
        //             .then((reg) => console.log('Success: ', reg.scope))
        //             .catch((err) => console.log('Failure: ', err));
        //     })
        // }

        // window.location.reload();
        if(!window.location.hash) {
            window.location = window.location + '#loaded';
            window.location.reload();
        }
        sessionStorage.setItem('status', 'false');

        let deferredPrompt;
        const addBtn = document.querySelector('#createicon');
        addBtn.style.display = 'none';
        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            deferredPrompt = e;
            // Update UI to notify the user they can add to home screen
            addBtn.style.display = 'block';

            addBtn.addEventListener('click', (e) => {
                // hide our user interface that shows our A2HS button
                addBtn.style.display = 'none';
                // Show the prompt
                deferredPrompt.prompt();
                // Wait for the user to respond to the prompt
                deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                        console.log('User accepted the A2HS prompt');
                        this.saveDownloadLogs("A2HS", "CUSTOM", "ADDED");
                    } else {
                        this.saveDownloadLogs("A2HS", "CUSTOM", "DISMISSED");
                    }
                    deferredPrompt = null;
                });
            });
        });

        this.signInUser();
    }

    signInUser() {
        var url = '';

        if (sessionStorage.getItem("loginType") == "social") {
          url = constantURL.PROFILER_COMBINED_IP +
            "/v1/fetchUserDetailsSocial?username=" +
            sessionStorage.getItem("username");
        }
        else {
          url = constantURL.PROFILER_COMBINED_IP +
            "/v1/editUserDetails?username=" +
            sessionStorage.getItem("username");
        }
      
        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            msisdn: sessionStorage.getItem("username"),
            access_token: sessionStorage.getItem("accessToken"),
            appid: sessionStorage.getItem("appid"),
        };


        axios
            .get(url, {
                headers: headers,
            })
            .then((response) => response.data)
            .then((data) => {
                if (data && data.code == 2000) {
                    if (data.data.dateOfBirth) {
                        // var dob = data.data.dateOfBirth;
                        // var dob1 = dob.split("T");
                        // var dob2 = dob1[0].split("-");
                        sessionStorage.setItem("dob", data.data.dateOfBirth);
                    }
                    sessionStorage.setItem("nameSalutation", data.data.nickName);
                    sessionStorage.setItem("gender", data.data.gender);
                    sessionStorage.setItem("emailid", data.data.email);
                    sessionStorage.setItem("address", data.data.address);
                } else {
                }
            });
    }



    // componentDidUpdate(){
    // console.log('KK')
    //     this.componentDidMount();
    // }

    //saveDownloadLogs
    saveDownloadLogs = (content, category, subCategory) => {
        const cookies = new Cookies();
        var url = Constant.APPLOGGER_COMBINED_IP + "/v1/saveDownloadLogs";

        const headers = {
            'Content-Type': 'application/json',
            'appid': Constant.APP_ID,
            'operator': Constant.OPERATOR,
            'msisdn': sessionStorage.getItem("username"),
            'channel': 'WAP'
        };

        const requestBody = {
            "content_name": content,
            "session_id": cookies.get('uniquevhgsession'),
            "country": "India",
            "circle": "All",
            "packname": "A2HS",
            "appname": Constant.APPLICATION_NAME,
            "content_type": category,
            "param1": subCategory,
            "param2": "",
            "substatus": '',
            "winningStatus": '',
            "gameStatus": "Success",
            "response": "Success",
            "dwn_flag": "0"
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == errorConstantURL.SUCCESS_CODE) {
                } else {
                }
            });
    }

    closeSharePopup() {
        this.setState({ sharePopup: false });
    }

    showShare() {
        logEvents(constantURL.SHARE)
        if (navigator.share) {
            navigator.share({
                title: 'Big Win',
                //   text: 'Check out Big Win.',
                url: constantURL.APP_URL,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            this.setState({ sharePopup: true });
        }
    }


    editProfile() {
        const isLogin = sessionStorage.getItem("isLogin");
        if (isLogin) {
            sessionStorage.setItem('status', 'forward');
            this.props.history.push(FRONTEND_NAME + "/editprofilenew");
            console.log('User already registered')
        } else {
            sessionStorage.setItem('status', 'forward');
            this.props.history.push(FRONTEND_NAME + "/editprofilenew");
            //  this.props.history.push(FRONTEND_NAME+"/login");
        }
    }

    logout() {
        this.setState({ showPopup: true })
    }
    togglePopup() {
        this.setState({ showPopup: false })
    }

    clearSession() {
        sessionStorage.removeItem('isLogin')
        sessionStorage.removeItem('isGuestLogin')
        sessionStorage.removeItem('loginType')
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("nameSalutation");
        sessionStorage.removeItem("mobileNumber");
        sessionStorage.removeItem("profileImg");

        
        this.setState({ enableLoader: true })
        localForage.clear();
        sessionStorage.setItem('status', 'forward');

        var d = new Date,
            dformat = [d.getFullYear(), ('0' + d.getMonth() + 1).slice(-2),
            ('0' + d.getDate()).slice(-2)].join('-') + ' ' +
                [('0' + d.getHours()).slice(-2),
                ('0' + d.getMinutes()).slice(-2),
                ('0' + d.getSeconds()).slice(-2)
                ].join(':');

        const cookies = new Cookies();
        var url = constantURL.APPLOGGER_COMBINED_IP + "/v1/saveLogs";

        const headers = {
            'Content-Type': 'application/json',
            'appid': constantURL.APP_ID,
            'operator': constantURL.OPERATOR,
            'msisdn': sessionStorage.getItem("username"),
            'channel': 'WAP'
        };

        const requestBody = {
            "category": 'LOGOUT',
            "subCategory": "",
            "content": 'LOGOUT',
            "url": "/menu",
            "packname": "",
            "appname": constantURL.APPLICATION_NAME,
            "sessionid": cookies.get('uniquevhgsession'),
            "operator": constantURL.OPERATOR,
            "country": 'INDIA',
            "endtime": dformat,
            'param4': 'USER_LOGOUT'
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {

                const headers = {
                    'Content-Type': 'application/json',
                    'appid': constantURL.APP_ID,
                    'deviceId': constantURL.DEVICE_ID,
                    'operator': constantURL.OPERATOR,
                    'Access_token': sessionStorage.getItem('accessToken'),
                    'msisdn': sessionStorage.getItem('mobileNumber')

                };
                const cookies = new Cookies();
                axios.get(constantURL.PROFILER_COMBINED_IP + "/v1/logout?username=" + sessionStorage.getItem('username'), { headers }).then(response => response.data)
                    .then((data) => {
                        if (data && (data.code == errorConstantURL.SUCCESS_CODE)) {
                            sessionStorage.removeItem('isLogin')
                            sessionStorage.removeItem('isGuestLogin')
                            sessionStorage.removeItem('loginType')
                            sessionStorage.removeItem("username");
                            sessionStorage.removeItem("accessToken");
                            sessionStorage.removeItem("nameSalutation");
                            sessionStorage.removeItem("mobileNumber");
                            sessionStorage.removeItem("profileImg");

                            cookies.remove('hardyIsLogin', { path: '/' });
                            cookies.remove('hardyAccessToken', { path: '/' });
                            cookies.remove('hardyMobileNumber', { path: '/' });
                            cookies.remove('hardyOperator', { path: '/' });
                            cookies.remove('hardyCountry', { path: '/' });
                            cookies.remove('hardyUsername', { path: '/' });
                            cookies.remove('hardyNameSalutation', { path: '/' });
                            cookies.remove('hardyProfileImg', { path: '/' });
                            // cookies.remove('uniquevhgsession',{ path: '/' });
                            cookies.set('uniquevhgsession', '0', { path: '/' });

                            console.log('COOKIE REMOVED')

                            this.props.history.push(FRONTEND_NAME + "/sociallogin");
                        } else {
                            sessionStorage.removeItem('isLogin')
                            sessionStorage.removeItem('isGuestLogin')
                            sessionStorage.removeItem("username");
                            sessionStorage.removeItem("accessToken");
                            sessionStorage.removeItem("nameSalutation");
                            sessionStorage.removeItem("mobileNumber");
                            sessionStorage.removeItem("profileImg");


                            cookies.remove('hardyIsLogin', { path: '/' });
                            cookies.remove('hardyAccessToken', { path: '/' });
                            cookies.remove('hardyMobileNumber', { path: '/' });
                            cookies.remove('hardyOperator', { path: '/' });
                            cookies.remove('hardyCountry', { path: '/' });
                            cookies.remove('hardyUsername', { path: '/' });
                            cookies.remove('hardyNameSalutation', { path: '/' });
                            cookies.remove('hardyProfileImg', { path: '/' });
                            cookies.remove('uniquevhgsession', { path: '/' });

                            console.log('COOKIE REMOVED')

                            this.props.history.push(FRONTEND_NAME + "/sociallogin");

                        }
                        this.setState({ enableLoader: false })

                    });
            });
    }


    saveLogs(category) {
        const cookies = new Cookies();
        var url = constantURL.APPLOGGER_COMBINED_IP + "/v1/saveLogs";
    
        const headers = {
          "Content-Type": "application/json",
          appid: constantURL.APP_ID,
          operator: constantURL.OPERATOR,
          msisdn: sessionStorage.getItem("username"),
          channel: "WAP",
        };
    
        const requestBody = {
          category: category,
          subCategory: "",
          content: category,
          url: "/menu",
          packname: "",
          appname: constantURL.APPLICATION_NAME,
          sessionid: cookies.get("uniquevhgsession"),
        };
    
        axios
          .post(url, requestBody, { headers })
          .then((response) => response.data)
          .then((data) => {
            if (data && data.code == errorConstantURL.SUCCESS_CODE) {
            } else {
            }
          });
      }
    



    componentWillUnmount() {
        if (sessionStorage.getItem('status') == 'false') {
            this.props.history.push(FRONTEND_NAME + "/home");
        }
    }

    showFaq() {
        this.saveLogs('FAQ');
        sessionStorage.setItem('status', 'forward');
        this.props.history.push(FRONTEND_NAME + "/faq");
    }

    showHelp() {
        sessionStorage.setItem('status', 'forward');
        this.props.history.push(FRONTEND_NAME + "/help");
    }

    showPrivacyPolicy(){
        this.saveLogs('PRIVACY_POLICY');
        sessionStorage.setItem('status', 'forward');
        this.props.history.push(FRONTEND_NAME + "/privacypolicy");
    }

    showTnc(){
        this.saveLogs('TNC');
        sessionStorage.setItem('status', 'forward');
        this.props.history.push(FRONTEND_NAME + "/tnc");
    }

    showRedemptionCenter() {
        this.saveLogs('REDEMPTION_CENTER');
        sessionStorage.setItem('status', 'forward');
        this.props.history.push(FRONTEND_NAME + "/redemption");

    }

    showWinnings() {
        this.saveLogs('MY_WINNINGS');
        sessionStorage.setItem('status', 'forward');
        this.props.history.push(FRONTEND_NAME + "/mywinnings");
    }

    showChangePassword() {
        sessionStorage.setItem('status', 'forward');
        sessionStorage.setItem('changePassword', 'true');
        this.props.history.push(FRONTEND_NAME + "/resetmsisdn")

    }

    back() {
        sessionStorage.setItem('status', 'forward');
        this.props.history.push(FRONTEND_NAME + "/home");

    }


    statShow() {
        console.log('CC')
        this.setState(prevState => ({
            statFlag: !prevState.statFlag
        }));
    }

    changeLang = (lang) => {
        let lablesData = JSON.parse(sessionStorage["lablesData"]);
        let item = lablesData.dialectSupported.filter((item) => item.dialect.languagekey == lang)[0];

        //let item = 
        sessionStorage.setItem("selectedDialect", JSON.stringify(item));
        sessionStorage["lang"] = item.dialect.languagekey;
        sessionStorage["appid"] = item.appId;
        this.setState({ activeLang: item.dialect.languagekey });
        this.setState({ lables: constantURL.getLables() });

    };


    render() {

        console.log(this.state.statFlag)
        const { lables } = this.state;
        return (
            <div className="container-profile" style={{ padding: '0px', overflow:'auto' }}>
                <img src={back} onClick={this.back.bind(this)} className="p-back-1"></img>
                <span className="header-text float-lft mt14">Profile</span>

                {this.state.sharePopup ?
                    <SocialMedia
                        closePopup={this.closeSharePopup.bind(this)}
                    />
                    : null
                }

                {this.state.enableLoader ?
                    <Loading />
                    : null
                }

                {this.state.showPopup ?

                    <Popup
                        status='A'
                        text={lables.areYouSureToLogout}
                        alertType={lables.logout}
                        buttonName={lables.yes}
                        closePopup={this.clearSession.bind(this)}
                        closePopupNotNow={this.togglePopup.bind(this)}
                        cancelText={lables.notNow}
                        isConfirm='Y'
                    /> : null}

                {/* onClick={this.props.closeMenu} */}
                {/* <div className="p-header">
                    <img src={back} onClick={this.back.bind(this)} className="p-back"></img>
                    <img src={edit} className="p-edit" onClick={this.editProfile.bind(this)}></img>
                </div>
                <div className="p-image">
                    <img className="profileImage" src={sessionStorage.getItem("profileImg") ? sessionStorage.getItem("profileImg") : profileImg} ></img>
                </div>
                <p className="p-msisdn">{sessionStorage.getItem("nameSalutation")}</p> */}

                {/* <div className="p-sfh"> */}
                {/* <div className="p-share" onClick={this.showFaq.bind(this)}>
                        <img src={faq} class="xc"></img>
                        <span>FAQ</span>
                    </div>
                    <div className="p-share" onClick={this.showShare.bind(this)}>
                        <img src={share} className="pp-share"></img>
                        <span>Share</span>
                    </div> */}

                {/* <div className="p-share" onClick={this.showHelp.bind(this)}>
                        <img src={help}></img>
                        <span>Help</span>
                    </div> */}
                {/* </div> */}

                {/* <div className="p-winnings" onClick={this.showWinnings.bind(this)}>
                    <p className="p-ques">My Winnings</p>
                    <span className="pp"><span>Rs</span> <p className="p-ans">1000</p></span> 
                    <img src={viewWhite}></img>
                </div> */}
                {/* <div className="p-winnings-b" onClick={this.statShow.bind(this)}>
                    <p className="p-ques">Stats</p>
                    <img src={viewWhite}></img>
                </div>
                {this.state.statFlag ?
                    <div className="stats-grey"  >

                        <div className="one-stat">
                            <p className="stats-left">Total Hours Played</p>
                            <p className="stats-right">250</p>
                        </div>
                        <div className="one-stat">
                            <p className="stats-left">Total Hours Played</p>
                            <p className="stats-right">250</p>
                        </div>
                    </div> : null} */}

                {/* {sessionStorage.getItem('isLogin') ? <button className="logout-p" onClick={this.logout.bind(this)}>Logout</button> : null} */}
                <div className="profile-head" onClick={this.editProfile.bind(this)}>
                    <div className="ph-1">
                        <img src={sessionStorage.getItem("profileImg") ? sessionStorage.getItem("profileImg") || logo : logo} ref={img => this.img = img}  onError={() => this.img.src = logo}></img>
                        <p className="ph-1-p">
                            <p>{sessionStorage.getItem("nameSalutation")}</p>
                            {(sessionStorage.getItem("loginType") == "social") ?
                                null :
                                <p>{sessionStorage.getItem("mobileNumber")}</p>
                            }
                        </p>
                    </div>
                    <div className="ph-2">
                        <img src={next}></img>
                    </div>
                </div>
                <div style={{ height: '20px' }}></div>
                <div className="pc-list" onClick={this.showWinnings.bind(this)}>
                    <div className="pl-left">
                        <img src={m1}></img>
                    </div>
                    <div className="pl-right">
                        <p>My Winnings</p>
                        <img src={next}></img>
                    </div>
                </div>
                <div className="pc-list" onClick={this.showRedemptionCenter.bind(this)}>
                    <div className="pl-left">
                        <img src={RedemptionCenter}></img>
                    </div>
                    <div className="pl-right">
                        <p>{lables.redemptionCenter}</p>
                        <img src={next}></img>
                    </div>
                </div>

                {/* <div className="pc-list"  style={{paddingTop:'0px'}}>
                        <div className="pl-left" style={{paddingTop:"15px"}}>
                            <img src={langicon}></img>
                        </div>
                        <div className="pl-right" style={{paddingTop:'13px',paddingBottom:'8px'}}>
                            <div style={{float:'left'}}>
                                <p style={{marginTop:'0px'}}>{lables.language}</p>
                                <p style={{float:'none',textAlign:'left',color: 'rgba(255,255,255,0.5)',marginTop:'0px'}}>{this.state.activeLangData.dialect.languageName}</p>
                            </div>
                            <div style={{float:'right'}}>
                                <p className={`eng-lang ${this.state.activeLang=="en"?"active-lang":""}`} onClick={()=>this.changeLang("en")}>English</p>
                                <p className="arrow-img"><img src={toggleArrow}></img></p>
                                <p className={`mg-l-15 ${this.state.activeLang=="ar"?"active-lang":""}`} onClick={()=>this.changeLang("ar")}>Arabic</p>
                            </div>
                        </div>
                    </div> */}

                <div className="pc-list" onClick={this.showFaq.bind(this)}>
                    <div className="pl-left">
                        <img src={m2}></img>
                    </div>
                    <div className="pl-right">
                        <p>{lables.faq}</p>
                        <img src={next}></img>
                    </div>
                </div>

                <div className="pc-list" onClick={this.showPrivacyPolicy.bind(this)}>
                    <div className="pl-left">
                        <img src={PrivacyPolicy}></img>
                    </div>
                    <div className="pl-right">
                        <p>{lables.privacyPolicy? lables.privacyPolicy :'Privacy Policy'}</p>
                        <img src={next}></img>
                    </div>
                </div>

                <div className="pc-list" onClick={this.showTnc.bind(this)}>
                    <div className="pl-left">
                        <img src={Tnc}></img>
                    </div>
                    <div className="pl-right">
                        <p>{lables.tnc? lables.tnc :'T&C'}</p>
                        <img src={next}></img>
                    </div>
                </div>

                <div className="pc-list" id="createicon">
                    <div className="pl-left">
                        <img src={m4}></img>
                    </div>
                    <div className="pl-right">
                        <p>{lables.createHomeIcon}</p>
                        <img src={next}></img>
                    </div>
                </div>
                <div className="pc-list" onClick={this.showShare.bind(this)}>
                    <div className="pl-left">
                        <img src={m5}></img>
                    </div>
                    <div className="pl-right">
                        <p>{lables.share}</p>
                        <img src={next}></img>
                    </div>
                </div>

                <div className="pc-list" onClick={this.logout.bind(this)}>
                        <div className="pl-left">
                            <img src={logouticon} style={{padding: '2px', marginTop: '1px'}}></img>
                        </div>
                        <div className="pl-right">
                            <p>{lables.logout}</p>
                         </div>
                    </div>

                <div className="wap-bottom">
                    <p>Powered by</p>
                    <img src={One97Poweredby}></img>
                    <p>Version 1.2.0</p>
                </div>
            </div>
        )
    }
}

export default withRouter(ProfileCollapsed);
