import React, { createRef } from 'react'
import axios from 'axios';
import '../styles/style.css';
import { withRouter } from 'react-router'
import * as Constant from '../Constants/constants'
import * as Errorcode from '../Constants/errorConstants'
import Modal from 'react-modal';
import PopUp from './popup'
import back from '../images/back.png';
import Loading from './loading'
import $ from 'jquery';
import Cookies from 'universal-cookie';
import {FRONTEND_NAME} from '../Constants/constants';
Modal.setAppElement('#root')

class EnterOtp extends React.Component {

    constructor(props) {
        super(props)

        this.inputRef1 = createRef();
        this.inputRef2 = createRef();
        this.inputRef3 = createRef();
        this.inputRef4 = createRef();
        this.inputRef5 = createRef();
        this.inputRef6 = createRef();

        sessionStorage.setItem("otpCounts", 0)

        this.state = {
            username: this.props.location.state.username,
            userType: this.props.location.state.usertype,
            digit1: '',
            digit2: '',
            digit3: '',
            digit4: '',
            digit5: '',
            digit6: '',
            counter: 59,
            enableCounter: true,
            invalidOTP: false,
            showPopup: false,
            message: '',
            msgType: '',
            alert: '',
            hideButton: true,
            enableLoader: false,
            lables : Constant.getLables()
        }

    }

    componentDidMount() {

        if (sessionStorage.getItem('isLogin')=='true'){
            this.props.history.push({
                pathname: FRONTEND_NAME+"/home"
            })
        }
        
        console.log('mob no. is ', this.state.username)
        this.startCountDownTimer()
        $("#1, #2, #3, #4, #5, #6").on("keyup keypress blur",function (event) {  

            var elem = this;
            
            $(this).val($(this).val().replace(/[^0-9]/gi, ''));
            if(this.value.length>1){
                this.value = this.value.slice(0,1); 
            }setTimeout(function(){
            var key = event.keyCode || event.charCode;
            var isBackspace = (key==8 || key==46);
            if(isBackspace){
                if(elem.id == '1'){
                   
                     
                }
                else{
                    $("#"+(parseInt(elem.id)-1)).focus();
                }
                return false;
            }
        },100);
        });
    }

    startCountDownTimer = () => {
        this.otpInterval = setInterval(() => {
            this.setState(previousState => ({
                counter: previousState.counter - 1
            }))
        }, 1000)
    }

    togglePopup = (msg, msgType, alert, hideBtn) => {
        this.setState({
            showPopup: !this.state.showPopup,
            message: msg,
            msgType: msgType,
            alert: alert,
            hideButton: hideBtn
        })

    }

    handleChange = (prevInputRef, nextInputRef, event) => {
        console.log('-->>>')
        console.log(event)
        const fieldName = event.target.name
        
        var fieldValue = event.target.value
        console.log(fieldValue)

        if (fieldValue.length > 1) {
            fieldValue = fieldValue.slice(0, 1);
        }

        // if (!Number(fieldValue) && fieldValue != "") {
        //     return;
        // }
        this.setState({
            [fieldName]: fieldValue,
            invalidOTP : false
        }, () => {
            if (fieldValue === '') {
              //  console.log('KKAK')
              //  prevInputRef.current.focus()
            } else {
                nextInputRef.current.focus()
            }
        })
    }

    signInUser(userType) {
        this.setState({ enableLoader: true })

        console.log('Inside ' + userType + ' SIGNIN API')
        const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SIGNING_API_PARAM}`
        console.log("calling signin with url " + url);


        const headers = {
            'Content-Type': Constant.APPLICATION_JSON,
            'Accept': Constant.APPLICATION_JSON,
            'deviceId': Constant.DEVICE_ID,
            'appid': Constant.APP_ID,
        };

        //this.setState({ msisdn: this.props.location.state.mydata.msisdn });

        const requestBody = {
            username: this.state.username,
            password: Constant.PASSWORD
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                console.log("signInUser response is : " + { data });
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                    console.log(data)

                    //alert("user has been validated successfully");
                    //After Session is created, user is redirected to Successful HomeScreen 
                    this.createLoginSession(data.data);
                    console.log('Redirecting ' + userType + ' to successful HOME screen... ')
                    this.openHomeScreen()
                    //this.openSetPassScreen();


                } else {
                    this.setState({
                        showPopup: true,
                        msgType: 'E',
                        msg: "Error in login!"
                    });
                }
                this.setState({ enableLoader: false })

            });
    }


    signUpUser(userType) {
        this.setState({ enableLoader: true })

        console.log('Inside SignUp user')
        const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SIGNUP_API_PARAM}`;
        console.log("calling SignUp with url " + url);

        const headers = {
            'Content-Type': Constant.APPLICATION_JSON,
            'Accept': Constant.APPLICATION_JSON,
            'deviceId': Constant.DEVICE_ID,
            'appid': Constant.APP_ID,
        };

        const requestBody = {
            username: this.state.username,
            password: Constant.PASSWORD,
            operatorName: Constant.MOB_OPERATOR
        }

        axios.post(url, requestBody, {
            headers: headers
        })
            .then(response => response.data)
            .then(data => {

                console.log("SignUp Response : " + { data })
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                    console.log("SignUp is successfull, going for SignIn")
                    this.signInUser(userType)
                } else {
                    this.setState({
                        showPopup: true,
                        msgType: 'E',
                        msg: "Error in login!"
                    });
                }
                this.setState({ enableLoader: false })

            });
    }

    createLoginSession = (data) => {

        const cookies = new Cookies();
        const current = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(current.getFullYear() + 1);

        cookies.set('hardyIsLogin', true, { path: '/'});
        cookies.set('hardyAccessToken', data.user.accessToken, { path: '/'});
        cookies.set('hardyMobileNumber', data.user.username, { path: '/' });
        cookies.set('hardyOperator', data.user.operator.id, { path: '/' });
        cookies.set('hardyCountry', data.user.country.id, { path: '/' });
        cookies.set('hardyUsername', data.user.username, { path: '/' });


        sessionStorage.setItem("isLogin", 'true');
        sessionStorage.removeItem("isGuestLogin");
        sessionStorage.setItem("accessToken", data.user.accessToken)
        sessionStorage.setItem("mobileNumber", data.user.username)
        sessionStorage.setItem("operator", data.user.operator.id)
        sessionStorage.setItem("country", data.user.country.id)
        sessionStorage.setItem("username", data.user.username)
        if (data.user.nickName) {
            sessionStorage.setItem("nameSalutation", data.user.nickName)
            cookies.set('hardyNameSalutation', data.user.nickName, { path: '/' });
     
        }
        else {
            cookies.set('hardyNameSalutation', data.user.username, { path: '/' }); 
            sessionStorage.setItem("nameSalutation", data.user.username)
        }
        console.log(data.user.userImagePath)
        console.log(data.user.userImagePath == null)
        console.log(data.user.userImagePath == 'null')

        if (data.user.userImagePath != null) {
            sessionStorage.setItem("profileImg", data.user.userImagePath)
            cookies.set('hardyProfileImg', data.user.userImagePath, { path: '/' });
     
        }

        this.saveLogs(Constant.LOGIN_ACTION);
     
    }

    saveLogs(category) {
        const cookies = new Cookies();
        var url = Constant.APPLOGGER_COMBINED_IP + "/v1/saveLogs";
        
        const headers = {
            'Content-Type': 'application/json',
            'appid': Constant.APP_ID,
            'operator': Constant.OPERATOR,
            'msisdn': sessionStorage.getItem("mobileNumber"),
            'channel': 'WAP'
        };

        const requestBody = {
            "category" : category,
            "subCategory" : "",
            "content" : category,
            "url" : "/login",
            "packname" :"",
            "appname" :Constant.APPLICATION_NAME,
            "sessionid" : cookies.get('uniquevhgsession')
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                } else {
                }
            });
    }




    checkUserType = (data) => {
        const {lables} = this.state;
        var changePassword= sessionStorage.getItem('changePassword');
        if(changePassword != "true")
        {

        switch (data.screenFlag) {

            case Constant.HOME_SCREEN:
                console.log("Inside HOME_SCREEN section : ")
                if (data.userType === Constant.NEW_USER || data.userType === Constant.EXISTING_USER) {

                    //After Session is created, user is redirected to Successful HomeScreen 
                    this.createLoginSession(data);
                    // if (this.state.userType === Constant.NEW_USER) {
                    //     this.togglePopup(Constant.OTP_VALIDATION_SUCCESS, 'S', Constant.REGISTRATION_SUCCESS_ALERT, true)
                    // }
                    // if (this.state.userType === Constant.EXISTING_USER) {
                        this.togglePopup(lables.changeYourAvatarInProfileSection, 'S', lables.loginSuccessfull, true)
                    // }
                    console.log('Popup has been toggled according to : ', this.state.userType)
                }
                break;
            case Constant.SET_PWD_SCREEN:
                console.log("Inside SET_PWD_SCREEN section : ")
                if (data.userType === Constant.NEW_USER) {
                    console.log('Redirecting NEW user to SET PWD screen... Since there is No PWD Validation ' +
                        'Screen, so calling SIGNUP API internally for New user ...')
                    //Here, First User is Signedup and if Successful, Signed Up then User will be signedIN and
                    //session will be created and Then User is Redirected to successfull Home Screen!! 
                    //this.signUpUser(Constant.NEW_USER);
                    
                    // this.openSetPassScreen();

                    this.createLoginSession(data);
                    this.togglePopup(lables.changeYourAvatarInProfileSection, 'S', lables.loginSuccessfull, true)
                

                }
                break;
            case Constant.VALIDATE_PWD_SCREEN:
                console.log("Inside VALIDATE_PWD_SCREEN section : ")
                if (data.userType === Constant.EXISTING_USER) {
                    console.log('Redirecting EXISTING user to VALIDATE PWD screen...Since there is No PWD Validation ' +
                        'Screen, so calling SIGNIN API internally for New user ...')
                    // this.signUpUser(Constant.EXISTING_USER);
                    //this.signInUser(Constant.EXISTING_USER);
                    this.openHomeScreen();
                }
                break;
            default:
                console.log("Error Fetching userType from DB")
        }

    }
    else 
    {
        this.openSetPassScreen();
    }


    }

    submitOtp = () => {
        const {lables} = this.state;
        this.setState({ enableLoader: true })

        console.log('Inside submitOtp method')
        const url = `${Constant.PROFILER_COMBINED_IP}${Constant.VALIDATE_LOGIN_OTP_API_PARAM}`;
        console.log("calling VALIDATE_OTP with url " + url);


        const headers = {
            'appid': Constant.APP_ID,
            'Content-Type': Constant.APPLICATION_JSON,
            'Accept': Constant.APPLICATION_JSON,
            'channel': 'wap',
            'operator': Constant.OPERATOR,
            //    'appkey':Constant.APPKEY
        };

        const requestBody = {
            "username": this.state.username,
            //   "otp": this.state.digit1+""+this.state.digit2+""+this.state.digit3+""+this.state.digit4, //Constant.OTP,
            "otp": this.state.digit1 + "" + this.state.digit2 + "" + this.state.digit3 + "" + this.state.digit4,
            "countryCode": Constant.COUNTRY_CODE

        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                console.log("Response : " + { data })
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                    this.checkUserType(data.data)
                } else if (data && data.code === Errorcode.OTP_NOT_MATCH) {
                    console.log(data.msg);
                    this.setState({
                        invalidOTP: true
                    })
                } else {
                    //  console.log("Something went wrong with code : ", data.code)
                    this.togglePopup(lables.pleaseTryAfterSometime, 'E', lables.error, true)
                }
                this.setState({ enableLoader: false })

            });
    }

    openLoginScreen = () => {
        this.props.history.push({
            pathname: FRONTEND_NAME+"/login",
            state : {
                userName : this.state.username
            }
        })
    }

    openHomeScreen = () => {
        sessionStorage.setItem("loginType","normal");
        this.props.history.push({
            pathname: FRONTEND_NAME+'/home',
            state: {
                username: this.state.username
            }
        });
    }

    openSetPassScreen = () => {
        this.props.history.push({
            pathname: FRONTEND_NAME+'/setpassword',
            state: {
                username: this.state.username
            }
        });
    }

    resetTimer = (flag) => {
        clearInterval(this.otpInterval)
        this.setState({
            counter: 59,
            enableCounter: flag
        })
    }

    back() {
        this.props.history.push(FRONTEND_NAME+"/login");
    }


    
    resendOtp = (event) => {
        const {lables} = this.state;
        if(!this.state.enableCounter){

            this.setState({ enableLoader: true })

            console.log('Resend OTP username : ', this.state.username)

            const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SEND_OTP_API_PARAM}${this.state.username}&type=${Constant.RESEND_OTP}`;
            console.log("Url to call : ", url);

            const headers = {
                'appid': Constant.APP_ID,
                'deviceId': Constant.DEVICE_ID,
                'Content-Type': Constant.APPLICATION_JSON,
                'Accept': Constant.APPLICATION_JSON
            }

            // GET call to Submit Login or SignUp
            axios.get(url, { headers })
                .then(response => response.data)
                .then(data => {
                    console.log(data)
                    if (data && data.code === Errorcode.SUCCESS_CODE) {
                        console.log("OTP resend successfully with response ", data.msg)
                        this.resetTimer(true)
                        this.startCountDownTimer()

                    } else if (data && data.code === Errorcode.OTP_LIMIT_EXHAUSTED) {
                        //Resend OTP after 15 mins
                        console.log(data.msg);
                        this.togglePopup(this.state.lables.otpLimitExhausted, 'A', this.state.lables.otpLimitReached, true)
                    } else {
                        console.log(Constant.ERROR_OCCURED);
                        this.togglePopup(lables.errorInProcessingRequest, 'E', lables.error, true)
                    }
                    this.setState({ enableLoader: false })
                })
                .catch(error => {
                    console.log("Error Occured : ", error)
                    this.setState({ enableLoader: false })
                    this.togglePopup(lables.errorInProcessingRequest, 'E', lables.error, true)
                })
        }

    }

    // handleKeyPress(event){
    //     console.log('jkjkj')
    //     console.log(event.value)
    // }

    handleKeyUp(event) {
        var fieldValue = event.target.value;
        console.log(fieldValue)

       

        if (fieldValue.length > 1) {
            fieldValue = fieldValue.slice(0, 1);
        }

        if (!Number(fieldValue) && fieldValue != "") {
            return;
        }
    }

    render() {

        const { username, counter, showPopup, message, msgType, enableCounter, alert, 
            digit1, digit2, digit3, digit4, digit5, digit6, hideButton, invalidOTP,lables } = this.state

        // const enabled = (digit1 != '' && digit2 != '' && digit3 != '' &&
        //     digit4 != '')

        const enabled = (digit1 != '' && digit2 != '' && digit3 != '' &&
            digit4 != '')

        if (counter === 0 && enableCounter === true) {
            this.resetTimer(false)
        }

        return (
            <div className="container-login">
                <div className="header-leaderboard edit-pad login-border" style={{borderBottom: '0px solid rgba(255, 255,255, 0.1)'}}>
                    <img src={back} alt="" onClick={this.openLoginScreen} className="float-lft"></img>
                </div>
                {
                    showPopup ?
                        <PopUp text={message} status={msgType} alertType={alert} hideButton={hideButton} closePopup={this.togglePopup, this.openHomeScreen} /> :
                        null
                }
                {this.state.enableLoader ?
                    <Loading />
                    : null
                }
                <p className="ve-p" style={{marginTop:'57px'}}>{lables.verifyMobileNumber?lables.verifyMobileNumber:'Verify Mobile Number'} {this.state.username}</p>
                <p className="msisdn-p" style={{marginBottom:'24px'}}>{lables.enterOtp}</p>
                <p className="msisdn-sub-p">{lables.oneTimePasswordOTP}</p>
                <div className="enter-otp">
                {/* <input type="number" oninput="this.value=(parseInt(this.value)||0)"   pattern="[0-9]" inputmode="numeric" className={enabled ? "enter-o" : "enter-o enter-o-gry"} name="digit1" id="1" onChange={(event) => this.handleChange(this.inputRef1, this.inputRef2, event)} */}
                    <input type="password" oninput="this.value=(parseInt(this.value)||0)"   pattern="[0-9]" inputmode="numeric"  name="digit1" id="1" onChange={(event) => this.handleChange(this.inputRef1, this.inputRef2, event)}
                         className={invalidOTP ? "enter-o enter-o-gry" : (enabled ? "enter-o enter-o-active" : "enter-o enter-o-gry-ph")} 
                         autoFocus={true}  ref={this.inputRef1} maxLength='1' value={digit1}></input>
                    <input type="password"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" pattern="[0-9]" inputmode="numeric" className={invalidOTP ? "enter-o enter-o-gry" : (enabled ? "enter-o enter-o-active" : "enter-o enter-o-gry-ph")} 
                          name="digit2" id="2" onChange={(event) => this.handleChange(this.inputRef1, this.inputRef3, event)}
                        ref={this.inputRef2}  maxLength='1' value={digit2}></input>
                    <input  type="password"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" pattern="[0-9]" inputmode="numeric" className={invalidOTP ? "enter-o enter-o-gry" : (enabled ? "enter-o enter-o-active" : "enter-o enter-o-gry-ph")} 
                          name="digit3" id="3" onChange={(event) => this.handleChange(this.inputRef2, this.inputRef4, event)}
                        ref={this.inputRef3} maxLength='1' value={digit3}></input>
                    <input type="password"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" pattern="[0-9]" inputmode="numeric" className={invalidOTP ? "enter-o enter-o-gry" : (enabled ? "enter-o enter-o-active" : "enter-o enter-o-gry-ph")} 
                          name="digit4" id="4" onChange={(event) => this.handleChange(this.inputRef3, this.inputRef4, event)}
                        ref={this.inputRef4} maxLength='1' value={digit4}></input>
                    {/* <input type="password"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" pattern="[0-9]" inputmode="numeric" className={invalidOTP ? "enter-o enter-o-gry" : (enabled ? "enter-o" : "enter-o enter-o-gry-ph")} 
                           name="digit5" id="5" onChange={(event) => this.handleChange(this.inputRef4, this.inputRef6, event)}
                        ref={this.inputRef5} maxLength='1' value={digit5}></input>
                    <input type="password"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" pattern="[0-9]" inputmode="numeric" className={invalidOTP ? "enter-o enter-o-gry" : (enabled ? "enter-o" : "enter-o enter-o-gry-ph")} 
                          name="digit6" id="6" onChange={(event) => this.handleChange(this.inputRef5, this.inputRef6, event)}
                        ref={this.inputRef6} maxLength='1' value={digit6}></input> */}

                </div>
                { this.state.enableCounter?<p className="re-oo">{lables.resendOTPIn} {this.state.counter} {lables.sec? lables.sec:'secs'}</p>:<p className="re-oo" onClick={this.resendOtp}>{lables.sendAgainOTP}</p>}
                <p className="error-txt-otp" hidden={!invalidOTP}>{lables.invalidOtp}</p>
            
                <div className="button-submit" style={{marginTop:'50px'}}>
                    <button type="submit" className={invalidOTP ? "submit-login disable-gry" : (enabled ? "submit-login" : "submit-login disable-gry")} onClick={this.submitOtp} disabled={!enabled}>{lables.confirm}</button>
                </div>


                {/* <div className="otp-div" ><p className="send-otp" onClick={ this.resendOtp} >Send Again OTP</p>
                    {
                        enableCounter ?
                            <span className="seconds" >({counter}s)</span> :
                            null
                    }
                </div>
                <p className="change" onClick={this.openLoginScreen}>Change Phone Number</p> */}


            </div>
        )
    }
}

export default withRouter(EnterOtp);