import React from 'react'
import axios from 'axios';
import '../styles/home.css';

import profileImg from '../images/profileImg.png';
import blueTick from '../images/blueTick.png';
import help from '../images/help.png';
import faq from '../images/faqNew.png';
import share from '../images/share.png';
import viewAll from '../images/viewAll.png';




import { withRouter } from 'react-router'
class Menu extends React.Component {

    render() {

        return (



<div id="revoke-modal" className="revokeModal">
                <div id="mySidenav" className="sidenav">
                   
                    <div className="menu-profile">
                        <img className="menu-pimg" src={profileImg} ></img>
                         <p className="menu-pname">9999999999</p>
                        <img class="white-right" src={blueTick}></img>
                        
                        
                    </div>
                     <div className="menu-tag">
                        <a href="#" ><span className="tag-logo profile-logo" ><img src={help} ></img></span><div className="underline-it"><span className="tag-name">Subscription</span><img className="side-i" src={viewAll}></img></div></a>
                        <a href="#" ><span className="tag-logo profile-logo" ><img src={faq} ></img></span><div className="underline-it"><span className="tag-name">Winner</span><img className="side-i" src={viewAll}></img></div></a>
                        <a href="#" ><span className="tag-logo lead-logo" ><img src={share} ></img></span><div className="underline-it"><span className="tag-name">Playing History</span><img className="side-i" src={viewAll}></img></div></a>
                       
                    </div> 
                </div>
            </div>


)
}
}

export default withRouter(Menu);
