import React, { Component, createRef } from 'react'
import { withRouter } from 'react-router'
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import axios from 'axios';
import Loading from './loading'
import $ from 'jquery';
import checked from '../images/checked.png';
import Cookies from 'universal-cookie';
import error from '../images/redError.png';
import downarrow from '../images/downarrow.svg';
import Searchcountry from './searchCountry'
import EditMobileOTPModal from './editMobileOTP'

class EditMobileModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showEditName: false,
            showEditImage: false,
            errorMsg: '',
            errorFlag: 'd',
            name: '',
            address: '',
            mobNo: '',
            userN: '',
            enableLoader: false,
            logoutPopup: false,
            lables: constantURL.getLables(),
            countryDetails: this.props.countryDetails,
            countryCode: this.props.countryDetails.countryCode,
            countryName: 'IN',
            ipAddress: '127.0.0.1',
            showChooseCountry: false,
            showOtp:false,
            
      
        }
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.closeOtpPopup = this.closeOtpPopup.bind(this);
        this.showMobileNoInMainPage = this.showMobileNoInMainPage.bind(this);
    }


    componentDidMount() {
        this.getIpData();

        this.setState({
            mobNo: sessionStorage.getItem("mobNo") == 'null' ? '' : sessionStorage.getItem("mobNo"),
        })
    }

    getIpData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        console.log(res.data);
        this.setState({ ipAddress: res.data.IPv4 });
    }


    openCountrySelect = () => {
        this.setState({ showChooseCountry: true })
    }

    closeOtpPopup= () =>{
        this.setState({showOtp:false})
        this.props.closePopup();
    }

    hideSearchCountry = () => {
        this.setState({ showChooseCountry: false })
    }

    chooseCounry = (country) => {
        console.log(country)
        this.setState({
            showChooseCountry: false,
            countryName: country.alpha2code,
            countryCode: country.countryCode,
            countryDetails: country
        })

    }


    handleKeyUp(event) {

        const { lables } = this.state;
        this.setState({
            mobNo: event.target.value,
            //  name: event.target.value
        });

        if (/^[6-9]{1}[0-9]{9}$/.test(event.target.value)) {
            this.setState({
                errorFlag: 'n',
                mobNo: event.target.value,
                errorMsg: '',
                disable: false
            });
        } else {
            this.setState({
                errorFlag: 'e',
                address: event.target.value,
                errorMsg: 'Only digits are allowed',
                disable: true
            });
        }
    }

    setMobileNo() {
        if (!this.state.disable) {
            // this.props.setMobNo(this.state.mobNo.trim())
            // this.props.setCountryDetails(this.state.countryDetails)
            this.setState({showOtp:true})
        };
    }

    showMobileNoInMainPage(){
        this.props.setMobNo(this.state.mobNo.trim())
        this.props.setCountryDetails(this.state.countryDetails)
    }


    render() {
        const { lables } = this.state;
        return (
            <div className="modal-e">

                {this.state.showChooseCountry ?
                    <Searchcountry cancel={this.hideSearchCountry}
                        chooseCounry={this.chooseCounry} />
                    : null
                }

                {this.state.showOtp ?
                    <EditMobileOTPModal showMobileNoInMainPage={this.showMobileNoInMainPage} mobNo={this.state.mobNo} countryDetails={this.state.countryDetails} closeOtpPopup={this.closeOtpPopup}/>
                    : null
                }


                <div className="edit-info">
                    <div className="cancel" style={{ borderBottom: '1px solid rgba(255,255,255,0.15)', padding: '22px 16px 22px 16px' }}>
                        <p className="cancel-pe" onClick={this.props.closePopup}>{lables.cancel}</p>
                        <p className="done-e" onClick={() => this.setMobileNo()}>{lables.next? lables.next:'Next'}</p>
                    </div>

                    <div className="enter-info">
                        <div className="what-e">
                            <div className="editMsisdnArrow" onClick={this.openCountrySelect}>
                                <span style={{ opacity: 0.6 }}> +{this.state.countryCode} </span>
                                <img src={downarrow} style={{ verticalAlign: 'middle', marginTop: '4px !important' }}></img>
                            </div>
                            <div>
                                <input type="text" ref="searchStringInput" value={this.state.mobNo} onChange={this.handleKeyUp} maxLength="10" placeholder={lables.enteryourmobileno ? lables.enteryourmobileno : 'Enter your mobile number'}  ></input>
                            </div>
                        </div>
                    </div>
                    <p className="error-e-new">{this.state.errorMsg}</p>
                </div>
            </div>
        )
    }
}

export default withRouter(EditMobileModal);