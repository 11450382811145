import { 
    FETCH_HOME_PAGE_START,
    FETCH_HOME_PAGE_SUCCESS,
    FETCH_HOME_PAGE_ERROR,
    FETCH_HOME_PAGE_CLEAR, 
} from '../container/Constants/fetchHomePageConstant'

const initialState = {
    enableLoader: true,
    logoutPopup: false,
    isInitialLoad: true,
    homeData : {
        banners: [
            {
              id: "1",
              title: "title",
              bannerName: "bannerName",
              imageUrl: "imageUrl",
              redirectUrl: "redirectUrl",
              gameurl: "game",
              bannerType: "ban",
              bannerText: "PLAY NOW",
            },
        ],
        winnerBanner: [],
        fantasyBanner: [],
        contestBanner: [],
        mobileVerificationBanner:[],
        popularGames : { categoryName: "", gameList: [] },
        arcadeGames: { categoryName: "", gameList: [] },
        keepPlaying: { categoryName: "", gameList: [] },
        subCategory: ""
    }
}
export const fetchHomePageReducers = (state = initialState, action) => {
    switch(action.type){
        case FETCH_HOME_PAGE_START:
            return initialState;
        case FETCH_HOME_PAGE_SUCCESS:
            return {enableLoader: false, isInitialLoad: false, logoutPopup: false, homeData: action.payload}
        case FETCH_HOME_PAGE_ERROR:
            return {
                ...state,
                enableLoader: false, 
                isInitialLoad: true, 
                logoutPopup: true
            }
        default:
            return state
    }
}

