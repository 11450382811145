import React from 'react'
import axios from 'axios';
import '../styles/leaderboard.css';
import { withRouter } from 'react-router'
import back from '../images/backNew.svg';
import profileImage from '../images/profileImage.png';
import checked from '../images/checked.png';
import viewAll from '../images/newView.svg';
import next from '../images/next.svg';
import error from '../images/redError.png';
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import profileImg from '../images/bigClown.svg';
import ReactDOM, { findDOMNode } from 'react-dom';
import Loading from './loading'
import LogoutPopup from './logoutPopup'
import Cookies from 'universal-cookie';
import EditNameModal from './editNameModal'
import {FRONTEND_NAME} from '../Constants/constants'
class EditProfile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showEditName: false,
            showEditImage: false,
            errorMsg: '',
            errorFlag: 'd',
            name: '',
            userNTemp: '',
            userN: '',
            enableLoader: false,
            logoutPopup: false,
            status:'false',
            lables : constantURL.getLables()
        }

        this.uploadFile = this.uploadFile.bind(this);
        this.fileUpload = React.createRef();
        this.showFileUpload = this.showFileUpload.bind(this);

    }

    componentDidMount() {
        this.setState({
            userN: sessionStorage.getItem("nameSalutation")=="null"? "":sessionStorage.getItem("nameSalutation"),
            userNTemp: sessionStorage.getItem("nameSalutation")=="null"? "":sessionStorage.getItem("nameSalutation"),
            name: sessionStorage.getItem("nameSalutation")=="null"? "":sessionStorage.getItem("nameSalutation"),
        })
    }

    componentWillUnmount() {
        console.log('KARAN1111')
        if (this.state.status == 'false') {
            this.props.history.push(FRONTEND_NAME+"/editprofilenew");
        }
    }

    back() {
        console.log('KARAN1111')
        // if (this.state.status == 'false') {
            this.props.history.push(FRONTEND_NAME+"/editprofilenew");
        // }
    }

    openEditPhoto() {
        this.props.history.push(FRONTEND_NAME+"/editPhoto");
    }

    
    openNameEdit() {
        this.setState({
            showEditName: true
        })
    }

    sessionOut(){
        this.saveLogs('LOGOUT');
        this.props.history.push(FRONTEND_NAME + "/logout")
    }

    logout(){
        this.setState({logoutPopup:true});
       }

    closeNameEdit(name) {

        if(name!=''){
            this.setState({userN:name});
        }

        this.setState({
            showEditName: false
        })
    }



    showFileUpload() {

        this.setState({status:'forward'})
        this.fileUpload.current.click();
    }


    saveLogs(category) {
    
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
    
        const cookies = new Cookies();
        var url = constantURL.APPLOGGER_COMBINED_IP + "/v1/saveLogs";
    
        const headers = {
          "Content-Type": "application/json",
          appid: metaMap.get('APP_ID'),
          operator: metaMap.get('OPERATOR'),
          msisdn: sessionStorage.getItem("username"),
          channel: "WAP",
        };
    
        const requestBody = {
          category: category,
          subCategory: "",
          content: category,
          url: "/editname",
          packname: "",
          appname: metaMap.get('APPLICATION_NAME'),
          sessionid: cookies.get("uniquevhgsession"),
        };
    
        axios
          .post(url, requestBody, { headers })
          .then((response) => response.data)
          .then((data) => {
            if (data && data.code == errorConstantURL.SUCCESS_CODE) {
            } else {
            }
          });
      }
    
    

    uploadFile(event) {
        if (event && event.target && event.target.files && event.target.files.length > 0) {
            // this.setState({
            // 	image: URL.createObjectURL(event.target.files[0]),
            // 	// imageData: event.target.files[0]
            // },()=>{
            this.props.history.push({
                pathname: FRONTEND_NAME+'/editPhoto',
                state: {
                    pic: URL.createObjectURL(event.target.files[0])
                }
                //    });  
            });

        } else {
            this.setState({
                showPopup: true,
                msgType: 'A',
                msg: "Image is mandatory."
            });
        }
    }

    render() {
        console.log('RENDER 1')
        const {lables} = this.state;
        return (
            <div className="container-leaderboard ">
                {this.state.enableLoader ?
                    <Loading />
                    : null
                }
                {
                    this.state.logoutPopup ?
                        <LogoutPopup
                            redirectPopup={this.sessionOut.bind(this)}
                        /> : null
                }
                <div className="header-leaderboard pd-18" style={{height: '55px', border: '0px'}}>
                    <img src={back} alt="" onClick={this.back.bind(this)} className="float-lft"></img>
                    <span className="header-text float-lft">{lables.editProfile}</span>
                </div>

                <div className="content-leaderboard"  >
                    <div className="e-div" onClick={this.showFileUpload} >
                        <p>{lables.avatar}</p>
                        <div className="e-relate">

                            <input accept="image/*" id="fileInput" type="file" onChange={this.uploadFile} ref={this.fileUpload} style={{ display: 'none' }} />
                            <img src={sessionStorage.getItem("profileImg") ? sessionStorage.getItem("profileImg") : profileImg} ref={img => this.img = img}  onError={() => this.img.src = profileImg} className="e-profile" ></img>
                            <img src={next} style={{height:'18px !important'}} className="e-view"></img>
                        </div>

                    </div>

                    <div className="e-div extra-p" onClick={this.openNameEdit.bind(this)}>
                        <p className="wrong-s">{lables.nickname}</p>
                        <div className="e-relate">
                            <p className="e-msisdn">{this.state.userN}</p>
                            <img src={next}  style={{height:'18px !important'}} className="e-view t-u"></img>
                        </div>

                    </div>

                </div>
                {this.state.showEditName ?
                    // <div className="edit-info">
                    //     <div className="cancel">
                    //         <p className="cancel-p" onClick={this.closeNameEdit.bind(this)}>CANCEL</p>
                    //         <p className="done" onClick={this.updateName.bind(this)}>DONE</p>
                    //     </div>

                    //     <div className="enter-info">
                    //         <div className="what-e">
                    //             <input type="text" ref="searchStringInput" value={this.state.userNTemp} onChange={this.handleKeyUp} maxLength="20" placeholder="Enter Your Nickname" v ></input>
                    //             {/* <p className="max-e">25/25</p> */}
                    //             {this.state.errorFlag == 'n' ? <img src={checked} ></img> : this.state.errorFlag == 'e' ? <img src={error} ></img> : null}
                    //         </div>
                    //         <p className="error-e">{this.state.errorMsg}</p>
                    //     </div>
                    // </div>


                    <div id="revoke-modal" className={this.state.showEditName ? 'revokeModalShow' : 'revokeModalHide'} >
                        {this.state.showEditName ? <div id="myModal">
                            <EditNameModal
                                logoutUser = {this.logout.bind(this)}
                                closeNameEdit={this.closeNameEdit.bind(this)}
                            />
                        </div> : null}
                    </div>

                    : null}
            </div>


        )
    }
}

export default withRouter(EditProfile);