import React from 'react'
import '../styles/home.css';
import { withRouter } from 'react-router';

import back from '../images/backNew.svg';
import copyCoupon1 from '../images/copyCoupon1.svg';

import redeemIcon from '../images/redeemIcon.svg';
import greentick from '../images/greentick.svg';
import alertCoupon from '../images/alertCoupon.svg';
import CloseCoupon from '../images/close.svg'
import folder from '../images/folder.svg';
import info from '../images/info.svg';
import axios from 'axios';
import * as constantURL from '../Constants/constants'
import Loading from './loading'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Cookies from 'universal-cookie';

class Redemption extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            enableLoader: false,
            showCouponDetails: false,
            showCouponRedemptionDetails: false,
            showCouponRedemptionDetailsNew: false,
            rewardRedemption: [],
            originalRedemtion: [],
            selectedValue: null,
            copied: false,
            isSearch: false,
            searchedString: '',
            showCloseButton: false,
            message: '',
            showErrorMessage: false,
            showCouponRedemptionDetailsError: false

        }
        this.closePopup = this.closePopup.bind(this);
        this.back = this.back.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.closeCouponPopup = this.closeCouponPopup.bind(this);
        this.openCouponPopup = this.openCouponPopup.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.search = this.search.bind(this);
        this.closesearch = this.closesearch.bind(this);
    }
    closePopup() {
        this.setState({ showCouponDetails: !this.state.showCouponDetails });
    }


    handleChange(event) {
        if (event.target.value.length > 0) {
            this.setState({ showCloseButton: true });
        }
        else {
            this.setState({ showCloseButton: false });
        }
        // console.log(event.target.value);
        var filteredList = [];
        {
            this.state.originalRedemtion.filter(name => name.redeemName.toLowerCase().includes(event.target.value.toLowerCase())
                || name.redeemPartnerName.toLowerCase().includes(event.target.value.toLowerCase())).map(filteredName => (
                    filteredList.push(filteredName)
                    // console.log(filteredName)
                ))
        }

        console.log(filteredList)

        this.setState({ rewardRedemption: filteredList, showErrorMessage: true, isSearch: true, searchedString: event.target.value });

    }

    openPopup() {
        this.setState({ showCouponDetails: !this.state.showCouponDetails });
    }

    openDetailPopup(list) {
        this.setState({
            selectedValue: list,
            showCouponDetails: !this.state.showCouponDetails
        })
    }

    search() {
        this.setState({ isSearch: true, rewardRedemption: this.state.originalRedemtion, showErrorMessage: false });
    }

    closesearch() {
        this.setState({
            isSearch: false, rewardRedemption: this.state.originalRedemtion
        });
    }

    saveLogs(category) {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
        
        const cookies = new Cookies();
        var url = constantURL.APPLOGGER_COMBINED_IP + "/v1/saveLogs";

        const headers = {
            'Content-Type': 'application/json',
            'appid': metaMap.get('APP_ID'),
            'operator': metaMap.get('OPERATOR'),
            'msisdn': sessionStorage.getItem("username"),
            'channel': 'WAP'
        };

        const requestBody = {
            "category": category,
            "subCategory": "",
            "content": category,
            "url": "/paidgames",
            "packname": "",
            "appname": metaMap.get('APPLICATION_NAME'),
            "sessionid": cookies.get('uniquevhgsession')
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == 2000) {
                } else {
                }
            });
    }


    closeCouponPopupOk() {
        this.setState({ showCouponRedemptionDetailsNew: true, showCouponRedemptionDetails: false })
    }


    closeCouponPopup() {
        console.log(this.state.originalRedemtion)
        this.setState({
            showCouponRedemptionDetails: false,
            copied: false,
            // rewardRedemption: this.state.originalRedemtion,
            showCouponRedemptionDetailsError: false,
            showCouponRedemptionDetailsNew: false
        });
    }
    openCouponPopup() {
        this.setState({ showCouponRedemptionDetails: !this.state.showCouponRedemptionDetails });
    }

    componentDidMount() {
        this.fetchRedemptionDetails();
    }


fetchRedemptionDetails(){
    
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
    // this.saveLogs("Redemption_Center");

    //sessionStorage.setItem("gcmId", notificationToken)
    const headers = {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'appid': metaMap.get('APP_ID'),
        'Access_token': sessionStorage.getItem("accessToken"),
        'Deviceid': constantURL.DEVICE_ID,
        'channel': constantURL.CHANNEL,
        'operator': metaMap.get('OPERATOR'),
        'Msisdn': sessionStorage.getItem("username"),
        'countryCode': metaMap.get('COUNTRY_CODE'),
        'appkey': metaMap.get('APPKEY')
    };

    const url = constantURL.SPS_COMBINED_IP + "/v3/redemptionCentre";
    this.setState({ enableLoader: true });

    axios.get(url, { headers }).then(response => response.data)
        .then((data) => {
            if (data && data.code == 2000) {
                this.setState({ enableLoader: false });

                var rewardRedemption = data.data.rewardRedemption;
                var list = [];
                for (var i = 0; i < rewardRedemption.length; i++) {
                    list.push({
                        id: 'redemption' + i,
                        couponCategoryId: rewardRedemption[i].couponCategoryId,
                        redeemId: rewardRedemption[i].redeemId,
                        redeemPartnerName: rewardRedemption[i].redeemPartnerName,
                        redeemName: rewardRedemption[i].redeemName,
                        redeemIcon: rewardRedemption[i].redeemIcon,
                        redeemValue: rewardRedemption[i].redeemValue,
                        redeemPriceType: rewardRedemption[i].redeemPriceType,
                        redeemPriceValue: rewardRedemption[i].redeemPriceValue,
                        redeemRewardType: rewardRedemption[i].redeemRewardType,
                        redeemRewardValue: rewardRedemption[i].redeemRewardValue,
                        redeemRewardIcon: rewardRedemption[i].redeemRewardIcon,
                        redeemAppname: rewardRedemption[i].redeemAppname,
                        redeemCountPerUser: rewardRedemption[i].redeemCountPerUser,
                        redeemExpiryDate: rewardRedemption[i].redeemExpiryDate,
                        redeemShortDescription: rewardRedemption[i].redeemShortDescription,
                        redeemLongDescription: rewardRedemption[i].redeemLongDescription,
                    });
                }
                
                this.setState({ rewardRedemption: list, originalRedemtion: list });
             
                if (list.length > 0) {
                    this.setState({ message: ''});
                } else {
                    this.setState({ message: 'Currently you don’t have sufficient coins to claim offers. More visits to the portal can help you earn more coins. Keep visiting !' });
                }
            
                console.log(list);
            } else {
                this.setState({ message: 'Error in fetching details.', enableLoader: false});
              }
        })
        .catch(err => {
            this.setState({ message: 'Error in fetching details.',enableLoader: false});
        })
}

    back() {
        this.props.history.push({
            pathname: constantURL.FRONTEND_NAME + '/profile',
            state: {
                url: "home",
            }
        })
    }

    backOneStepBack(){
        this.setState({
            isSearch: false, rewardRedemption: this.state.originalRedemtion
        });
    
    }

    pay() {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
        const headers = {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'appid': metaMap.get('APP_ID'),
            'Access_token': sessionStorage.getItem("accessToken"),
            'Deviceid': constantURL.DEVICE_ID,
            'channel': constantURL.CHANNEL,
            'operator': metaMap.get('OPERATOR'),
            'Msisdn': sessionStorage.getItem("username"),
            'countryCode': metaMap.get('COUNTRY_CODE'),
            'appkey': metaMap.get('APPKEY')
        };


        const url = constantURL.SPS_COMBINED_IP + "/v3/buyCoupon?redeemId=" + this.state.selectedValue.redeemId + "&info=" + this.state.selectedValue.redeemName
        // this.setState({ enableLoader: true });

        axios.get(url, { headers }).then(response => response.data)
            .then((data) => {
                if (data && data.code == 2000) {
                    this.setState({
                        selectedValue: data.data.couponDetails,
                        showCouponRedemptionDetails: true,
                        showCouponDetails: false,
                        copied: false
                    });
                } else {
                    // alert('No record found.');
                    this.setState({ showCouponDetails: false, showCouponRedemptionDetailsNew: false, showCouponRedemptionDetailsError: true });

                }
            })
            .catch(err => {
                this.setState({ enableLoader: false });
                console.log(err);
            })
    }

    openMyCoupons() {
        this.props.history.push({
            pathname: constantURL.FRONTEND_NAME + '/mycoupon',
            state: {
                url: "home",
            }
        })

    }

    render() {

        var redemptionRow = [];
        this.state.rewardRedemption.forEach(function (list) {
            // alert(list.redeemName.substring(0,5));
            redemptionRow.push(
                <div className="sub-card" onClick={() => this.openDetailPopup(list)}>
                    <div className="sc-head">
                        <div className="sc-left">
                            <p>{list.redeemPartnerName}</p>
                            <p>{list.redeemName.length>18?list.redeemName.substring(0,18)+"...": list.redeemName}</p>
                        </div>
                        <div className="sc-right">
                            <img alt='vi' alt="" src={list.redeemIcon}></img>
                        </div>
                    </div>
                    <div className="sc-bottom sc-b1">
                        <span><img alt='vi' alt="" src={info}></img></span>
                        <span>Worth &#8377;{list.redeemValue}</span>
                    </div>

                    <p className="info-div">
                        <div>
                            <span><img alt='vi' alt="" src={redeemIcon}></img></span>
                            <span className="info-span">{list.redeemRewardValue}</span>
                        </div>
                        <div>
                            <span style={{ float: 'right', marginTop: "5px" }} className="expire">Exp {list.redeemExpiryDate ? list.redeemExpiryDate.slice(0, 6) : ''} </span>
                        </div>
                    </p>
                </div>);

        }.bind(this));


        return (
            <div className="container-home">

                {this.state.enableLoader ?
                    <Loading />
                    : null
                }
                {this.state.showCouponDetails ?
                    <div className="coupon-modal">
                        <div className="view-coupon">
                            <img alt='vi' onClick={this.backToPrevious} className="close-popup" src={CloseCoupon} alt="" onClick={this.closePopup.bind(this)} ></img>
                            <div className="view-coupon-body">
                                <div className="view-coupon-ticket">
                                    <p className="rnk"><img alt='vi' src={this.state.selectedValue.redeemIcon} alt="" /></p>
                                    <p className="wn-typ">{this.state.selectedValue.redeemName}</p>
                                    <p className="cpn-des">{this.state.selectedValue.redeemPartnerName}</p>
                                    <p className="cc-popup">{this.state.selectedValue.redeemPartnerName} coupon worth <span>₹</span>{this.state.selectedValue.redeemValue}.</p>
                                    <p className="cc-popup">Your {this.state.selectedValue.redeemRewardValue} Loyalty Coins will be deducted.</p>
                                    <button className="cc-pay-btn" onClick={this.pay.bind(this)}>PAY</button>
                                    <p className="coupon-note">• Expiry Date: {this.state.selectedValue.redeemExpiryDate} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}

                {this.state.showCouponRedemptionDetailsNew ?
                    <div className="coupon-modal">
                        <div className="view-coupon">
                            <img alt='vi' onClick={this.backToPrevious} className="vcb-close-popup" style={{ marginTop: '11px' }} src={CloseCoupon} alt="" onClick={this.closeCouponPopup.bind(this)} ></img>
                            <div className="view-coupon-body  vcb">
                                <div className="view-coupon-ticket" style={{ marginTop: "-56px" }}>
                                    <p className="rnk"><img alt='vi' src={this.state.selectedValue.redeemIcon} alt="" /></p>
                                    <p className="wn-typ">{this.state.selectedValue.redeemName}</p>
                                    <p className="cpn-des">{this.state.selectedValue.redeemPartnerName}</p>

                                    {this.state.selectedValue.redeemPriceType.toLowerCase() != 'game' ? <div> <p className="cpn-ttl">Coupon Code</p>
                                        <CopyToClipboard text={this.state.selectedValue.couponCode}
                                            onCopy={() => this.setState({ copied: true })}>
                                            <p className="cpn-cd">
                                                <span>{this.state.selectedValue.couponCode}</span>
                                                <span className="cpn-file-logo"><img alt='vi' src={copyCoupon1} alt="" /></span>
                                            </p>

                                        </CopyToClipboard>
                                        {this.state.copied ? <p className="copied">Copied!</p> : null}
                                    </div> : null
                                    }

                                    <p className="wn-dt">Received on {this.state.selectedValue.redeemedDate} </p>
                                    <p className="cc-popup">{this.state.selectedValue.couponLongDescription}.</p>
                                    <button className="cc-pay-btn" onClick={this.closeCouponPopup.bind(this)}>OK</button>
                                    <p className="wn-dt">Expiry Date: {this.state.selectedValue.validTill} </p>


                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}



                {this.state.showCouponRedemptionDetails ?
                    <div className="coupon-modal">
                        <div className="view-coupon">
                            <div className="view-coupon-body  vcb" style={{ padding: "75px 16px 0" }}>
                                <div className="view-coupon-ticket" style={{ marginTop: '30%' }}>
                                    {/* <p className="rnk"><img alt='vi' src={this.state.selectedValue.redeemIcon} alt="" /></p> */}
                                    <img alt='vi' style={{ float: 'right' }} src={greentick} alt=""  ></img>

                                    <p className="wn-typ" style={{ display: 'flex', marginLeft: '10px' }}>{this.state.selectedValue.redeemName}</p>
                                    <p className="cpn-des" style={{ display: 'flex', marginLeft: '10px' }}>{this.state.selectedValue.redeemPartnerName}</p>

                                    {/* {this.state.selectedValue.redeemPriceType.toLowerCase() != 'game' ? <div> <p className="cpn-ttl">Coupon Code</p>
                                        <CopyToClipboard text={this.state.selectedValue.couponCode}
                                            onCopy={() => this.setState({ copied: true })}>
                                            <p className="cpn-cd">
                                                <span>{this.state.selectedValue.couponCode}</span>
                                                <span className="cpn-file-logo"><img alt='vi' src={folder} alt="" /></span>
                                            </p>

                                        </CopyToClipboard>
                                        {this.state.copied ? <p className="copied">Copied!</p> : null}
                                    </div> : null
                                    } */}

                                    <p className="wn-dt" style={{ textAlign: 'left', marginLeft: '10px' }}>You have successfully purchased {this.state.selectedValue.redeemName} </p>
                                    <p className="couponOk" onClick={this.closeCouponPopupOk.bind(this)}>OK</p>
                                    {/* <p className="cc-popup">{this.state.selectedValue.couponLongDescription}.</p> */}
                                    {/* <button className="cc-pay-btn" onClick={this.closeCouponPopup.bind(this)}>OK</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}

                {this.state.showCouponRedemptionDetailsError ?
                    <div className="coupon-modal">
                        <div className="view-coupon">
                            <div className="view-coupon-body  vcb" style={{ padding: "75px 16px 0" }}>
                                <div className="view-coupon-ticket" style={{ marginTop: '30%' }}>
                                    {/* <p className="rnk"><img alt='vi' src={this.state.selectedValue.redeemIcon} alt="" /></p> */}
                                    <img alt='vi' style={{ float: 'right' }} src={alertCoupon} alt=""  ></img>

                                    <p className="wn-typ" style={{ display: 'flex', marginLeft: '10px' }}>{this.state.selectedValue.redeemName}</p>
                                    <p className="cpn-des" style={{ display: 'flex', marginLeft: '10px' }}>{this.state.selectedValue.redeemPartnerName}</p>

                                    {/* {this.state.selectedValue.redeemPriceType.toLowerCase() != 'game' ? <div> <p className="cpn-ttl">Coupon Code</p>
                                        <CopyToClipboard text={this.state.selectedValue.couponCode}
                                            onCopy={() => this.setState({ copied: true })}>
                                            <p className="cpn-cd">
                                                <span>{this.state.selectedValue.couponCode}</span>
                                                <span className="cpn-file-logo"><img alt='vi' src={folder} alt="" /></span>
                                            </p>

                                        </CopyToClipboard>
                                        {this.state.copied ? <p className="copied">Copied!</p> : null}
                                    </div> : null
                                    } */}

                                    <p className="wn-dt" style={{ textAlign: 'left', marginLeft: '10px' }}>We’re unable to process your request, kindly try after sometime.  </p>
                                    <p className="couponOk" onClick={this.closeCouponPopup.bind(this)}>OK</p>
                                    {/* <p className="cc-popup">{this.state.selectedValue.couponLongDescription}.</p> */}
                                    {/* <button className="cc-pay-btn" onClick={this.closeCouponPopup.bind(this)}>OK</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}


                {!this.state.isSearch ?
                    <div className="header-leaderboard header-no-border  line-it" style={{ height: '55px' }}>
                        <img src={back} onClick={this.back.bind(this)} className="float-lft"></img>
                        <span className="header-text float-lft">Redemption Center</span>
                        <button className="mycouponbutton" onClick={this.openMyCoupons.bind(this)}>My Coupons</button>
                    </div>
                    :
                    <div className="header-leaderboard-c line-it" style={{ height: '55px' }}>
                        <img style={{ marginLeft: '-14px' }} src={back} onClick={this.backOneStepBack.bind(this)} className="float-lft"></img>
                        <input type="text" autoFocus={true} placeholder="Search Coupon" className="searchcouponheading" onChange={this.handleChange}></input>
                        {this.state.showCloseButton ? <img src={CloseCoupon} style={{ width: '24px', height: '24px' }} onClick={this.closesearch.bind(this)} className="float-rgt"></img>
                            : null}
                    </div>
                }
                <div className={this.state.isSearch ? "content-search" : "content margintop1"}  >
                    {redemptionRow.length > 0 ? (!this.state.isSearch ? <input type="text" placeholder="Search" className="searchcoupon" onClick={this.search}></input> : null) : null}
                    {redemptionRow.length > 0 ? redemptionRow : (this.state.isSearch ? (this.state.showErrorMessage ? <div><p className="no-record-coins">No result found</p>
                        <p className="sorry">Sorry, we couldn’t find coupons matching
                            "{this.state.searchedString}"</p>
                    </div> : null) : <p className="sorry state-et">{this.state.message}
                        </p>)}

                </div>

            </div>
        )
    }
}
export default withRouter(Redemption)