import React from 'react'
import axios from 'axios';
import { withRouter } from 'react-router'
import '../styles/style.css'
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Popup from './popup'
import Iframe from 'react-iframe'
import loading from '../images/loader.gif';
import games from '../images/games.svg';
import '../styles/loader.css'
import LoaderGif from '../images/loading.png';
import queryString from 'query-string'
import Cookies from 'universal-cookie';

class ErrorLogin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div >
                <div  className="error-login">
                    <p>Error in Login</p>
                </div>
                <div  className="error-login-desc"> 
                    <p className="error-login">MSISDN not found in header.</p>
                </div>
            </div>
        )
    }
}

export default withRouter(ErrorLogin);