import React from 'react'
import '../styles/home.css';


import homeRed from '../images/yellowHome.png';
import freeGray from '../images/dice.svg';
import battleGray from '../images/battle.svg';
import paidGray from '../images/paid.svg';
import Popup from '../Home/popup';
import { withRouter } from 'react-router'
import { FRONTEND_NAME } from '../Constants/constants';
import axios from 'axios';
import * as constantURL from '../Constants/constants';
import { connect } from 'react-redux';
import { homeMenu } from '../../actions/homeMenuActions'
import localForage from 'localforage';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentScreen: '',
            showPopup: false,
            bottomMenu: [],
            cachedImage: new Map(),
            showPopupOld: false,

            showPopup: false,
            msg: "",
            msgType: "S",
            loginRedirect: "N",
            showPopupNew: false,
            message: "",
            alert: "",

        }
        this.fetchMenuData();
        // this.props.homeMenu();

        // let tempMap = new Map();
        // localForage.iterate((value, key) => {
        //     if(!tempMap.has(key)) {
        //         tempMap.set(key, value)
        //     }
        // })
        // .then(() => {
        //     this.setState({
        //         cachedImage: tempMap
        //     });
        // });
    }

    // componentDidMount() {
    //     let tempMap = new Map();
    //     localForage.iterate((value, key) => {
    //         if(!tempMap.has(key)) {
    //             tempMap.set(key, value)
    //         }
    //     })
    //     .then(() => {
    //         this.setState({
    //             cachedImage: tempMap
    //         });
    //     });
    // }

    fetchMenuData = () => {
        console.log("Inside fetchMenuData");
        try {
            const headers = {
                'Content-Type': 'application/json',
                'appid': sessionStorage["appid"],
                'deviceId': constantURL.DEVICE_ID,
                'operator': constantURL.OPERATOR,
                'Access_token': sessionStorage.getItem("accessToken"),
                'channel': 'wap',
                'Msisdn': sessionStorage.getItem("username")
            };


            axios.get(constantURL.CONTENT_COMBINED_IP + "/v1/fetchMenu", { headers }).then(response => response.data)
                .then((data) => {
                    ;
                    if (data && (data.code == "2000")) {
                        var bottomMenu = data.data.categories[0].subCategories;

                        console.log("Bottom menu fetched" + bottomMenu);

                        this.setState({ bottomMenu: bottomMenu })


                    } else {
                        sessionStorage.removeItem('isLogin')
                        sessionStorage.removeItem('isGuestLogin')
                        this.setState({ enableLoader: false })

                        this.setState({
                            logoutPopup: true
                        });

                    }
                });
        } catch (ex) {
            console.log("ex :", ex)
        }
    }

    openBattleGame = () => {
        console.log("inside openRequiredGame method ");

        this.props.history.push({
            pathname: FRONTEND_NAME + "/battlehome",
            state: {
                // game: {
                //     title: "Battle",
                //     gameurl: "http://digi.mv1.in/bigwinbattle?msisdn=<msisdn>&lang=<lang>".replace('<msisdn>', sessionStorage.getItem("mobileNumber")).replace('<lang>', sessionStorage.getItem("lang"))
                // }
            }
        })

    }

    // renderMenuTabs = () => {
    //     console.log("inside renderMenuTabs", this.state.bottomMenu);

    //     return this.state.bottomMenu.map((event, index) => {

    //         return (

    //             <div className="footer-c"  >
    //                 <img src={this.props.currentSelection === event.title ? event.previewImageUrl2 : event.previewImageUrl} className="first-footer" alt="" onClick={() => this.openPage(event)}></img>
    //                 <p className={this.props.currentSelection === event.title ? 'active-p' : 'unactive-p'}>{event.subCategoryName}</p>
    //             </div>
    //         )
    //     });
    // }

    togglePopOld() {
        this.setState({
            showPopupOld: !this.state.showPopupOld
        });
    }

    togglePopupOld() {
        this.setState({
            showPopupOld: !this.state.showPopupOld
        });
        if (this.state.loginRedirect == 'Y') {
            this.props.history.push(FRONTEND_NAME + "/logout")
        }
        if (this.state.loginRedirect == 'C') {
            this.deduct()
        }

        if (this.state.loginRedirect == 'INC') {
            this.props.history.push(FRONTEND_NAME + "/buyplaycoins")
        }
        if (this.state.loginRedirect == 'E') {
            this.props.history.push(FRONTEND_NAME + "/editprofilenew")
        }

        if (this.state.loginRedirect == 'EP') {
            this.props.history.push(FRONTEND_NAME + "/editprofilenew")
        }
        if (this.state.loginRedirect == 'LOGIN') {
            this.props.history.push(FRONTEND_NAME + "/sociallogin")
        }


    }


    openPage(data) {
        console.log(data);
        const { subCategoryId, title, subCategoryName } = data;
        if (title === 'home') {
            this.props.history.push({
                pathname: FRONTEND_NAME + '/home'
            })
        }

        if (title === 'freeGames' || title === "paid") {
            this.props.history.push({
                pathname: FRONTEND_NAME + '/paidgames',
                state: {
                    currentCategory: { categoryId: subCategoryId, categoryName: subCategoryName, currentSelection: subCategoryName, fromHome: false }
                }
            })
        }

        if (title === 'battle') {

            if (sessionStorage.getItem("loginType") && sessionStorage.getItem("loginType") == "normal") {
                this.props.history.push({
                    pathname: FRONTEND_NAME + "/battlehome",
                    state: {
                        battlehomeid: subCategoryId
                    }
                })
            } else if (sessionStorage.getItem("loginType") == "social") {
                this.setState({
                    showPopupOld: true,
                    msgType: "A",
                    isConfirm: "Y",
                    alertMessage: "Alert!",
                    msg:
                        "Mobile number is mandatory to access this functionality. Please provide your mobile number.",
                    loginRedirect: "E",
                });
            } else {
                this.setState({
                    showPopupOld: true,
                    msgType: 'E',
                    isConfirm: 'Y',
                    alertMessage: 'Alert!',
                    msg: "You need to be logged in to access this feature. Do you wish to proceed?",
                    loginRedirect: 'LOGIN'
                });
            }
        }

        if (data === 'paidgames') {
            this.props.history.push({
                pathname: FRONTEND_NAME + '/paidgames',
                state: {
                    currentCategory: { categoryId: 1033, categoryName: "Premium Games", currentSelection: "Paid Games", fromHome: false }
                }
            })
        }



        if (title === 'fantasy') {
            if (sessionStorage.getItem("loginType") && sessionStorage.getItem("loginType") == "normal") {
                window.location.href = constantURL.FANTASY_URL + sessionStorage.getItem("username");
            } else if (sessionStorage.getItem("loginType") == "social") {
                this.setState({
                    showPopupOld: true,
                    msgType: "A",
                    isConfirm: "Y",
                    alertMessage: "Alert!",
                    msg:
                        "Mobile number is mandatory to access this functionality. Please provide your mobile number.",
                    loginRedirect: "E",
                });
            } else {
                this.setState({
                    showPopupOld: true,
                    msgType: 'E',
                    isConfirm: 'Y',
                    alertMessage: 'Alert!',
                    msg: "You need to be logged in to access this feature. Do you wish to proceed?",
                    loginRedirect: 'LOGIN'
                });

            }
        }
    }

    renderMenuTabs = () => {
        console.log("inside renderMenuTabs", this.state.bottomMenu);
        //console.log("inside renderMenuTabs", this.props.bottomMenu);

        return this.state.bottomMenu.map((event, index) => {
            // return this.props.bottomMenu.map((event, index) => {

            return (

                <div className="footer-c"  >
                    {(this.hasContentId(event.itemId + "1") && this.hasContentId(event.itemId)) ?
                        <img src={(this.props.currentSelection === event.subCategoryName || this.props.currentSelection === event.title) ? this.getContentId(event.itemId + "1") : this.getContentId(event.itemId)} className="first-footer" alt="" onClick={() => this.openPage(event)}></img>
                        : <img src={(this.props.currentSelection === event.subCategoryName || this.props.currentSelection === event.title) ? event.previewImageUrl2 : event.previewImageUrl} className="first-footer" alt="" onClick={() => this.openPage(event)}></img>
                    }
                    <p className={(this.props.currentSelection === event.subCategoryName || this.props.currentSelection === event.title) ? 'active-p' : 'unactive-p'}>{event.subCategoryName}</p>
                </div>
            )
        });
    }

    // togglePopup(){
    //     this.props.history.push({
    //         pathname: FRONTEND_NAME+'/login'
    //     })
    // }
    // togglePop(){
    //     this.setState({showPopup:false})
    // }

    hasContentId(id) {
        //return false;
        return this.props.cachedImage.has(id);
    }

    getContentId(id) {
        //return false;
        //console.log("Resturning " + this.state.cachedImage.has(id))
        return this.props.cachedImage.get(id);
    }

    render() {

        console.log('Current: ' + this.props.currentSelection)

        return (
            <div className="footer">
                {this.state.showPopupOld ?
                    <Popup
                        status={this.state.msgType}
                        text={this.state.msg}
                        alertType={this.state.alertMessage}
                        buttonName={this.state.loginRedirect == 'C' ? 'CONFIRM' : 'OK'}
                        closePopup={this.togglePopupOld.bind(this)}
                        closePopupNotNow={this.togglePopOld.bind(this)}
                        isConfirm={this.state.isConfirm}
                    /> : null}

                {/* {this.state.showPopup ?
                    <Popup
                        status='A'
                        text="You need to login to open this module."
                        alertType='Login Required!'
                        closePopup={this.togglePopup.bind(this)}
                        closePopupNotNow={this.togglePop.bind(this)}
                    /> : null} */}


                {/* <div className="footer-c"  >
                    <img src={this.props.currentSelection === 'homescreen' ? homeRed : homeRed} className="first-footer" alt="" onClick={() => this.openPage('home')}></img>
                    <p className={this.props.currentSelection === 'homescreen' ? 'active-p' : 'unactive-p'}>Home</p>
                </div>
                <div className="footer-c" >
                    <img src={this.props.currentSelection === 'freegames' ? freeGray : freeGray} className="second-footer" alt="" onClick={() => this.openPage('freegames')}></img>
                    <p className={this.props.currentSelection === 'freegames' ? 'active-p' : 'unactive-p'}>Free Games</p>
                </div>
                <div className="footer-c margin-top-footer"  >
                    <img src={this.props.currentSelection === 'battle' ? battleGray : battleGray} className="third-footer" alt="" onClick={() => this.openPage('battle')}></img>
                    <p className={this.props.currentSelection === 'battle' ? 'active-p' : 'unactive-p'}>Battle</p>
                </div>
                <div className="footer-c"  >
                    <img src={this.props.currentSelection === 'paidgames' ? paidGray : paidGray} className="last-footer" alt="" onClick={() => this.openPage('paidgames')}></img>
                    <p className={this.props.currentSelection === 'paidgames' ? 'active-p' : 'unactive-p'}>Paid Games</p>
                </div> */}
                {this.renderMenuTabs()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        enableLoader,
        isInitialLoad,
        logoutPopup,
        bottomMenu
    } = state.menu

    return {
        enableLoader,
        isInitialLoad,
        logoutPopup,
        bottomMenu
    }
}

export default connect(mapStateToProps, { homeMenu })(withRouter(Footer));
