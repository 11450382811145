import React from 'react'
import axios from 'axios';
import { withRouter } from 'react-router'
import * as Constant from '../Constants/constants'
import * as ErrorCode from '../Constants/errorConstants'
import Cookies from 'universal-cookie';
import {FRONTEND_NAME} from '../Constants/constants'

class Logout extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
         
        }
    }

    componentDidMount() {


        const cookies = new Cookies();
        sessionStorage.removeItem('isLogin')
        sessionStorage.removeItem('isGuestLogin')
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("nameSalutation");
        sessionStorage.removeItem("mobileNumber");
        sessionStorage.removeItem("profileImg");

        cookies.remove('hardyIsLogin',{ path: '/' });
        cookies.remove('hardyAccessToken',{ path: '/' });
        cookies.remove('hardyMobileNumber',{ path: '/' });
        cookies.remove('hardyOperator',{ path: '/' });
        cookies.remove('hardyCountry',{ path: '/' });
        cookies.remove('hardyUsername',{ path: '/' });
        cookies.remove('hardyNameSalutation',{ path: '/' });
        cookies.remove('hardyProfileImg',{ path: '/' });
        cookies.remove('uniquevhgsession',{ path: '/' });
        
        this.props.history.push(FRONTEND_NAME+"/home");
    }
    render() {
        return null
    }
}

export default withRouter(Logout);