import React from 'react'
import '../styles/home.css';
import { withRouter } from 'react-router';

import back from '../images/backNew.svg';
import copyCoupon1 from '../images/copyCoupon1.svg';

import redeemIcon from '../images/redeemIcon.svg';
import greentick from '../images/greentick.svg';
import redeemIconExpired from '../images/redeemIconExpired.svg';
import alertCoupon from '../images/alertCoupon.svg';
import CloseCoupon from '../images/close.svg'
import folder from '../images/folder.svg';
import info from '../images/info.svg';
import grayinfo from '../images/grayinfo.svg';
import axios from 'axios';
import * as constantURL from '../Constants/constants'
import Loading from './loading'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Cookies from 'universal-cookie';

class MyCoupon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            enableLoader: false,
            showCouponDetails: false,
            showCouponRedemptionDetails: false,
            showCouponRedemptionDetailsNew: false,
            rewardRedemption: [],
            originalRedemtion: [],
            selectedValue: null,
            copied: false,
            option:'avail',
            isSearch: false,
            searchedString: '',
            showErrorMessage: false,
            showCouponRedemptionDetailsError: false

        }
        this.closePopup = this.closePopup.bind(this);
        this.back = this.back.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.closeCouponPopup = this.closeCouponPopup.bind(this);
        this.openCouponPopup = this.openCouponPopup.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.search = this.search.bind(this);
        this.closesearch = this.closesearch.bind(this);
    }
    closePopup() {
        this.setState({ showCouponDetails: !this.state.showCouponDetails });
    }


    handleChange(event) {
        // console.log(event.target.value);
        var filteredList = [];
        {
            this.state.originalRedemtion.filter(name => name.redeemName.toLowerCase().includes(event.target.value.toLowerCase())).map(filteredName => (
                filteredList.push(filteredName)
                // console.log(filteredName)
            ))
        }

        console.log(filteredList)

        this.setState({ rewardRedemption: filteredList, showErrorMessage: true, isSearch: true, searchedString: event.target.value });

    }

    openPopup() {
        this.setState({ showCouponDetails: !this.state.showCouponDetails });
    }

    openDetailPopup(list) {
        if(this.state.option=="avail"){
        this.setState({
            selectedValue: list,
            showCouponRedemptionDetailsNew: !this.state.showCouponRedemptionDetailsNew
        })
    }
    }

    search() {
        this.setState({ isSearch: true, rewardRedemption: [], showErrorMessage: false });
    }

    closesearch() {
        this.setState({
            isSearch: false, rewardRedemption: this.state.originalRedemtion
        });
    }

    saveLogs(category) {
        
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

        const cookies = new Cookies();
        var url = constantURL.APPLOGGER_COMBINED_IP + "/v1/saveLogs";

        const headers = {
            'Content-Type': 'application/json',
            'appid': metaMap.get('APP_ID'),
            'operator': metaMap.get('OPERATOR'),
            'msisdn': sessionStorage.getItem("username"),
            'channel': 'WAP'
        };

        const requestBody = {
            "category": category,
            "subCategory": "",
            "content": category,
            "url": "/paidgames",
            "packname": "",
            "appname": metaMap.get('APPLICATION_NAME'),
            "sessionid": cookies.get('uniquevhgsession')
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == 2000) {
                } else {
                }
            });
    }


    closeCouponPopupOk() {
        this.setState({ showCouponRedemptionDetailsNew: true, showCouponRedemptionDetails: false })
    }


    closeCouponPopup() {
        console.log(this.state.originalRedemtion)
        this.setState({
            showCouponRedemptionDetails: false,
            copied: false,
            // rewardRedemption: this.state.originalRedemtion,
            showCouponRedemptionDetailsError: false,
            showCouponRedemptionDetailsNew: false
        });
    }
    openCouponPopup() {
        this.setState({ showCouponRedemptionDetails: !this.state.showCouponRedemptionDetails });
    }

    componentDidMount() {

        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
      
        this.saveLogs("MY_COUPONS");

        const headers = {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'appid': metaMap.get('APP_ID'),
            'Access_token': sessionStorage.getItem("accessToken"),
            'Deviceid': constantURL.DEVICE_ID,
            'channel': constantURL.CHANNEL,
            'operator': metaMap.get('OPERATOR'),
            'Msisdn': sessionStorage.getItem("username"),
            'countryCode': metaMap.get('COUNTRY_CODE'),
            'appkey': metaMap.get('APPKEY')
        };

        const url = constantURL.SPS_COMBINED_IP + "/v3/boughtCoupon?limit=100&pageNo=1";
        this.setState({ enableLoader: true });


        axios.get(url, { headers }).then(response => response.data)
            .then((data) => {
                if (data && data.code == 2000) {
                    this.setState({ enableLoader: false });

                    var rewardRedemption = data.data.rewardRedemption;
                    var list = [];
                    for (var i = 0; i < rewardRedemption.length; i++) {
                        list.push({
                            id: 'redemption' + i,
                            couponId: rewardRedemption[i].couponId,
                            Info: rewardRedemption[i].Info,
                            couponExpired:rewardRedemption[i].couponExpired,
                            redeemedDate: rewardRedemption[i].redeemedDate,
                            couponCode: rewardRedemption[i].couponCode,
                            couponValue: rewardRedemption[i].couponValue,
                            couponCurrency: rewardRedemption[i].couponCurrency,
                            validFrom: rewardRedemption[i].validFrom,
                            validTill: rewardRedemption[i].validTill,
                            couponShortDescription: rewardRedemption[i].couponShortDescription,
                            couponCategoryId: rewardRedemption[i].couponCategoryId,
                            redeemId: rewardRedemption[i].redeemId,
                            redeemPartnerName: rewardRedemption[i].redeemPartnerName,
                            redeemName: rewardRedemption[i].redeemName,
                            redeemIcon: rewardRedemption[i].redeemIcon,
                            redeemValue: rewardRedemption[i].redeemValue,
                            redeemPriceType: rewardRedemption[i].redeemPriceType,
                            redeemPriceValue: rewardRedemption[i].redeemPriceValue,
                            redeemRewardType: rewardRedemption[i].redeemRewardType,
                            redeemRewardValue: rewardRedemption[i].redeemRewardValue,
                            redeemRewardIcon: rewardRedemption[i].redeemRewardIcon,
                            redeemAppname: rewardRedemption[i].redeemAppname,
                            redeemCountPerUser: rewardRedemption[i].redeemCountPerUser,
                            redeemExpiryDate: rewardRedemption[i].redeemExpiryDate,
                            redeemShortDescription: rewardRedemption[i].redeemShortDescription,
                            redeemLongDescription: rewardRedemption[i].redeemLongDescription,
                        });
                        this.setState({ rewardRedemption: list, originalRedemtion: list });
                
                        var filteredList = [];
                        {
                            this.state.originalRedemtion.filter(name => name.couponExpired==false).map(filteredName => (
                                filteredList.push(filteredName)
                                // console.log(filteredName)
                            ))
                        }
                       this.setState({ rewardRedemption: filteredList});
                
                
                    }

                    console.log(list);
                }else{
                    this.setState({ enableLoader: false });
                }
            })
            .catch(err => {
                this.setState({ enableLoader: false });
            })
    }

    changeOption(option){
        this.setState({option:option})
        var bva=false;
        if(option=='avail'){
            bva=false;
        }else{
        bva=true;
        }
        var filteredList = [];
        {
            this.state.originalRedemtion.filter(name => name.couponExpired==bva).map(filteredName => (
                filteredList.push(filteredName)
                // console.log(filteredName)
            ))
        }
       this.setState({ rewardRedemption: filteredList});


    }

    back() {
        this.props.history.push({
            pathname: constantURL.FRONTEND_NAME + '/redemption',
            state: {
                url: "home",
            }
        })
    }


    pay() {

        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
        const headers = {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'appid': metaMap.get('APP_ID'),
            'Access_token': sessionStorage.getItem("accessToken"),
            'Deviceid': constantURL.DEVICE_ID,
            'channel': constantURL.CHANNEL,
            'operator': metaMap.get('OPERATOR'),
            'Msisdn': sessionStorage.getItem("username"),
            'countryCode': metaMap.get('COUNTRY_CODE'),
            'appkey': metaMap.get('APPKEY')
        };


        const url = constantURL.SPS_COMBINED_IP + "/v3/buyCoupon?redeemId=" + this.state.selectedValue.redeemId + "&info=" + this.state.selectedValue.redeemName
        // this.setState({ enableLoader: true });

        axios.get(url, { headers }).then(response => response.data)
            .then((data) => {
                if (data && data.code == 2000) {
                    this.setState({
                        selectedValue: data.data.couponDetails,
                        showCouponRedemptionDetails: true,
                        showCouponDetails: false,
                        copied: false
                    });
                } else {
                    // alert('No record found.');
                    this.setState({ showCouponDetails: false, showCouponRedemptionDetailsNew: false, showCouponRedemptionDetailsError: true });

                }
            })
            .catch(err => {
                this.setState({ enableLoader: false });
                console.log(err);
            })
    }

    openMyCoupons() {
        this.props.history.push({
            pathname: constantURL.FRONTEND_NAME + '/mycoupon',
            state: {
                url: "home",
            }
        })

    }

    render() {

        var redemptionRow = [];
        this.state.rewardRedemption.forEach(function (list) {
            redemptionRow.push(
                <div className={"sub-card"} onClick={() => this.openDetailPopup(list)}>
                    <div className="sc-head" style={{ height: '65px' }}>
                        <div className={this.state.option=="expired"?"sc-left-expired":"sc-left"}   >
                            <p>{list.redeemPartnerName}</p>
                            <p>{list.redeemName.length>18?list.redeemName.substring(0,18)+"...": list.redeemName}</p>
                       </div>
                        <div className={this.state.option=="expired"?"sc-right":"sc-right"}>
                            <img alt='vi' alt="" className={this.state.option == 'expired' ? 'grayInfoIcon' : 'noGray'} src={list.redeemIcon}></img>
                        </div>
                    </div>
                    <div  className={this.state.option=="expired"?"sc-bottom-expired":"sc-bottom"}>
                        <span><img alt='vi' alt=""  src={this.state.option=="expired"?grayinfo:info}></img></span>
                        <span>Worth &#8377;{list.redeemValue}</span>
                    </div>

                    <p className={this.state.option=="expired"?"info-div-expired":"info-div" }>
                        <div>
                            <span><img style={{marginTop:'2px'}} alt='vi' alt="" src={this.state.option == 'expired' ?  redeemIconExpired : redeemIcon}></img></span>
                            <span>{list.redeemRewardValue}</span>
                        </div>
                        <div>
                            <span style={{ float: 'right', marginTop:"5px" }} className={this.state.option=="expired"?"expire-gray":"expire"}>Exp {list.redeemExpiryDate ? list.redeemExpiryDate.slice(0,10):''} </span>
                        </div>
                    </p>
                </div>);

        }.bind(this));


        return (
            <div className="container-home">

                {this.state.enableLoader ?
                    <Loading />
                    : null
                }
                {this.state.showCouponDetails ?
                    <div className="coupon-modal">
                        <div className="view-coupon">
                            <img alt='vi' onClick={this.backToPrevious} className="close-popup" src={CloseCoupon} alt="" onClick={this.closePopup.bind(this)} ></img>
                            <div className="view-coupon-body">
                                <div className="view-coupon-ticket">
                                    <p className="rnk"><img alt='vi' src={this.state.selectedValue.redeemIcon} alt="" /></p>
                                    <p className="wn-typ">{this.state.selectedValue.redeemName}</p>
                                    <p className="cpn-des">{this.state.selectedValue.redeemPartnerName}</p>
                                    <p className="cc-popup">{this.state.selectedValue.redeemPartnerName} coupon worth <span>₹</span>{this.state.selectedValue.redeemValue}.</p>
                                    <p className="cc-popup">Your {this.state.selectedValue.redeemRewardValue} Loyalty Coins will be deducted.</p>
                                    <button className="cc-pay-btn" onClick={this.pay.bind(this)}>PAY</button>
                                    <p className="coupon-note">• Expiry Date: {this.state.selectedValue.redeemExpiryDate} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}

                {this.state.showCouponRedemptionDetailsNew ?
                    <div className="coupon-modal">
                        <div className="view-coupon">
                            <img alt='vi' onClick={this.backToPrevious} className="vcb-close-popup" style={{ marginTop: '11px' }} src={CloseCoupon} alt="" onClick={this.closeCouponPopup.bind(this)} ></img>
                            <div className="view-coupon-body  vcb">
                                <div className="view-coupon-ticket" style={{ marginTop: "-56px" }}>
                                    <p className="rnk"><img alt='vi' src={this.state.selectedValue.redeemIcon} alt="" /></p>
                                    <p className="wn-typ">{this.state.selectedValue.redeemName}</p>
                                    <p className="cpn-des">{this.state.selectedValue.redeemPartnerName}</p>

                                    {this.state.selectedValue.redeemPriceType.toLowerCase() != 'game' ? <div> <p className="cpn-ttl">Coupon Code</p>
                                        <CopyToClipboard text={this.state.selectedValue.couponCode}
                                            onCopy={() => this.setState({ copied: true })}>
                                            <p className="cpn-cd">
                                                <span>{this.state.selectedValue.couponCode}</span>
                                                <span className="cpn-file-logo"><img alt='vi' src={copyCoupon1} alt="" /></span>
                                            </p>

                                        </CopyToClipboard>
                                        {this.state.copied ? <p className="copied">Copied!</p> : null}
                                    </div> : null
                                    }

                                    <p className="wn-dt">Received on {this.state.selectedValue.redeemedDate} </p>
                                    <p className="cc-popup">{this.state.selectedValue.redeemLongDescription}.</p>
                                    <button className="cc-pay-btn" onClick={this.closeCouponPopup.bind(this)}>OK</button>
                                    <p className="wn-dt">Expiry Date: {this.state.selectedValue.validTill} </p>


                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}



                {this.state.showCouponRedemptionDetails ?
                    <div className="coupon-modal">
                        <div className="view-coupon">
                            <div className="view-coupon-body  vcb" style={{ padding: "75px 16px 0" }}>
                                <div className="view-coupon-ticket" style={{ marginTop: '30%' }}>
                                    {/* <p className="rnk"><img alt='vi' src={this.state.selectedValue.redeemIcon} alt="" /></p> */}
                                    <img alt='vi' style={{ float: 'right' }} src={greentick} alt=""  ></img>

                                    <p className="wn-typ" style={{ display: 'flex', marginLeft: '10px' }}>{this.state.selectedValue.redeemName}</p>
                                    <p className="cpn-des" style={{ display: 'flex', marginLeft: '10px' }}>{this.state.selectedValue.redeemPartnerName}</p>

                                    {/* {this.state.selectedValue.redeemPriceType.toLowerCase() != 'game' ? <div> <p className="cpn-ttl">Coupon Code</p>
                                        <CopyToClipboard text={this.state.selectedValue.couponCode}
                                            onCopy={() => this.setState({ copied: true })}>
                                            <p className="cpn-cd">
                                                <span>{this.state.selectedValue.couponCode}</span>
                                                <span className="cpn-file-logo"><img alt='vi' src={folder} alt="" /></span>
                                            </p>

                                        </CopyToClipboard>
                                        {this.state.copied ? <p className="copied">Copied!</p> : null}
                                    </div> : null
                                    } */}

                                    <p className="wn-dt" style={{ textAlign: 'left', marginLeft: '10px' }}>You have successfully purchased {this.state.selectedValue.redeemName} </p>
                                    <p className="couponOk" onClick={this.closeCouponPopupOk.bind(this)}>OK</p>
                                    {/* <p className="cc-popup">{this.state.selectedValue.couponLongDescription}.</p> */}
                                    {/* <button className="cc-pay-btn" onClick={this.closeCouponPopup.bind(this)}>OK</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}

                {this.state.showCouponRedemptionDetailsError ?
                    <div className="coupon-modal">
                        <div className="view-coupon">
                            <div className="view-coupon-body  vcb" style={{ padding: "75px 16px 0" }}>
                                <div className="view-coupon-ticket" style={{ marginTop: '30%' }}>
                                    {/* <p className="rnk"><img alt='vi' src={this.state.selectedValue.redeemIcon} alt="" /></p> */}
                                    <img alt='vi' style={{ float: 'right' }} src={alertCoupon} alt=""  ></img>

                                    <p className="wn-typ" style={{ display: 'flex', marginLeft: '10px' }}>{this.state.selectedValue.redeemName}</p>
                                    <p className="cpn-des" style={{ display: 'flex', marginLeft: '10px' }}>{this.state.selectedValue.redeemPartnerName}</p>

                                    {/* {this.state.selectedValue.redeemPriceType.toLowerCase() != 'game' ? <div> <p className="cpn-ttl">Coupon Code</p>
                                        <CopyToClipboard text={this.state.selectedValue.couponCode}
                                            onCopy={() => this.setState({ copied: true })}>
                                            <p className="cpn-cd">
                                                <span>{this.state.selectedValue.couponCode}</span>
                                                <span className="cpn-file-logo"><img alt='vi' src={folder} alt="" /></span>
                                            </p>

                                        </CopyToClipboard>
                                        {this.state.copied ? <p className="copied">Copied!</p> : null}
                                    </div> : null
                                    } */}

                                    <p className="wn-dt" style={{ textAlign: 'left', marginLeft: '10px' }}>We’re unable to process your request, kindly try after sometime.  </p>
                                    <p className="couponOk" onClick={this.closeCouponPopup.bind(this)}>OK</p>
                                    {/* <p className="cc-popup">{this.state.selectedValue.couponLongDescription}.</p> */}
                                    {/* <button className="cc-pay-btn" onClick={this.closeCouponPopup.bind(this)}>OK</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}


                <div className="header-leaderboard header-no-border line-it" style={{height:'55px'}}>
                    <img src={back} onClick={this.back.bind(this)} className="float-lft"></img>
                    <span className="header-text float-lft">My Coupons</span>
                </div>

                <div className="content" style={{marginTop: '55px'}} >
                    
                    <div className="avaex">
                        <div   onClick={() => this.changeOption("avail")} className={this.state.option=="avail"?"avail-active":"avail-unactive"}>Avail</div>
                        <div onClick={() => this.changeOption("expired")} className={this.state.option=="expired"?"expired-active":"expired-unactive"}>Expired</div>
                    </div>
                    <div class="avaex-1"></div>
                    <div className="couponssub" style={{marginTop:"10px"}}>
                    {redemptionRow.length > 0 ? redemptionRow : null}
                    </div>
                </div>

            </div>
        )
    }
}
export default withRouter(MyCoupon)