import React from 'react'
import axios from 'axios';
import '../styles/editPhoto.css';
import { withRouter } from 'react-router'
import cancel from '../images/cancel.png';
import ReactDOM from 'react-dom';
import Cropper from 'react-easy-crop';
import profileImg from '../images/profileImg.png';
import $ from "jquery";
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Loading from './loading'
import imageCompression from 'browser-image-compression';
import LogoutPopup from './logoutPopup'
import { FRONTEND_NAME } from '../Constants/constants'
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import Cookies from "universal-cookie";

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: 'https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000',
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 3 / 3,
      croppedAreaPixels: {},
      enableLoader: false,
      logoutPopup: false,
      lables: constantURL.getLables()
    }

    this.saveImage = this.saveImage.bind(this);
  }

  componentDidMount() {
    this.setState({
      image: this.props.location.state.pic
    })
  }

  drawImage = () => {
    const { croppedAreaPixels } = this.state;
    this.ctx.drawImage(this.image, croppedAreaPixels.x, croppedAreaPixels.y, croppedAreaPixels.width, croppedAreaPixels.height, 0, 0, croppedAreaPixels.width, croppedAreaPixels.height);
  }


  onCropChange = crop => {
    this.setState({ crop })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedAreaPixels
    }, () => {
      this.drawImage();
    })


  }

  onZoomChange = zoom => {
    this.setState({ zoom })
  }

  back() {
    this.props.history.push(FRONTEND_NAME + "/editProfile");
  }
  sessionOut() {
    this.props.history.push(FRONTEND_NAME + "/logout");
  }

  saveImage() {
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));

    this.setState({ enableLoader: true })

    var canvas = $("#canvass")[0];
    var flag = false;
    const headers = {
      'Content-Type': 'application/json',
      'appid': metaMap.get('APP_ID'),
      'access_token': sessionStorage.getItem("accessToken"),

    };


    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }

    let data1 = new FormData();
    data1.append('username', sessionStorage.getItem("username"));
    // data1.append('name', sessionStorage.getItem("nameSalutation"));

    canvas.toBlob(function (blob) {
      try {
        const compressedFile = imageCompression(blob, options).then(result => {
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob) // true
          console.log(`compressedFile size ${result.size / 1024 / 1024} MB`) // smaller than maxSizeMB

          data1.append('file', result);

          var url = '';
          if (sessionStorage.getItem("loginType") == "social") {
            url = constantURL.PROFILER_COMBINED_IP + "/v1/updateUserDetailsWithFileSocial";
          }
          else {
            url = constantURL.PROFILER_COMBINED_IP + "/v1/updateUserDetailsWithFile";
          }


          axios
            .post(url, data1, { headers },
          )
            .then((data) => {
              if (data && (data.data.code == errorConstantURL.SUCCESS_CODE)) {
                this.saveLogs('EDIT_PHOTO');
                this.setState({ enableLoader: false })
                sessionStorage.setItem("profileImg", canvas.toDataURL("image/jpeg", 0.1))
                this.props.history.push(FRONTEND_NAME + "/editProfile");
              } else if (data.data.code == errorConstantURL.OFFENSIVE_CODE) {
                this.setState({ logoutPopup: true });
              } else {
                this.setState({ enableLoader: false })
                sessionStorage.setItem("profileImg", canvas.toDataURL("image/jpeg", 0.1))
                this.props.history.push(FRONTEND_NAME + "/editProfile");
              }
            });
        })
      } catch (error) {
        console.log(error);
      }


    }.bind(this));
    // if (flag) {
    //   
    // }

  }


  saveLogs(category) {
    var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
 
    const cookies = new Cookies();
    var url = constantURL.APPLOGGER_COMBINED_IP + "/v1/saveLogs";

    const headers = {
      "Content-Type": "application/json",
      appid: metaMap.get('APP_ID'),
      operator: metaMap.get('OPERATOR'),
      msisdn: sessionStorage.getItem("username"),
      channel: "WAP",
    };

    const requestBody = {
      category: category,
      subCategory: "",
      content: category,
      url: "/editphoto",
      packname: "",
      appname: metaMap.get('APPLICATION_NAME'),
      sessionid: cookies.get("uniquevhgsession"),
    };

    axios
      .post(url, requestBody, { headers })
      .then((response) => response.data)
      .then((data) => {
        if (data && data.code == errorConstantURL.SUCCESS_CODE) {
        } else {
        }
      });
  }

  render() {
    const { image, croppedAreaPixels, crop, zoom, aspect, lables } = this.state;
    return (
      <>
        <div className="container-prize">
          {this.state.enableLoader ?
            <Loading />
            : null
          }
          {
            this.state.logoutPopup ?
              <LogoutPopup
                redirectPopup={this.sessionOut.bind(this)}
              /> : null
          }
          <div className="header-leaderboard edit-pad">
            <img src={cancel} alt="" onClick={this.back.bind(this)} className="float-lft"></img>

            {/* <input accept="image/*" id="fileInput" type="file" onChange={this.uploadFile} ref={this.fileUpload} style={{ display: 'none' }} /> */}
            <span className="header-text float-ryt" onClick={this.saveImage}  >{lables.selectPhoto}</span>
          </div>

          <img
            ref={imageRef => this.image = imageRef}
            id="source"
            style={{ display: 'none' }}
            src={image} />

          <canvas id="canvass"
            style={{ height: '100px', width: "100px", "margin-top": "100px", display: 'none' }}
            ref={canvas => {
              this.canvas = canvas;
              canvas && (this.ctx = canvas.getContext('2d'))
            }}
            width={croppedAreaPixels.width}
            height={croppedAreaPixels.height}
          />

          <Cropper
            image={this.state.image}
            crop={this.state.crop}
            zoom={this.state.zoom}
            aspect={this.state.aspect}
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
            cropShape='round'
            position='relative'
          />

        </div>

      </>
    )
  }
}

export default withRouter(EditProfile);