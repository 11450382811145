
import React from 'react'
import axios from 'axios';
import '../styles/leaderboard.css';
import { withRouter } from 'react-router'
import close from '../images/close.png';
import LeaderboardBanner from '../images/leaderBanner.png';
import * as constantURL from "../Constants/constants";
import Loading from './loading'


class LeaderboardMonthlyPhase2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            winners: [],
            enableLoader: true,

        }
    }

    componentDidMount() {
        var metaMap = new Map(JSON.parse(sessionStorage.getItem('metaInfo')));
        var url = constantURL.SPS_COMBINED_IP + "/v1/getResponse";
        const headers = {
            'appkey': metaMap.get('APP_KEY_SUBS'),
            'Content-Type': constantURL.APPLICATION_JSON,
            'appid': sessionStorage["appid"],
            'deviceId': constantURL.DEVICE_ID,
            'operator': metaMap.get('OPERATOR'),
            'access_token': sessionStorage.getItem("accessToken"),
            'channel': constantURL.CHANNEL,
            'countryCode': metaMap.get('COUNTRY_CODE'),
            'msisdn': sessionStorage.getItem("username"),
        };

        const body = {

            "apiName": "winnerList",
            "requestBody": {
                "appName": this.props.location.state.list.appName,
                "winnerDate": this.props.location.state.list.winnerDeclarationDate,
                "winnerType": "MONTHLY",
                "winnerPeriod": this.props.location.state.list.winnerPeriod
            }

        }

        axios.post(url, body, { headers }).then(response => response.data)
            .then((data) => {
                console.log(data);
                if (data) {

                    var winners = data.data;
                    var list = [];
                    for (var i = 0; i < winners.length; i++) {
                        list.push({
                            "msisdn": winners[i].msisdn,
                            "msisdn": winners[i].msisdn,
                            "appName": winners[i].appName,
                            "winnerRank": winners[i].winnerRank,
                            "creditRewards": winners[i].creditRewards,
                            "winnerDeclarationDate": winners[i].winnerDeclarationDate,
                            "winnerType": winners[i].winnerType,
                            "winnerPeriod": winners[i].winnerPeriod,
                            "operator": winners[i].operator,
                            "circle": winners[i].circle
                        })
                    }

                    this.setState({ winners: list });
                }
                this.setState({enableLoader:false});
            });
    }


    back = () => {
        window.history.go(-1)
    }

    render() {
        function maskMsisdn(val){
            var msisdn =
            val.substring(0, 2) +
             "XXXXXX" +
             val.substring(7, 12);
         
             return msisdn;
        }

      function getMonthName(val) {
          var monthName = '';
          switch (val) {
               case '1': monthName = "January";
                    break;
               case '2': monthName = "February";
                    break;
               case '3': monthName = "March";
                    break;
               case '4': monthName = "April";
                    break;
               case '5': monthName = "May";
                    break;
               case '6': monthName = "June";
                    break;
               case '7': monthName = "July";
                    break;
               case '8': monthName = "August";
                    break;
               case '9': monthName = "September";
                    break;
               case '10': monthName = "October";
                    break;
               case '11': monthName = "November";
                    break;
               case '12': monthName = "December";
                    break;
          }
          return monthName;
     }

      var winnersDisplay = [];
        this.state.winners.forEach(function (list) {
          winnersDisplay.push(
          <tr>
             <td className={sessionStorage.getItem("username")==list.msisdn ? "active":"active1"}>{maskMsisdn(list.msisdn)}</td>
             <td className={sessionStorage.getItem("username")==list.msisdn ? "active":"active1"}>{list.creditRewards}</td>
              <td className= {sessionStorage.getItem("username")==list.msisdn ? "active":"active1"}>#{list.winnerRank}</td>
          </tr>
          )});
          

        return (
            <div className="container-leaderboard">

                 {this.state.enableLoader ?
                    <Loading />
                    : null
                }

                <div class="m-leader-head">

                    <span class="head">{getMonthName(this.props.location.state.list.winnerPeriod)}</span>
                    <span><img onClick={this.back} src={close} alt="" /></span>
                </div>
                <div class="leader-container">
                    <img class="leaderBanner" src={LeaderboardBanner} alt="" />


                    <div class="leader-tbl">
                        <table>
                            <tr>
                                <th>User</th>
                                <th>Points</th>
                                <th>Rank</th>
                            </tr>
                          {winnersDisplay}
                        </table>
                    </div>

                </div>



            </div>
        )
    }
}

export default withRouter(LeaderboardMonthlyPhase2);