
import React from 'react'
import axios from 'axios';
import '../styles/style.css';
import { withRouter, Redirect } from 'react-router'
import * as Constant from '../Constants/constants'
import * as Errorcode from '../Constants/errorConstants'
import Popup from './popup';
import Loading from './loading'
import back from '../images/back.png';
import stop from '../images/stop.svg';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import {FRONTEND_NAME} from '../Constants/constants'

class ResetMisisdn extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            username: this.props.location.state === undefined ? '' : this.props.location.state.userName,
            showPopup: false,
            message: '',
            msgType: '',
            alert: '',
            hideButton: true,
            error: false,
            enableLoader: false,
            showCross : false

        }
        this.msisdnRegex = /^[6-9]{1}[0-9]{9}$/
        this.userRegex  = /^\d+$/
    }

    componentDidMount(){

        // const cookies = new Cookies();      
        // if(cookies.get('hardyIsLogin')){
        //     sessionStorage.setItem("isLogin", true);
        //     sessionStorage.setItem("accessToken", cookies.get('hardyAccessToken'))
        //     sessionStorage.setItem("mobileNumber", cookies.get('hardyMobileNumber'))
        //     sessionStorage.setItem("operator", cookies.get('hardyOperator'))
        //     sessionStorage.setItem("country", cookies.get('hardyCountry'))
        //     sessionStorage.setItem("username", cookies.get('hardyUsername'))
        //     sessionStorage.setItem("nameSalutation", cookies.get('hardyNameSalutation'))
        //     sessionStorage.setItem("profileImg", cookies.get('hardyProfileImg'))   
        //     this.props.history.push({
        //         pathname: FRONTEND_NAME+'/home'
        //     });     
        // }
        const cookies = new Cookies();   
        var changePassword= sessionStorage.getItem('changePassword');
      
        if(cookies.get('hardyIsLogin') && changePassword != "true"){
            sessionStorage.setItem("isLogin", true);
            sessionStorage.setItem("accessToken", cookies.get('hardyAccessToken'))
            sessionStorage.setItem("mobileNumber", cookies.get('hardyMobileNumber'))
            sessionStorage.setItem("operator", cookies.get('hardyOperator'))
            sessionStorage.setItem("country", cookies.get('hardyCountry'))
            sessionStorage.setItem("username", cookies.get('hardyUsername'))
            sessionStorage.setItem("nameSalutation", cookies.get('hardyNameSalutation'))
            sessionStorage.setItem("profileImg", cookies.get('hardyProfileImg'))   
            this.props.history.push({
                pathname: FRONTEND_NAME+'/home'
            });     
        }

        
       $("#mobNum").on("keyup keypress blur keydown",function (event) { 
        console.log("inside jqueryListener : key is ", event.key )
        console.log("inside jqueryListener : keyCode is ", event.keyCode )

        var key = event.keyCode || event.charCode;
            var isBackspace = (key==187 || key==189 || key==69 || key==46 || key==43 || key==101 || key==190 || key==158);
            if(isBackspace){
                return false
            }

        // let key = Number(event.key) 
        // if (event.keyCode !== 8 && (isNaN(key) || event.key === null || event.key === ' ')) {
        // console.log("is not numeric")
        // return false;
        // }
       })

    

    }

   
    signInUser(userType) {

        console.log('Inside ' + userType + ' SIGNIN API')
        const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SIGNING_API_PARAM}`
        console.log("calling signin with url " + url);
        this.setState({ enableLoader: true })


        const headers = {
            'Content-Type': Constant.APPLICATION_JSON,
            'Accept': Constant.APPLICATION_JSON,
            'deviceId': Constant.DEVICE_ID,
            'appid': Constant.APP_ID,
        };

        //this.setState({ msisdn: this.props.location.state.mydata.msisdn });

        const requestBody = {
            username: this.state.username,
            password: Constant.PASSWORD
        }

        axios.post(url, requestBody, {
            headers: headers
        })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                    console.log(data)

                    //alert("user has been validated successfully");
                    const transfer = {
                        msisdn: data.data.username,
                        operator: data.data.operator.id,
                        country: data.data.country.id,
                        userName: data.data.username,
                        accessToken: data.data.accessToken
                    }
                    //After Session is created, user is redirected to Successful HomeScreen 
                    this.createLoginSession(transfer, data.data);
                    console.log('Redirecting ' + userType + ' to successful HOME screen... ')
                    this.openHomeScreen()

                } else {
                    this.setState({
                        showModal: true,
                        msgType: 'E',
                        msg: "Error in login!"
                    });
                }
                this.setState({ enableLoader: false })

            });
    }


    signUpUser() {

        console.log('Inside SignUp user')
        const url = `${Constant.PROFILER_COMBINED_IP}${Constant.SIGNUP_API_PARAM}`;
        console.log("calling SignUp with url " + url);
        this.setState({ enableLoader: true })

        const headers = {
            'Content-Type': Constant.APPLICATION_JSON,
            'Accept': Constant.APPLICATION_JSON,
            'deviceId': Constant.DEVICE_ID,
            'appid': Constant.APP_ID,
        };

        const requestBody = {
            username: this.state.username,
            password: Constant.PASSWORD,
            operatorName: Constant.MOB_OPERATOR
        }

        axios.post(url, requestBody, {
            headers: headers
        })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == Errorcode.SUCCESS_CODE) {
                    console.log("SignUp is successfull, going for SignIn")
                    this.signInUser('NEW USER')
                } else {
                    this.setState({
                        showModal: true,
                        msgType: 'E',
                        msg: "Error in login!"
                    });
                }
                this.setState({ enableLoader: false })

            });
    }


    checkUserType = (data) => {
         ;
        var changePassword= sessionStorage.getItem('changePassword');
        if(changePassword != "true")
        {
        switch (data.screenFlag) {
            case Constant.OTP_SCREEN:
                console.log("Inside OTP_SCREEN section : ")
                if (data.userType === Constant.NEW_USER ) {

                    console.log('Redirecting ' + data.userType + ' to OTP screen... ')
                    this.openEnterOtpPage(data.userType)
                }
                else if(data.userType === Constant.EXISTING_USER){
                    this.openEnterPassPage(data.userType);
                }
                break;
            case Constant.SET_PWD_SCREEN:
                console.log("Inside SET_PWD_SCREEN section : ")
                if (data.userType === Constant.NEW_USER) {
                    console.log('Redirecting NEW user to SET PWD screen... Since there is No PWD Validation ' +
                        'Screen, so calling SIGNUP API internally for New user ...')
                     this.openSetPassPage(data.userType)
                }
                else if(data.userType === Constant.EXISTING_USER){
                    this.openEnterPassPage(data.userType);
                }
                break;
            case Constant.VALIDATE_PWD_SCREEN:
                console.log("Inside VALIDATE_PWD_SCREEN section : ")
                if (data.userType === Constant.EXISTING_USER) {
                    console.log('Redirecting EXISTING user to VALIDATE PWD screen...Since there is No PWD Validation ' +
                        'Screen, so calling SIGNIN API internally for New user ...')
                    //this.signInUser('EXISTING USER');
                    this.openEnterPassPage(data.userType);
                }
                break;
            default:
                console.log("Error Fetching userType from DB")
        }
    }
    else {
     
        console.log("go to otp page");
                    this.openEnterOtpPage(data.userType);
                
        }



    
    }


    submitHandler = (event) => {
        event.preventDefault()
        console.log('username : ', this.state.username)
        if (!this.msisdnRegex.test(this.state.username)) {
            this.setState({
                error: true
            })
            console.log('from submit after : ', this.state.error)
        } else {
        	console.log("inside loginUser method");
			var url = Constant.PROFILER2_COMBINED_IP+"/v1/checkExistingUser?username=" + this.state.username;
			
 			const headers = {
				'Content-Type': 'application/json',
				'Accept' : 'application/json',
				'msisdn': this.state.username,
				'deviceId': Constant.DEVICE_ID,
				'channel': Constant.CHANNEL,
				'operator': Constant.OPERATOR,
				'appid': Constant.APP_ID,
				
			};
			
            this.setState({enableLoader:true});
            console.log("url: "+url);
            console.log("headers: "+JSON.stringify(headers));
            
			axios.get(url, {headers})
				.then(response => response.data)
				.then((data) => {
                    this.setState({ enableLoader: false })
					console.log("Response:"+JSON.stringify(data))
					if (data && (data.code === 2000)) {
                        var url2 = Constant.PROFILER2_COMBINED_IP+"/v1/sendOtp?username="+this.state.username+"&type="+Constant.RESET_PASSWORD;
						axios.get(url2,{headers})
								.then((response) => {
									if (response.data.code == 2000) {
										const msisdn = this.state.username;
										const transfer = {
											msisdn: this.state.username,
											
										}
										this.props.history.push(FRONTEND_NAME+"/resetotp",{ mydata: transfer});
									} else {
                                        this.togglePopup('Something went wrong. Please try again later.', 'E', 'Error!', true)
                   
										// this.setState({
										// 	showPopup: true,
										// 	msgType: 'E',
											// msg: "Something went wrong. Please try again later."
										// });

										//alert(response.data.msg);
									}
								}, (error) => {
									// this.setState({
									// 	showPopup: true,
									// 	msgType: 'E',
									// 	msg: "Something went wrong. Please try again later."
                                    // });
                                    this.togglePopup('Something went wrong. Please try again later.', 'E', 'Error!', true)
                   
									//console.log(error);
								});						
                    }
                     else {
                        this.togglePopup(""+this.state.username + " is not registered.", 'E', 'Error!', true)
                   
                        // this.setState({
                        //     showPopup: true,
                        //     msgType: 'E',
                        //     msg: ""+this.state.username + " is not registered"
                        // });
                    }
                }, 
                (error) => {
                //console.log(error);
                // this.setState({
                //     showPopup: true,
                //     msgType: 'E',
                //     msg: "Something went wrong. Please try again later."
                // });
                this.togglePopup('Something went wrong. Please try again later.', 'E', 'Error!', true)
                   
            });		
					
			
			

        }

    }

    changeHandler = (event) => {
        console.log("inside handleListener : key is ", event.key )
        console.log("inside handleListener : keyCode is ", event.keyCode )
        console.log("input value is ", event.target.value)
        if(event.keyCode === 46){
            console.log("inside changeHandler : ")
            return false
        }

        const userName = event.target.value.trim();
        console.log("username : ", userName)
        console.log("userName.includes(.) : ", userName.includes("."))
        console.log("this.userRegex.test(userName) : ", this.userRegex.test(userName))
        if (!Number(userName) || !this.userRegex.test(userName) || (userName.length >= 10 && this.state.username.length === 10) ) {
            console.log("username inside: ", userName)
            if( userName === ''){
                this.setState({
                    username : ''
                })
            }
            return false;
        }
        console.log("username outside: ", userName)
        this.setState({
            username: userName,
            error: false,
            showCross : userName.length > 0
        })
        
    }

    openEnterOtpPage = (type) => {

        this.props.history.push({
            pathname: FRONTEND_NAME+'/loginotp',
            state: {
                username: this.state.username,
                usertype: type
            }
        });
    }

    openSetPassPage = (type) => {

        this.props.history.push({
            pathname: FRONTEND_NAME+'/setpassword',
            state: {
                username: this.state.username,
                usertype: type
            }
        });
    }
    openEnterPassPage = (type) => {
         ;
        this.props.history.push({
            pathname: FRONTEND_NAME+'/enterpassword',
            state: {
                username: this.state.username,
                usertype: type
            }
        });
    }


    openHomeScreen = () => {
        this.props.history.push({
            pathname: FRONTEND_NAME+'/home',
            state: {
                username: this.state.username
            }
        });
    }

    togglePopup = (msg, msgType, alert, hideBtn) => {
        this.setState({
            showPopup: !this.state.showPopup,
            message: msg,
            msgType: msgType,
            alert: alert,
            hideButton: hideBtn
        })

    }

    back() {
        this.props.history.push(FRONTEND_NAME+"/home");
    }

    render() {

        const { username, message, msgType, alert, showPopup, error, hideButton, showCross } = this.state
        const enabled = username.length === 10
        return (
            <div className="container-login">
                {/* <div className="header-leaderboard edit-pad login-border">
                    <img src={back} alt="" onClick={this.back.bind(this)} className="float-lft"></img>
                </div> */}
                {
                    showPopup ?
                        <Popup text={message} status={msgType} alertType={alert} hideButton={hideButton} closePopup={this.togglePopup, this.openHomeScreen} /> :
                        null
                }
                {this.state.enableLoader ?
                    <Loading />
                    : null
                }
                <p className="loginn">Login</p>
                <p className="msisdn-p">Enter Mobile Number</p>
                <p className="msisdn-sub-p">Mobile Number</p>
                <div className={enabled ? "enter-mm" : "enter-mm-gry"}>
                    {/* <div className="enter-mm"> */}
                    <span>+91 </span>

                    <input id="mobNum" type="number" className={enabled ? "enter-m" : "enter-m-gry"} name="msisdnId" value={username}
                        onChange={this.changeHandler} placeholder="Mobile Number"></input>
                    <span style={{width:'16px'}} onClick={()=>{console.log("abcdef");this.setState({username:"",showCross:false})}}>{showCross && <img src={stop} ></img>}</span>
                </div>
                {
                    error ?
                        <p className="error-txt-red" >Invalid Phone Number*</p> :
                        null
                }
                <div className="button-submit">
                    <button type="submit" className={enabled ? "submit-login" : "submit-login disable-gry"} onClick={this.submitHandler} disabled={!enabled}>PROCEED</button>
                </div>
            </div>
        )
    }
}

export default withRouter(ResetMisisdn);