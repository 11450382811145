import React from 'react'
import '../styles/socialMedia.css';
// import { Twitter, Facebook, Mail, Whatsapp, Google } from "react-social-sharing";
import { EmailShareButton, FacebookShareButton, InstapaperShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton, MailruShareButton, ViberShareButton,
    EmailIcon, FacebookIcon, InstapaperIcon, LinkedinIcon, TwitterIcon, WhatsappIcon, MailruIcon, ViberIcon} from 'react-share'
    import grey from '../images/grey.png'
    import lightGrey from '../images/lightGrey.png'
import axios from 'axios';
import * as Constant from  '../Constants/constants'

class SocialMedia extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			lables : Constant.getLables()
        };
    }

    render() {
			const {lables} = this.state;
           return (
            <div id="myModal" onClick={this.props.closePopup} className="modal-social">

                <div className="modal-content-social">

                    <div className="modal-body-social">

                    <div className="modal-share-content">
					<p className="share-txt">{lables.shareUsing}...</p>
					<div className="first">
						<div className="mode1 left txt-left">
							<WhatsappShareButton url={Constant.APP_URL}>
								<WhatsappIcon />
							</WhatsappShareButton>
						</div>

						<div className="mode1 center-new f-new">
							<FacebookShareButton url={Constant.APP_URL} >
								<FacebookIcon />
							</FacebookShareButton>
						</div>

						<div className="mode1 right">
							<TwitterShareButton url={Constant.APP_URL} >
								<TwitterIcon />
							</TwitterShareButton>
						</div>

					</div>
					<div className="second">
						<div className="mode1 left txt-left">
							<LinkedinShareButton url={Constant.APP_URL}>
								<LinkedinIcon />
							</LinkedinShareButton>
						</div>

						<div className="mode1 center-new f-new">
							<InstapaperShareButton url={Constant.APP_URL} >
								<InstapaperIcon />
							</InstapaperShareButton>
						</div>

						<div className="mode1 right">
							<EmailShareButton url={Constant.APP_URL} >
								<EmailIcon />
							</EmailShareButton>
						</div>
					</div>
					{/* <div className="dots">
						<img src={grey}></img>
						<img src={lightGrey}></img>
					</div> */}
				</div>

                        {/* <div className="social-icons-parent">

                            <div className="social-icons">
                                <div className="icons">
                                    <Twitter link={url} />
                                </div>
                                <div className="icons">
                                    <Facebook link={url} />

                                </div>
                                <div className="icons">
                                    <Whatsapp link={url}  />

                                </div>

                                <div className="icons">
                                    <Google link={url} />

                                </div>
                                <div className="icons">
                                    <Mail link={url}  />

                                </div>



                            </div>

                        </div> */}
                    </div>
                </div>
            </div>






        );
    }
}
export default SocialMedia;