import React, { Component } from 'react';
import { withRouter } from 'react-router';
import '../styles/menu.css';
import axios from 'axios';
import * as constantURL from '../Constants/constants'
import { FRONTEND_NAME } from '../Constants/constants'

class SearchCountry extends Component {
    constructor(props) {
        super(props)

        this.state = {
            countryList: [],
            originalCountryList: []
        }
        this.handleChange = this.handleChange.bind(this);

    }


    componentDidMount() {
        const headers = {
            'Content-type': 'application/json',
            'Accept': 'application/json',
        };

        const url = constantURL.PROFILER2_COMBINED_IP + "/v3/getCountryList";

        axios.get(url, { headers }).then(response => response.data)
            .then((data) => {
                if (data && data.code == 2000) {

                    console.log(data.data)
                    this.setState({ countryList: data.data, originalCountryList: data.data })
                }
            })
            .catch(err => {
            })
    }

    back() {
        this.props.history.push({
            pathname: FRONTEND_NAME + "/loginphase2"
        })
    }

    handleChange(event) {
        console.log(this.state.countryList)
        var filteredList = [];
        {
            this.state.originalCountryList.filter(name => name.displayName.toLowerCase().includes(event.target.value.toLowerCase())
            ).map(filteredName => (
                filteredList.push(filteredName)
            ))
        }

        console.log(filteredList)

        this.setState({ countryList: filteredList });

    }


    chooseCountry(country) {
       this.props.chooseCounry(country);
    }

    render() {

        var countries = [];
        this.state.countryList.forEach(function (list) {
            countries.push(
                <div onClick={() => this.chooseCountry(list)}>
                    <span>{list.displayName}</span>
                    <span>+{list.countryCode}</span>
                </div>
            );
        }.bind(this));

     
        return <div className="coupon-modal" style={{ backgroundColor: 'black' }}>
            <div className="search-field">
                <input type="text" placeholder="Search Country" className="searchcountry" onChange={this.handleChange}></input>
                <span onClick={this.props.cancel}>Cancel</span>
            </div>
            <div className="search-content">
                {countries}
            </div>
        </div>;
    }
}

export default withRouter(SearchCountry)
