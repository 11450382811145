import React from 'react'
import axios from 'axios';
import '../styles/home.css';
import { withRouter } from 'react-router'
import back from '../images/back.png';
import games from '../images/games.svg';
import profileImg from '../images/profileImg.png';
import * as Constant from '../Constants/constants'
import * as ErrorCode from '../Constants/errorConstants'
import Popup from './popup'
import PopupNew from'./popupNew'
import Loading from './loading'
import {FRONTEND_NAME} from '../Constants/constants'

class WinbigGames extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            winbigGameList: [],
            categoryId: (this.props.location.state === undefined ? "" : this.props.location.state.wbGSubCategory),
            showPopup: false,
            message: '',
            alert: '',
            msgType: '',
            redirectScreen: Constant.PAID_GAMES,
            hideButton: false,
            enableLoader: true,
            displayButtons: [],
            currentSelectedGame: {}

        }
        console.log(this.state.winbigGameList)
    }

    subsHeaders = {
        'appKey' : Constant.APP_KEY_SUBS,
        'operator': Constant.OPERATOR,
        'Msisdn': sessionStorage.getItem("username"),
        'channel':Constant.CHANNEL,
        'Access_token' : sessionStorage.getItem("accessToken"),
        'deviceId': Constant.DEVICE_ID,
        'Content-Type' : Constant.APPLICATION_JSON,
        'Accept' : Constant.APPLICATION_JSON
    }

    componentDidMount() {

                console.log("Calling WINBIG GAMES API from componentDidMount with categoryId received as ", this.state.categoryId)
                const gameCatID = (this.state.categoryId === "" || this.state.categoryId === null) ?
                                  Constant.WINBIG_ID :
                                  this.state.categoryId

                const url = `${Constant.CONTENT_COMBINED_IP}${Constant.FETCH_VIEW_ALL_API_PARAM}${gameCatID}`
                console.log("url to hit is : ", url)

                const headers = {
                    'Content-Type': Constant.APPLICATION_JSON,
                    'accept': Constant.APPLICATION_JSON,
                    'appid': Constant.APP_ID,
                    'deviceId': Constant.DEVICE_ID,
                    'operator': Constant.MOB_OPERATOR,
                    'Access_token': sessionStorage.getItem("accessToken"),
                    'channel': Constant.CHANNEL,
                    'Msisdn': sessionStorage.getItem("username")
                };

                axios.get(url, { headers })
                    .then(response => response.data)
                    .then(data => {
                        if (data && data.code === ErrorCode.SUCCESS_CODE) {
                            console.log('Successfully recieved  View All success')
                            console.log('data is ', data.data.content)
                            if(data.data.content){
                                this.setState({
                                    winbigGameList: data.data.content
                                })
                            } else{
                                console.log('There is Error in fetching View All Content for WinBig games')
                            
                            this.togglePopup(Constant.ERROR_MESSAGE, 'E', Constant.ERROR_ALERT, ['1'])
                            }
                           

                        } else {
                            console.log('There is Error in fetching View All Content for WinBig games')
                            // this.setState({
                            //     redirectScreen: Constant.PAID_GAMES
                            // })
                            this.togglePopup(Constant.ERROR_MESSAGE, 'E', Constant.ERROR_ALERT, ['1'])
                        }
                        this.setState({ enableLoader: false })

                    }).catch( error => {
                        console.log('There is Error in fetching View All Content for Instant games : ', error)
                        // this.setState({
                        //     redirectScreen: Constant.PAID_GAMES
                        // })
                        this.togglePopup(Constant.ERROR_MESSAGE, 'E', Constant.ERROR_ALERT,['1'])
                })
        //}
    }

    // redirectToRequiredGame = (gameToPlay) => {

    //     console.log("inside redirectToRequiredGame : ", gameToPlay.gameurl)
    //     const isLogin = sessionStorage.getItem("isLogin");
    //     if (isLogin) {
    //         console.log('User is logged In, rediret to selected game ')
    //         window.open(gameToPlay.gameurl, "_blank")
    //     }
    //     else {
    //         console.log('User is NOT logged In, showing popup to login')
    //         this.setState({
    //             redirectScreen: Constant.LOGIN_SCREEN_TITLE
    //         })
    //         this.togglePopup(Constant.LOGIN_REQUIRED_MESSAGE, 'A', Constant.LOGIN_REQUIRED_ALERT)
    //         //If YES, want to login then redirect to login page and after successful login, redirect to respective Game Page
    //     }
    // }

    // togglePopup = (message, msgType, alert) => {
    //     this.setState({
    //         showPopup: !this.state.showPopup,
    //         message: message,
    //         msgType: msgType,
    //         alert: alert
    //     })
    // }

    deductCoins = (data1) => {
        
        const headers = {
            'Content-Type': 'application/json',
            'appkey': Constant.APPKEY,
            'operator': Constant.OPERATOR,
            'msisdn': sessionStorage.getItem("username")
        };

        const body = {
            "rewardName": "coin",
            "rewardFrom": "tambola_win",
            "operation": "debit",
            "rewardCount": data1.coinsRequired.split(' ')[0],
            "info": data1.title,
            "operationSource": "WAP"

        }
        this.setState({enableLoader:true})

        axios.post(Constant.SPS_COMBINED_IP + "/v1/userOperation", body, { headers }).then(response => response.data)
            .then((data) => {
                if (data && (data.code == ErrorCode.SUCCESS_CODE)) {
                    this.setState({
                        showPopup: false,
                    });
                    console.log(data1)
                    window.open(data1.gameurl, "_blank")
                } else if (data.code == 2004) {
                    this.setState({
                        showPopup: true,
                        msgType: 'E',
                        msg: "Insufficient coins!"
                    });
                }
                this.setState({enableLoader:false})

            });
    }

    openRequiredGame = (gameToPlay) => {
        console.log("inside openRequiredGame method ")
        this.props.history.push({
            pathname: FRONTEND_NAME+'/gameview',
            state: {
                game: gameToPlay,
                gameScreen : 'winbiggames'
            }
            })
    }

    checkUserSubscription = (gameToPlay, headers) => {

            console.log("current game is : ", this.state.currentSelectedGame)
            const url =`${Constant.SPS_COMBINED_IP}${Constant.CHECK_SUBS_API_PARAM}${gameToPlay.checkSubKeyword}` 
            console.log('Url to hit for checksubs : ', url)

            axios.get(url, {headers})
                .then(response => response.data)
                .then(data => {
                    if(data && data.code === ErrorCode.SUCCESS_CODE ){
                        console.log(data.data)
                        if(data.data.subscriberStausCode == "0" || data.data.subscriberStatus === Constant.ACTIVE){
                            console.log("User is Subscribed for selected Game, No need for confirmartion, redirecting to game directly")
                            //window.open(gameToPlay.gameurl, "_blank")
                            this.openRequiredGame(gameToPlay);
                        }else if(data.data.subscriberStausCode == "1" || data.data.subscriberStatus === Constant.NOT_SUBSCRIBED){
                            console.log("User is NOT Subscribed  for selected Game, taking user confirmartion using popup ")
                            console.log('If Confirmation is Yes, then call charginKeywordSub API for payment and then redirect to game')
                            this.togglePopup(Constant.CONFIRM_PAYMENT_MESSAGE.replace("<xx>", gameToPlay.chargingvalue), "A", Constant.CONFIRM_PAYMENT_ALERT, ['3','4'])
                        }else{
                            console.log("User status is pending, showing pending popup for other response code")
                            this.togglePopup(Constant.PENDING_SUBSCRIPTION_MESSAGE, "A", Constant.PENDING_SUBSCRIPTION_ALERT, ['1'])
                        }

                    }else {
                            console.log('Some failure response : ', data);
                            this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
                        }
                })
                .catch(error => {
                    console.log("Error Occured : ", error)
                    this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
                })

        }

    checkSubscriptionAndMakePayment = (gameToPlay) => {

            this.checkUserSubscription(gameToPlay, this.subsHeaders)
        }

    submitPaymentSubscription = () => {

            const url =`${Constant.SPS_COMBINED_IP}${Constant.SUBS_PACK_API_PARAM}${this.state.currentSelectedGame.chargingKeyword}` 
            console.log('Url to hit for chargingKeyword payment : ', url)

            const headers = this.subsHeaders;

            axios.get(url, {headers})
                .then(response => response.data)
                .then(data => {
                    if(data && data.code === ErrorCode.SUCCESS_CODE ){
                        console.log(data.data)
                        if(data.data.subscriptionStatusCode === 0 || data.data.subscribtionStatus === Constant.ACTIVE){
                            console.log("User has successfully made payment for selected Game, showing con gratulation popup "
                                         +" and then redirecting to game directly")

                            this.togglePopup(Constant.PAYMENT_SUCCESSFUL_MESSAGE, "S", Constant.PAYMENT_SUCCESSFUL_ALERT, ['1']);
                            
                        }else{
                            console.log("User status is pending, showing pending popup for other response code")
                            this.togglePopup(Constant.PAYMENT_UNSUCCESSFUL_MESSAGE, "E", Constant.PAYMENT_UNSUCCESSFUL_ALERT, ['1'])
                        }

                    }else {
                            console.log('Some failure response : ', data);
                            this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
                        }
                })
                .catch(error => {
                    console.log("Error Occured : ", error)
                    this.togglePopup(Constant.ERROR_OCCURED, 'E', Constant.ERROR_TYPE_ALERT, ['1'])
                })

        }

    redirectToRequiredGame = (gameToPlay) => {

            //Current game is set ti state variable because we need it when payment popup gives confirmation.
            //At that time it cannot be passed through a method
            this.setState({
                currentSelectedGame : gameToPlay
            })
            console.log("inside redirectToRequiredGame : ", gameToPlay.gameurl)
            const isLogin = sessionStorage.getItem("isLogin");
            if (isLogin) {
                console.log('User is logged In, checking game type to make decision. Game type is ', gameToPlay.gametype)

                if(gameToPlay.gametype.toUpperCase() === Constant.GAME_TYPE_SUB || 
                     gameToPlay.gametype.toUpperCase() === Constant.GAME_TYPE_ONE_SUB){
                        this.checkSubscriptionAndMakePayment(gameToPlay)
                    }
                else if(gameToPlay.gametype.toUpperCase() === Constant.GAME_TYPE_PPP || 
                     gameToPlay.gametype.toUpperCase() === Constant.GAME_TYPE_ONE97PPP ){
                    //After confirming from popup, this.submitPaymentSubscription() will be called.
                    this.togglePopup(Constant.CONFIRM_PAYMENT_MESSAGE.replace("<xx>", gameToPlay.chargingvalue), "A", Constant.CONFIRM_PAYMENT_ALERT, ['3','4'])
                    
                }
                else if(gameToPlay.gametype.toUpperCase() === Constant.GAME_TYPE_COIN){
                    console.log('Since game type is COIN GAME so calling API to deduct User Coins' )
                    this.deductCoins(gameToPlay)
                }
                else if(gameToPlay.gametype.toUpperCase() === Constant.GAME_TYPE_FREE || 
                        gameToPlay.chargingvalue === "" || gameToPlay.chargingvalue === null){
                    console.log('Since game type is FREE GAME so redirecting user to selected game screen' )
                    //window.open(gameToPlay.gameurl, "_blank")
                    this.openRequiredGame(gameToPlay)
                }
                
            }
            else {
                console.log('User is NOT logged In, showing popup to login')
                this.setState({
                    redirectScreen: Constant.LOGIN_SCREEN_TITLE
                })
                this.togglePopup(Constant.LOGIN_REQUIRED_MESSAGE, 'A', Constant.LOGIN_REQUIRED_ALERT,["1","2"])
                //If YES, want to login then redirect to login page and after successful login, redirect to respective Game Page
            }
        }

    togglePopup = (message, msgType, alert, buttonArray) => {
        this.setState({
            showPopup: !this.state.showPopup,
            message: message,
            msgType: msgType,
            alert: alert,
            displayButtons: buttonArray
        })
        }


    openLoginScreen = () => {
        this.props.history.push({
            pathname: FRONTEND_NAME+'/login',
            state: {

            }
        })
        }

    openHomeScreen = () => {
        this.props.history.push({
            pathname: FRONTEND_NAME+'/home',
            state: {

            }
        })
        }

    back() {
        this.props.history.push(FRONTEND_NAME+"/paidgames");
    }

    performTaskOnClose = (flag) => {
        this.togglePopup()
        console.log("inside performTaskOnClose method, flag is ", flag)
        if(flag === 'confirm'){
            this.submitPaymentSubscription();
        }if(flag === 'ok-'+Constant.LOGIN_REQUIRED_MESSAGE){
            this.props.history.push(FRONTEND_NAME+"/login")
        }if(flag === 'ok-'+Constant.PAYMENT_SUCCESSFUL_MESSAGE){
           // window.open(this.state.currentSelectedGame.gameurl, "_blank")
           this.openRequiredGame(this.state.currentSelectedGame)
        }if(flag === 'ok-'+Constant.ERROR_MESSAGE){
            this.props.history.push(FRONTEND_NAME+"/paidgames")
        }
    }

    render() {

        const { winbigGameList, showPopup, message, msgType, alert, displayButtons } = this.state
        //const prizeKey = 'Win 32” TV'
        const gameDisplayList = winbigGameList.map(eachGame =>
            <div className="Coin-games" onClick={() => this.redirectToRequiredGame(eachGame)} key={eachGame.title}>
                <div className="gamess">
                    <img src={eachGame.previewImageUrl} ></img>
                    <div className="for-line line-s">
                        <div className="coin-p">
                            <p className="coin-g-a">{eachGame.title}</p>
                            <p className="coin-g-b ">{eachGame.noOfUsersDynamic} played</p>
                            <p className="coin-g-w">{eachGame.chargingcurrency + " " + eachGame.chargingvalue}</p>
                        </div>
                        <div className="coin-amt">
                            <p>{eachGame.chargingcurrency + " " + eachGame.chargingvalue}</p>
                        </div>
                    </div>
                </div>

            </div>
        );

        return (

            <div className="container-leaderboard">
                {
                    // showPopup ?
                    //     (this.state.redirectScreen === Constant.LOGIN_SCREEN_TITLE ?
                    //         <Popup text={message} status={msgType} alertType={alert} hideButton={hideButton}
                    //             closePopupNotNow={this.togglePopup} closePopup={this.togglePopup, this.openLoginScreen} /> :
                    //         <Popup text={message} status={msgType} alertType={alert} hideButton={hideButton}
                    //             closePopupNotNow={this.togglePopup} closePopup={this.togglePopup, this.openHomeScreen} />
                    //     ) :
                    //     null

                    showPopup ?
                    <PopupNew text={message} status={msgType} alertType={alert} displayButtons={displayButtons}
                        closePopupWithTask={this.performTaskOnClose} closePopup={this.togglePopup } /> :
                null
                }
                {this.state.enableLoader ?
                    <Loading />
                    : null
                }

                <div className="header-leaderboard">
                    <img src={back} onClick={this.back.bind(this)} className="float-lft"></img>
                    <span className="header-text float-lft">WIN BIG</span>
                </div>
                <div className="content-leaderboard for-popular">
                    <div className="popular-games-d" >
                        {
                            (gameDisplayList && gameDisplayList.length > 0) ?
                                gameDisplayList :
                                null
                        }   
                            
                    </div>
               </div>
            </div>

        )
    }
}

export default withRouter(WinbigGames);