import React from 'react'
import axios from 'axios';
import '../styles/home.css';
import { withRouter } from 'react-router'
import back from '../images/backNew.svg';
import profileImage from '../images/profileImage.png';
import viewAll from '../images/viewAll.png';
import plusPlay from '../images/plusPlay.png'
import minusPlay from '../images/minusPlay.png'
import * as constantURL from '../Constants/constants'
import * as errorConstantURL from '../Constants/errorConstants'
import Iframe from 'react-iframe'
import $ from "jquery";
import Popup from './popup';
import Cookies from 'universal-cookie';
import Loading from "./loading";


class GameView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            gameScreen: this.props.location.state.gameScreen,
            replaceParam: this.props.location.state.game.replacedynamicparam,
            orientations:'portrait',
            lables : constantURL.getLables(),
            enableLoader: true
     
        }
    }

    componentDidMount(){
        // var doc = window.document;
        // document.body.requestFullscreen();
        // window.scrollTo(0, 0)
        this.saveGameLogs(constantURL.GAME_ACTION);
        this.incrementTotalCount();  
        
        if (window.matchMedia("(orientation: portrait)").matches) {
            this.setState({orientations:'portrait'});
        }else{
            this.setState({orientations:'landscape'});
        }


     
        // if(window.innerHeight > window.innerWidth){
        //     this.setState({orientations:'portrait'});
        // }else{
        //     alert('Landscape Mode');
        //     this.setState({orientations:'landscape'});
        // }
    }

    


    saveGameLogs(category) {
        const cookies = new Cookies();
        var url = constantURL.APPLOGGER_COMBINED_IP + "/v1/saveDownloadLogs";
        
        const headers = {
            'Content-Type': 'application/json',
            'appid': constantURL.APP_ID,
            'operator': constantURL.OPERATOR,
            'msisdn': sessionStorage.getItem("username"),
            'channel': 'WAP'
        };

        const requestBody = {
            "content_name" : this.props.location.state.game.title,
            "session_id" : cookies.get('uniquevhgsession'),
            "country" : "India",
            "circle" : "All", 
            "packname" :this.props.location.state.game.chargingKeyword,
            "appname" :constantURL.APPLICATION_NAME,
            "content_type" : category,
            "param1" : this.props.location.state.game.gametype,
            "param2" : "",
            "substatus" : '',
            "winningStatus" : '',
            "gameStatus": "SUCCESS",
            "response" : "Success",
            "dwn_flag" : "0"
        }

        axios.post(url, requestBody, { headers })
            .then(response => response.data)
            .then(data => {
                if (data && data.code == errorConstantURL.SUCCESS_CODE) {
                } else {
                }
            });
    }

    incrementTotalCount(){
        if(this.props.location.state.game.contentId){
        axios.get(constantURL.TOTAL_COUNT_URL+this.props.location.state.game.contentName)
            .then(response => response.data)
            .then(data => {
               console.log(data)
            });
        }
    }
    


    back() {
        // this.props.history.push(FRONTEND_NAME+"/home");
        this.setState({showPopup:true})
    }

    backToHome(){
        this.props.history.push(constantURL.FRONTEND_NAME+"/home");
    }
    backToRequiredScreen() {
         ;
        var stateObject = {
            detail:this.props.location.state.detail,
            
        }
        if(this.state.gameScreen=="paidgames"){
            stateObject.currentCategory = this.props.location.state.currentCategory;
            stateObject.from = this.props.location.state.from;
        }

        this.props.history.push({
            pathname: constantURL.FRONTEND_NAME+'/'+ this.state.gameScreen,
            state: stateObject
        })
    }

    togglePopup(){
        this.setState({showPopup:false})
    }

    createFinalUrl(paramArray, url){

        console.log("url before :: ", url)
        paramArray.map(param => {
            if(param === constantURL.MSISDN_NAME ){
                url = url.replace("=MSISDN", "="+sessionStorage.getItem("username"))
                url = url.replace("=msisdn", "="+sessionStorage.getItem("username"))
            }
            if(param === constantURL.ACCESS_TOKEN_NAME){
                url = url.replace("=accesstoken", "="+sessionStorage.getItem("accessToken"))
                url = url.replace("=accessToken", "=" + sessionStorage.getItem("accessToken"))
            }
            if(param === constantURL.CHANNEL_NAME){
                url = url.replace("=channel", "="+constantURL.CHANNEL)
            }
            if(param === constantURL.DEVICE_ID_NAME){
                url = url.replace("=deviceid", "="+constantURL.DEVICE_ID)
                url = url.replace("=deviceId", "=" + constantURL.DEVICE_ID)
           
            }
        })
        console.log("url after :: ", url)

        return url;
    }


    render() {
        console.log('=============')

        const { replaceParam } = this.state
        var name = this.props.location.state.game.title;
        var url = this.props.location.state.game.gameurl;

        console.log("game name : ", name)
        console.log("game url : ", url)

        console.log("replaceParam is  ", replaceParam)
        // if(replaceParam){
        if( replaceParam != "" && replaceParam != undefined ){
            const paramArray = replaceParam.split(" | ");
            console.log("paramArray : ", paramArray)

            url = this.createFinalUrl(paramArray, url);
            
        }
        console.log("After return, url : ", url)
        url =url.replace("http://220.226.188.60",constantURL.APPLICATIONS_IP_ADRESS);
        url= url.replace('<msisdn>',sessionStorage.getItem("username"));
        //url = "https://h5.gamepind.com/g/MSM/index.html"
        console.log(url)
        const {lables} = this.state;
        return (

            <div className="container-home"  >
                {this.state.enableLoader ? <Loading /> : null}
                {this.state.showPopup ?
                    <Popup
                        status='A'
                        text={lables.areYouSureToExitGame}
                        alertType={lables.exitGame}
                        buttonName={lables.yes}
                        closePopup={this.backToRequiredScreen.bind(this)}
                        closePopupNotNow={this.togglePopup.bind(this)}
                        cancelText={lables.notNow}
                        isConfirm='Y'
                        orientations={this.state.orientations}
                    /> : null}
                
                {
                    (url.includes("winbigcricket") || url.includes("winbigfootball")) ?
                        null : 
                    <div className="header-leaderboard margin-game-left">
                        <img src={back} onClick={this.back.bind(this)} className="float-lft"></img>
                        <span className="header-text-game float-lft">{name}</span>
                    </div>
                }

                <div className="content-game"  >
                    {/* <object type="text/html" id="spinn" data={url}
                        style={{ width: '100%', height: '590px' }} />
                    {/*     
                     */}
                    <Iframe url={url}
                    
                        width="100%"
                        height="100%"
                        id="gameview"
                        className="gameview"
                        display="initial"
                        position="relative" 
                        top= "56px !important"
                        onLoad={() => {this.setState({enableLoader: false})}}
                        /> 
                </div>

            </div>
        )
    }
}

export default withRouter(GameView);